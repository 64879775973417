<template>
  <Popup
    v-model:show="showDebugPanel"
    position="bottom"
    :style="{ height: '40%' }"
  >
    <div class="debug-panel">
      <h1 style="margin: 10px">Debug Panel</h1>
      <Field label="Api Env">
        <template #input>
          <RadioGroup
            :model-value="apiEnv"
            @update:model-value="onApiEnvChange"
            direction="horizontal"
          >
            <Radio name="staging">Staging</Radio>
            <Radio name="prod">Prod</Radio>
          </RadioGroup>
        </template>
      </Field>
      <Field label="Version">
        <template #input>
          {{ commit }}
        </template>
      </Field>
      <Field label="环境">
        <template #input>
          微信:{{ isWx }} QQ:{{ isQQ }}
        </template>
      </Field>
    </div>
  </Popup>
</template>

<script setup lang="ts">
import { Popup } from "vant"
import { RadioGroup, Radio } from "vant"
import { Field } from "vant"
import { ref } from "vue"
import { ApiEnv } from "./init"
import { onMultipleClick } from "~/utils"

const showDebugPanel = ref(false)
const apiEnv = ref(_global.apiEnv)
const commit = ref(_global.gitCommit)
const isWx = ref(_global.isWx)
const isQQ = ref(_global.isQQ)

// PC 可以使用 f1
window.addEventListener("keydown", function (evt) {
  if (evt.code === "F1") {
    showDebugPanel.value = true
  }
})
onMultipleClick(3, function () {
  showDebugPanel.value = true
})

function onApiEnvChange(newEnv: ApiEnv) {
  _db.value.debug.apiEnv = newEnv
  location.reload()
}
</script>

<style lang="less">
.debug-panel {
  padding: 20px;
}
</style>

import { avatars } from "./assets/avatars"
import { random } from "lodash"
import axios from "axios"
import db from "~/db"
import { parse } from "lossless-json"

const baseURL = _global.apiURL

export function onMultipleClick(
  times: number,
  cb: () => void,
  dom: HTMLElement = document.body
) {
  let count: number = 0
  let lastTs: number = 0

  dom.addEventListener("click", function () {
    if (lastTs !== 0) {
      if (Date.now() - lastTs > 200) {
        count = 1
        lastTs = Date.now()
        return
      }
    }

    count++
    lastTs = Date.now()

    if (count === times) {
      cb()
      count = 0
      lastTs = 0
    }
  })
}

function reviver(key: any, value: any) {
  if (value && value.isLosslessNumber) {
    const parsed = Number(value.value)

    if (Number.isSafeInteger(parsed)) {
      return parsed
    }

    return value.value
  } else {
    return value
  }
}
export function tryParseLosslessJSON(str: string, dft: any = null): any {
  try {
    return parse(str, reviver)
  } catch (err) {
    return dft
  }
}

export function tryParseJSON(str: string, dft: any = null): any {
  try {
    return JSON.parse(str)
  } catch (err) {
    return dft
  }
}

export const fenToYuan = (fen: number | undefined, digits = 2) =>
  fen ? (fen / 100).toFixed(digits) : "0.00"

export const yuanToFen = (yuan: number) => yuan * 100

export const getRandomAvatar = () =>
  "https://cdn.huajiapp.com/library_book_report/avatars/20210616_images/" +
  avatars[random(avatars.length)]

export const isMobileSystem = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export const stripEmoji = (str: string | undefined) =>
  str
    ? str.replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ""
      )
    : ""

const YING_YONG_BAO =
  "https://a.app.qq.com/o/simple.jsp?pkgname=ai.qianmo.huajiapp"
export const toApp = (path: string) => {
  // pc
  if (screen.width > 500) {
    location.href = YING_YONG_BAO
    return
  }

  if (isAndroid() || isIos()) {
    const $a = document.createElement(`a`)
    $a.href = path
    $a.click()
  }

  location.href = YING_YONG_BAO
}

const filledInt = (length: number, n: number): number => {
  try {
    return parseInt(Array.from({ length }, () => n).join("")) ?? 0
  } catch (e) {
    return 0
  }
}

const kBookKey = 599387

export const hEncrypt = (plainText: number, key: number): number => {
  const v = (plainText * plainText) ^ key
  const numOfDigits = v.toString().length - 1
  const lastNum = v % 10
  const offset = filledInt(numOfDigits, lastNum) * 10
  return v + offset
}

export const hDecrypt = (input: number, key: number) => {
  const numOfDigits = input.toString().length - 1
  const lastNum = input % 10

  let offset = filledInt(numOfDigits, lastNum) * 10
  if (offset > input) {
    offset = filledInt(numOfDigits - 1, lastNum) * 10
  }
  const v = input - offset
  return Math.trunc(Math.sqrt(v ^ key))
}

export const hEncryptBookId = (originalBookId: number) =>
  `BK${hEncrypt(originalBookId, kBookKey)}`

export const hDecryptBookId = (bookId: string) => {
  // HUA-5542 这是为了兼容 query 不存在的情况，否则使用层需要每个地方处理
  if (bookId == null) return null

  if (bookId.startsWith("BK")) {
    return hDecrypt(parseInt(bookId.replace("BK", "")), kBookKey)
  } else {
    return bookId
  }
}

export const parseUserId = async (uid: string) => {
  // HUA-5542 这是为了兼容 query 不存在的情况
  if (uid == null) return null

  if (uid.startsWith("h") || uid.startsWith("H")) {
    const { data } = await axios.get(`${baseURL}/user/v1/basic_info`, {
      params: {
        hua_id: uid.slice(1),
      },
    })
    return data.user_id
  }

  return uid
}

const BAQ_ASSET_ID = "1770368460651533"

export const baqTrack = (event: string, data = {}) => {
  _baq.track(event, {
    assets_id: BAQ_ASSET_ID,
    ...data,
  })
}

export const getTxcParams = () => {
  const userInfo = db.value.userInfo
  return {
    path: "/txc/321510",
    query: {
      nickname: userInfo?.nickname,
      avatar: userInfo?.image_url,
      openid: userInfo?.phone_number_e164?.replace("+", ""),
      customInfo: JSON.stringify({
        uid: userInfo?.user_id,
        phone: userInfo?.phone_number_e164,
        source: "wap",
        env: location.origin,
      }),
    },
  }
}

export const DAY = 3600 * 24
export const HOUR = 3600
export const MIN = 60
export const YEAR = 365 * DAY

export const secToDay = (secs: number) => {
  if (secs < HOUR) {
    return `${Math.ceil(secs / MIN)} 分钟`
  }
  if (secs < DAY) {
    return `${Math.ceil(secs / HOUR)} 小时`
  }

  if (secs > YEAR) {
    return `永久`
  }

  return `${Math.ceil(secs / DAY)} 天`
}

const userAgent = navigator.userAgent.toLowerCase()

export function isAndroid() {
  return /android/.test(userAgent)
}

export function isIos() {
  const matchIpad = userAgent.match(/ipad.+?os (\d+)/)
  const matchIphone = userAgent.match(/iphone(?:.+?os (\d+))?/)

  return matchIpad != null || matchIphone != null
}

export function isChrome() {
  return userAgent.includes("safari") && userAgent.includes("chrome")
}

export function isSafari() {
  return userAgent.includes("safari") && !userAgent.includes("chrome")
}

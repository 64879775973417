/* tslint:disable */
/* eslint-disable */
/**
 * Qianmo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Abtest202401
 */
export interface Abtest202401 {
    /**
     * 
     * @type {string}
     * @memberof Abtest202401
     */
    sku_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Abtest202401
     */
    bottom_text?: string;
    /**
     * 
     * @type {string}
     * @memberof Abtest202401
     */
    condition_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Abtest202401
     */
    countdown?: number;
}
/**
 * 成就列表
 * @export
 * @interface AchievementVO
 */
export interface AchievementVO {
    /**
     * 
     * @type {Array<object>}
     * @memberof AchievementVO
     */
    results?: Array<object>;
}
/**
 * 
 * @export
 * @interface ActivateInviteCodeRequest
 */
export interface ActivateInviteCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivateInviteCodeRequest
     */
    invite_code: string;
}
/**
 * 
 * @export
 * @interface AddExtraCardsTodayRequest
 */
export interface AddExtraCardsTodayRequest {
    /**
     * 根deck id
     * @type {number}
     * @memberof AddExtraCardsTodayRequest
     */
    deck_id?: number;
    /**
     * 子deck id，可以是任意章节，如果为null，则学习整本书
     * @type {number}
     * @memberof AddExtraCardsTodayRequest
     */
    sub_deck_id?: number;
    /**
     * 增加的卡片数，请按推荐值来请求
     * @type {number}
     * @memberof AddExtraCardsTodayRequest
     */
    extra_cards_count?: number;
}
/**
 * 
 * @export
 * @interface AdvertisingResponse
 */
export interface AdvertisingResponse {
    /**
     * 
     * @type {string}
     * @memberof AdvertisingResponse
     */
    item_key?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingResponse
     */
    img_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisingResponse
     */
    event_link?: string;
    /**
     * browser表示使用浏览器打开 ,webview表示  内嵌webview打开（默认值为 webview））
     * @type {string}
     * @memberof AdvertisingResponse
     */
    open_type?: string;
    /**
     * 广告项目所属广告组
     * @type {string}
     * @memberof AdvertisingResponse
     */
    group_key?: string;
    /**
     * 广告项目所属广告位
     * @type {string}
     * @memberof AdvertisingResponse
     */
    position_key?: string;
}
/**
 * 
 * @export
 * @interface AnkiCardStatusVO
 */
export interface AnkiCardStatusVO {
    /**
     * 
     * @type {number}
     * @memberof AnkiCardStatusVO
     */
    card_id?: number;
    /**
     * 当前卡片的状态
     * @type {string}
     * @memberof AnkiCardStatusVO
     */
    card_type?: AnkiCardStatusVOCardTypeEnum;
    /**
     * 
     * @type {AnkiCardStatusVONextStudyInterval}
     * @memberof AnkiCardStatusVO
     */
    next_study_interval?: AnkiCardStatusVONextStudyInterval;
}

/**
    * @export
    * @enum {string}
    */
export enum AnkiCardStatusVOCardTypeEnum {
    New = 'NEW',
    Learn = 'LEARN',
    Review = 'REVIEW'
}

/**
 * 点击对应按钮时卡片下次学习的间隔（单位为秒）
 * @export
 * @interface AnkiCardStatusVONextStudyInterval
 */
export interface AnkiCardStatusVONextStudyInterval {
    /**
     * 
     * @type {number}
     * @memberof AnkiCardStatusVONextStudyInterval
     */
    again?: number;
    /**
     * 
     * @type {number}
     * @memberof AnkiCardStatusVONextStudyInterval
     */
    hard?: number;
    /**
     * 
     * @type {number}
     * @memberof AnkiCardStatusVONextStudyInterval
     */
    good?: number;
    /**
     * 
     * @type {number}
     * @memberof AnkiCardStatusVONextStudyInterval
     */
    easy?: number;
}
/**
 * 
 * @export
 * @interface AnkiCardVo
 */
export interface AnkiCardVo {
    /**
     * 
     * @type {number}
     * @memberof AnkiCardVo
     */
    id: number;
    /**
     * 
     * @type {AnkiNoteVo}
     * @memberof AnkiCardVo
     */
    note?: AnkiNoteVo;
    /**
     * 
     * @type {number}
     * @memberof AnkiCardVo
     */
    ankiNoteId: number;
    /**
     * v2版本的noteId，如果当前版本是v1版本的则为null
     * @type {string}
     * @memberof AnkiCardVo
     */
    ankiNoteIdV2?: string;
    /**
     * 
     * @type {number}
     * @memberof AnkiCardVo
     */
    ankiDeckId: number;
    /**
     * 
     * @type {number}
     * @memberof AnkiCardVo
     */
    ankiTemplateId: number;
    /**
     * 所属model为cloze时，该字段才会出现
     * @type {number}
     * @memberof AnkiCardVo
     */
    clozeOrd?: number;
    /**
     * card在package内部的全局排序，决定了整本书的学习顺序。注意，当deck序发生变化时，也要修改card序
     * @type {number}
     * @memberof AnkiCardVo
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface AnkiDeckVo
 */
export interface AnkiDeckVo {
    /**
     * 
     * @type {number}
     * @memberof AnkiDeckVo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiDeckVo
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnkiDeckVo
     */
    isRoot?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnkiDeckVo
     */
    parentAnkiDeckId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnkiDeckVo
     */
    cardIdList?: Array<number>;
    /**
     * 如果是v2版本的卡片，这个数组不会为空，元素为v2版本的id
     * @type {Array<string>}
     * @memberof AnkiDeckVo
     */
    cardIdV2List?: Array<string>;
    /**
     * deck排序，只会影响同级之间的排序，用于展示章节顺序。注意，修改排序后，请将deck下面的card的全局顺序也一并修改（card序与deck序保持一致）。
     * @type {number}
     * @memberof AnkiDeckVo
     */
    order?: number;
}
/**
 * 一个media文件的信息
 * @export
 * @interface AnkiMediaFileInfo
 */
export interface AnkiMediaFileInfo {
    /**
     * 
     * @type {number}
     * @memberof AnkiMediaFileInfo
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiMediaFileInfo
     */
    filename: string;
}
/**
 * 
 * @export
 * @interface AnkiMediaInfo
 */
export interface AnkiMediaInfo {
    /**
     * 
     * @type {string}
     * @memberof AnkiMediaInfo
     */
    file_name?: string;
    /**
     * 
     * @type {number}
     * @memberof AnkiMediaInfo
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiMediaInfo
     */
    sha1_sum?: string;
}
/**
 * 
 * @export
 * @interface AnkiMediaList
 */
export interface AnkiMediaList {
    /**
     * 
     * @type {Array<AnkiMediaInfo>}
     * @memberof AnkiMediaList
     */
    files?: Array<AnkiMediaInfo>;
}
/**
 * 卡册静态资源的映射信息
 * @export
 * @interface AnkiMediaMappingResponse
 */
export interface AnkiMediaMappingResponse {
    /**
     * 
     * @type {string}
     * @memberof AnkiMediaMappingResponse
     */
    base_url: string;
}
/**
 * 滑记2.0model
 * @export
 * @interface AnkiModelV2Vo
 */
export interface AnkiModelV2Vo {
    /**
     * 
     * @type {string}
     * @memberof AnkiModelV2Vo
     */
    id?: string;
    /**
     * 若不是则为null
     * @type {string}
     * @memberof AnkiModelV2Vo
     */
    shared_model_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AnkiModelV2Vo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AnkiModelV2Vo
     */
    css?: string;
    /**
     * 
     * @type {number}
     * @memberof AnkiModelV2Vo
     */
    sort_field_index?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiModelV2Vo
     */
    model_type?: string;
    /**
     * 
     * @type {Array<ModelFieldVo>}
     * @memberof AnkiModelV2Vo
     */
    fields?: Array<ModelFieldVo>;
    /**
     * 只保留 name card_front_format card_back_format
     * @type {Array<AnkiTemplateVo>}
     * @memberof AnkiModelV2Vo
     */
    templates?: Array<AnkiTemplateVo>;
}
/**
 * 对于model如果是官方model可以修改样式，不能删除，不能引用别的model；对于没有引用官方model的普通model，可以修改样式，不能添加引用；对于引用官方model的model可以切换不同的官方模板引用，但是不能修改。违背上述条件会报400
 * @export
 * @interface AnkiModelVo
 */
export interface AnkiModelVo {
    /**
     * 
     * @type {number}
     * @memberof AnkiModelVo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiModelVo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AnkiModelVo
     */
    css: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnkiModelVo
     */
    fields: Array<string>;
    /**
     * 引用的官方model或者其他作者moedl的id
     * @type {number}
     * @memberof AnkiModelVo
     */
    ref_anki_model_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiModelVo
     */
    type: AnkiModelVoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AnkiModelVoTypeEnum {
    Standard = 'standard',
    Cloze = 'cloze'
}

/**
 * 
 * @export
 * @interface AnkiNoteVo
 */
export interface AnkiNoteVo {
    /**
     * 填充的是modelId原始值
     * @type {string}
     * @memberof AnkiNoteVo
     */
    anki_model_id_v2?: string;
    /**
     * 填充的是id_v2的hash值
     * @type {number}
     * @memberof AnkiNoteVo
     */
    anki_model_id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnkiNoteVo
     */
    field_values?: Array<string>;
    /**
     * 新滑记传入的是id_v2的hash值
     * @type {number}
     * @memberof AnkiNoteVo
     */
    id?: number;
    /**
     * v2版本id
     * @type {string}
     * @memberof AnkiNoteVo
     */
    id_v2?: string;
    /**
     * 
     * @type {string}
     * @memberof AnkiNoteVo
     */
    card_id?: string;
}
/**
 * ankiPackage返回体
 * @export
 * @interface AnkiPackageResponse
 */
export interface AnkiPackageResponse {
    /**
     * 
     * @type {number}
     * @memberof AnkiPackageResponse
     */
    anki_package_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiPackageResponse
     */
    anki_package_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AnkiPackageResponse
     */
    name?: string;
    /**
     * 卡片数量
     * @type {any}
     * @memberof AnkiPackageResponse
     */
    card_count?: any | null;
    /**
     * 创建日期
     * @type {string}
     * @memberof AnkiPackageResponse
     */
    created_date?: string;
    /**
     * 卡册状态
     * @type {string}
     * @memberof AnkiPackageResponse
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface AnkiPackageVo
 */
export interface AnkiPackageVo {
    /**
     * 
     * @type {string}
     * @memberof AnkiPackageVo
     */
    createdDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AnkiPackageVo
     */
    ankiPackageId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiPackageVo
     */
    packageUUID?: string;
    /**
     * 
     * @type {number}
     * @memberof AnkiPackageVo
     */
    packageCardCount?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiPackageVo
     */
    packageTitle?: string;
    /**
     * 
     * @type {Array<AnkiDeckVo>}
     * @memberof AnkiPackageVo
     */
    decks?: Array<AnkiDeckVo>;
    /**
     * 
     * @type {Array<AnkiModelVo>}
     * @memberof AnkiPackageVo
     */
    models?: Array<AnkiModelVo>;
    /**
     * 
     * @type {Array<AnkiTemplateVo>}
     * @memberof AnkiPackageVo
     */
    templates?: Array<AnkiTemplateVo>;
    /**
     * 
     * @type {string}
     * @memberof AnkiPackageVo
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface AnkiSharedTemplateVO
 */
export interface AnkiSharedTemplateVO {
    /**
     * 引用的packageId
     * @type {number}
     * @memberof AnkiSharedTemplateVO
     */
    anki_package_id?: number;
    /**
     * 引用的package uuid
     * @type {string}
     * @memberof AnkiSharedTemplateVO
     */
    anki_package_uuid?: string;
    /**
     * 引用的model Id
     * @type {number}
     * @memberof AnkiSharedTemplateVO
     */
    anki_model_id?: number;
    /**
     * 引用的templateId
     * @type {number}
     * @memberof AnkiSharedTemplateVO
     */
    anki_template_id?: number;
    /**
     * 标签，用竖线分割
     * @type {string}
     * @memberof AnkiSharedTemplateVO
     */
    tags?: string;
    /**
     * 官方模板名称
     * @type {string}
     * @memberof AnkiSharedTemplateVO
     */
    name?: string;
    /**
     * 模板拥有者（一般是官方账号）
     * @type {number}
     * @memberof AnkiSharedTemplateVO
     */
    owner_user_id?: number;
    /**
     * 用于在创建新卡片时，指定每个note字段的默认值
     * @type {{ [key: string]: string; }}
     * @memberof AnkiSharedTemplateVO
     */
    fields_placeholder?: { [key: string]: string; };
    /**
     * 预览图 url
     * @type {string}
     * @memberof AnkiSharedTemplateVO
     */
    preview_url?: string;
}
/**
 * 对于template如果是官方模板可以修改样式，不能删除，不能引用别的模板；对于没有引用官方模板的普通模板，可以修改样式，不能添加引用；对于引用官方模板的模板可以切换不同的官方模板引用，但是不能修改。违背上述条件会报400
 * @export
 * @interface AnkiTemplateVo
 */
export interface AnkiTemplateVo {
    /**
     * 
     * @type {number}
     * @memberof AnkiTemplateVo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnkiTemplateVo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AnkiTemplateVo
     */
    card_front_format?: string;
    /**
     * 
     * @type {string}
     * @memberof AnkiTemplateVo
     */
    card_back_format?: string;
    /**
     * 
     * @type {number}
     * @memberof AnkiTemplateVo
     */
    anki_model_id?: number;
    /**
     * 引用官方template或者其他作者template的Id
     * @type {number}
     * @memberof AnkiTemplateVo
     */
    ref_anki_template_id?: number;
}
/**
 * 如果更新，请传回相应VO的原来的ID。如果要引用新对象，请使用负数ID. 文档： https://qianmojiaoyu.feishu.cn/docs/doccntSrQHLSMVlRN6C4b4v0EEb#0DveD9 
 * @export
 * @interface ApkgBatchMutateJob
 */
export interface ApkgBatchMutateJob {
    /**
     * 
     * @type {BatchMutateCard}
     * @memberof ApkgBatchMutateJob
     */
    card?: BatchMutateCard;
    /**
     * 
     * @type {BatchMutateNote}
     * @memberof ApkgBatchMutateJob
     */
    note?: BatchMutateNote;
    /**
     * 
     * @type {BatchMutateDeck}
     * @memberof ApkgBatchMutateJob
     */
    deck?: BatchMutateDeck;
    /**
     * 
     * @type {BatchMutateTemplate}
     * @memberof ApkgBatchMutateJob
     */
    template?: BatchMutateTemplate;
    /**
     * 
     * @type {BatchMutateModel}
     * @memberof ApkgBatchMutateJob
     */
    model?: BatchMutateModel;
    /**
     * 
     * @type {string}
     * @memberof ApkgBatchMutateJob
     */
    preview_json_data?: string;
}
/**
 * 状态说明：under_review审核中；in_mutate_queue准备更新；mutating正在更新；mutate_finished更新完成；cancelled已删除，或已取消；FAILED执行失败 
 * @export
 * @interface ApkgBatchMutateJobStatus
 */
export interface ApkgBatchMutateJobStatus {
    /**
     * 
     * @type {number}
     * @memberof ApkgBatchMutateJobStatus
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApkgBatchMutateJobStatus
     */
    status?: ApkgBatchMutateJobStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApkgBatchMutateJobStatus
     */
    review_comment?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApkgBatchMutateJobStatusStatusEnum {
    UnderReview = 'UNDER_REVIEW',
    InMutateQueue = 'IN_MUTATE_QUEUE',
    Mutating = 'MUTATING',
    MutateFinished = 'MUTATE_FINISHED',
    Rejected = 'REJECTED',
    Cancelled = 'CANCELLED',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface ApkgCardBulkGetRequest
 */
export interface ApkgCardBulkGetRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ApkgCardBulkGetRequest
     */
    card_id_list?: Array<number>;
}
/**
 * 
 * @export
 * @interface ApkgCardBulkGetResponse
 */
export interface ApkgCardBulkGetResponse {
    /**
     * 
     * @type {Array<AnkiCardVo>}
     * @memberof ApkgCardBulkGetResponse
     */
    cards?: Array<AnkiCardVo>;
}
/**
 * 
 * @export
 * @interface ApkgLitePackageCreateRequest
 */
export interface ApkgLitePackageCreateRequest {
    /**
     * 卡册名称
     * @type {string}
     * @memberof ApkgLitePackageCreateRequest
     */
    name?: string;
    /**
     * 是否创建一张空白卡片，如果是，则卡包里会有一张卡片，使用最简单的正反面模板
     * @type {boolean}
     * @memberof ApkgLitePackageCreateRequest
     */
    create_sample_card?: boolean;
}
/**
 * 滑记2.0保存media文件返回信息
 * @export
 * @interface ApkgMediaResponse
 */
export interface ApkgMediaResponse {
    /**
     * 
     * @type {string}
     * @memberof ApkgMediaResponse
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof ApkgMediaResponse
     */
    file_size: number;
    /**
     * 
     * @type {string}
     * @memberof ApkgMediaResponse
     */
    sha1: string;
}
/**
 * 目录结构中卡包信息
 * @export
 * @interface ApkgTagInfoVO
 */
export interface ApkgTagInfoVO {
    /**
     * apkg卡包id
     * @type {number}
     * @memberof ApkgTagInfoVO
     */
    ankiPackageId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApkgTagInfoVO
     */
    ankiPackageUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApkgTagInfoVO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApkgTagInfoVO
     */
    lastModifiedDate?: string;
    /**
     * 从ugc book中取得
     * @type {string}
     * @memberof ApkgTagInfoVO
     */
    coverImageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof ApkgTagInfoVO
     */
    cardCount?: number;
    /**
     * 可能为空
     * @type {number}
     * @memberof ApkgTagInfoVO
     */
    libraryBookId?: number;
    /**
     * 可能为空
     * @type {number}
     * @memberof ApkgTagInfoVO
     */
    ugcBookId?: number;
    /**
     * library_book_id对应的图书名称
     * @type {string}
     * @memberof ApkgTagInfoVO
     */
    qmTitle?: string;
    /**
     * 文件的标签集合（不会重复）
     * @type {Array<string>}
     * @memberof ApkgTagInfoVO
     */
    tags?: Array<string>;
}
/**
 * 用户卡包详情集合
 * @export
 * @interface ApkgTagResponse
 */
export interface ApkgTagResponse {
    /**
     * 标签情况
     * @type {Array<ApkgTagInfoVO>}
     * @memberof ApkgTagResponse
     */
    apkgList?: Array<ApkgTagInfoVO>;
}
/**
 * 
 * @export
 * @interface AppBulkLogRequest
 */
export interface AppBulkLogRequest {
    /**
     * 用户的设备id
     * @type {string}
     * @memberof AppBulkLogRequest
     */
    device_id: string;
    /**
     * 
     * @type {Array<AppLogInfo>}
     * @memberof AppBulkLogRequest
     */
    log_info_list: Array<AppLogInfo>;
}
/**
 * 
 * @export
 * @interface AppConfigResponse
 */
export interface AppConfigResponse {
    /**
     * 是否开启免费拼团
     * @type {boolean}
     * @memberof AppConfigResponse
     */
    enable_free_group_buy?: boolean;
}
/**
 * 来自APP 上的问题反馈请求
 * @export
 * @interface AppFeedbackRequest
 */
export interface AppFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof AppFeedbackRequest
     */
    feedback_content: string;
    /**
     * 反馈来源， 如  首次学习
     * @type {string}
     * @memberof AppFeedbackRequest
     */
    source?: string;
    /**
     * 图书ID
     * @type {number}
     * @memberof AppFeedbackRequest
     */
    book_id?: number;
    /**
     * 学习时长 单位 秒
     * @type {number}
     * @memberof AppFeedbackRequest
     */
    time_taken?: number;
    /**
     * 目前(HUA4607)应该只有\"一般\"和 “不满意”， HUA-4819 任意评价选项都可以填写评价内容，且需要增加评价来源
     * @type {string}
     * @memberof AppFeedbackRequest
     */
    evaluation: string;
}
/**
 * 
 * @export
 * @interface AppInfoResponse
 */
export interface AppInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof AppInfoResponse
     */
    creator_gather_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AppInfoResponse
     */
    china_machine_table?: string;
    /**
     * 
     * @type {string}
     * @memberof AppInfoResponse
     */
    apple_review_verion?: string;
    /**
     * 
     * @type {string}
     * @memberof AppInfoResponse
     */
    tencent_app_store_review_version?: string;
    /**
     * 
     * @type {string}
     * @memberof AppInfoResponse
     */
    app_default_config?: string;
    /**
     * 
     * @type {string}
     * @memberof AppInfoResponse
     */
    android_edu_review_version?: string;
    /**
     * 
     * @type {string}
     * @memberof AppInfoResponse
     */
    android_review_version?: string;
}
/**
 * 
 * @export
 * @interface AppLogInfo
 */
export interface AppLogInfo {
    /**
     * 只包括三种类型：info,warning ,error
     * @type {string}
     * @memberof AppLogInfo
     */
    level: string;
    /**
     * 
     * @type {string}
     * @memberof AppLogInfo
     */
    module_name: string;
    /**
     * 
     * @type {string}
     * @memberof AppLogInfo
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof AppLogInfo
     */
    stack_trace?: string;
    /**
     * 
     * @type {string}
     * @memberof AppLogInfo
     */
    created_time?: string;
}
/**
 * 
 * @export
 * @interface AppSearchResponse
 */
export interface AppSearchResponse {
    /**
     * 
     * @type {Array<AppSearchResponseLibraryBookList>}
     * @memberof AppSearchResponse
     */
    library_book_list?: Array<AppSearchResponseLibraryBookList>;
}
/**
 * 
 * @export
 * @interface AppSearchResponseLibraryBookList
 */
export interface AppSearchResponseLibraryBookList {
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    qm_title?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    qm_publisher?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    qm_cover?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    qm_description?: string;
    /**
     * 
     * @type {number}
     * @memberof AppSearchResponseLibraryBookList
     */
    library_book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    origin_book_author?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    origin_book_title?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    origin_book_cover?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    origin_book_description?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    expire_date?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    package_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    package_editor?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    package_proofreader?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    copyright?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSearchResponseLibraryBookList
     */
    related_link?: string;
    /**
     * 
     * @type {number}
     * @memberof AppSearchResponseLibraryBookList
     */
    apkg_version?: number;
}
/**
 * 
 * @export
 * @interface AppVersionListResponse
 */
export interface AppVersionListResponse {
    /**
     * 
     * @type {Array<AppVersionResponse>}
     * @memberof AppVersionListResponse
     */
    list?: Array<AppVersionResponse>;
}
/**
 * 
 * @export
 * @interface AppVersionResponse
 */
export interface AppVersionResponse {
    /**
     * 
     * @type {number}
     * @memberof AppVersionResponse
     */
    app_version_id?: number;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    latest_version?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    system_type?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    force?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    ios_app_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    ios_app_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    android_app_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    channel?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponse
     */
    last_modified_date?: string;
}
/**
 * 
 * @export
 * @interface AppVersionUpdateRequest
 */
export interface AppVersionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    latest_version?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    force?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    ios_app_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    ios_app_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    android_app_url?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionUpdateRequest
     */
    channel?: string;
}
/**
 * 登录成功的响应体
 * @export
 * @interface AuthTokenResponse
 */
export interface AuthTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokenResponse
     */
    expire_date?: string;
}
/**
 * 备份日志详情
 * @export
 * @interface BackupLogDetail
 */
export interface BackupLogDetail {
    /**
     * 
     * @type {number}
     * @memberof BackupLogDetail
     */
    transaction_id?: number;
    /**
     * 
     * @type {string}
     * @memberof BackupLogDetail
     */
    package_uuid?: string;
    /**
     * 备份名称（路径）
     * @type {string}
     * @memberof BackupLogDetail
     */
    backup_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BackupLogDetail
     */
    backup_date?: string;
    /**
     * 备份方式 主动备份/自动备份/发布更新
     * @type {string}
     * @memberof BackupLogDetail
     */
    tag?: string;
    /**
     * 当前备份状态的卡片数量
     * @type {number}
     * @memberof BackupLogDetail
     */
    card_count?: number;
}
/**
 * 备份日志列表
 * @export
 * @interface BackupLogListResponse
 */
export interface BackupLogListResponse {
    /**
     * 
     * @type {Array<BackupLogDetail>}
     * @memberof BackupLogListResponse
     */
    list?: Array<BackupLogDetail>;
}
/**
 * 备份状态response
 * @export
 * @interface BackupStatusResponse
 */
export interface BackupStatusResponse {
    /**
     * 取值：BUSY/FREE 备份中/空闲中
     * @type {string}
     * @memberof BackupStatusResponse
     */
    backup_status?: string;
}
/**
 * 
 * @export
 * @interface BadgeResponse
 */
export interface BadgeResponse {
    /**
     * 
     * @type {string}
     * @memberof BadgeResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeResponse
     */
    image_url?: string;
}
/**
 * 
 * @export
 * @interface BannerConfigRequest
 */
export interface BannerConfigRequest {
    /**
     * 图片信息数组
     * @type {Array<BannerContent>}
     * @memberof BannerConfigRequest
     */
    banner_list: Array<BannerContent>;
    /**
     * banner图的位置 取值范围（\"my\", \"library\"） my: 在我的主页展示 library: 在图书馆页面展示
     * @type {string}
     * @memberof BannerConfigRequest
     */
    position: string;
}
/**
 * 
 * @export
 * @interface BannerConfigVO
 */
export interface BannerConfigVO {
    /**
     * 我的主页banner图列表
     * @type {Array<BannerContent>}
     * @memberof BannerConfigVO
     */
    my?: Array<BannerContent>;
    /**
     * 图书馆页面banner图列表
     * @type {Array<BannerContent>}
     * @memberof BannerConfigVO
     */
    library?: Array<BannerContent>;
}
/**
 * 
 * @export
 * @interface BannerContent
 */
export interface BannerContent {
    /**
     * 图片链接
     * @type {string}
     * @memberof BannerContent
     */
    img?: string;
    /**
     * 跳转链接
     * @type {string}
     * @memberof BannerContent
     */
    url?: string;
}
/**
 * 批量下载media请求体
 * @export
 * @interface BatchDownloadMediaRequest
 */
export interface BatchDownloadMediaRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchDownloadMediaRequest
     */
    media_name_list?: Array<string>;
}
/**
 * 
 * @export
 * @interface BatchMutateCard
 */
export interface BatchMutateCard {
    /**
     * 
     * @type {Array<AnkiCardVo>}
     * @memberof BatchMutateCard
     */
    create?: Array<AnkiCardVo>;
    /**
     * 
     * @type {Array<AnkiCardVo>}
     * @memberof BatchMutateCard
     */
    update?: Array<AnkiCardVo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchMutateCard
     */
    _delete?: Array<number>;
}
/**
 * 
 * @export
 * @interface BatchMutateDeck
 */
export interface BatchMutateDeck {
    /**
     * 
     * @type {Array<AnkiDeckVo>}
     * @memberof BatchMutateDeck
     */
    create?: Array<AnkiDeckVo>;
    /**
     * 
     * @type {Array<AnkiDeckVo>}
     * @memberof BatchMutateDeck
     */
    update?: Array<AnkiDeckVo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchMutateDeck
     */
    _delete?: Array<number>;
}
/**
 * 
 * @export
 * @interface BatchMutateModel
 */
export interface BatchMutateModel {
    /**
     * 
     * @type {Array<AnkiModelVo>}
     * @memberof BatchMutateModel
     */
    create?: Array<AnkiModelVo>;
    /**
     * 
     * @type {Array<AnkiModelVo>}
     * @memberof BatchMutateModel
     */
    update?: Array<AnkiModelVo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchMutateModel
     */
    _delete?: Array<number>;
}
/**
 * 
 * @export
 * @interface BatchMutateNote
 */
export interface BatchMutateNote {
    /**
     * 
     * @type {Array<AnkiNoteVo>}
     * @memberof BatchMutateNote
     */
    create?: Array<AnkiNoteVo>;
    /**
     * 
     * @type {Array<AnkiNoteVo>}
     * @memberof BatchMutateNote
     */
    update?: Array<AnkiNoteVo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchMutateNote
     */
    _delete?: Array<number>;
}
/**
 * 
 * @export
 * @interface BatchMutateTemplate
 */
export interface BatchMutateTemplate {
    /**
     * 
     * @type {Array<AnkiTemplateVo>}
     * @memberof BatchMutateTemplate
     */
    create?: Array<AnkiTemplateVo>;
    /**
     * 
     * @type {Array<AnkiTemplateVo>}
     * @memberof BatchMutateTemplate
     */
    update?: Array<AnkiTemplateVo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchMutateTemplate
     */
    _delete?: Array<number>;
}
/**
 * 客户端批量处理消息请求体
 * @export
 * @interface BatchUpdateMessageStatusRequest
 */
export interface BatchUpdateMessageStatusRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof BatchUpdateMessageStatusRequest
     */
    receiver_message_id_list?: Array<number>;
    /**
     * 目标状态 -已读 -删除
     * @type {string}
     * @memberof BatchUpdateMessageStatusRequest
     */
    status?: string;
    /**
     * 当该字段是true的时候，会忽略 receiver_message_id_list 内的值
     * @type {boolean}
     * @memberof BatchUpdateMessageStatusRequest
     */
    all_message?: boolean;
}
/**
 * 获取滑块验证拼图返回体
 * @export
 * @interface BlockPuzzleResponse
 */
export interface BlockPuzzleResponse {
    /**
     * 
     * @type {string}
     * @memberof BlockPuzzleResponse
     */
    repCode: string;
    /**
     * 
     * @type {BlockPuzzleResponseRepData}
     * @memberof BlockPuzzleResponse
     */
    repData: BlockPuzzleResponseRepData;
    /**
     * 
     * @type {boolean}
     * @memberof BlockPuzzleResponse
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface BlockPuzzleResponseRepData
 */
export interface BlockPuzzleResponseRepData {
    /**
     * 
     * @type {string}
     * @memberof BlockPuzzleResponseRepData
     */
    originalImageBase64?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockPuzzleResponseRepData
     */
    jigsawImageBase64?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockPuzzleResponseRepData
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockPuzzleResponseRepData
     */
    secretKey?: string;
}
/**
 * 获取某本图书的好评率，差评率
 * @export
 * @interface BookRemarkRatioResponse
 */
export interface BookRemarkRatioResponse {
    /**
     * 
     * @type {number}
     * @memberof BookRemarkRatioResponse
     */
    book_id?: number;
    /**
     * 好评率/推荐率 只对于查询单本图书的用户评价是显示
     * @type {number}
     * @memberof BookRemarkRatioResponse
     */
    good_ratio?: number;
    /**
     * 差评率/不满意率 只对于查询单本图书的用户评价是显示
     * @type {number}
     * @memberof BookRemarkRatioResponse
     */
    bad_ratio?: number;
    /**
     * 评价率
     * @type {number}
     * @memberof BookRemarkRatioResponse
     */
    remark_ratio?: number;
}
/**
 * 
 * @export
 * @interface BookRewardDetailPageResponse
 */
export interface BookRewardDetailPageResponse {
    /**
     * 
     * @type {Array<BookRewardDetailResponse>}
     * @memberof BookRewardDetailPageResponse
     */
    content?: Array<BookRewardDetailResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof BookRewardDetailPageResponse
     */
    empty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BookRewardDetailPageResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof BookRewardDetailPageResponse
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof BookRewardDetailPageResponse
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof BookRewardDetailPageResponse
     */
    total_pages?: number;
}
/**
 * 
 * @export
 * @interface BookRewardDetailResponse
 */
export interface BookRewardDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof BookRewardDetailResponse
     */
    book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof BookRewardDetailResponse
     */
    book_title?: string;
    /**
     * 元
     * @type {number}
     * @memberof BookRewardDetailResponse
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookRewardDetailResponse
     */
    learner_count?: number;
}
/**
 * 系统中的卡册类型
 * @export
 * @enum {string}
 */

export enum BookTypeResponse {
    Bundle = 'Bundle',
    SingleLibraryBook = 'SingleLibraryBook',
    Dlc = 'Dlc'
}

/**
 * 
 * @export
 * @interface Campaign202104OrderReqeust
 */
export interface Campaign202104OrderReqeust {
    /**
     * 
     * @type {string}
     * @memberof Campaign202104OrderReqeust
     */
    action: Campaign202104OrderReqeustActionEnum;
    /**
     * 
     * @type {string}
     * @memberof Campaign202104OrderReqeust
     */
    openId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum Campaign202104OrderReqeustActionEnum {
    Book = 'BOOK',
    PlaceOrder = 'PLACE_ORDER'
}

/**
 * 
 * @export
 * @interface Campaign202104StatusResponse
 */
export interface Campaign202104StatusResponse {
    /**
     * 
     * @type {string}
     * @memberof Campaign202104StatusResponse
     */
    status: Campaign202104StatusResponseStatusEnum;
    /**
     * 如果这个响应包含这个字段，则应该调用JSAPI或者Ping++SDK进行支付请求
     * @type {string}
     * @memberof Campaign202104StatusResponse
     */
    charge?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum Campaign202104StatusResponseStatusEnum {
    NotBooked = 'NOT_BOOKED',
    Booked = 'BOOKED',
    OrderedNotPaid = 'ORDERED_NOT_PAID',
    OrderedPaid = 'ORDERED_PAID'
}

/**
 * 阿里云人机验证的结果，同时附加业务请求结果
 * @export
 * @interface CaptchaActionResponse
 */
export interface CaptchaActionResponse {
    /**
     * 
     * @type {number}
     * @memberof CaptchaActionResponse
     */
    bizCode?: number;
}
/**
 * 评论笔记请求体
 * @export
 * @interface CardCommentRequest
 */
export interface CardCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof CardCommentRequest
     */
    content?: string;
}
/**
 * 评论笔记返回体
 * @export
 * @interface CardCommentResponse
 */
export interface CardCommentResponse {
    /**
     * 
     * @type {Array<CardCommentResponseContent>}
     * @memberof CardCommentResponse
     */
    content?: Array<CardCommentResponseContent>;
}
/**
 * 
 * @export
 * @interface CardCommentResponseContent
 */
export interface CardCommentResponseContent {
    /**
     * 
     * @type {number}
     * @memberof CardCommentResponseContent
     */
    card_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CardCommentResponseContent
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof CardCommentResponseContent
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CardCommentResponseContent
     */
    last_modified_date?: string;
}
/**
 * 
 * @export
 * @interface CardRateLogDetailResponse
 */
export interface CardRateLogDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof CardRateLogDetailResponse
     */
    proficiency?: number;
    /**
     * 
     * @type {string}
     * @memberof CardRateLogDetailResponse
     */
    rate_type?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRateLogDetailResponse
     */
    created_date?: string;
}
/**
 * 学习统计图请求体(统计接口公用的)
 * @export
 * @interface CardRateLogHistogramRequest
 */
export interface CardRateLogHistogramRequest {
    /**
     * 统计起始日期， 如果为null表示全部日期
     * @type {string}
     * @memberof CardRateLogHistogramRequest
     */
    start_date?: string;
    /**
     * 统计截止日期
     * @type {string}
     * @memberof CardRateLogHistogramRequest
     */
    end_date?: string;
}
/**
 * 学习行为直方图数据
 * @export
 * @interface CardRateLogHistogramResponse
 */
export interface CardRateLogHistogramResponse {
    /**
     * 起止日期数组
     * @type {Array<string>}
     * @memberof CardRateLogHistogramResponse
     */
    date_series?: Array<string>;
    /**
     * 直方图数据列表 和 date_series 数组下标一一对应
     * @type {Array<CardRateLogVO>}
     * @memberof CardRateLogHistogramResponse
     */
    histogram_data_list?: Array<CardRateLogVO>;
}
/**
 * 
 * @export
 * @interface CardRateLogListResponse
 */
export interface CardRateLogListResponse {
    /**
     * 
     * @type {Array<CardRateLogDetailResponse>}
     * @memberof CardRateLogListResponse
     */
    content?: Array<CardRateLogDetailResponse>;
    /**
     * 总共多少个日志文件
     * @type {number}
     * @memberof CardRateLogListResponse
     */
    total?: number;
    /**
     * 当前是第几个日志文件，倒序
     * @type {number}
     * @memberof CardRateLogListResponse
     */
    current?: number;
    /**
     * 还剩多少个日志文件
     * @type {number}
     * @memberof CardRateLogListResponse
     */
    left?: number;
}
/**
 * 卡片学习统计
 * @export
 * @interface CardRateLogVO
 */
export interface CardRateLogVO {
    /**
     * 点击忘记的次数
     * @type {number}
     * @memberof CardRateLogVO
     */
    again_times?: number;
    /**
     * 点击模糊的次数
     * @type {number}
     * @memberof CardRateLogVO
     */
    hard_times?: number;
    /**
     * 点击记得的次数
     * @type {number}
     * @memberof CardRateLogVO
     */
    good_times?: number;
    /**
     * 点击简单的次数
     * @type {number}
     * @memberof CardRateLogVO
     */
    easy_times?: number;
}
/**
 * 卡片学习统计数据
 * @export
 * @interface CardRateStatisticVO
 */
export interface CardRateStatisticVO {
    /**
     * 上次计算时间（时间戳），用于客户端计算卡片学习次数
     * @type {string}
     * @memberof CardRateStatisticVO
     */
    last_update_time?: string;
    /**
     * 点击忘记的次数
     * @type {number}
     * @memberof CardRateStatisticVO
     */
    again_times?: number;
    /**
     * 点击模糊的次数
     * @type {number}
     * @memberof CardRateStatisticVO
     */
    hard_times?: number;
    /**
     * 点击记得的次数
     * @type {number}
     * @memberof CardRateStatisticVO
     */
    good_times?: number;
    /**
     * 点击简单的次数
     * @type {number}
     * @memberof CardRateStatisticVO
     */
    easy_times?: number;
}
/**
 * 卡片渲染请求体，非常低级的数据结构
 * @export
 * @interface CardRenderRequest
 */
export interface CardRenderRequest {
    /**
     * 渲染正面还是反面
     * @type {boolean}
     * @memberof CardRenderRequest
     */
    is_anwser?: boolean;
    /**
     * media base url
     * @type {string}
     * @memberof CardRenderRequest
     */
    base_url?: string;
    /**
     * notes的名称
     * @type {Array<string>}
     * @memberof CardRenderRequest
     */
    model_fields?: Array<string>;
    /**
     * notes的字段值
     * @type {Array<string>}
     * @memberof CardRenderRequest
     */
    field_values?: Array<string>;
    /**
     * 正面html模板
     * @type {string}
     * @memberof CardRenderRequest
     */
    qfmt?: string;
    /**
     * 反面html模板
     * @type {string}
     * @memberof CardRenderRequest
     */
    afmt?: string;
    /**
     * CSS内容
     * @type {string}
     * @memberof CardRenderRequest
     */
    css?: string;
    /**
     * 自定义html，会插入到特定的位置
     * @type {string}
     * @memberof CardRenderRequest
     */
    custom_html?: string;
    /**
     * 如果是填空题，它是第几个空，从0开始
     * @type {number}
     * @memberof CardRenderRequest
     */
    cloze_ord?: number;
    /**
     * 上下文信息，可能模板需要读取此值
     * @type {string}
     * @memberof CardRenderRequest
     */
    card_id?: string;
    /**
     * 上下文信息，可能模板需要读取此值
     * @type {string}
     * @memberof CardRenderRequest
     */
    model_name?: string;
    /**
     * 上下文信息，可能模板需要读取此值
     * @type {string}
     * @memberof CardRenderRequest
     */
    deck_name?: string;
    /**
     * 上下文信息，可能模板需要读取此值
     * @type {string}
     * @memberof CardRenderRequest
     */
    template_name?: string;
}
/**
 * 
 * @export
 * @interface CardStateInfo
 */
export interface CardStateInfo {
    /**
     * 
     * @type {string}
     * @memberof CardStateInfo
     */
    card_type?: string;
    /**
     * 
     * @type {number}
     * @memberof CardStateInfo
     */
    due?: number;
    /**
     * 
     * @type {number}
     * @memberof CardStateInfo
     */
    factor?: number;
    /**
     * 
     * @type {number}
     * @memberof CardStateInfo
     */
    interval?: number;
    /**
     * 
     * @type {string}
     * @memberof CardStateInfo
     */
    queue_type?: string;
    /**
     * 
     * @type {number}
     * @memberof CardStateInfo
     */
    repeat_times?: number;
    /**
     * 
     * @type {number}
     * @memberof CardStateInfo
     */
    repeat_times_left_today?: number;
    /**
     * 
     * @type {number}
     * @memberof CardStateInfo
     */
    repeat_times_left_till_grad?: number;
    /**
     * 
     * @type {string}
     * @memberof CardStateInfo
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface CertifiedCreatorVO
 */
export interface CertifiedCreatorVO {
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedCreatorVO
     */
    is_certified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertifiedCreatorVO
     */
    creator_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedCreatorVO
     */
    domain_name?: string;
}
/**
 * 
 * @export
 * @interface ChangeApkgTagRequest
 */
export interface ChangeApkgTagRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeApkgTagRequest
     */
    tags: Array<string>;
}
/**
 * 统计各渠道待发放（审核中）金额（分）
 * @export
 * @interface ChannelRequestingAmount
 */
export interface ChannelRequestingAmount {
    /**
     * 支付宝待发放金额（分）
     * @type {number}
     * @memberof ChannelRequestingAmount
     */
    alipay_amount?: number;
    /**
     * 微信待发放金额（分）
     * @type {number}
     * @memberof ChannelRequestingAmount
     */
    wechat_amount?: number;
}
/**
 * 
 * @export
 * @interface CheckNewUserResponse
 */
export interface CheckNewUserResponse {
    /**
     * 是否是新用户
     * @type {boolean}
     * @memberof CheckNewUserResponse
     */
    new_user?: boolean;
}
/**
 * 
 * @export
 * @interface CheckPaymentRequest
 */
export interface CheckPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckPaymentRequest
     */
    order_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckPaymentRequest
     */
    transaction_receipt?: string;
}
/**
 * 检验参数是否合法返回体
 * @export
 * @interface CheckValidResponse
 */
export interface CheckValidResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckValidResponse
     */
    is_valid?: boolean;
    /**
     * 如果is_valid=false，则会有message返回
     * @type {string}
     * @memberof CheckValidResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CheckWeixinMpBindingResponse
 */
export interface CheckWeixinMpBindingResponse {
    /**
     * 是否已经绑定到某滑记账号
     * @type {boolean}
     * @memberof CheckWeixinMpBindingResponse
     */
    has_binding?: boolean;
    /**
     * 如果绑定到，则为该账号的昵称
     * @type {string}
     * @memberof CheckWeixinMpBindingResponse
     */
    nickname?: string;
    /**
     * 如果绑定到，则为该账号的 user id
     * @type {number}
     * @memberof CheckWeixinMpBindingResponse
     */
    user_id?: number;
}
/**
 * 礼物绑定关系信息
 * @export
 * @interface ChestBindInfoResponse
 */
export interface ChestBindInfoResponse {
    /**
     * 送礼物人Id
     * @type {number}
     * @memberof ChestBindInfoResponse
     */
    sender?: number;
    /**
     * 是否购买过会员免费商品
     * @type {boolean}
     * @memberof ChestBindInfoResponse
     */
    bought_vip_free_product?: boolean;
}
/**
 * 礼物详情
 * @export
 * @interface ChestDetailResponse
 */
export interface ChestDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof ChestDetailResponse
     */
    chest_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChestDetailResponse
     */
    sku_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChestDetailResponse
     */
    expire_date?: string;
    /**
     * 单位是分
     * @type {number}
     * @memberof ChestDetailResponse
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ChestDetailResponse
     */
    received_user?: number;
    /**
     * 
     * @type {string}
     * @memberof ChestDetailResponse
     */
    received_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ChestDetailResponse
     */
    remark?: string;
    /**
     * 
     * @type {string}
     * @memberof ChestDetailResponse
     */
    created_date?: string;
    /**
     * 
     * @type {number}
     * @memberof ChestDetailResponse
     */
    sender?: number;
}
/**
 * 礼物列表
 * @export
 * @interface ChestListResponse
 */
export interface ChestListResponse {
    /**
     * 
     * @type {Array<ChestDetailResponse>}
     * @memberof ChestListResponse
     */
    list?: Array<ChestDetailResponse>;
}
/**
 * 
 * @export
 * @interface ClearSchedulerAndStudyStatRequest
 */
export interface ClearSchedulerAndStudyStatRequest {
    /**
     * 
     * @type {string}
     * @memberof ClearSchedulerAndStudyStatRequest
     */
    level?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClearSchedulerAndStudyStatRequest
     */
    user_id_list?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClearSchedulerAndStudyStatRequest
     */
    deck_id_list?: Array<number>;
}
/**
 * 收藏的图书列表
 * @export
 * @interface CollectionBookListResponse
 */
export interface CollectionBookListResponse {
    /**
     * 
     * @type {Array<CollectionBookVO>}
     * @memberof CollectionBookListResponse
     */
    list?: Array<CollectionBookVO>;
}
/**
 * 图书封面图返回体
 * @export
 * @interface CollectionBookVO
 */
export interface CollectionBookVO {
    /**
     * 
     * @type {number}
     * @memberof CollectionBookVO
     */
    book_id?: number;
    /**
     * 图书名字
     * @type {string}
     * @memberof CollectionBookVO
     */
    qm_title?: string;
    /**
     * 创作者头像
     * @type {string}
     * @memberof CollectionBookVO
     */
    qm_cover?: string;
    /**
     * 发布人名字
     * @type {string}
     * @memberof CollectionBookVO
     */
    qm_publisher_nickname?: string;
    /**
     * 学习人数
     * @type {number}
     * @memberof CollectionBookVO
     */
    learner_count?: number;
    /**
     * 价格，取团购和单买最低价
     * @type {number}
     * @memberof CollectionBookVO
     */
    fixed_price?: number;
    /**
     * 是否上架状态
     * @type {boolean}
     * @memberof CollectionBookVO
     */
    on_sale?: boolean;
    /**
     * 营销图
     * @type {string}
     * @memberof CollectionBookVO
     */
    marketing_img_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBookVO
     */
    package_uuid?: string;
    /**
     * 
     * @type {number}
     * @memberof CollectionBookVO
     */
    qm_publisher_user_id?: number;
    /**
     * 收藏时间
     * @type {string}
     * @memberof CollectionBookVO
     */
    collect_date?: string;
}
/**
 * 收藏状态
 * @export
 * @interface CollectionStatusResponse
 */
export interface CollectionStatusResponse {
    /**
     * 收藏状态
     * @type {boolean}
     * @memberof CollectionStatusResponse
     */
    collect_status?: boolean;
}
/**
 * 往往是用来表示200成功的响应体
 * @export
 * @interface CommonResponse
 */
export interface CommonResponse {
    /**
     * 
     * @type {string}
     * @memberof CommonResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Compensation202111OrderVO
 */
export interface Compensation202111OrderVO {
    /**
     * 差额 单位 分
     * @type {number}
     * @memberof Compensation202111OrderVO
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ConsumeRedeemCodeRequest
 */
export interface ConsumeRedeemCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof ConsumeRedeemCodeRequest
     */
    redeem_code: string;
    /**
     * 需要领取兑换虚拟商品的手机号，如果不为空，会忽略当前用户
     * @type {string}
     * @memberof ConsumeRedeemCodeRequest
     */
    receive_phone?: string;
    /**
     * 领取渠道
     * @type {string}
     * @memberof ConsumeRedeemCodeRequest
     */
    channel?: string;
}
/**
 * 
 * @export
 * @interface ConsumeRedeemCodeResponse
 */
export interface ConsumeRedeemCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof ConsumeRedeemCodeResponse
     */
    coupon_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumeRedeemCodeResponse
     */
    type?: ConsumeRedeemCodeResponseTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsumeRedeemCodeResponseTypeEnum {
    InviteCode = 'INVITE_CODE',
    RedeemCode = 'REDEEM_CODE'
}

/**
 * 格式化机审数实体类
 * @export
 * @interface ContentMachineReviewResultVO
 */
export interface ContentMachineReviewResultVO {
    /**
     * 
     * @type {ReviewStats}
     * @memberof ContentMachineReviewResultVO
     */
    stats?: ReviewStats;
    /**
     * 
     * @type {Array<ReviewField>}
     * @memberof ContentMachineReviewResultVO
     */
    fields?: Array<ReviewField>;
}
/**
 * 制卡大赛信息返回体
 * @export
 * @interface Contest202208InfoResponse
 */
export interface Contest202208InfoResponse {
    /**
     * 是否参赛
     * @type {boolean}
     * @memberof Contest202208InfoResponse
     */
    is_participant?: boolean;
    /**
     * 真实姓名
     * @type {string}
     * @memberof Contest202208InfoResponse
     */
    real_name?: string;
    /**
     * 代表学校
     * @type {string}
     * @memberof Contest202208InfoResponse
     */
    school?: string;
    /**
     * 赛区
     * @type {string}
     * @memberof Contest202208InfoResponse
     */
    division?: string;
    /**
     * 参赛作品名称
     * @type {string}
     * @memberof Contest202208InfoResponse
     */
    book_title?: string;
    /**
     * 参赛作品id
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    book_id?: number;
    /**
     * 数据更新时间
     * @type {string}
     * @memberof Contest202208InfoResponse
     */
    updated_date?: string;
    /**
     * 今日新学人数
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    new_learner_count?: number;
    /**
     * 今日被学习小时数
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    learning_duration?: number;
    /**
     * 同校今日新提交作品选手数
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    new_participant_count?: number;
    /**
     * 今日积分
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    today_score?: number;
    /**
     * 今日排名
     * @type {string}
     * @memberof Contest202208InfoResponse
     */
    today_rank?: string;
    /**
     * 今日奖金
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    today_prize?: number;
    /**
     * 累计积分
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    total_score?: number;
    /**
     * 累计奖金
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    total_prize?: number;
    /**
     * 当前总排名
     * @type {string}
     * @memberof Contest202208InfoResponse
     */
    total_rank?: string;
    /**
     * 上一名分差
     * @type {number}
     * @memberof Contest202208InfoResponse
     */
    previous_gap?: number;
}
/**
 * 
 * @export
 * @interface CreateDonationOrderRequest
 */
export interface CreateDonationOrderRequest {
    /**
     * 金额，单位：分
     * @type {number}
     * @memberof CreateDonationOrderRequest
     */
    amount?: number;
}
/**
 * 捐赠订单响应
 * @export
 * @interface CreateDonationOrderResponse
 */
export interface CreateDonationOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateDonationOrderResponse
     */
    order_no?: number;
}
/**
 * 
 * @export
 * @interface CreateEggLetterRequest
 */
export interface CreateEggLetterRequest {
    /**
     * 第一行加粗部分
     * @type {string}
     * @memberof CreateEggLetterRequest
     */
    title?: string;
    /**
     * 正文
     * @type {string}
     * @memberof CreateEggLetterRequest
     */
    content?: string;
    /**
     * 发送人
     * @type {string}
     * @memberof CreateEggLetterRequest
     */
    from?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEggLetterRequest
     */
    visible?: boolean;
}
/**
 * 发起免费拼团
 * @export
 * @interface CreateFreeGroupBuyRequest
 */
export interface CreateFreeGroupBuyRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateFreeGroupBuyRequest
     */
    library_book_id?: number;
}
/**
 * 发起拼团请求体
 * @export
 * @interface CreateGroupBuyOrderRequest
 */
export interface CreateGroupBuyOrderRequest {
    /**
     * 是否初始化学习计划， false:初始化, true:不初始化
     * @type {boolean}
     * @memberof CreateGroupBuyOrderRequest
     */
    manual_init_study_plan?: boolean;
    /**
     * 假团长的Id
     * @type {number}
     * @memberof CreateGroupBuyOrderRequest
     */
    fake_initiator_user_id?: number;
    /**
     * 邀请团购的营销用户ID（optional)， 创建返利团购时需要，加入返利团购不需要
     * @type {number}
     * @memberof CreateGroupBuyOrderRequest
     */
    marketing_user_id?: number;
    /**
     * 该邀请团购的父团购实例ID(optional)，当父实例已满员时需要传入此参数创建新的子实例
     * @type {number}
     * @memberof CreateGroupBuyOrderRequest
     */
    parent_instance_id?: number;
    /**
     * 订单来源，见链接 https://qianmojiaoyu.feishu.cn/wiki/wikcnSURNUMSs74nZqvzEiFhaUf#i81aSz
     * @type {string}
     * @memberof CreateGroupBuyOrderRequest
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface CreateLetterCommentRequest
 */
export interface CreateLetterCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLetterCommentRequest
     */
    content: string;
    /**
     * 评论创建者的署名
     * @type {string}
     * @memberof CreateLetterCommentRequest
     */
    commenter_name?: string;
}
/**
 * 创建letter请求
 * @export
 * @interface CreateLetterRequest
 */
export interface CreateLetterRequest {
    /**
     * 接受者名称
     * @type {string}
     * @memberof CreateLetterRequest
     */
    receiver_name?: string;
    /**
     * 发送者署名
     * @type {string}
     * @memberof CreateLetterRequest
     */
    sender_name: string;
    /**
     * 发送内容
     * @type {string}
     * @memberof CreateLetterRequest
     */
    content: string;
    /**
     * 是否私密
     * @type {boolean}
     * @memberof CreateLetterRequest
     */
    is_private: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    manual_init_study_plan?: boolean;
    /**
     * 分享者Id
     * @type {number}
     * @memberof CreateOrderRequest
     */
    marketing_user_id?: number;
    /**
     * 如果是订阅订单，需要填充该字段
     * @type {string}
     * @memberof CreateOrderRequest
     */
    subscribe_key?: string;
    /**
     * 订单来源，见链接 https://qianmojiaoyu.feishu.cn/wiki/wikcnSURNUMSs74nZqvzEiFhaUf#i81aSz
     * @type {string}
     * @memberof CreateOrderRequest
     */
    source?: string;
    /**
     * 是否是会员免拼单
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    vip_group_pass?: boolean;
    /**
     * 是否是会员免费拼
     * @type {boolean}
     * @memberof CreateOrderRequest
     */
    vip_free?: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    order_id?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    quantity?: number;
    /**
     * 订单总金额 = 内容价格+AI服务费
     * @type {number}
     * @memberof CreateOrderResponse
     */
    amount?: number;
    /**
     * 内容价格
     * @type {number}
     * @memberof CreateOrderResponse
     */
    content_amount?: number;
    /**
     * AI服务费，如果没有则传0
     * @type {number}
     * @memberof CreateOrderResponse
     */
    ai_service_amount?: number;
    /**
     * 没有折算时的合辑价格，若订单不是合辑订单则为null
     * @type {number}
     * @memberof CreateOrderResponse
     */
    bundle_original_amount?: number;
}
/**
 * 
 * @export
 * @interface CreatePaymentRequest
 */
export interface CreatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentRequest
     */
    order_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    transaction_gateway?: CreatePaymentRequestTransactionGatewayEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentRequest
     */
    coupon_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    pay_channel?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    open_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentRequest
     */
    success_callback_url?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreatePaymentRequestTransactionGatewayEnum {
    ApplePay = 'APPLE_PAY',
    PingPp = 'PING_PP',
    IosWallet = 'IOS_WALLET',
    Alipay = 'ALIPAY',
    Wxpay = 'WXPAY',
    AndroidWallet = 'ANDROID_WALLET'
}

/**
 * 创建兑换码请求体
 * @export
 * @interface CreateRedeemRequest
 */
export interface CreateRedeemRequest {
    /**
     * 生成随机码方式
     * @type {string}
     * @memberof CreateRedeemRequest
     */
    type: string;
    /**
     * 生成的数量
     * @type {number}
     * @memberof CreateRedeemRequest
     */
    code_count?: number;
    /**
     * 可使用次数
     * @type {number}
     * @memberof CreateRedeemRequest
     */
    redeem_limit: number;
    /**
     * 截至兑换时间
     * @type {string}
     * @memberof CreateRedeemRequest
     */
    end_time?: string;
    /**
     * 优惠券模板id
     * @type {number}
     * @memberof CreateRedeemRequest
     */
    coupon_template_id: number;
    /**
     * 描述
     * @type {string}
     * @memberof CreateRedeemRequest
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRedeemRequest
     */
    redeem_code?: string;
    /**
     * 兑换码名字
     * @type {string}
     * @memberof CreateRedeemRequest
     */
    redeem_name?: string;
    /**
     * 目标用户信息
     * @type {string}
     * @memberof CreateRedeemRequest
     */
    target_customer_info?: string;
    /**
     * 生成兑换码原因
     * @type {string}
     * @memberof CreateRedeemRequest
     */
    reason?: string;
}
/**
 * 创建兑换码返回体
 * @export
 * @interface CreateRedeemResponse
 */
export interface CreateRedeemResponse {
    /**
     * 所属提交批次
     * @type {string}
     * @memberof CreateRedeemResponse
     */
    batch_code?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRedeemResponse
     */
    redeem_code_list?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateShortLinkRequest
 */
export interface CreateShortLinkRequest {
    /**
     * 订单id或者其它id
     * @type {string}
     * @memberof CreateShortLinkRequest
     */
    object_id?: string;
    /**
     * 实际链接，可以是json字符串，格式见下面的example。为了方便向前兼容，phoneNumber为空字符串，orderId为long类型
     * @type {string}
     * @memberof CreateShortLinkRequest
     */
    actual_link?: string;
}
/**
 * 
 * @export
 * @interface CreateTransactionResponse
 */
export interface CreateTransactionResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionResponse
     */
    transactionId?: number;
    /**
     * 支付宝支付时 唤醒支付宝页面参数/form跳转表单元素
     * @type {string}
     * @memberof CreateTransactionResponse
     */
    requestSign?: string;
    /**
     * PING++使用的支付参数
     * @type {string}
     * @memberof CreateTransactionResponse
     */
    pingPPChargeString?: string;
    /**
     * 
     * @type {PingTransactionChargeRequest}
     * @memberof CreateTransactionResponse
     */
    request?: PingTransactionChargeRequest;
    /**
     * 
     * @type {PingTransactionChargeRequest}
     * @memberof CreateTransactionResponse
     */
    mobileChargeRequest?: PingTransactionChargeRequest;
    /**
     * 
     * @type {WxJsApiOrderParam}
     * @memberof CreateTransactionResponse
     */
    wxJsApiOrderRequest?: WxJsApiOrderParam;
    /**
     * 
     * @type {WxAppPayOrderParam}
     * @memberof CreateTransactionResponse
     */
    wxAppOrderRequest?: WxAppPayOrderParam;
    /**
     * 网页唤起app支付要用到
     * @type {string}
     * @memberof CreateTransactionResponse
     */
    mwebUrl?: string;
}
/**
 * 
 * @export
 * @interface CreateVipOrderExtraParamRequest
 */
export interface CreateVipOrderExtraParamRequest {
    /**
     * 巨量事件参数
     * @type {string}
     * @memberof CreateVipOrderExtraParamRequest
     */
    click_id?: string;
}
/**
 * 
 * @export
 * @interface CreateVipOrderRequest
 */
export interface CreateVipOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateVipOrderRequest
     */
    sku_id?: string;
    /**
     * 当前客户端操作系统类型：true 为ios，false为安卓
     * @type {boolean}
     * @memberof CreateVipOrderRequest
     */
    ios_system?: boolean;
    /**
     * 订单来源
     * @type {string}
     * @memberof CreateVipOrderRequest
     */
    source?: string;
    /**
     * 是否是赠送礼物订单,该种类型的订单不会发送会员，只有被赠送的用户领取后
     * @type {boolean}
     * @memberof CreateVipOrderRequest
     */
    gift?: boolean;
    /**
     * 从哪个图书点进来购买会员的
     * @type {number}
     * @memberof CreateVipOrderRequest
     */
    vip_source_book_id?: number;
    /**
     * 从哪个团购点进来购买d会员的
     * @type {string}
     * @memberof CreateVipOrderRequest
     */
    vip_source_group_id?: string;
    /**
     * 会员限时折扣条件 id
     * @type {string}
     * @memberof CreateVipOrderRequest
     */
    condition_id?: string;
    /**
     * 
     * @type {CreateVipOrderExtraParamRequest}
     * @memberof CreateVipOrderRequest
     */
    extra_param?: CreateVipOrderExtraParamRequest;
}
/**
 * 创作者图书
 * @export
 * @interface CreatorBookVO
 */
export interface CreatorBookVO {
    /**
     * 
     * @type {number}
     * @memberof CreatorBookVO
     */
    book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatorBookVO
     */
    book_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorBookVO
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorBookVO
     */
    book_type?: string;
}
/**
 * 
 * @export
 * @interface CreatorMsgItem
 */
export interface CreatorMsgItem {
    /**
     * 
     * @type {number}
     * @memberof CreatorMsgItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatorMsgItem
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorMsgItem
     */
    title?: string;
    /**
     * 
     * @type {CreatorMsgItemRewardData}
     * @memberof CreatorMsgItem
     */
    reward_data?: CreatorMsgItemRewardData;
    /**
     * 
     * @type {string}
     * @memberof CreatorMsgItem
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorMsgItem
     */
    read_date?: string;
}
/**
 * 
 * @export
 * @interface CreatorMsgItemRewardData
 */
export interface CreatorMsgItemRewardData {
    /**
     * 激励金金额
     * @type {number}
     * @memberof CreatorMsgItemRewardData
     */
    reward_amount?: number;
    /**
     * 会员学习人数
     * @type {number}
     * @memberof CreatorMsgItemRewardData
     */
    vip_learner_count?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatorMsgItemRewardData
     */
    date?: string;
}
/**
 * 
 * @export
 * @interface CreatorMsgListResponse
 */
export interface CreatorMsgListResponse {
    /**
     * 
     * @type {Array<CreatorMsgItem>}
     * @memberof CreatorMsgListResponse
     */
    messages?: Array<CreatorMsgItem>;
}
/**
 * 
 * @export
 * @interface CreatorMsgPageListResponse
 */
export interface CreatorMsgPageListResponse {
    /**
     * 
     * @type {Array<CreatorMsgItem>}
     * @memberof CreatorMsgPageListResponse
     */
    content?: Array<CreatorMsgItem>;
    /**
     * 
     * @type {number}
     * @memberof CreatorMsgPageListResponse
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatorMsgPageListResponse
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatorMsgPageListResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatorMsgPageListResponse
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatorMsgPageListResponse
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface CreatorRewardBookDetailListResponse
 */
export interface CreatorRewardBookDetailListResponse {
    /**
     * 
     * @type {Array<BookRewardDetailResponse>}
     * @memberof CreatorRewardBookDetailListResponse
     */
    book_rewards?: Array<BookRewardDetailResponse>;
}
/**
 * 
 * @export
 * @interface CreatorRewardDetailPageResponse
 */
export interface CreatorRewardDetailPageResponse {
    /**
     * 
     * @type {Array<CreatorRewardDetailResponse>}
     * @memberof CreatorRewardDetailPageResponse
     */
    content?: Array<CreatorRewardDetailResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof CreatorRewardDetailPageResponse
     */
    empty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatorRewardDetailPageResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatorRewardDetailPageResponse
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatorRewardDetailPageResponse
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatorRewardDetailPageResponse
     */
    total_pages?: number;
}
/**
 * 
 * @export
 * @interface CreatorRewardDetailResponse
 */
export interface CreatorRewardDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatorRewardDetailResponse
     */
    date?: string;
    /**
     * 元
     * @type {number}
     * @memberof CreatorRewardDetailResponse
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatorRewardDetailResponse
     */
    learner_count?: number;
}
/**
 * 
 * @export
 * @interface DailyReports
 */
export interface DailyReports {
    /**
     * 
     * @type {string}
     * @memberof DailyReports
     */
    date?: string;
    /**
     * 
     * @type {Array<RateEvent>}
     * @memberof DailyReports
     */
    rate_events?: Array<RateEvent>;
}
/**
 * 
 * @export
 * @interface DebugStudyHistory
 */
export interface DebugStudyHistory {
    /**
     * 
     * @type {Array<DailyReports>}
     * @memberof DebugStudyHistory
     */
    daily_reports?: Array<DailyReports>;
    /**
     * key是cardId对应的String
     * @type {{ [key: string]: Array<CardStateInfo>; }}
     * @memberof DebugStudyHistory
     */
    card_state_series?: { [key: string]: Array<CardStateInfo>; };
}
/**
 * 
 * @export
 * @interface DeckLearningStatVo
 */
export interface DeckLearningStatVo {
    /**
     * deck id
     * @type {number}
     * @memberof DeckLearningStatVo
     */
    id?: number;
    /**
     * 未学习卡片数量
     * @type {number}
     * @memberof DeckLearningStatVo
     */
    new_count?: number;
    /**
     * 已学习卡片数量（=所有-new-proficient）
     * @type {number}
     * @memberof DeckLearningStatVo
     */
    learn_count?: number;
    /**
     * 已掌握卡片数量
     * @type {number}
     * @memberof DeckLearningStatVo
     */
    proficient_count?: number;
}
/**
 * 
 * @export
 * @interface DeleteUgcBookRequest
 */
export interface DeleteUgcBookRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteUgcBookRequest
     */
    ugc_book_id_str?: string;
}
/**
 * 
 * @export
 * @interface DepositWalletRequest
 */
export interface DepositWalletRequest {
    /**
     * 
     * @type {string}
     * @memberof DepositWalletRequest
     */
    sku_id?: string;
}
/**
 * 
 * @export
 * @interface DepositWalletResponse
 */
export interface DepositWalletResponse {
    /**
     * 
     * @type {string}
     * @memberof DepositWalletResponse
     */
    order_no?: string;
}
/**
 * 用户捐款记录
 * @export
 * @interface DonationDataVO
 */
export interface DonationDataVO {
    /**
     * 总计捐款金额
     * @type {number}
     * @memberof DonationDataVO
     */
    donated_amount?: number;
    /**
     * 捐款次数
     * @type {number}
     * @memberof DonationDataVO
     */
    donated_times?: number;
}
/**
 * 
 * @export
 * @interface EggLetter
 */
export interface EggLetter {
    /**
     * 
     * @type {number}
     * @memberof EggLetter
     */
    id?: number;
    /**
     * 第一行加粗部分
     * @type {string}
     * @memberof EggLetter
     */
    title?: string;
    /**
     * 正文
     * @type {string}
     * @memberof EggLetter
     */
    content?: string;
    /**
     * 发送人
     * @type {string}
     * @memberof EggLetter
     */
    from?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EggLetter
     */
    visible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EggLetter
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EggLetter
     */
    lastModifiedDate?: string;
}
/**
 * 查询单词返回体
 * @export
 * @interface EnWordDetailResponse
 */
export interface EnWordDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof EnWordDetailResponse
     */
    word?: string;
    /**
     * 单词释义
     * @type {string}
     * @memberof EnWordDetailResponse
     */
    explain?: string;
    /**
     * 英式音标
     * @type {string}
     * @memberof EnWordDetailResponse
     */
    phonetic_uk?: string;
    /**
     * 美式音标
     * @type {string}
     * @memberof EnWordDetailResponse
     */
    phonetic_us?: string;
}
/**
 * 单词列表
 * @export
 * @interface EnWordListResponse
 */
export interface EnWordListResponse {
    /**
     * 
     * @type {Array<EnWordDetailResponse>}
     * @memberof EnWordListResponse
     */
    list?: Array<EnWordDetailResponse>;
}
/**
 * 通用的错误返回
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 业务上会将所有错误码定义好。可以用它作为翻译
     * @type {number}
     * @memberof ErrorResponse
     */
    errorCode: number;
    /**
     * 往往是内部错误信息或者英文提示，不建议直接显示给用户
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
    /**
     * 一个string to string map，用于显示错误的额外信息，本字段一般情况下没有内容
     * @type {object}
     * @memberof ErrorResponse
     */
    params: object;
    /**
     * 指明该错误的所属领域。不同domain下error code可以重复，见各domain的error code定义
     * @type {string}
     * @memberof ErrorResponse
     */
    domain: string;
}
/**
 * 主要是事件信息集合
 * @export
 * @interface EventLogListRequest
 */
export interface EventLogListRequest {
    /**
     * 
     * @type {Array<EventLogRequest>}
     * @memberof EventLogListRequest
     */
    events_data: Array<EventLogRequest>;
}
/**
 * 要被保存的事件信息
 * @export
 * @interface EventLogRequest
 */
export interface EventLogRequest {
    /**
     * 事件发生的时间
     * @type {string}
     * @memberof EventLogRequest
     */
    event_time: string;
    /**
     * 发生的事件类型
     * @type {string}
     * @memberof EventLogRequest
     */
    event_key: string;
    /**
     * 发生的事件详细信息
     * @type {string}
     * @memberof EventLogRequest
     */
    event_data: string;
    /**
     * 上报火山数据中的概念
     * @type {string}
     * @memberof EventLogRequest
     */
    app_id?: string;
    /**
     * 火山中表明用户与设备关联的标识
     * @type {string}
     * @memberof EventLogRequest
     */
    ssid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLogRequest
     */
    device_id?: string;
    /**
     * 
     * @type {number}
     * @memberof EventLogRequest
     */
    user_unique_id?: number;
}
/**
 * 
 * @export
 * @interface ExchangeVipDaysResponse
 */
export interface ExchangeVipDaysResponse {
    /**
     * 
     * @type {number}
     * @memberof ExchangeVipDaysResponse
     */
    vip_days?: number;
}
/**
 * 
 * @export
 * @interface ExchangeVipResultResponse
 */
export interface ExchangeVipResultResponse {
    /**
     * 
     * @type {number}
     * @memberof ExchangeVipResultResponse
     */
    vip_days?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeVipResultResponse
     */
    expire_date?: string;
}
/**
 * 
 * @export
 * @interface ExtendStudyPlanRequest
 */
export interface ExtendStudyPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof ExtendStudyPlanRequest
     */
    deck_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtendStudyPlanRequest
     */
    study_days_extension_count?: number;
}
/**
 * 用户反馈信息
 * @export
 * @interface FeedbackResponseVO
 */
export interface FeedbackResponseVO {
    /**
     * 反馈信息唯一id
     * @type {number}
     * @memberof FeedbackResponseVO
     */
    feedback_id?: number;
    /**
     * 类型（感谢作者， 问题反馈）
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    type?: string;
    /**
     * 反馈的页面来源
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    source?: string;
    /**
     * 反馈（感谢）内容 / 评价内容
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    content?: string;
    /**
     * 回复用户的内容
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    replied_content?: string;
    /**
     * 
     * @type {number}
     * @memberof FeedbackResponseVO
     */
    report_user_id?: number;
    /**
     * 反馈（反馈）人
     * @type {any}
     * @memberof FeedbackResponseVO
     */
    report_user_nickname?: any | null;
    /**
     * 卡片id
     * @type {number}
     * @memberof FeedbackResponseVO
     */
    card_id?: number;
    /**
     * 卡片id(字符串类型)
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    card_id_v2?: string;
    /**
     * 图书id
     * @type {number}
     * @memberof FeedbackResponseVO
     */
    library_book_id?: number;
    /**
     * 图书（卡册）名称
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    library_book_title?: string;
    /**
     * 图书作者
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    library_book_author?: string;
    /**
     * 反馈者id
     * @type {number}
     * @memberof FeedbackResponseVO
     */
    book_author_id?: number;
    /**
     * 卡册UUID
     * @type {any}
     * @memberof FeedbackResponseVO
     */
    package_uuid?: any | null;
    /**
     * 图片列表（反馈问题有可能会用到图片）
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    image_url_list?: string;
    /**
     * 已读未读（问题反馈API会用到）
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    last_modified_date?: string;
    /**
     * 问题反馈解决时间
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    resolved_date?: string;
    /**
     * 用户在该本书下学了多长时间 单位 秒
     * @type {number}
     * @memberof FeedbackResponseVO
     */
    learnt_duration?: number;
    /**
     * 用户头像
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    avatar?: string;
    /**
     * 是否公开展示
     * @type {boolean}
     * @memberof FeedbackResponseVO
     */
    is_shown?: boolean;
    /**
     * 评价类型 推荐/不满意
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    remark_type?: string;
    /**
     * 反馈的卡包的releaseTag发布时间
     * @type {string}
     * @memberof FeedbackResponseVO
     */
    release_date?: string;
}
/**
 * 
 * @export
 * @interface FeedbackUpdateVO
 */
export interface FeedbackUpdateVO {
    /**
     * 必选参数 取值范围 [\'感谢作者\', \'内容纠错\']，这里没有设置成required是兼容接口原来的实现，用户评价继续使用 感谢作者
     * @type {string}
     * @memberof FeedbackUpdateVO
     */
    type?: string;
    /**
     * 内容来源 卡片页，图书 可选参数
     * @type {string}
     * @memberof FeedbackUpdateVO
     */
    source?: string;
    /**
     * 反馈内容/评价内容
     * @type {string}
     * @memberof FeedbackUpdateVO
     */
    content?: string;
    /**
     * 评价类型：好评/差评 可选值： GOOD/BAD
     * @type {string}
     * @memberof FeedbackUpdateVO
     */
    remark_type?: string;
    /**
     * 卡片id 保存感谢作者/用户评价 的时候不需要传
     * @type {number}
     * @memberof FeedbackUpdateVO
     */
    card_id?: number;
    /**
     * 保存感谢作者/用户评价 的时候不需要传 如果不为 null，则为 hpkg 卡片
     * @type {string}
     * @memberof FeedbackUpdateVO
     */
    card_id_v2?: string;
    /**
     * 上传图片的url列表，按|分隔
     * @type {string}
     * @memberof FeedbackUpdateVO
     */
    image_url_list?: string;
    /**
     * 输入图书id（新增感谢信时为必传）
     * @type {number}
     * @memberof FeedbackUpdateVO
     */
    library_book_id?: number;
    /**
     * z只用于修改感谢用户展示状态
     * @type {boolean}
     * @memberof FeedbackUpdateVO
     */
    shown?: boolean;
    /**
     * 对应的卡册版本
     * @type {string}
     * @memberof FeedbackUpdateVO
     */
    releaseTag?: string;
}
/**
 * 
 * @export
 * @interface FixedCardsCountLimitRequest
 */
export interface FixedCardsCountLimitRequest {
    /**
     * 
     * @type {number}
     * @memberof FixedCardsCountLimitRequest
     */
    deck_id?: number;
    /**
     * 
     * @type {number}
     * @memberof FixedCardsCountLimitRequest
     */
    debut_cards_count_limit_fixed_per_day?: number;
    /**
     * 
     * @type {number}
     * @memberof FixedCardsCountLimitRequest
     */
    review_cards_count_limit_fixed_per_day?: number;
    /**
     * 学习顺序设置 可选值（NEW_FIRST, REVIEW_FIRST, RANDOM_MIXED）
     * @type {string}
     * @memberof FixedCardsCountLimitRequest
     */
    learn_order?: string;
}
/**
 * 
 * @export
 * @interface FixedCardsCountLimitResponse
 */
export interface FixedCardsCountLimitResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FixedCardsCountLimitResponse
     */
    is_cards_count_limit_fixed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FixedCardsCountLimitResponse
     */
    debut_cards_count_limit_fixed_per_day?: number;
    /**
     * 
     * @type {number}
     * @memberof FixedCardsCountLimitResponse
     */
    review_cards_count_limit_fixed_per_day?: number;
}
/**
 * 
 * @export
 * @interface ForgettingCurveDotsDataRequest
 */
export interface ForgettingCurveDotsDataRequest {
    /**
     * 
     * @type {number}
     * @memberof ForgettingCurveDotsDataRequest
     */
    unused?: number;
}
/**
 * 
 * @export
 * @interface ForgettingCurveDotsDataResponse
 */
export interface ForgettingCurveDotsDataResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof ForgettingCurveDotsDataResponse
     */
    ebbinghaus_curve_dots?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ForgettingCurveDotsDataResponse
     */
    memo_algorithm_curve_dots?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ForgettingCurveDotsDataResponse
     */
    user_actual_curve_dots?: Array<number>;
}
/**
 * 会员免费拼团次数信息
 * @export
 * @interface FreeGroupInfo
 */
export interface FreeGroupInfo {
    /**
     * 剩余免费怒拼团次数
     * @type {number}
     * @memberof FreeGroupInfo
     */
    left_times?: number;
}
/**
 * 通用实体flag请求体
 * @export
 * @interface GeneralEntityFlagRequest
 */
export interface GeneralEntityFlagRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralEntityFlagRequest
     */
    value?: string;
}
/**
 * 通用实体flag返回体
 * @export
 * @interface GeneralEntityFlagResponse
 */
export interface GeneralEntityFlagResponse {
    /**
     * 
     * @type {string}
     * @memberof GeneralEntityFlagResponse
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntityFlagResponse
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntityFlagResponse
     */
    last_modified_date?: string;
}
/**
 * 生成小程序scheme
 * @export
 * @interface GenerateMpSchemeRequest
 */
export interface GenerateMpSchemeRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateMpSchemeRequest
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateMpSchemeRequest
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateMpSchemeRequest
     */
    env_version?: string;
}
/**
 * 生成小程序scheme返回体
 * @export
 * @interface GenerateMpSchemeResponse
 */
export interface GenerateMpSchemeResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateMpSchemeResponse
     */
    error_code?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateMpSchemeResponse
     */
    error_msg?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateMpSchemeResponse
     */
    open_link?: string;
}
/**
 * 
 * @export
 * @interface GenerateTokenCodeResponse
 */
export interface GenerateTokenCodeResponse {
    /**
     * 服务端生成授权码
     * @type {string}
     * @memberof GenerateTokenCodeResponse
     */
    auth_code?: string;
}
/**
 * 
 * @export
 * @interface GetAllSuspendedCardsRequest
 */
export interface GetAllSuspendedCardsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetAllSuspendedCardsRequest
     */
    deck_id?: number;
}
/**
 * 
 * @export
 * @interface GetAllSuspendedCardsResponse
 */
export interface GetAllSuspendedCardsResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetAllSuspendedCardsResponse
     */
    card_id_list?: Array<number>;
}
/**
 * 后台创作者图书列表
 * @export
 * @interface GetCreatorBookListResponse
 */
export interface GetCreatorBookListResponse {
    /**
     * 
     * @type {Array<CreatorBookVO>}
     * @memberof GetCreatorBookListResponse
     */
    list?: Array<CreatorBookVO>;
}
/**
 * 
 * @export
 * @interface GetDaysExtensionCountResponse
 */
export interface GetDaysExtensionCountResponse {
    /**
     * 
     * @type {number}
     * @memberof GetDaysExtensionCountResponse
     */
    days?: number;
}
/**
 * 图书详情页内容，包括未购和已购两种状态
 * @export
 * @interface GetLibraryBookInfo
 */
export interface GetLibraryBookInfo {
    /**
     * 是否支持试学
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    trail_support?: boolean;
    /**
     * 该图书是否已拥有（完全拥有，会员免费学和借阅都不属于拥有）
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    owned?: boolean;
    /**
     * 是否是上架状态, true:上架状态, false:下架状态
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    on_sale?: boolean;
    /**
     * 图书评论数量（感谢作者）
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    comment_number?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    is_free?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    price_of_book?: number;
    /**
     * 团购价格 若未开启团购则团购价为空
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    group_buy_price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    recommend?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    qm_title?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    qm_publisher?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    qm_publisher_user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    qm_publisher_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    qm_publisher_nickname?: string;
    /**
     * 
     * @type {Array<BadgeResponse>}
     * @memberof GetLibraryBookInfo
     */
    qm_publisher_badge_list?: Array<BadgeResponse>;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    qm_cover?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    qm_description?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    library_book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    origin_book_author?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    origin_book_title?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    origin_book_cover?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    origin_book_description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    category?: string;
    /**
     * 是否购买过
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    bought?: boolean;
    /**
     * （已购出现），图书过期时间（已经废弃）
     * @type {string}
     * @memberof GetLibraryBookInfo
     * @deprecated
     */
    expire_date?: string;
    /**
     * （已购出现）是否允许退款
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    is_refundable?: boolean;
    /**
     * 图书对应的package，如果创作者进行了更新，则这个值会发生变化，且与用户购买的 package_uuid不同。建议客户端提示有图书新版本，建议重置学习计划来更新。
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    book_latest_package_uuid?: string;
    /**
     * 如果未购，为图书对应的package；如果已购，则为用户正在学习的package。可以用于直接学习
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    package_uuid?: string;
    /**
     * 对于 SingleLibraryBook，旧版图书返回 1，新版图书返回 2
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    apkg_version?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    package_editor?: string;
    /**
     * 
     * @type {ListLibraryBookVO}
     * @memberof GetLibraryBookInfo
     */
    bundle_type_book_list?: ListLibraryBookVO;
    /**
     * 
     * @type {ListLibraryBookVO}
     * @memberof GetLibraryBookInfo
     */
    single_book_type_bundle_list?: ListLibraryBookVO;
    /**
     * 如果当前book type是 bundle，且用户已登录，则显示用户已经购买的图书列表
     * @type {Array<number>}
     * @memberof GetLibraryBookInfo
     */
    bundle_type_owned_book_list?: Array<number>;
    /**
     * 
     * @type {ListLibraryBookVO}
     * @memberof GetLibraryBookInfo
     */
    single_book_type_dlc_list?: ListLibraryBookVO;
    /**
     * 
     * @type {BookTypeResponse}
     * @memberof GetLibraryBookInfo
     */
    type?: BookTypeResponse;
    /**
     * bundle合集的价格，排除了用户已经购买的部分
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    price_of_bundle?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    package_proofreader?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    copyright?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    related_link?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetLibraryBookInfo
     */
    image_url_list?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    card_count?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    borrower_count?: number;
    /**
     * （已购出现，但现在未购也为0）已经学了多少张卡
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    learned_card_count?: number;
    /**
     * 关联的考试，还有几天考试
     * @type {number}
     * @memberof GetLibraryBookInfo
     * @deprecated
     */
    default_exam_plan_in_days?: number;
    /**
     * 关联的考试，考试日期
     * @type {string}
     * @memberof GetLibraryBookInfo
     * @deprecated
     */
    next_exam_date?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetLibraryBookInfo
     */
    flags?: { [key: string]: string; };
    /**
     * 图书原价（仅仅是展示用，不参与交易）
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    original_price?: number;
    /**
     * 营销图
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    marketing_img_url?: string;
    /**
     * v2版营销图字端
     * @type {Array<string>}
     * @memberof GetLibraryBookInfo
     */
    marketing_img_url_v2?: Array<string>;
    /**
     * 返利比例
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    marketing_channel_ratio?: number;
    /**
     * （分享返利）如果成功邀请一人，预计获得的收益（单位为分）
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    marketing_channel_return_amount?: number;
    /**
     * （分享返利）已经成功邀请的人数
     * @type {number}
     * @memberof GetLibraryBookInfo
     */
    marketing_channel_successful_invited?: number;
    /**
     * 当前libraryBook是否属于hjml
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    hjml?: boolean;
    /**
     * 当前libraryBook是否属于hjdoc
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    hjdoc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     * @deprecated
     */
    study_plan_exist?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryBookInfo
     */
    subscribe_expire_date?: string;
    /**
     * 
     * @type {SubscribeConfigSettingResponse}
     * @memberof GetLibraryBookInfo
     */
    subscribe_setting?: SubscribeConfigSettingResponse;
    /**
     * 是否可借阅
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    subscribe_available?: boolean;
    /**
     * （已购时不为空）列出每个deck下学习的张数
     * @type {Array<DeckLearningStatVo>}
     * @memberof GetLibraryBookInfo
     * @deprecated
     */
    deck_learning_stats?: Array<DeckLearningStatVo>;
    /**
     * 是否开启了会员免费学
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    vip_free?: boolean;
    /**
     * 该图书是否是使用会员免费学获取的
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    used_vip_free?: boolean;
    /**
     * 单独购买 abtest 是否命中
     * @type {boolean}
     * @memberof GetLibraryBookInfo
     */
    single_buy_abtest?: boolean;
}
/**
 * 
 * @export
 * @interface GetLibraryCategoryResponse
 */
export interface GetLibraryCategoryResponse {
    /**
     * 
     * @type {Array<GetLibraryCategoryResponseCategoryList>}
     * @memberof GetLibraryCategoryResponse
     */
    category_list?: Array<GetLibraryCategoryResponseCategoryList>;
}
/**
 * 
 * @export
 * @interface GetLibraryCategoryResponseCategoryList
 */
export interface GetLibraryCategoryResponseCategoryList {
    /**
     * 
     * @type {string}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    is_leaf?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    is_root?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    parent_category_id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    category_id?: number;
    /**
     * 是否已关注该分类
     * @type {boolean}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    followed?: boolean;
    /**
     * 分类别名
     * @type {string}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    alias?: string;
    /**
     * 关键词标签列表
     * @type {Array<string>}
     * @memberof GetLibraryCategoryResponseCategoryList
     */
    keywords_tag?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetLibraryLeaseListResponse
 */
export interface GetLibraryLeaseListResponse {
    /**
     * 
     * @type {Array<GetLibraryLeaseListResponseLeaseList>}
     * @memberof GetLibraryLeaseListResponse
     */
    lease_list?: Array<GetLibraryLeaseListResponseLeaseList>;
}
/**
 * 
 * @export
 * @interface GetLibraryLeaseListResponseLeaseList
 */
export interface GetLibraryLeaseListResponseLeaseList {
    /**
     * 
     * @type {GetLibraryLeaseListResponseOrder}
     * @memberof GetLibraryLeaseListResponseLeaseList
     */
    order?: GetLibraryLeaseListResponseOrder;
    /**
     * 
     * @type {GetLibraryLeaseListResponseLibraryBook}
     * @memberof GetLibraryLeaseListResponseLeaseList
     */
    library_book?: GetLibraryLeaseListResponseLibraryBook;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseLeaseList
     */
    resource_id?: number;
}
/**
 * 
 * @export
 * @interface GetLibraryLeaseListResponseLibraryBook
 */
export interface GetLibraryLeaseListResponseLibraryBook {
    /**
     * 
     * @type {string}
     * @memberof GetLibraryLeaseListResponseLibraryBook
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryLeaseListResponseLibraryBook
     */
    image_url?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseLibraryBook
     */
    library_book_id?: number;
}
/**
 * 
 * @export
 * @interface GetLibraryLeaseListResponseOrder
 */
export interface GetLibraryLeaseListResponseOrder {
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    order_id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    created_date?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    order_status?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    seller_user_id?: number;
    /**
     * 
     * @type {GetLibraryLeaseListResponseOrderCoupon}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    coupon?: GetLibraryLeaseListResponseOrderCoupon;
    /**
     * 
     * @type {GetLibraryLeaseListResponseOrderPayment}
     * @memberof GetLibraryLeaseListResponseOrder
     */
    payment?: GetLibraryLeaseListResponseOrderPayment;
}
/**
 * 
 * @export
 * @interface GetLibraryLeaseListResponseOrderCoupon
 */
export interface GetLibraryLeaseListResponseOrderCoupon {
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseOrderCoupon
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface GetLibraryLeaseListResponseOrderPayment
 */
export interface GetLibraryLeaseListResponseOrderPayment {
    /**
     * 
     * @type {string}
     * @memberof GetLibraryLeaseListResponseOrderPayment
     */
    gateway?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLibraryLeaseListResponseOrderPayment
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface GetStudyPlanRequest
 */
export interface GetStudyPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof GetStudyPlanRequest
     */
    deck_id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStudyPlanRequest
     */
    study_days_count?: number;
}
/**
 * 
 * @export
 * @interface GetStudyPlanResponse
 */
export interface GetStudyPlanResponse {
    /**
     * 
     * @type {number}
     * @memberof GetStudyPlanResponse
     */
    cards_count_planned_per_day?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStudyPlanResponse
     */
    total_study_days_count?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStudyPlanResponse
     */
    study_time_planned_per_day?: number;
}
/**
 * 
 * @export
 * @interface GetWeixinMpBindingResponse
 */
export interface GetWeixinMpBindingResponse {
    /**
     * 是否已经绑定过某一个openId
     * @type {boolean}
     * @memberof GetWeixinMpBindingResponse
     */
    has_binding?: boolean;
}
/**
 * 消耗金箔之后的返回数据
 * @export
 * @interface GildConsumeResponse
 */
export interface GildConsumeResponse {
    /**
     * 全局已经使用的金箔
     * @type {number}
     * @memberof GildConsumeResponse
     */
    gild_global_consumed?: number;
    /**
     * 用户还剩余的金箔数量
     * @type {number}
     * @memberof GildConsumeResponse
     */
    gild_remaining?: number;
    /**
     * 用户已经使用过的金箔数量
     * @type {number}
     * @memberof GildConsumeResponse
     */
    gild_consumed?: number;
    /**
     * 全局使用金箔可以达到的上线，后端的话，会在数据库写死
     * @type {number}
     * @memberof GildConsumeResponse
     */
    global_maximum_gild?: number;
}
/**
 * 当前团购的状态，用于判断是否可以取消拼团
 * @export
 * @interface GroupBuyCancelableStatus
 */
export interface GroupBuyCancelableStatus {
    /**
     * 
     * @type {string}
     * @memberof GroupBuyCancelableStatus
     */
    group_buy_status?: GroupBuyCancelableStatusGroupBuyStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GroupBuyCancelableStatusGroupBuyStatusEnum {
    Cancelable = 'CANCELABLE',
    UnableLessThanOneHour = 'UNABLE_LESS_THAN_ONE_HOUR',
    Unable = 'UNABLE'
}

/**
 * 拼团快照VO
 * @export
 * @interface GroupBuyConfigSnapshotVO
 */
export interface GroupBuyConfigSnapshotVO {
    /**
     * 最大成团人数
     * @type {number}
     * @memberof GroupBuyConfigSnapshotVO
     */
    max_count: number;
    /**
     * 最小成团人数
     * @type {number}
     * @memberof GroupBuyConfigSnapshotVO
     */
    min_count: number;
    /**
     * 开团持续时间
     * @type {number}
     * @memberof GroupBuyConfigSnapshotVO
     */
    duration?: number;
    /**
     * 团购类型（固定成团还是阶梯）
     * @type {string}
     * @memberof GroupBuyConfigSnapshotVO
     */
    group_buy_type: string;
    /**
     * 开团成团的金额
     * @type {number}
     * @memberof GroupBuyConfigSnapshotVO
     */
    fixed_price: number;
    /**
     * 
     * @type {string}
     * @memberof GroupBuyConfigSnapshotVO
     */
    created_date?: string;
    /**
     * 是否允许进行拼团
     * @type {number}
     * @memberof GroupBuyConfigSnapshotVO
     */
    enable_initiate_group?: number;
    /**
     * 用户邀请拼团分成
     * @type {number}
     * @memberof GroupBuyConfigSnapshotVO
     */
    marketing_channel_ratio?: number;
    /**
     * 是否是无限拼团 true 开启无限拼团 false 关闭无限拼团
     * @type {boolean}
     * @memberof GroupBuyConfigSnapshotVO
     */
    enable_infinite_groupbuy?: boolean;
    /**
     * 是否开启自动完成拼团 true是 false不是
     * @type {boolean}
     * @memberof GroupBuyConfigSnapshotVO
     */
    automatic_complete_groupbuy?: boolean;
}
/**
 * 拼团实例VO
 * @export
 * @interface GroupBuyInstanceVO
 */
export interface GroupBuyInstanceVO {
    /**
     * 
     * @type {number}
     * @memberof GroupBuyInstanceVO
     */
    group_buy_instance_id: number;
    /**
     * 
     * @type {number}
     * @memberof GroupBuyInstanceVO
     */
    initiator_user_id: number;
    /**
     * 
     * @type {GroupBuyConfigSnapshotVO}
     * @memberof GroupBuyInstanceVO
     */
    group_buy_config_snapshot: GroupBuyConfigSnapshotVO;
    /**
     * 
     * @type {string}
     * @memberof GroupBuyInstanceVO
     */
    open_date: string;
    /**
     * 
     * @type {string}
     * @memberof GroupBuyInstanceVO
     */
    close_date?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupBuyInstanceVO
     */
    status?: string;
    /**
     * 
     * @type {Array<GroupBuyInstanceVOMembers>}
     * @memberof GroupBuyInstanceVO
     */
    members: Array<GroupBuyInstanceVOMembers>;
}
/**
 * 
 * @export
 * @interface GroupBuyInstanceVOMembers
 */
export interface GroupBuyInstanceVOMembers {
    /**
     * 
     * @type {number}
     * @memberof GroupBuyInstanceVOMembers
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupBuyInstanceVOMembers
     */
    user_nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupBuyInstanceVOMembers
     */
    user_image_url?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupBuyInstanceVOMembers
     */
    purchase_order_id?: number;
}
/**
 * 
 * @export
 * @interface HjdocNoteVo
 */
export interface HjdocNoteVo {
    /**
     * 
     * @type {number}
     * @memberof HjdocNoteVo
     */
    id?: number;
    /**
     * v2版本id
     * @type {string}
     * @memberof HjdocNoteVo
     */
    id_v2?: string;
    /**
     * hjml内容
     * @type {string}
     * @memberof HjdocNoteVo
     */
    hjdoc?: string;
}
/**
 * 
 * @export
 * @interface HjdocTemplateListResponse
 */
export interface HjdocTemplateListResponse {
    /**
     * 
     * @type {Array<HjdocTemplateResponse>}
     * @memberof HjdocTemplateListResponse
     */
    list?: Array<HjdocTemplateResponse>;
}
/**
 * 
 * @export
 * @interface HjdocTemplateResponse
 */
export interface HjdocTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof HjdocTemplateResponse
     */
    hjdoc_template_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HjdocTemplateResponse
     */
    published?: boolean;
    /**
     * 模板名称
     * @type {string}
     * @memberof HjdocTemplateResponse
     */
    template_name?: string;
    /**
     * 查询模板详情时会填充该值
     * @type {string}
     * @memberof HjdocTemplateResponse
     */
    template?: string;
    /**
     * 
     * @type {string}
     * @memberof HjdocTemplateResponse
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof HjdocTemplateResponse
     */
    last_modified_date?: string;
}
/**
 * 
 * @export
 * @interface HjdocTemplateUpdateRequest
 */
export interface HjdocTemplateUpdateRequest {
    /**
     * 新增时需要用到，优先使用客户端传入的id作为主键
     * @type {string}
     * @memberof HjdocTemplateUpdateRequest
     */
    template_id?: string;
    /**
     * 模板名称
     * @type {string}
     * @memberof HjdocTemplateUpdateRequest
     */
    template_name?: string;
    /**
     * 模板内容
     * @type {string}
     * @memberof HjdocTemplateUpdateRequest
     */
    template?: string;
}
/**
 * 
 * @export
 * @interface HjmlNoteVo
 */
export interface HjmlNoteVo {
    /**
     * 
     * @type {number}
     * @memberof HjmlNoteVo
     */
    id?: number;
    /**
     * v2版本id
     * @type {string}
     * @memberof HjmlNoteVo
     */
    id_v2?: string;
    /**
     * hjml内容
     * @type {string}
     * @memberof HjmlNoteVo
     */
    hjml?: string;
}
/**
 * 
 * @export
 * @interface HjmlTemplateListResponse
 */
export interface HjmlTemplateListResponse {
    /**
     * 
     * @type {Array<HjmlTemplateResponse>}
     * @memberof HjmlTemplateListResponse
     */
    list?: Array<HjmlTemplateResponse>;
}
/**
 * 
 * @export
 * @interface HjmlTemplateResponse
 */
export interface HjmlTemplateResponse {
    /**
     * 
     * @type {string}
     * @memberof HjmlTemplateResponse
     */
    hjml_template_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HjmlTemplateResponse
     */
    published?: boolean;
    /**
     * 模板名称
     * @type {string}
     * @memberof HjmlTemplateResponse
     */
    template_name?: string;
    /**
     * 查询模板详情时会填充该值
     * @type {string}
     * @memberof HjmlTemplateResponse
     */
    template?: string;
    /**
     * 
     * @type {string}
     * @memberof HjmlTemplateResponse
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof HjmlTemplateResponse
     */
    last_modified_date?: string;
}
/**
 * 
 * @export
 * @interface HjmlTemplateUpdateRequest
 */
export interface HjmlTemplateUpdateRequest {
    /**
     * 新增时需要用到，优先使用客户端传入的id作为主键
     * @type {string}
     * @memberof HjmlTemplateUpdateRequest
     */
    template_id?: string;
    /**
     * 模板名称
     * @type {string}
     * @memberof HjmlTemplateUpdateRequest
     */
    template_name?: string;
    /**
     * 模板内容
     * @type {string}
     * @memberof HjmlTemplateUpdateRequest
     */
    template?: string;
}
/**
 * 
 * @export
 * @interface HpkgCardRateLogRequest
 */
export interface HpkgCardRateLogRequest {
    /**
     * 
     * @type {string}
     * @memberof HpkgCardRateLogRequest
     */
    package_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgCardRateLogRequest
     */
    card_id?: string;
    /**
     * 
     * @type {number}
     * @memberof HpkgCardRateLogRequest
     */
    page?: number;
}
/**
 * 
 * @export
 * @interface HpkgCardStatisticRequest
 */
export interface HpkgCardStatisticRequest {
    /**
     * 
     * @type {string}
     * @memberof HpkgCardStatisticRequest
     */
    package_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgCardStatisticRequest
     */
    card_id?: string;
}
/**
 * hpkg发布更新日志列表
 * @export
 * @interface HpkgChangeLogListResponse
 */
export interface HpkgChangeLogListResponse {
    /**
     * 日志列表
     * @type {Array<HpkgChangeLogResponse>}
     * @memberof HpkgChangeLogListResponse
     */
    list?: Array<HpkgChangeLogResponse>;
}
/**
 * hpkg发布更新日志
 * @export
 * @interface HpkgChangeLogResponse
 */
export interface HpkgChangeLogResponse {
    /**
     * 卡包ID
     * @type {string}
     * @memberof HpkgChangeLogResponse
     */
    hpkg_id?: string;
    /**
     * 当时的发布版本
     * @type {string}
     * @memberof HpkgChangeLogResponse
     */
    release_tag?: string;
    /**
     * 更新说明（用户填写的，客户端展示需要使用这个）
     * @type {string}
     * @memberof HpkgChangeLogResponse
     */
    change_log?: string;
    /**
     * 
     * @type {HpkgChangesSummary}
     * @memberof HpkgChangeLogResponse
     */
    changes_summary?: HpkgChangesSummary;
    /**
     * 发布时间
     * @type {string}
     * @memberof HpkgChangeLogResponse
     */
    release_date?: string;
    /**
     * 
     * @type {HpkgMeta}
     * @memberof HpkgChangeLogResponse
     */
    meta?: HpkgMeta;
}
/**
 * 
 * @export
 * @interface HpkgChangesResponse
 */
export interface HpkgChangesResponse {
    /**
     * 当前最新的版本，如果要发布更新，需要传入 version，就在这里返回吧
     * @type {string}
     * @memberof HpkgChangesResponse
     */
    newest_version?: string;
    /**
     * 
     * @type {HpkgChangesSummary}
     * @memberof HpkgChangesResponse
     */
    summary?: HpkgChangesSummary;
}
/**
 * 
 * @export
 * @interface HpkgChangesSummary
 */
export interface HpkgChangesSummary {
    /**
     * 首次发布时为 null
     * @type {string}
     * @memberof HpkgChangesSummary
     */
    old_package_name?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgChangesSummary
     */
    new_package_name?: string;
    /**
     * 添加的卡片数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    cards_added?: number;
    /**
     * 更新的卡片数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    cards_updated?: number;
    /**
     * 删除的卡片数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    cards_deleted?: number;
    /**
     * 添加的目录数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    decks_added?: number;
    /**
     * 更新的目录数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    decks_updated?: number;
    /**
     * 删除的目录数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    decks_deleted?: number;
    /**
     * 添加的 model 数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    models_added?: number;
    /**
     * 更新的 model 数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    models_updated?: number;
    /**
     * 删除的 model 数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    models_deleted?: number;
    /**
     * 添加的 media 数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    medias_added?: number;
    /**
     * 更新的 media 数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    medias_updated?: number;
    /**
     * 删除的 media 数量
     * @type {number}
     * @memberof HpkgChangesSummary
     */
    medias_deleted?: number;
}
/**
 * 
 * @export
 * @interface HpkgIdVersionOrTag
 */
export interface HpkgIdVersionOrTag {
    /**
     * 
     * @type {string}
     * @memberof HpkgIdVersionOrTag
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgIdVersionOrTag
     */
    version_or_tag?: string;
}
/**
 * 
 * @export
 * @interface HpkgMeta
 */
export interface HpkgMeta {
    /**
     * 
     * @type {string}
     * @memberof HpkgMeta
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgMeta
     */
    version_or_tag?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgMeta
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgMeta
     */
    icon?: string;
    /**
     * 
     * @type {number}
     * @memberof HpkgMeta
     */
    card_count?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HpkgMeta
     */
    is_bought?: boolean;
    /**
     * 如果是购买卡册，对应购买时间
     * @type {string}
     * @memberof HpkgMeta
     */
    created_time?: string;
    /**
     * 最后更新时间
     * @type {string}
     * @memberof HpkgMeta
     */
    last_update_time?: string;
    /**
     * 最后发布时间
     * @type {string}
     * @memberof HpkgMeta
     */
    last_release_time?: string;
    /**
     * 所属分类
     * @type {string}
     * @memberof HpkgMeta
     */
    category?: string;
    /**
     * 所属作者
     * @type {string}
     * @memberof HpkgMeta
     */
    author?: string;
    /**
     * 该图书的借阅状态是否开启
     * @type {boolean}
     * @memberof HpkgMeta
     */
    subscribe_status?: boolean;
    /**
     * 借阅过期时间
     * @type {string}
     * @memberof HpkgMeta
     */
    subscribe_expire_date?: string;
    /**
     * 是否是会员免费学
     * @type {boolean}
     * @memberof HpkgMeta
     */
    vip_free?: boolean;
    /**
     * 是否是hjml卡册
     * @type {boolean}
     * @memberof HpkgMeta
     */
    hjml?: boolean;
    /**
     * 是否是hjdoc卡册
     * @type {boolean}
     * @memberof HpkgMeta
     */
    hjdoc?: boolean;
}
/**
 * 
 * @export
 * @interface HpkgPublishRequest
 */
export interface HpkgPublishRequest {
    /**
     * 
     * @type {string}
     * @memberof HpkgPublishRequest
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof HpkgPublishRequest
     */
    change_log?: string;
}
/**
 * 客户端传入已有 hpkg 信息，包括自建卡册的 id version 和 购买卡册的 id release tag
 * @export
 * @interface HpkgSyncRequest
 */
export interface HpkgSyncRequest {
    /**
     * 
     * @type {Array<HpkgIdVersionOrTag>}
     * @memberof HpkgSyncRequest
     */
    original?: Array<HpkgIdVersionOrTag>;
    /**
     * 
     * @type {Array<HpkgIdVersionOrTag>}
     * @memberof HpkgSyncRequest
     */
    bought?: Array<HpkgIdVersionOrTag>;
}
/**
 * 返回与客户端 hpkg 列表的差异
 * @export
 * @interface HpkgSyncResponse
 */
export interface HpkgSyncResponse {
    /**
     * 已删除的 hpkg id 列表
     * @type {Array<string>}
     * @memberof HpkgSyncResponse
     */
    deleted?: Array<string>;
    /**
     * 
     * @type {Array<HpkgMeta>}
     * @memberof HpkgSyncResponse
     */
    updated?: Array<HpkgMeta>;
    /**
     * 
     * @type {Array<HpkgMeta>}
     * @memberof HpkgSyncResponse
     */
    created?: Array<HpkgMeta>;
}
/**
 * 实名认证结果返回体
 * @export
 * @interface IdentityVerifyResultResponse
 */
export interface IdentityVerifyResultResponse {
    /**
     * 是否已实名认证
     * @type {boolean}
     * @memberof IdentityVerifyResultResponse
     */
    is_identified: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerifyResultResponse
     */
    message?: string;
}
/**
 * 实名认证请求体
 * @export
 * @interface IdentityVerifyTokenRequest
 */
export interface IdentityVerifyTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof IdentityVerifyTokenRequest
     */
    real_name: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerifyTokenRequest
     */
    id_number: string;
}
/**
 * 实名认证返回token（30分钟内有效）
 * @export
 * @interface IdentityVerifyTokenResponse
 */
export interface IdentityVerifyTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof IdentityVerifyTokenResponse
     */
    verify_token: string;
}
/**
 * 
 * @export
 * @interface IncomeDetailVO
 */
export interface IncomeDetailVO {
    /**
     * 图书借阅/团购
     * @type {string}
     * @memberof IncomeDetailVO
     */
    transaction_type?: string;
    /**
     * 交易名
     * @type {string}
     * @memberof IncomeDetailVO
     */
    transaction_name?: string;
    /**
     * 
     * @type {number}
     * @memberof IncomeDetailVO
     */
    order_amount?: number;
    /**
     * 交易状态
     * @type {string}
     * @memberof IncomeDetailVO
     */
    transaction_status?: string;
    /**
     * 
     * @type {string}
     * @memberof IncomeDetailVO
     */
    purchase_order_id?: string;
    /**
     * 交易渠道
     * @type {string}
     * @memberof IncomeDetailVO
     */
    transaction_channel?: string;
    /**
     * 礼券折扣
     * @type {number}
     * @memberof IncomeDetailVO
     */
    coupon_discount?: number;
    /**
     * 实付金额
     * @type {number}
     * @memberof IncomeDetailVO
     */
    actual_amount?: number;
    /**
     * 支付渠道手续费
     * @type {number}
     * @memberof IncomeDetailVO
     */
    channel_cost?: number;
    /**
     * 活动费用，用户邀请返利也使用在这字段
     * @type {number}
     * @memberof IncomeDetailVO
     */
    campaign_cost?: number;
    /**
     * 平台分成
     * @type {number}
     * @memberof IncomeDetailVO
     */
    platform_cost?: number;
    /**
     * 结算金额
     * @type {number}
     * @memberof IncomeDetailVO
     */
    settlement_amount?: number;
    /**
     * 用户id
     * @type {number}
     * @memberof IncomeDetailVO
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof IncomeDetailVO
     */
    created_date?: string;
    /**
     * 结算时间
     * @type {string}
     * @memberof IncomeDetailVO
     */
    settlement_date?: string;
    /**
     * 购书用户昵称
     * @type {string}
     * @memberof IncomeDetailVO
     */
    nickname?: string;
    /**
     * 购买用户的电话
     * @type {string}
     * @memberof IncomeDetailVO
     */
    phone_number?: string;
}
/**
 * 
 * @export
 * @interface InitDefaultDeckAndCardDynamicScheduleRequest
 */
export interface InitDefaultDeckAndCardDynamicScheduleRequest {
    /**
     * 
     * @type {number}
     * @memberof InitDefaultDeckAndCardDynamicScheduleRequest
     */
    package_id?: number;
}
/**
 * 
 * @export
 * @interface InitDefaultPackageAndDeckConfigRequest
 */
export interface InitDefaultPackageAndDeckConfigRequest {
    /**
     * 
     * @type {number}
     * @memberof InitDefaultPackageAndDeckConfigRequest
     */
    package_id?: number;
    /**
     * 3 valid values, DEFAULT, FREQUENT, CRAM.
     * @type {string}
     * @memberof InitDefaultPackageAndDeckConfigRequest
     */
    study_mode?: string;
}
/**
 * 
 * @export
 * @interface InitDefaultUserConfigRequest
 */
export interface InitDefaultUserConfigRequest {
    /**
     * 
     * @type {number}
     * @memberof InitDefaultUserConfigRequest
     */
    unused?: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    level?: InlineObjectLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    source?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObjectLevelEnum {
    Info = 'info',
    Warning = 'warning',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    count?: number;
}
/**
 * 站内信详情
 * @export
 * @interface InnerMessageDetail
 */
export interface InnerMessageDetail {
    /**
     * 用于管理员查看站内信时返回
     * @type {number}
     * @memberof InnerMessageDetail
     */
    inner_message_id?: number;
    /**
     * 用于用户拉取消息列表时返回
     * @type {number}
     * @memberof InnerMessageDetail
     */
    receiver_inner_message_id?: number;
    /**
     * 
     * @type {string}
     * @memberof InnerMessageDetail
     */
    content?: string;
    /**
     * 是否是全局消息
     * @type {boolean}
     * @memberof InnerMessageDetail
     */
    global?: boolean;
    /**
     * 消息状态 未读，已读
     * @type {string}
     * @memberof InnerMessageDetail
     */
    status?: string;
    /**
     * 消息发送时间，可用于客户端消息拉取
     * @type {string}
     * @memberof InnerMessageDetail
     */
    send_time?: string;
    /**
     * 
     * @type {string}
     * @memberof InnerMessageDetail
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof InnerMessageDetail
     */
    last_modified_date?: string;
}
/**
 * 
 * @export
 * @interface InteractiveCountResponse
 */
export interface InteractiveCountResponse {
    /**
     * 
     * @type {number}
     * @memberof InteractiveCountResponse
     */
    merit_count?: number;
    /**
     * 
     * @type {number}
     * @memberof InteractiveCountResponse
     */
    enc_count?: number;
}
/**
 * 
 * @export
 * @interface InteractiveMsgItem
 */
export interface InteractiveMsgItem {
    /**
     * 
     * @type {number}
     * @memberof InteractiveMsgItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InteractiveMsgItem
     */
    sender_nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractiveMsgItem
     */
    sender_avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractiveMsgItem
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractiveMsgItem
     */
    message_type?: InteractiveMsgItemMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InteractiveMsgItem
     */
    replied_date?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractiveMsgItem
     */
    read_date?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractiveMsgItem
     */
    created_date?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InteractiveMsgItemMessageTypeEnum {
    Encouraging = 'ENCOURAGING',
    Merit = 'MERIT'
}

/**
 * 
 * @export
 * @interface InteractiveMsgListResponse
 */
export interface InteractiveMsgListResponse {
    /**
     * 
     * @type {Array<InteractiveMsgItem>}
     * @memberof InteractiveMsgListResponse
     */
    messages?: Array<InteractiveMsgItem>;
}
/**
 * 
 * @export
 * @interface InteractiveMsgPageListResponse
 */
export interface InteractiveMsgPageListResponse {
    /**
     * 
     * @type {Array<InteractiveMsgItem>}
     * @memberof InteractiveMsgPageListResponse
     */
    content?: Array<InteractiveMsgItem>;
    /**
     * 
     * @type {number}
     * @memberof InteractiveMsgPageListResponse
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof InteractiveMsgPageListResponse
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof InteractiveMsgPageListResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof InteractiveMsgPageListResponse
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InteractiveMsgPageListResponse
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface JoinSquadRequest
 */
export interface JoinSquadRequest {
    /**
     * 
     * @type {string}
     * @memberof JoinSquadRequest
     */
    invite_code?: string;
}
/**
 * 
 * @export
 * @interface JsonDocBatchDeleteRequest
 */
export interface JsonDocBatchDeleteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonDocBatchDeleteRequest
     */
    docIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface JsonDocBatchDownloadRequest
 */
export interface JsonDocBatchDownloadRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonDocBatchDownloadRequest
     */
    docIds?: Array<string>;
    /**
     * 如果不为空，返回的 json doc content 将根据 extractions 抽取
     * @type {Array<JsonDocExtractRequest>}
     * @memberof JsonDocBatchDownloadRequest
     */
    extractions?: Array<JsonDocExtractRequest>;
}
/**
 * 
 * @export
 * @interface JsonDocBatchUploadRequest
 */
export interface JsonDocBatchUploadRequest {
    /**
     * 
     * @type {Array<JsonDocUploadRequest>}
     * @memberof JsonDocBatchUploadRequest
     */
    batch?: Array<JsonDocUploadRequest>;
}
/**
 * 
 * @export
 * @interface JsonDocExtractRequest
 */
export interface JsonDocExtractRequest {
    /**
     * 返回 json 中的 key
     * @type {string}
     * @memberof JsonDocExtractRequest
     */
    key?: string;
    /**
     * json path，语法参考 https://github.com/alibaba/fastjson/wiki/JSONPath
     * @type {string}
     * @memberof JsonDocExtractRequest
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface JsonDocIdVersion
 */
export interface JsonDocIdVersion {
    /**
     * 
     * @type {string}
     * @memberof JsonDocIdVersion
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonDocIdVersion
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface JsonDocListResponse
 */
export interface JsonDocListResponse {
    /**
     * 
     * @type {Array<JsonDocResponse>}
     * @memberof JsonDocListResponse
     */
    json_docs?: Array<JsonDocResponse>;
}
/**
 * 
 * @export
 * @interface JsonDocRecentUpdatesRequest
 */
export interface JsonDocRecentUpdatesRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonDocRecentUpdatesRequest
     */
    after?: string;
    /**
     * 是否是首次同步，首次同步时不会返回低优先级的内容，也不会返回已删除的内容，默认为 false
     * @type {boolean}
     * @memberof JsonDocRecentUpdatesRequest
     */
    is_first_time?: boolean;
}
/**
 * 
 * @export
 * @interface JsonDocRecentUpdatesResponse
 */
export interface JsonDocRecentUpdatesResponse {
    /**
     * 
     * @type {string}
     * @memberof JsonDocRecentUpdatesResponse
     */
    synced_time?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonDocRecentUpdatesResponse
     */
    deleted_docs?: Array<string>;
    /**
     * 
     * @type {Array<JsonDocIdVersion>}
     * @memberof JsonDocRecentUpdatesResponse
     */
    updated_docs?: Array<JsonDocIdVersion>;
    /**
     * 
     * @type {Array<JsonDocIdVersion>}
     * @memberof JsonDocRecentUpdatesResponse
     */
    recent_releases?: Array<JsonDocIdVersion>;
    /**
     * 
     * @type {Array<JsonDocResponse>}
     * @memberof JsonDocRecentUpdatesResponse
     */
    recent_open_releases?: Array<JsonDocResponse>;
}
/**
 * 
 * @export
 * @interface JsonDocReleaseBatchDownloadRequest
 */
export interface JsonDocReleaseBatchDownloadRequest {
    /**
     * 
     * @type {Array<JsonDocIdVersion>}
     * @memberof JsonDocReleaseBatchDownloadRequest
     */
    id_versions?: Array<JsonDocIdVersion>;
    /**
     * 如果不为空，返回的 json doc content 将根据 extractions 抽取
     * @type {Array<JsonDocExtractRequest>}
     * @memberof JsonDocReleaseBatchDownloadRequest
     */
    extractions?: Array<JsonDocExtractRequest>;
}
/**
 * 
 * @export
 * @interface JsonDocReleasedVersionInfoResponse
 */
export interface JsonDocReleasedVersionInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof JsonDocReleasedVersionInfoResponse
     */
    latest_released_version?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonDocReleasedVersionInfoResponse
     */
    newest_version?: string;
}
/**
 * 
 * @export
 * @interface JsonDocResponse
 */
export interface JsonDocResponse {
    /**
     * 
     * @type {string}
     * @memberof JsonDocResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonDocResponse
     */
    releaseTag?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonDocResponse
     */
    version?: string;
    /**
     * 序列化的 json
     * @type {string}
     * @memberof JsonDocResponse
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonDocResponse
     */
    group?: string;
    /**
     * 只在 upload 中使用，如果该 doc 在服务端已经有某个版本，但客户端却认为从未上传过（因为某些 bug，或者其他设备离线时初始化的学习数据等），则要求客户端先进行合并再重新同步
     * @type {boolean}
     * @memberof JsonDocResponse
     */
    upload_accepted?: boolean;
}
/**
 * 
 * @export
 * @interface JsonDocSnapshotBatchDownloadRequest
 */
export interface JsonDocSnapshotBatchDownloadRequest {
    /**
     * 
     * @type {Array<JsonDocIdVersion>}
     * @memberof JsonDocSnapshotBatchDownloadRequest
     */
    idVersions?: Array<JsonDocIdVersion>;
}
/**
 * 
 * @export
 * @interface JsonDocSyncRequest
 */
export interface JsonDocSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonDocSyncRequest
     */
    id?: string;
    /**
     * 客户端上次同步的版本
     * @type {string}
     * @memberof JsonDocSyncRequest
     */
    version?: string;
    /**
     * 客户端本地的更新，序列化的 json patch
     * @type {string}
     * @memberof JsonDocSyncRequest
     */
    patch?: string;
}
/**
 * 
 * @export
 * @interface JsonDocSyncResponse
 */
export interface JsonDocSyncResponse {
    /**
     * 服务器目前最新的版本
     * @type {string}
     * @memberof JsonDocSyncResponse
     */
    version?: string;
    /**
     * 客户端没有同步的其他更新，序列化的 json patch
     * @type {string}
     * @memberof JsonDocSyncResponse
     */
    patch?: string;
}
/**
 * 
 * @export
 * @interface JsonDocUploadRequest
 */
export interface JsonDocUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonDocUploadRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonDocUploadRequest
     */
    content?: string;
    /**
     * 检查授权时，检查 group 的授权
     * @type {string}
     * @memberof JsonDocUploadRequest
     */
    group?: string;
}
/**
 * 
 * @export
 * @interface JsonDocVersionInfo
 */
export interface JsonDocVersionInfo {
    /**
     * 版本号
     * @type {string}
     * @memberof JsonDocVersionInfo
     */
    version?: string;
    /**
     * 数据大小
     * @type {number}
     * @memberof JsonDocVersionInfo
     */
    file_size?: number;
    /**
     * 创建时间
     * @type {string}
     * @memberof JsonDocVersionInfo
     */
    created_date_time?: string;
    /**
     * 是否为当前版本
     * @type {boolean}
     * @memberof JsonDocVersionInfo
     */
    is_current_version?: boolean;
}
/**
 * 
 * @export
 * @interface JsonDocVersionInfoResponse
 */
export interface JsonDocVersionInfoResponse {
    /**
     * jsonDoc版本号信息列表
     * @type {Array<JsonDocVersionInfo>}
     * @memberof JsonDocVersionInfoResponse
     */
    doc_version_list?: Array<JsonDocVersionInfo>;
}
/**
 * 
 * @export
 * @interface LatestAppVersionResponse
 */
export interface LatestAppVersionResponse {
    /**
     * 
     * @type {object}
     * @memberof LatestAppVersionResponse
     */
    ios?: object;
    /**
     * 
     * @type {object}
     * @memberof LatestAppVersionResponse
     */
    android?: object;
    /**
     * 
     * @type {object}
     * @memberof LatestAppVersionResponse
     */
    windows?: object;
    /**
     * 
     * @type {object}
     * @memberof LatestAppVersionResponse
     */
    mac?: object;
}
/**
 * 
 * @export
 * @interface LearnRewardStatus
 */
export interface LearnRewardStatus {
    /**
     * 
     * @type {string}
     * @memberof LearnRewardStatus
     */
    received_date?: string;
    /**
     * 累计学习天数
     * @type {number}
     * @memberof LearnRewardStatus
     */
    accumulate_days?: number;
}
/**
 * 
 * @export
 * @interface LearnScheduleStatisticsResponse
 */
export interface LearnScheduleStatisticsResponse {
    /**
     * 日期纬度数据
     * @type {Array<string>}
     * @memberof LearnScheduleStatisticsResponse
     */
    date_series?: Array<string>;
    /**
     * 
     * @type {Array<LearnScheduleStatisticsResponseDataSeries>}
     * @memberof LearnScheduleStatisticsResponse
     */
    data_series?: Array<LearnScheduleStatisticsResponseDataSeries>;
}
/**
 * 
 * @export
 * @interface LearnScheduleStatisticsResponseDataSeries
 */
export interface LearnScheduleStatisticsResponseDataSeries {
    /**
     * 学习卡片数量
     * @type {number}
     * @memberof LearnScheduleStatisticsResponseDataSeries
     */
    learn_card_count?: number;
    /**
     * 单位是秒， 学习时长
     * @type {number}
     * @memberof LearnScheduleStatisticsResponseDataSeries
     */
    learn_duration?: number;
    /**
     * 学习次数
     * @type {number}
     * @memberof LearnScheduleStatisticsResponseDataSeries
     */
    learn_times?: number;
    /**
     * 记得次数
     * @type {number}
     * @memberof LearnScheduleStatisticsResponseDataSeries
     */
    good_times?: number;
    /**
     * 忘记次数
     * @type {number}
     * @memberof LearnScheduleStatisticsResponseDataSeries
     */
    again_times?: number;
}
/**
 * 活动页面letter的简介信息
 * @export
 * @interface LetterBriefInfo
 */
export interface LetterBriefInfo {
    /**
     * 发送者头像
     * @type {string}
     * @memberof LetterBriefInfo
     */
    avatar_url: string;
    /**
     * 接收者头像
     * @type {string}
     * @memberof LetterBriefInfo
     */
    recipient_avatar_url?: string;
    /**
     * 发送者id
     * @type {number}
     * @memberof LetterBriefInfo
     */
    sender_id?: number;
    /**
     * 发送者id
     * @type {number}
     * @memberof LetterBriefInfo
     */
    recipient_id?: number;
    /**
     * 
     * @type {string}
     * @memberof LetterBriefInfo
     */
    content: string;
    /**
     * 评论的数量
     * @type {number}
     * @memberof LetterBriefInfo
     */
    comment_count: number;
    /**
     * 
     * @type {number}
     * @memberof LetterBriefInfo
     */
    instance_letter_id?: number;
}
/**
 * letter的评论信息
 * @export
 * @interface LetterComment
 */
export interface LetterComment {
    /**
     * 
     * @type {string}
     * @memberof LetterComment
     */
    avatar_url: string;
    /**
     * 评论的内容
     * @type {string}
     * @memberof LetterComment
     */
    content: string;
    /**
     * 评论人的内容
     * @type {string}
     * @memberof LetterComment
     */
    comment_user_name: string;
}
/**
 * letter的详细信息
 * @export
 * @interface LetterInfoResponse
 */
export interface LetterInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof LetterInfoResponse
     */
    receiver_name: string;
    /**
     * 
     * @type {string}
     * @memberof LetterInfoResponse
     */
    sender_name: string;
    /**
     * 
     * @type {string}
     * @memberof LetterInfoResponse
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LetterInfoResponse
     */
    is_private?: boolean;
    /**
     * 头像url
     * @type {string}
     * @memberof LetterInfoResponse
     */
    avatar_url: string;
    /**
     * 创建者的用户id
     * @type {number}
     * @memberof LetterInfoResponse
     */
    sender_user_id?: number;
    /**
     * 评论列表
     * @type {Array<LetterComment>}
     * @memberof LetterInfoResponse
     */
    comment_list?: Array<LetterComment>;
}
/**
 * letter创建好了之后的返回值
 * @export
 * @interface LetterResponse
 */
export interface LetterResponse {
    /**
     * 返回创建好的letterid
     * @type {number}
     * @memberof LetterResponse
     */
    broadcast_letter_id?: number;
}
/**
 * 
 * @export
 * @interface LibraryBookLeaseResponse
 */
export interface LibraryBookLeaseResponse {
    /**
     * 
     * @type {LibraryBookLeaseResponseSku}
     * @memberof LibraryBookLeaseResponse
     */
    sku?: LibraryBookLeaseResponseSku;
    /**
     * 
     * @type {LibraryBookLeaseResponseLease}
     * @memberof LibraryBookLeaseResponse
     */
    lease?: LibraryBookLeaseResponseLease;
}
/**
 * 
 * @export
 * @interface LibraryBookLeaseResponseLease
 */
export interface LibraryBookLeaseResponseLease {
    /**
     * 
     * @type {string}
     * @memberof LibraryBookLeaseResponseLease
     */
    expire_date?: string;
}
/**
 * 
 * @export
 * @interface LibraryBookLeaseResponseSku
 */
export interface LibraryBookLeaseResponseSku {
    /**
     * 
     * @type {string}
     * @memberof LibraryBookLeaseResponseSku
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookLeaseResponseSku
     */
    amount?: number;
    /**
     * 
     * @type {LibraryBookLeaseResponseSkuPrice}
     * @memberof LibraryBookLeaseResponseSku
     */
    price?: LibraryBookLeaseResponseSkuPrice;
}
/**
 * 
 * @export
 * @interface LibraryBookLeaseResponseSkuPrice
 */
export interface LibraryBookLeaseResponseSkuPrice {
    /**
     * 
     * @type {number}
     * @memberof LibraryBookLeaseResponseSkuPrice
     */
    base_price?: number;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookLeaseResponseSkuPrice
     */
    fixed_price?: number;
    /**
     * 
     * @type {Array<LibraryBookLeaseResponseSkuPriceEdgeSetting>}
     * @memberof LibraryBookLeaseResponseSkuPrice
     */
    edge_setting?: Array<LibraryBookLeaseResponseSkuPriceEdgeSetting>;
}
/**
 * 
 * @export
 * @interface LibraryBookLeaseResponseSkuPriceEdgeSetting
 */
export interface LibraryBookLeaseResponseSkuPriceEdgeSetting {
    /**
     * 
     * @type {number}
     * @memberof LibraryBookLeaseResponseSkuPriceEdgeSetting
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookLeaseResponseSkuPriceEdgeSetting
     */
    factor?: number;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookLeaseResponseSkuPriceEdgeSetting
     */
    label_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryBookLeaseResponseSkuPriceEdgeSetting
     */
    is_show?: boolean;
}
/**
 * 
 * @export
 * @interface LibraryBookReportRequest
 */
export interface LibraryBookReportRequest {
    /**
     * 
     * @type {string}
     * @memberof LibraryBookReportRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookReportRequest
     */
    contact_info?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LibraryBookReportRequest
     */
    image_url_list?: Array<string>;
}
/**
 * Root Type for LibraryBook
 * @export
 * @interface LibraryBookVO
 */
export interface LibraryBookVO {
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    version?: number;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    recommend?: number;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    qm_title?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    qm_publisher?: string;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    qm_publisher_user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    qm_publisher_image_url?: string;
    /**
     * 创作者昵称
     * @type {string}
     * @memberof LibraryBookVO
     */
    qm_publisher_nickname?: string;
    /**
     * 
     * @type {Array<BadgeResponse>}
     * @memberof LibraryBookVO
     */
    qm_publisher_badge_list?: Array<BadgeResponse>;
    /**
     * 创作者头像
     * @type {string}
     * @memberof LibraryBookVO
     */
    qm_cover?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    qm_description?: string;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    library_book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    origin_book_author?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    origin_book_title?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    origin_book_cover?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    origin_book_description?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    category?: string;
    /**
     * 如果已经购买，则有值，往往是10年后的一个时间
     * @type {string}
     * @memberof LibraryBookVO
     * @deprecated
     */
    expire_date?: string;
    /**
     * 是否已经购买
     * @type {boolean}
     * @memberof LibraryBookVO
     */
    bought?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    package_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    package_editor?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    package_proofreader?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    copyright?: string;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    related_link?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LibraryBookVO
     */
    image_url_list?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    card_count?: number;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    borrower_count?: number;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    learned_card_count?: number;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    learned_card_count_today?: number;
    /**
     * 
     * @type {number}
     * @memberof LibraryBookVO
     */
    card_to_learn_per_day?: number;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    learn_status?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LibraryBookVO
     */
    flags?: { [key: string]: string; };
    /**
     * 废弃
     * @type {boolean}
     * @memberof LibraryBookVO
     */
    study_plan_exist?: boolean;
    /**
     * 分享返利返现比例
     * @type {number}
     * @memberof LibraryBookVO
     */
    marketing_channel_ratio?: number;
    /**
     * （分享返利）如果成功邀请一人，预计获得的收益（单位为分）
     * @type {number}
     * @memberof LibraryBookVO
     */
    marketing_channel_return_amount?: number;
    /**
     * 是否已上架；用户购买的图书，有可能已经下架了，此时不可分享赚钱
     * @type {boolean}
     * @memberof LibraryBookVO
     */
    on_sale?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    initial_on_sale_date?: string;
    /**
     * library_book 对应的ugc_book_id
     * @type {number}
     * @memberof LibraryBookVO
     */
    ugc_book_id?: number;
    /**
     * 图书价格（显示团购与单买价格中的最小值）。如果图书是bundle类型的话，就是bundle内图书价格之和 逐步废弃，今后避免使用这个字段
     * @type {number}
     * @memberof LibraryBookVO
     */
    fixed_price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryBookVO
     */
    is_free?: boolean;
    /**
     * 图书原价（仅仅是展示用，不参与交易）
     * @type {number}
     * @memberof LibraryBookVO
     */
    original_price?: number;
    /**
     * 实际上为图书sku 价格，获取方式同 GetLibraryBookInfo中同名字段
     * @type {number}
     * @memberof LibraryBookVO
     */
    price_of_book?: number;
    /**
     * 团购价格 若未开启团购则团购价为空
     * @type {number}
     * @memberof LibraryBookVO
     */
    group_buy_price?: number | null;
    /**
     * 是否开启了团购
     * @type {boolean}
     * @memberof LibraryBookVO
     */
    enable_group_buy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LibraryBookVO
     */
    dlc_key?: string;
    /**
     * 表明当前图书类型为bundle 或者其他类型, 废弃 后面不要继续使用
     * @type {string}
     * @memberof LibraryBookVO
     * @deprecated
     */
    book_type?: string;
    /**
     * 
     * @type {BookTypeResponse}
     * @memberof LibraryBookVO
     */
    type?: BookTypeResponse;
    /**
     * v2版营销图字端
     * @type {Array<string>}
     * @memberof LibraryBookVO
     */
    marketing_img_url_v2?: Array<string>;
    /**
     * 印象分
     * @type {number}
     * @memberof LibraryBookVO
     */
    impression_score?: number;
    /**
     * 是否是会员免费（包括会员免费学和会员免拼）
     * @type {boolean}
     * @memberof LibraryBookVO
     */
    vip_free_get?: boolean;
}
/**
 * 
 * @export
 * @interface ListEggLetterResponse
 */
export interface ListEggLetterResponse {
    /**
     * 
     * @type {Array<EggLetter>}
     * @memberof ListEggLetterResponse
     */
    letters?: Array<EggLetter>;
}
/**
 * 
 * @export
 * @interface ListLibraryBookVO
 */
export interface ListLibraryBookVO {
    /**
     * 
     * @type {Array<LibraryBookVO>}
     * @memberof ListLibraryBookVO
     */
    content?: Array<LibraryBookVO>;
}
/**
 * 
 * @export
 * @interface LockedDecksVO
 */
export interface LockedDecksVO {
    /**
     * 
     * @type {Array<number>}
     * @memberof LockedDecksVO
     */
    deck_id_list?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof LockedDecksVO
     */
    root_deck_id?: number;
}
/**
 * 修改图书的人工评分
 * @export
 * @interface ManualScoreRequest
 */
export interface ManualScoreRequest {
    /**
     * 
     * @type {number}
     * @memberof ManualScoreRequest
     */
    library_book_id: number;
    /**
     * 
     * @type {number}
     * @memberof ManualScoreRequest
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof ManualScoreRequest
     */
    reason?: string;
}
/**
 * 获取商家账户信息
 * @export
 * @interface MerchantAccountVO
 */
export interface MerchantAccountVO {
    /**
     * 支付宝余额 单位 分
     * @type {number}
     * @memberof MerchantAccountVO
     */
    alipay_balance?: number;
    /**
     * 微信账户余额 单位 分
     * @type {number}
     * @memberof MerchantAccountVO
     */
    wechat_balance?: number;
}
/**
 * 
 * @export
 * @interface MessageUnReadResponse
 */
export interface MessageUnReadResponse {
    /**
     * 
     * @type {number}
     * @memberof MessageUnReadResponse
     */
    inner_msg_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageUnReadResponse
     */
    interactive_msg_count?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageUnReadResponse
     */
    creator_msg_count?: number;
}
/**
 * 向ml请求获取图书推荐列表
 * @export
 * @interface MlRecommendBooksRequest
 */
export interface MlRecommendBooksRequest {
    /**
     * 
     * @type {string}
     * @memberof MlRecommendBooksRequest
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof MlRecommendBooksRequest
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof MlRecommendBooksRequest
     */
    register_source?: string;
    /**
     * 
     * @type {Array<MlRecommendBooksRequestHistBook>}
     * @memberof MlRecommendBooksRequest
     */
    hist_book?: Array<MlRecommendBooksRequestHistBook>;
    /**
     * 
     * @type {number}
     * @memberof MlRecommendBooksRequest
     */
    top_k?: number;
}
/**
 * 
 * @export
 * @interface MlRecommendBooksRequestHistBook
 */
export interface MlRecommendBooksRequestHistBook {
    /**
     * 
     * @type {number}
     * @memberof MlRecommendBooksRequestHistBook
     */
    book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof MlRecommendBooksRequestHistBook
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof MlRecommendBooksRequestHistBook
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof MlRecommendBooksRequestHistBook
     */
    event_time?: string;
}
/**
 * ml返回图书推荐列表
 * @export
 * @interface MlRecommendBooksResponse
 */
export interface MlRecommendBooksResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof MlRecommendBooksResponse
     */
    book_ids?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof MlRecommendBooksResponse
     */
    model_tag?: string;
}
/**
 * 
 * @export
 * @interface ModelFieldVo
 */
export interface ModelFieldVo {
    /**
     * 
     * @type {string}
     * @memberof ModelFieldVo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFieldVo
     */
    font?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFieldVo
     */
    placeholder?: string;
}
/**
 * 
 * @export
 * @interface NextCardListRequest
 */
export interface NextCardListRequest {
    /**
     * 根deck id
     * @type {number}
     * @memberof NextCardListRequest
     */
    deck_id?: number;
    /**
     * 子deck id，可以是任意章节，如果为null，则学习整本书
     * @type {number}
     * @memberof NextCardListRequest
     */
    sub_deck_id?: number;
}
/**
 * 
 * @export
 * @interface NextCardListResponse
 */
export interface NextCardListResponse {
    /**
     * v1时的card id list，只有card id
     * @type {Array<number>}
     * @memberof NextCardListResponse
     */
    card_id_list?: Array<number>;
    /**
     * v2 (2021.9月) 新增加的card list，包括卡片状态和各按钮的预测间隔
     * @type {Array<AnkiCardStatusVO>}
     * @memberof NextCardListResponse
     */
    card_list?: Array<AnkiCardStatusVO>;
    /**
     * 达到今日新学限额
     * @type {boolean}
     * @memberof NextCardListResponse
     */
    new_card_limit_reached?: boolean;
    /**
     * 达到今日复习限额
     * @type {boolean}
     * @memberof NextCardListResponse
     */
    review_card_limit_reached?: boolean;
    /**
     * 该deck中已经没有要学习的新卡了
     * @type {boolean}
     * @memberof NextCardListResponse
     */
    no_more_new_card_in_this_deck?: boolean;
    /**
     * 该deck中已经没有要复习的卡片了
     * @type {boolean}
     * @memberof NextCardListResponse
     */
    no_more_review_card_in_this_deck?: boolean;
}
/**
 * note信息
 * @export
 * @interface NoteContentListResponse
 */
export interface NoteContentListResponse {
    /**
     * 
     * @type {Array<AnkiNoteVo>}
     * @memberof NoteContentListResponse
     */
    note_list?: Array<AnkiNoteVo>;
    /**
     * 
     * @type {Array<AnkiModelV2Vo>}
     * @memberof NoteContentListResponse
     */
    model_list?: Array<AnkiModelV2Vo>;
    /**
     * 
     * @type {Array<HjmlNoteVo>}
     * @memberof NoteContentListResponse
     */
    hjml_note_list?: Array<HjmlNoteVo>;
    /**
     * 
     * @type {Array<HjdocNoteVo>}
     * @memberof NoteContentListResponse
     */
    hjdoc_note_list?: Array<HjdocNoteVo>;
}
/**
 * 获取not信息请体
 * @export
 * @interface NoteInfoGetRequest
 */
export interface NoteInfoGetRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof NoteInfoGetRequest
     */
    card_id_list?: Array<string>;
}
/**
 * 
 * @export
 * @interface OCRData
 */
export interface OCRData {
    /**
     * 原图的宽度
     * @type {number}
     * @memberof OCRData
     */
    org_width?: number;
    /**
     * 
     * @type {string}
     * @memberof OCRData
     */
    request_id?: string;
    /**
     * 识别的文字块的数量，prism_wordsInfo数组的大小
     * @type {number}
     * @memberof OCRData
     */
    prism_wnum?: number;
    /**
     * 算法矫正图片后的宽度
     * @type {number}
     * @memberof OCRData
     */
    width?: number;
    /**
     * 原图的高度
     * @type {number}
     * @memberof OCRData
     */
    org_height?: number;
    /**
     * 算法矫正图片后端的宽度
     * @type {number}
     * @memberof OCRData
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof OCRData
     */
    prism_version?: string;
    /**
     * 识别出图片的文字块汇总
     * @type {string}
     * @memberof OCRData
     */
    content?: string;
    /**
     * 图片的角度，当NeedRotate为true时才会返回，0表示正向，90表示图片朝右，180朝下，270朝左
     * @type {number}
     * @memberof OCRData
     */
    angle?: number;
    /**
     * 文字块 
     * @type {Array<PrismWordsInfo>}
     * @memberof OCRData
     */
    prism_words_info?: Array<PrismWordsInfo>;
}
/**
 * 
 * @export
 * @interface OCRResult
 */
export interface OCRResult {
    /**
     * 状态码
     * @type {string}
     * @memberof OCRResult
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof OCRResult
     */
    request_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OCRResult
     */
    message?: string;
    /**
     * 
     * @type {OCRData}
     * @memberof OCRResult
     */
    data?: OCRData;
}
/**
 * 
 * @export
 * @interface OCRWordPosition
 */
export interface OCRWordPosition {
    /**
     * 
     * @type {number}
     * @memberof OCRWordPosition
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof OCRWordPosition
     */
    y: number;
}
/**
 * 
 * @export
 * @interface OneStepLoginRequest
 */
export interface OneStepLoginRequest {
    /**
     * 阿里云一键登录 SDK 获取到的 AccessToken
     * @type {string}
     * @memberof OneStepLoginRequest
     */
    access_token?: string;
}
/**
 * 
 * @export
 * @interface OpenGroupBuyOrderListResponse
 */
export interface OpenGroupBuyOrderListResponse {
    /**
     * 
     * @type {Array<OpenGroupBuyOrderResponse>}
     * @memberof OpenGroupBuyOrderListResponse
     */
    orders?: Array<OpenGroupBuyOrderResponse>;
}
/**
 * 
 * @export
 * @interface OpenGroupBuyOrderResponse
 */
export interface OpenGroupBuyOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof OpenGroupBuyOrderResponse
     */
    group_buy_instance_id?: number;
    /**
     * 
     * @type {number}
     * @memberof OpenGroupBuyOrderResponse
     */
    order_id?: number;
    /**
     * 
     * @type {string}
     * @memberof OpenGroupBuyOrderResponse
     */
    book_title?: string;
}
/**
 * 
 * @export
 * @interface OrcQueryRequest
 */
export interface OrcQueryRequest {
    /**
     * 图片的链接
     * @type {string}
     * @memberof OrcQueryRequest
     */
    img_url?: string;
}
/**
 * 用户订单详情
 * @export
 * @interface OrderDetailVO
 */
export interface OrderDetailVO {
    /**
     * 订单内容详情，包括 group buy instance id
     * @type {string}
     * @memberof OrderDetailVO
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailVO
     */
    order_id?: number;
    /**
     * CREATED, // 未支付 PAID, // 已支付 DELIVERED, // 已发货 REFUNDED // 已退款
     * @type {string}
     * @memberof OrderDetailVO
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailVO
     */
    sku?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailVO
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailVO
     */
    actual_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailVO
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailVO
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailVO
     */
    created_date?: string;
}
/**
 * 用户订单信息
 * @export
 * @interface OrderInfo
 */
export interface OrderInfo {
    /**
     * 
     * @type {number}
     * @memberof OrderInfo
     */
    order_id?: number;
    /**
     * 创作者id
     * @type {number}
     * @memberof OrderInfo
     */
    creator_id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    book_title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    order_type?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    purchaser?: string;
    /**
     * 购买者用户id
     * @type {number}
     * @memberof OrderInfo
     */
    purchaser_id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderInfo
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    order_status?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    purchase_date?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderInfo
     */
    actual_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    phone_number?: string;
    /**
     * 订单中商品的类型,如果是会员订单则是 连续包月/连续包年/连续包年 等
     * @type {string}
     * @memberof OrderInfo
     */
    goods_type?: string;
    /**
     * 退款时间
     * @type {string}
     * @memberof OrderInfo
     */
    refund_date?: string | null;
}
/**
 * 用户营销订单信息
 * @export
 * @interface OrderMarketingInfo
 */
export interface OrderMarketingInfo {
    /**
     * 
     * @type {number}
     * @memberof OrderMarketingInfo
     */
    purchase_order_marketing_info_id?: number;
    /**
     * 
     * @type {OrderDetailVO}
     * @memberof OrderMarketingInfo
     */
    content?: OrderDetailVO;
    /**
     * 
     * @type {number}
     * @memberof OrderMarketingInfo
     */
    hua_platform_ratio?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMarketingInfo
     */
    marketing_user_id?: number;
    /**
     * 返利比例
     * @type {number}
     * @memberof OrderMarketingInfo
     */
    marketing_channel_ratio?: number;
    /**
     * 
     * @type {GroupBuyConfigSnapshotVO}
     * @memberof OrderMarketingInfo
     */
    group_buy_config_snapshot?: GroupBuyConfigSnapshotVO;
    /**
     * 
     * @type {string}
     * @memberof OrderMarketingInfo
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderMarketingInfo
     */
    last_modified_date?: string;
}
/**
 * 分页获取package返回体
 * @export
 * @interface PageAnkiPackage
 */
export interface PageAnkiPackage {
    /**
     * 
     * @type {Array<AnkiPackageResponse>}
     * @memberof PageAnkiPackage
     */
    content?: Array<AnkiPackageResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageAnkiPackage
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAnkiPackage
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAnkiPackage
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAnkiPackage
     */
    total_pages?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageAnkiPackage
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAnkiPackage
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAnkiPackage
     */
    empty?: boolean;
}
/**
 * 分页查询反馈信息
 * @export
 * @interface PageFeedbackResponseVO
 */
export interface PageFeedbackResponseVO {
    /**
     * 
     * @type {Array<FeedbackResponseVO>}
     * @memberof PageFeedbackResponseVO
     */
    content?: Array<FeedbackResponseVO>;
    /**
     * 
     * @type {number}
     * @memberof PageFeedbackResponseVO
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeedbackResponseVO
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeedbackResponseVO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeedbackResponseVO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageFeedbackResponseVO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeedbackResponseVO
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeedbackResponseVO
     */
    empty?: boolean;
}
/**
 * 分页查询图书拼团实例
 * @export
 * @interface PageGroupBuyInstanceVO
 */
export interface PageGroupBuyInstanceVO {
    /**
     * 
     * @type {Array<GroupBuyInstanceVO>}
     * @memberof PageGroupBuyInstanceVO
     */
    content?: Array<GroupBuyInstanceVO>;
    /**
     * 
     * @type {number}
     * @memberof PageGroupBuyInstanceVO
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGroupBuyInstanceVO
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageGroupBuyInstanceVO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGroupBuyInstanceVO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageGroupBuyInstanceVO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGroupBuyInstanceVO
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageGroupBuyInstanceVO
     */
    empty?: boolean;
}
/**
 * 收入记录分页
 * @export
 * @interface PageIncomeDetailVO
 */
export interface PageIncomeDetailVO {
    /**
     * 
     * @type {Array<IncomeDetailVO>}
     * @memberof PageIncomeDetailVO
     */
    content?: Array<IncomeDetailVO>;
    /**
     * 
     * @type {boolean}
     * @memberof PageIncomeDetailVO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIncomeDetailVO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIncomeDetailVO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageIncomeDetailVO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIncomeDetailVO
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIncomeDetailVO
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIncomeDetailVO
     */
    total_pages?: number;
}
/**
 * 管理员获取创建过的消息列表
 * @export
 * @interface PageInnerMessageRequest
 */
export interface PageInnerMessageRequest {
    /**
     * 
     * @type {number}
     * @memberof PageInnerMessageRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInnerMessageRequest
     */
    page_size?: number;
}
/**
 * 管理员查看创建过的消息列表返回体/用户拉取消息列表
 * @export
 * @interface PageInnerMessageResponse
 */
export interface PageInnerMessageResponse {
    /**
     * 
     * @type {Array<InnerMessageDetail>}
     * @memberof PageInnerMessageResponse
     */
    content?: Array<InnerMessageDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageInnerMessageResponse
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInnerMessageResponse
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageInnerMessageResponse
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInnerMessageResponse
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageInnerMessageResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInnerMessageResponse
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageInnerMessageResponse
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageLibraryBookVO
 */
export interface PageLibraryBookVO {
    /**
     * 
     * @type {Array<LibraryBookVO>}
     * @memberof PageLibraryBookVO
     */
    content?: Array<LibraryBookVO>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PageLibraryBookVO
     */
    hidden_book_id_list?: Array<number>;
    /**
     * 
     * @type {PageLibraryBookVOPageable}
     * @memberof PageLibraryBookVO
     */
    pageable?: PageLibraryBookVOPageable;
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVO
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVO
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageLibraryBookVO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVO
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVO
     */
    number_of_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageLibraryBookVO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLibraryBookVO
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageLibraryBookVOPageable
 */
export interface PageLibraryBookVOPageable {
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVOPageable
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVOPageable
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLibraryBookVOPageable
     */
    page_size?: number;
}
/**
 * 分页查询用户订单信息
 * @export
 * @interface PageOrderInfo
 */
export interface PageOrderInfo {
    /**
     * 
     * @type {Array<OrderInfo>}
     * @memberof PageOrderInfo
     */
    content?: Array<OrderInfo>;
    /**
     * 
     * @type {number}
     * @memberof PageOrderInfo
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrderInfo
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrderInfo
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrderInfo
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOrderInfo
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrderInfo
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrderInfo
     */
    empty?: boolean;
}
/**
 * 分页查询用户营销订单信息
 * @export
 * @interface PageOrderMarketingInfo
 */
export interface PageOrderMarketingInfo {
    /**
     * 
     * @type {Array<OrderMarketingInfo>}
     * @memberof PageOrderMarketingInfo
     */
    content?: Array<OrderMarketingInfo>;
    /**
     * 
     * @type {number}
     * @memberof PageOrderMarketingInfo
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrderMarketingInfo
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrderMarketingInfo
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrderMarketingInfo
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOrderMarketingInfo
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrderMarketingInfo
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrderMarketingInfo
     */
    empty?: boolean;
}
/**
 * 兑换码生成记录分页
 * @export
 * @interface PageRedeem
 */
export interface PageRedeem {
    /**
     * 
     * @type {Array<RedeemVO>}
     * @memberof PageRedeem
     */
    content?: Array<RedeemVO>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeem
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeem
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeem
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRedeem
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeem
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeem
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeem
     */
    total_pages?: number;
}
/**
 * 兑换码生成记录分页
 * @export
 * @interface PageRedeemConsumeLog
 */
export interface PageRedeemConsumeLog {
    /**
     * 
     * @type {Array<RedeemConsumeLogVO>}
     * @memberof PageRedeemConsumeLog
     */
    content?: Array<RedeemConsumeLogVO>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeemConsumeLog
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeemConsumeLog
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeemConsumeLog
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemConsumeLog
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemConsumeLog
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemConsumeLog
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemConsumeLog
     */
    total_pages?: number;
}
/**
 * 兑换码生成记录分页
 * @export
 * @interface PageRedeemGenerationLog
 */
export interface PageRedeemGenerationLog {
    /**
     * 
     * @type {Array<RedeemGenerationLogVO>}
     * @memberof PageRedeemGenerationLog
     */
    content?: Array<RedeemGenerationLogVO>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeemGenerationLog
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeemGenerationLog
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRedeemGenerationLog
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemGenerationLog
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemGenerationLog
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemGenerationLog
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRedeemGenerationLog
     */
    total_pages?: number;
}
/**
 * 
 * @export
 * @interface PageUgcBook
 */
export interface PageUgcBook {
    /**
     * 
     * @type {Array<UgcBook>}
     * @memberof PageUgcBook
     */
    content?: Array<UgcBook>;
    /**
     * 
     * @type {number}
     * @memberof PageUgcBook
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUgcBook
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUgcBook
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUgcBook
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUgcBook
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUgcBook
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUgcBook
     */
    empty?: boolean;
}
/**
 * 用户卡片分页信息
 * @export
 * @interface PageUserCardInfo
 */
export interface PageUserCardInfo {
    /**
     * 
     * @type {Array<UserCardInfoVO>}
     * @memberof PageUserCardInfo
     */
    content?: Array<UserCardInfoVO>;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserCardInfo
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserCardInfo
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserCardInfo
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUserCardInfo
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserCardInfo
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserCardInfo
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserCardInfo
     */
    total_pages?: number;
}
/**
 * 分页查询用户关注者信息
 * @export
 * @interface PageUserFollowerInfo
 */
export interface PageUserFollowerInfo {
    /**
     * 
     * @type {Array<UserFollowerInfo>}
     * @memberof PageUserFollowerInfo
     */
    content?: Array<UserFollowerInfo>;
    /**
     * 
     * @type {number}
     * @memberof PageUserFollowerInfo
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserFollowerInfo
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserFollowerInfo
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserFollowerInfo
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUserFollowerInfo
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserFollowerInfo
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserFollowerInfo
     */
    empty?: boolean;
}
/**
 * 分页查询用户订单信息
 * @export
 * @interface PageUserInfo
 */
export interface PageUserInfo {
    /**
     * 
     * @type {Array<UserInfo>}
     * @memberof PageUserInfo
     */
    content?: Array<UserInfo>;
    /**
     * 
     * @type {number}
     * @memberof PageUserInfo
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserInfo
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserInfo
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserInfo
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUserInfo
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserInfo
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserInfo
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageVipLog
 */
export interface PageVipLog {
    /**
     * 
     * @type {Array<VipRedeemLog>}
     * @memberof PageVipLog
     */
    content?: Array<VipRedeemLog>;
    /**
     * 
     * @type {number}
     * @memberof PageVipLog
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageVipLog
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageVipLog
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageVipLog
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageVipLog
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageVipLog
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageVipLog
     */
    empty?: boolean;
}
/**
 * 收入记录分页
 * @export
 * @interface PageVipMarketingIncomeDetailResponse
 */
export interface PageVipMarketingIncomeDetailResponse {
    /**
     * 
     * @type {Array<VipMarketingIncomeDetailResponse>}
     * @memberof PageVipMarketingIncomeDetailResponse
     */
    content?: Array<VipMarketingIncomeDetailResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageVipMarketingIncomeDetailResponse
     */
    empty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageVipMarketingIncomeDetailResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageVipMarketingIncomeDetailResponse
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageVipMarketingIncomeDetailResponse
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageVipMarketingIncomeDetailResponse
     */
    total_pages?: number;
}
/**
 * 兑换码生成记录分页
 * @export
 * @interface PageWithdrawalApplicationVO
 */
export interface PageWithdrawalApplicationVO {
    /**
     * 
     * @type {Array<WithdrawalApplicationVO>}
     * @memberof PageWithdrawalApplicationVO
     */
    content?: Array<WithdrawalApplicationVO>;
    /**
     * 
     * @type {boolean}
     * @memberof PageWithdrawalApplicationVO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWithdrawalApplicationVO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWithdrawalApplicationVO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageWithdrawalApplicationVO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWithdrawalApplicationVO
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWithdrawalApplicationVO
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWithdrawalApplicationVO
     */
    total_pages?: number;
}
/**
 * 
 * @export
 * @interface PayBindingInfoVO
 */
export interface PayBindingInfoVO {
    /**
     * ALIPAY或WECHAT方的用户id
     * @type {string}
     * @memberof PayBindingInfoVO
     */
    pay_user_id?: string;
    /**
     * alipay/wechat
     * @type {string}
     * @memberof PayBindingInfoVO
     */
    type?: string;
    /**
     * 账户名称
     * @type {string}
     * @memberof PayBindingInfoVO
     */
    account_name?: string;
}
/**
 * 支付请求要用的数据
 * @export
 * @interface PayFormResponse
 */
export interface PayFormResponse {
    /**
     * 支付表单
     * @type {string}
     * @memberof PayFormResponse
     */
    pay_form?: string;
}
/**
 * 用户会员周期性订阅信息
 * @export
 * @interface PeriodPayAgreementInfo
 */
export interface PeriodPayAgreementInfo {
    /**
     * null
     * @type {string}
     * @memberof PeriodPayAgreementInfo
     */
    name?: string;
    /**
     * 签约渠道，例如：支付宝，苹果支付。
     * @type {string}
     * @memberof PeriodPayAgreementInfo
     */
    agreement_channel?: string;
    /**
     * 签约时间
     * @type {string}
     * @memberof PeriodPayAgreementInfo
     */
    agreement_create_date?: string;
    /**
     * 下次扣费时间
     * @type {string}
     * @memberof PeriodPayAgreementInfo
     */
    next_execute_date?: string;
    /**
     * 扣款金额，单位：分
     * @type {number}
     * @memberof PeriodPayAgreementInfo
     */
    amount?: number;
    /**
     * 当前周期性订阅的状态：ACTIVE/CANCELLED
     * @type {string}
     * @memberof PeriodPayAgreementInfo
     */
    status?: string;
}
/**
 * 周期性扣款的SKU数据
 * @export
 * @interface PeriodSku
 */
export interface PeriodSku {
    /**
     * 关联的优惠券对应的 sku
     * @type {string}
     * @memberof PeriodSku
     */
    coupon_sku_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodSku
     */
    sku_id: string;
    /**
     * 表示订阅时长的基本单位：天/月。与period_num 相乘可得当期订阅的总时长
     * @type {string}
     * @memberof PeriodSku
     */
    period_type: PeriodSkuPeriodTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PeriodSku
     */
    period_num: number;
    /**
     * 连续订阅的首次付费的金额，单位：分
     * @type {number}
     * @memberof PeriodSku
     */
    initial_amount?: number;
    /**
     * 连续订阅中的续费价格（单位：分）
     * @type {number}
     * @memberof PeriodSku
     */
    renew_amount: number;
    /**
     * 客户端是否需要展示
     * @type {boolean}
     * @memberof PeriodSku
     */
    display?: boolean;
    /**
     * 活动的赠送天数
     * @type {number}
     * @memberof PeriodSku
     */
    gift_days?: number;
    /**
     * 
     * @type {string}
     * @memberof PeriodSku
     */
    sku_name?: string;
    /**
     * 活动名称
     * @type {string}
     * @memberof PeriodSku
     */
    campaign_name?: string;
    /**
     * 划线价格
     * @type {number}
     * @memberof PeriodSku
     */
    strikethrough_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PeriodSku
     */
    start_date?: string;
    /**
     * 气泡提示文字
     * @type {string}
     * @memberof PeriodSku
     */
    popup_word?: string;
    /**
     * 底部描述文字
     * @type {string}
     * @memberof PeriodSku
     */
    bottom_desc?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodSku
     */
    end_date?: string;
    /**
     * 队伍会员人员上限
     * @type {number}
     * @memberof PeriodSku
     */
    squad_member_limit?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PeriodSkuPeriodTypeEnum {
    Day = 'day',
    Month = 'month'
}

/**
 * 
 * @export
 * @interface PingTransactionChargeRequest
 */
export interface PingTransactionChargeRequest {
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    body?: string;
    /**
     * 
     * @type {number}
     * @memberof PingTransactionChargeRequest
     */
    amountCent?: number;
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    clientIp?: string;
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    userOpenId?: string;
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    payChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    successCallbackUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PingTransactionChargeRequest
     */
    wxOpenId?: string;
}
/**
 * 诗歌列表
 * @export
 * @interface PoetryDetailListResponse
 */
export interface PoetryDetailListResponse {
    /**
     * 
     * @type {Array<PoetryDetailResponse>}
     * @memberof PoetryDetailListResponse
     */
    list?: Array<PoetryDetailResponse>;
    /**
     * 
     * @type {Array<PoetryDetailResponse>}
     * @memberof PoetryDetailListResponse
     */
    origin_list?: Array<PoetryDetailResponse>;
}
/**
 * 诗歌内容结构
 * @export
 * @interface PoetryDetailResponse
 */
export interface PoetryDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof PoetryDetailResponse
     */
    poetry_id?: number;
    /**
     * 
     * @type {string}
     * @memberof PoetryDetailResponse
     */
    title?: string;
    /**
     * 作者
     * @type {string}
     * @memberof PoetryDetailResponse
     */
    author?: string;
    /**
     * 诗歌内容
     * @type {string}
     * @memberof PoetryDetailResponse
     */
    paragraph?: string;
    /**
     * 朝代
     * @type {string}
     * @memberof PoetryDetailResponse
     */
    dynasty?: string;
}
/**
 * 
 * @export
 * @interface PostWeixinMpBindingRequest
 */
export interface PostWeixinMpBindingRequest {
    /**
     * 用户在该公众号下面的open
     * @type {string}
     * @memberof PostWeixinMpBindingRequest
     */
    open_id?: string;
}
/**
 * 
 * @export
 * @interface PrismWordsInfo
 */
export interface PrismWordsInfo {
    /**
     * 
     * @type {number}
     * @memberof PrismWordsInfo
     */
    prob?: number;
    /**
     * 文字块宽度
     * @type {number}
     * @memberof PrismWordsInfo
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof PrismWordsInfo
     */
    x?: number;
    /**
     *  字块的角度，这个角度只影响width和height，当角度为-90、90、-270、270，width和height的值需要自行互换
     * @type {number}
     * @memberof PrismWordsInfo
     */
    angle?: number;
    /**
     * 
     * @type {number}
     * @memberof PrismWordsInfo
     */
    y?: number;
    /**
     * 文字块内容
     * @type {string}
     * @memberof PrismWordsInfo
     */
    word?: string;
    /**
     * 
     * @type {number}
     * @memberof PrismWordsInfo
     */
    direction?: number;
    /**
     * 文字块的高度
     * @type {number}
     * @memberof PrismWordsInfo
     */
    height?: number;
    /**
     * 文字块的外矩形四个点的坐标按顺时针排列，左上、右上、右下、左下，当NeedRotate为true时，如果最外层的angle不为0，需要按照angle矫正图片后，坐标才准确
     * @type {Array<OCRWordPosition>}
     * @memberof PrismWordsInfo
     */
    pos?: Array<OCRWordPosition>;
}
/**
 * 返回官网首页的产品信息，详见HUA-2160
 * @export
 * @interface ProductInfoResponse
 */
export interface ProductInfoResponse {
    /**
     * 用户学习行为数量
     * @type {number}
     * @memberof ProductInfoResponse
     */
    study_behavior_count: number;
    /**
     * 用户已经学习过的数量。 实际上会统计所有的card数量，见https://qianmojiaoyu.feishu.cn/wiki/wikcnnpiYrPLTKRtRfXi08Fp0Ue
     * @type {number}
     * @memberof ProductInfoResponse
     */
    reviewed_card_count: number;
}
/**
 * 发布共享模板
 * @export
 * @interface PublishSharedModelRequest
 */
export interface PublishSharedModelRequest {
    /**
     * 客户端当前最新版本，只有和服务端最新版本一致才可以发布
     * @type {string}
     * @memberof PublishSharedModelRequest
     */
    version?: string;
    /**
     * | 分隔的 tags，例如：aaa|bbb|ccc
     * @type {string}
     * @memberof PublishSharedModelRequest
     */
    tags?: string;
    /**
     * 预览图 url
     * @type {string}
     * @memberof PublishSharedModelRequest
     */
    preview_url?: string;
}
/**
 * 当前可购买的会员详细信息
 * @export
 * @interface PurchaseInfoResponse
 */
export interface PurchaseInfoResponse {
    /**
     * 是否在公测期间
     * @type {boolean}
     * @memberof PurchaseInfoResponse
     */
    in_beta_period: boolean;
    /**
     * 公测期间是否购买过会员
     * @type {boolean}
     * @memberof PurchaseInfoResponse
     */
    ever_purchased_in_beta_period: boolean;
    /**
     * 
     * @type {Array<PeriodSku>}
     * @memberof PurchaseInfoResponse
     */
    period_sku_list?: Array<PeriodSku>;
    /**
     * 
     * @type {Array<SingleBuySku>}
     * @memberof PurchaseInfoResponse
     */
    single_buy_sku_list?: Array<SingleBuySku>;
    /**
     * 
     * @type {Array<SingleBuySku>}
     * @memberof PurchaseInfoResponse
     */
    coupon_sku_list?: Array<SingleBuySku>;
}
/**
 * 分页获取ankiPackage请求参数
 * @export
 * @interface QueryAnkiPackageRequest
 */
export interface QueryAnkiPackageRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryAnkiPackageRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryAnkiPackageRequest
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAnkiPackageRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface QueryFollowerBooksRequest
 */
export interface QueryFollowerBooksRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryFollowerBooksRequest
     */
    page_number: number;
    /**
     * 
     * @type {number}
     * @memberof QueryFollowerBooksRequest
     */
    page_size: number;
}
/**
 * 分页查询用户关注者信息请求体
 * @export
 * @interface QueryFollowerRequest
 */
export interface QueryFollowerRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryFollowerRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryFollowerRequest
     */
    page_size?: number;
    /**
     * 排序类型 默认为 FOLLOW_DATE。 参考值：FOLLOW_DATE, ON_SALE_DATE。 FOLLOW_DATE按照关注时间排序；ON_SALE_DATE 按照上架图书时间排序
     * @type {string}
     * @memberof QueryFollowerRequest
     */
    sort_type?: string;
}
/**
 * 
 * @export
 * @interface QueryGroupBuyRequest
 */
export interface QueryGroupBuyRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryGroupBuyRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryGroupBuyRequest
     */
    page_size?: number;
}
/**
 * 查询返利图书列表请求体
 * @export
 * @interface QueryMarketingBooksRequest
 */
export interface QueryMarketingBooksRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryMarketingBooksRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryMarketingBooksRequest
     */
    page_size?: number;
    /**
     * 排序类型 默认为 RANK_SCORE。 参考值：RANK_SCORE（综合评分）, MARKETING_RATIO（返现比例）, MARKETING_AMOUNT（返现金额）
     * @type {string}
     * @memberof QueryMarketingBooksRequest
     */
    sort_type?: string;
    /**
     * 选择分类下的返利图书
     * @type {string}
     * @memberof QueryMarketingBooksRequest
     */
    category?: string;
    /**
     * 是否是倒序，默认倒序
     * @type {boolean}
     * @memberof QueryMarketingBooksRequest
     */
    desc?: boolean;
}
/**
 * 这是通用的分页请求！只建议使用page_number, page_size, start_date, end_date。请勿增加更多的字段了，旧字段将会被淘汰。其它字段请放在query param
 * @export
 * @interface QueryPageRequest
 */
export interface QueryPageRequest {
    /**
     * 当前页码
     * @type {number}
     * @memberof QueryPageRequest
     */
    page_number?: number;
    /**
     * 当前页码大小
     * @type {number}
     * @memberof QueryPageRequest
     */
    page_size?: number;
    /**
     * 搜索开始时间
     * @type {string}
     * @memberof QueryPageRequest
     */
    start_date?: string;
    /**
     * 搜索截至时间
     * @type {string}
     * @memberof QueryPageRequest
     */
    end_date?: string;
    /**
     * 电话可以用来查询订单
     * @type {string}
     * @memberof QueryPageRequest
     */
    phone_number?: string;
    /**
     * 书名可以用来查询订单
     * @type {string}
     * @memberof QueryPageRequest
     */
    book_title?: string;
    /**
     * 管理员可以设置为true，则查询所有用户的数据
     * @type {boolean}
     * @memberof QueryPageRequest
     */
    query_all_user?: boolean;
}
/**
 * 
 * @export
 * @interface QueryRedeemConsumeLogRequest
 */
export interface QueryRedeemConsumeLogRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryRedeemConsumeLogRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryRedeemConsumeLogRequest
     */
    page_size?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryRedeemConsumeLogRequest
     */
    redeem_id?: number;
}
/**
 * 
 * @export
 * @interface QueryRedeemGenerationLogRequest
 */
export interface QueryRedeemGenerationLogRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryRedeemGenerationLogRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryRedeemGenerationLogRequest
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryRedeemGenerationLogRequest
     */
    batch_code?: string;
}
/**
 * 
 * @export
 * @interface QueryRedeemRequest
 */
export interface QueryRedeemRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryRedeemRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryRedeemRequest
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryRedeemRequest
     */
    batch_code?: string;
}
/**
 * 
 * @export
 * @interface QueryUgcBookRequest
 */
export interface QueryUgcBookRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryUgcBookRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryUgcBookRequest
     */
    page_size?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryUgcBookRequest
     */
    library_book_name?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryUgcBookRequest
     */
    library_book_id?: number;
    /**
     * 用户ID或手机号
     * @type {string}
     * @memberof QueryUgcBookRequest
     */
    qm_publisher_id?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryUgcBookRequest
     */
    book_status?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryUgcBookRequest
     */
    category?: string;
    /**
     * 根据卡包版本进行筛选，填 2 返回新版 hpkg 发布的 ugc book，否则返回旧 ugc book
     * @type {number}
     * @memberof QueryUgcBookRequest
     */
    apkg_version?: number;
    /**
     * 如果参数为null ，则会默认为 非bundle 图书
     * @type {string}
     * @memberof QueryUgcBookRequest
     */
    ugc_type?: QueryUgcBookRequestUgcTypeEnum;
    /**
     * 
     * @type {QueryUgcBookRequestSort}
     * @memberof QueryUgcBookRequest
     */
    sort?: QueryUgcBookRequestSort;
}

/**
    * @export
    * @enum {string}
    */
export enum QueryUgcBookRequestUgcTypeEnum {
    Bundle = 'Bundle',
    SingleLibraryBook = 'SingleLibraryBook'
}

/**
 * 当参数为 rank_value 时，实际上会以 rank_value(机器评分)+manual_score(手工评分) 作为排序规则
 * @export
 * @interface QueryUgcBookRequestSort
 */
export interface QueryUgcBookRequestSort {
    /**
     * 
     * @type {string}
     * @memberof QueryUgcBookRequestSort
     */
    column?: QueryUgcBookRequestSortColumnEnum;
    /**
     * 
     * @type {boolean}
     * @memberof QueryUgcBookRequestSort
     */
    asc?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum QueryUgcBookRequestSortColumnEnum {
    RankValue = 'rank_value',
    CreatedDate = 'created_date'
}

/**
 * 
 * @export
 * @interface QueryWithdrawalApplicationRequest
 */
export interface QueryWithdrawalApplicationRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryWithdrawalApplicationRequest
     */
    page_number?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryWithdrawalApplicationRequest
     */
    page_size?: number;
    /**
     * REVIEWING, 待审核/审核中  REJECTED, 已驳回  WITHDRAWING, 提现中/审核通过  WITHDRAW_SUCCESS, 已提现  WITHDRAW_FAIL 提现失败
     * @type {string}
     * @memberof QueryWithdrawalApplicationRequest
     */
    withdrawal_status?: string;
    /**
     * 用户id或这个手机号 app端可以不传入
     * @type {string}
     * @memberof QueryWithdrawalApplicationRequest
     */
    user_query_param?: string;
    /**
     * CREATOR_WALLET(创作者), MARKETING_WALLET(分享者), CREATOR_REWARD_WALLET(激励金)
     * @type {string}
     * @memberof QueryWithdrawalApplicationRequest
     */
    withdrawal_wallet_type?: string;
    /**
     * 是否查询所有， 防止某些接口管理员会查到所有数据
     * @type {boolean}
     * @memberof QueryWithdrawalApplicationRequest
     */
    query_all_user?: boolean;
}
/**
 * 
 * @export
 * @interface QuoteResponse
 */
export interface QuoteResponse {
    /**
     * 
     * @type {string}
     * @memberof QuoteResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteResponse
     */
    author?: string;
}
/**
 * 
 * @export
 * @interface RankUgcBookRequest
 */
export interface RankUgcBookRequest {
    /**
     * 
     * @type {number}
     * @memberof RankUgcBookRequest
     */
    rank_value?: number;
    /**
     * 
     * @type {number}
     * @memberof RankUgcBookRequest
     */
    recommend?: number;
}
/**
 * 
 * @export
 * @interface RateCardRequest
 */
export interface RateCardRequest {
    /**
     * 
     * @type {number}
     * @memberof RateCardRequest
     */
    card_id?: number;
    /**
     * 4 valid values, AGAIN, HARD, GOOD, EASY.
     * @type {string}
     * @memberof RateCardRequest
     */
    card_rating_button_type?: string;
}
/**
 * 
 * @export
 * @interface RateEvent
 */
export interface RateEvent {
    /**
     * 
     * @type {string}
     * @memberof RateEvent
     */
    card_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RateEvent
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof RateEvent
     */
    type?: string;
}
/**
 * 学习起止事件请求体
 * @export
 * @interface RateStartAndEventRequest
 */
export interface RateStartAndEventRequest {
    /**
     * 
     * @type {number}
     * @memberof RateStartAndEventRequest
     */
    library_book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RateStartAndEventRequest
     */
    anki_package_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RateStartAndEventRequest
     */
    deck_id?: string;
    /**
     * BEGIN/FINISHED
     * @type {string}
     * @memberof RateStartAndEventRequest
     */
    event_key?: string;
}
/**
 * 
 * @export
 * @interface RecordStudyAuditLogStartTimeRequest
 */
export interface RecordStudyAuditLogStartTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof RecordStudyAuditLogStartTimeRequest
     */
    card_id?: number;
}
/**
 * 修复数据通用请求体
 * @export
 * @interface RecoverHpkgRequest
 */
export interface RecoverHpkgRequest {
    /**
     * 修复数据的开始日期
     * @type {string}
     * @memberof RecoverHpkgRequest
     */
    start_date?: string;
    /**
     * 修复数据的截止日期
     * @type {string}
     * @memberof RecoverHpkgRequest
     */
    end_date?: string;
}
/**
 * 恢复购买请求体
 * @export
 * @interface RecoverOrderRequest
 */
export interface RecoverOrderRequest {
    /**
     * Apple支付的凭证
     * @type {string}
     * @memberof RecoverOrderRequest
     */
    receipt?: string;
}
/**
 * 
 * @export
 * @interface RedeemConsumeLogVO
 */
export interface RedeemConsumeLogVO {
    /**
     * 
     * @type {number}
     * @memberof RedeemConsumeLogVO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RedeemConsumeLogVO
     */
    redeem_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RedeemConsumeLogVO
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RedeemConsumeLogVO
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemConsumeLogVO
     */
    coupon_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemConsumeLogVO
     */
    coupon_consumed_date?: string;
}
/**
 * 
 * @export
 * @interface RedeemGenerationLogVO
 */
export interface RedeemGenerationLogVO {
    /**
     * 
     * @type {number}
     * @memberof RedeemGenerationLogVO
     */
    redeem_log_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RedeemGenerationLogVO
     */
    redeem_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemGenerationLogVO
     */
    batch_code?: string;
    /**
     * 
     * @type {number}
     * @memberof RedeemGenerationLogVO
     */
    redeem_number?: number;
    /**
     * 
     * @type {string}
     * @memberof RedeemGenerationLogVO
     */
    user_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemGenerationLogVO
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemGenerationLogVO
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface RedeemVO
 */
export interface RedeemVO {
    /**
     * 
     * @type {number}
     * @memberof RedeemVO
     */
    redeem_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RedeemVO
     */
    redeem_code?: string;
    /**
     * 
     * @type {number}
     * @memberof RedeemVO
     */
    redeem_limit?: number;
    /**
     * 
     * @type {number}
     * @memberof RedeemVO
     */
    redeemed_count?: number;
    /**
     * 
     * @type {string}
     * @memberof RedeemVO
     */
    end_time?: string;
    /**
     * 优惠券模板
     * @type {number}
     * @memberof RedeemVO
     */
    coupon_template_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RedeemVO
     */
    redeem_name?: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemVO
     */
    coupon_name?: string;
}
/**
 * 返回token
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface RequestChangePackageName
 */
export interface RequestChangePackageName {
    /**
     * 
     * @type {string}
     * @memberof RequestChangePackageName
     */
    new_name?: string;
}
/**
 * 
 * @export
 * @interface ReviewApkgBatchMutateJobRequest
 */
export interface ReviewApkgBatchMutateJobRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ReviewApkgBatchMutateJobRequest
     */
    isRejected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReviewApkgBatchMutateJobRequest
     */
    rejectComment?: string;
}
/**
 * 
 * @export
 * @interface ReviewField
 */
export interface ReviewField {
    /**
     * 
     * @type {string}
     * @memberof ReviewField
     */
    path?: string;
    /**
     * 
     * @type {ReviewResult}
     * @memberof ReviewField
     */
    result?: ReviewResult;
}
/**
 * 
 * @export
 * @interface ReviewResult
 */
export interface ReviewResult {
    /**
     * 
     * @type {string}
     * @memberof ReviewResult
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewResult
     */
    raw?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviewResult
     */
    labels?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReviewStats
 */
export interface ReviewStats {
    /**
     * 机审不通过数量
     * @type {number}
     * @memberof ReviewStats
     */
    block_num?: number;
    /**
     * 机审报警数量
     * @type {number}
     * @memberof ReviewStats
     */
    warning_num?: number;
}
/**
 * 
 * @export
 * @interface ReviewUgcBookRequest
 */
export interface ReviewUgcBookRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewUgcBookRequest
     */
    result?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewUgcBookRequest
     */
    review_comment?: string;
    /**
     * 是否发布到社区
     * @type {boolean}
     * @memberof ReviewUgcBookRequest
     */
    community_visible?: boolean;
}
/**
 * 
 * @export
 * @interface ReviewWithdrawalRequest
 */
export interface ReviewWithdrawalRequest {
    /**
     * 审核意见
     * @type {string}
     * @memberof ReviewWithdrawalRequest
     */
    review_comment?: string;
    /**
     * true 审核通过， false 驳回
     * @type {boolean}
     * @memberof ReviewWithdrawalRequest
     */
    pass_review: boolean;
}
/**
 * 
 * @export
 * @interface RewardWalletDetailListResponse
 */
export interface RewardWalletDetailListResponse {
    /**
     * 
     * @type {RewardWalletDetailPage}
     * @memberof RewardWalletDetailListResponse
     */
    withdrawal_detail_list?: RewardWalletDetailPage;
    /**
     * 
     * @type {RewardWalletDetailPage}
     * @memberof RewardWalletDetailListResponse
     */
    wallet_log_detail_list?: RewardWalletDetailPage;
    /**
     * 
     * @type {RewardWalletDetailPage}
     * @memberof RewardWalletDetailListResponse
     */
    vip_marketing_detail_list?: RewardWalletDetailPage;
}
/**
 * 
 * @export
 * @interface RewardWalletDetailPage
 */
export interface RewardWalletDetailPage {
    /**
     * 
     * @type {Array<RewardWalletDetailVO>}
     * @memberof RewardWalletDetailPage
     */
    content?: Array<RewardWalletDetailVO>;
    /**
     * 
     * @type {boolean}
     * @memberof RewardWalletDetailPage
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RewardWalletDetailPage
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RewardWalletDetailPage
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RewardWalletDetailPage
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof RewardWalletDetailPage
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof RewardWalletDetailPage
     */
    total_elements?: number;
    /**
     * 
     * @type {number}
     * @memberof RewardWalletDetailPage
     */
    total_pages?: number;
}
/**
 * 
 * @export
 * @interface RewardWalletDetailVO
 */
export interface RewardWalletDetailVO {
    /**
     * 
     * @type {number}
     * @memberof RewardWalletDetailVO
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof RewardWalletDetailVO
     */
    created_date?: string;
    /**
     * WITHDRAW_REVIEWING 审核中，SETTLED 已入账， WITHDRAW_REJECTED 提现申请被退回, WITHDRAW_SUCCESS 提现完成 UNSETTLED 待入账 INVALID_MARKETING 失效
     * @type {string}
     * @memberof RewardWalletDetailVO
     */
    status?: RewardWalletDetailVOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RewardWalletDetailVO
     */
    rejected_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardWalletDetailVO
     */
    settlement_date?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardWalletDetailVO
     */
    invalid_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof RewardWalletDetailVO
     */
    remark?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RewardWalletDetailVOStatusEnum {
    WithdrawReviewing = 'WITHDRAW_REVIEWING',
    Settled = 'SETTLED',
    WithdrawRejected = 'WITHDRAW_REJECTED',
    WithdrawSuccess = 'WITHDRAW_SUCCESS',
    Unsettled = 'UNSETTLED',
    InvalidMarketing = 'INVALID_MARKETING'
}

/**
 * 
 * @export
 * @interface SearchClickEventRequest
 */
export interface SearchClickEventRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchClickEventRequest
     */
    keyword?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchClickEventRequest
     */
    book_id?: number;
}
/**
 * 
 * @export
 * @interface SearchHintResponse
 */
export interface SearchHintResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchHintResponse
     */
    hints?: Array<string>;
}
/**
 * 
 * @export
 * @interface SearchNotesContentResponse
 */
export interface SearchNotesContentResponse {
    /**
     * 
     * @type {Array<SearchNotesContentResponseContent>}
     * @memberof SearchNotesContentResponse
     */
    content?: Array<SearchNotesContentResponseContent>;
}
/**
 * 
 * @export
 * @interface SearchNotesContentResponseContent
 */
export interface SearchNotesContentResponseContent {
    /**
     * 
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    library_book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchNotesContentResponseContent
     */
    qm_title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchNotesContentResponseContent
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchNotesContentResponseContent
     */
    qm_description?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    qm_publisher_user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchNotesContentResponseContent
     */
    qm_publisher_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchNotesContentResponseContent
     */
    qm_publisher_nickname?: string;
    /**
     * 
     * @type {Array<BadgeResponse>}
     * @memberof SearchNotesContentResponseContent
     */
    qm_publisher_badge_list?: Array<BadgeResponse>;
    /**
     * 
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    book_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    notes_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    rank_score?: number;
    /**
     * 总卡片数量
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    card_count?: number;
    /**
     * 是否拥有该图书
     * @type {boolean}
     * @memberof SearchNotesContentResponseContent
     */
    is_bought?: boolean;
    /**
     * 借阅人数
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    borrower_count?: number;
    /**
     * 图书价格。如果图书是bundle类型的话，就是bundle内图书价格之和
     * @type {number}
     * @memberof SearchNotesContentResponseContent
     */
    fixed_price?: number;
    /**
     * 图书封面图
     * @type {string}
     * @memberof SearchNotesContentResponseContent
     */
    marketing_img_url?: string;
    /**
     * 会员免拼或会员免费学
     * @type {boolean}
     * @memberof SearchNotesContentResponseContent
     */
    vip_free_get?: boolean;
    /**
     * 
     * @type {Array<SearchNotesContentResponseMatch>}
     * @memberof SearchNotesContentResponseContent
     */
    match?: Array<SearchNotesContentResponseMatch>;
}
/**
 * 
 * @export
 * @interface SearchNotesContentResponseMatch
 */
export interface SearchNotesContentResponseMatch {
    /**
     * 
     * @type {number}
     * @memberof SearchNotesContentResponseMatch
     */
    note_id?: number;
    /**
     * v1与v2字段都会存在，看客户端用哪个了
     * @type {string}
     * @memberof SearchNotesContentResponseMatch
     */
    note_id_v2?: string;
    /**
     * 匹配结果的高亮预览片段（用省略号截断）
     * @type {string}
     * @memberof SearchNotesContentResponseMatch
     */
    match_preview?: string;
    /**
     * 匹配被高亮后的全部field_values_json，已经变成纯文本类型了
     * @type {Array<string>}
     * @memberof SearchNotesContentResponseMatch
     */
    field_values?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchNotesContentResponseMatch
     */
    card_ids?: Array<number>;
    /**
     * v1与v2字段都会存在，看客户端用哪个了
     * @type {Array<string>}
     * @memberof SearchNotesContentResponseMatch
     */
    card_ids_v2?: Array<string>;
}
/**
 * 分页关键词查询cards
 * @export
 * @interface SearchPageCardsVO
 */
export interface SearchPageCardsVO {
    /**
     * 
     * @type {Array<SearchPageCardsVOContent>}
     * @memberof SearchPageCardsVO
     */
    content?: Array<SearchPageCardsVOContent>;
    /**
     * 
     * @type {number}
     * @memberof SearchPageCardsVO
     */
    total_pages?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchPageCardsVO
     */
    total_elements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageCardsVO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageCardsVO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchPageCardsVO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchPageCardsVO
     */
    size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageCardsVO
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface SearchPageCardsVOContent
 */
export interface SearchPageCardsVOContent {
    /**
     * 
     * @type {number}
     * @memberof SearchPageCardsVOContent
     */
    card_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchPageCardsVOContent
     */
    matches_preview?: string;
}
/**
 * 发送短信请求体
 * @export
 * @interface SendInnerMessageRequest
 */
export interface SendInnerMessageRequest {
    /**
     * 目标对象Id列表
     * @type {Array<number>}
     * @memberof SendInnerMessageRequest
     */
    user_id_list?: Array<number>;
    /**
     * 如果需要选择已有的站内信，则传入id
     * @type {number}
     * @memberof SendInnerMessageRequest
     */
    inner_message_id?: number | null;
    /**
     * 如果不选择已有的站内信，则传入内容
     * @type {string}
     * @memberof SendInnerMessageRequest
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @interface SendInteractiveMsgRequest
 */
export interface SendInteractiveMsgRequest {
    /**
     * 
     * @type {string}
     * @memberof SendInteractiveMsgRequest
     */
    message_type?: SendInteractiveMsgRequestMessageTypeEnum;
    /**
     * 
     * @type {SendInteractiveMsgRequestEnc}
     * @memberof SendInteractiveMsgRequest
     */
    enc?: SendInteractiveMsgRequestEnc;
    /**
     * 
     * @type {SendInteractiveMsgRequestMerit}
     * @memberof SendInteractiveMsgRequest
     */
    merit?: SendInteractiveMsgRequestMerit;
}

/**
    * @export
    * @enum {string}
    */
export enum SendInteractiveMsgRequestMessageTypeEnum {
    Encouraging = 'ENCOURAGING',
    Merit = 'MERIT'
}

/**
 * 
 * @export
 * @interface SendInteractiveMsgRequestEnc
 */
export interface SendInteractiveMsgRequestEnc {
    /**
     * 
     * @type {number}
     * @memberof SendInteractiveMsgRequestEnc
     */
    replied_message_id?: number;
}
/**
 * 
 * @export
 * @interface SendInteractiveMsgRequestMerit
 */
export interface SendInteractiveMsgRequestMerit {
    /**
     * 
     * @type {number}
     * @memberof SendInteractiveMsgRequestMerit
     */
    book_id?: number;
}
/**
 * 发送短信验证码的请求体
 * @export
 * @interface SendSmsCodeRequest
 */
export interface SendSmsCodeRequest {
    /**
     * 发送短信的目的， SIGNIN登录 REBIND 重新绑定手机号
     * @type {string}
     * @memberof SendSmsCodeRequest
     */
    purpose: SendSmsCodeRequestPurposeEnum;
    /**
     * 手机号的国家码，中国为86
     * @type {number}
     * @memberof SendSmsCodeRequest
     */
    country_code?: number;
    /**
     * 手机号码
     * @type {string}
     * @memberof SendSmsCodeRequest
     */
    phone_number: string;
    /**
     * 图形或者滑块验证码所生成的信息。目前支持多种验证方式： * 对于NVC（无感验证），直接传入NVCVal即可，是一个字符串。 * 对于IC（智能验证），需要以这样的格式传入：IC::{\"token\":\"...\", \"sig\": \"...\", \"sessionId\": \"...\"} * 对于AJ（滑块验证），需要以AJ::的前缀传入：AJ::{\"token\":\"...\", \"captchaType\": \"...\", \"pointJson\": \"...} * 对于QA环境，可以直接传入 \"QA\", 即可通过验证（方便回归测试） 
     * @type {string}
     * @memberof SendSmsCodeRequest
     */
    captcha: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SendSmsCodeRequestPurposeEnum {
    Signin = 'SIGNIN',
    Rebind = 'REBIND'
}

/**
 * 共享模板元数据列表
 * @export
 * @interface SharedModelListResponse
 */
export interface SharedModelListResponse {
    /**
     * 
     * @type {Array<SharedModelResponse>}
     * @memberof SharedModelListResponse
     */
    shared_models?: Array<SharedModelResponse>;
}
/**
 * 
 * @export
 * @interface SharedModelResponse
 */
export interface SharedModelResponse {
    /**
     * 
     * @type {string}
     * @memberof SharedModelResponse
     */
    anki_shared_model_id?: string;
    /**
     * 模板名称
     * @type {string}
     * @memberof SharedModelResponse
     */
    model_name?: string;
    /**
     * 模板内容（在模板详情时候会填充该字段）
     * @type {string}
     * @memberof SharedModelResponse
     */
    json_doc_content?: string;
    /**
     * 是否为官方模板
     * @type {boolean}
     * @memberof SharedModelResponse
     */
    is_official?: boolean;
    /**
     * | 分隔的 tags，例如：aaa|bbb|ccc
     * @type {string}
     * @memberof SharedModelResponse
     */
    tags?: string;
    /**
     * 预览图 url
     * @type {string}
     * @memberof SharedModelResponse
     */
    preview_url?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedModelResponse
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedModelResponse
     */
    last_modified_date?: string;
}
/**
 * 创建新的共享模板
 * @export
 * @interface SharedTemplateRequest
 */
export interface SharedTemplateRequest {
    /**
     * 引用的anki_package_id
     * @type {number}
     * @memberof SharedTemplateRequest
     */
    anki_package_id?: number;
    /**
     * 引用的anki_package_uuid
     * @type {string}
     * @memberof SharedTemplateRequest
     */
    anki_package_uuid?: string;
    /**
     * 引用的 model id
     * @type {number}
     * @memberof SharedTemplateRequest
     */
    anki_model_id?: number;
    /**
     * 引用的template id
     * @type {number}
     * @memberof SharedTemplateRequest
     */
    anki_template_id?: number;
    /**
     * 标签，用竖线分割
     * @type {string}
     * @memberof SharedTemplateRequest
     */
    tags?: string;
    /**
     * 是否可以被公开列出
     * @type {boolean}
     * @memberof SharedTemplateRequest
     */
    is_public?: boolean;
    /**
     * 官方模板名称
     * @type {string}
     * @memberof SharedTemplateRequest
     */
    name?: string;
    /**
     * 用于在创建新卡片时，指定每个note字段的默认值
     * @type {{ [key: string]: string; }}
     * @memberof SharedTemplateRequest
     */
    fields_placeholder?: { [key: string]: string; };
    /**
     * 预览图 url
     * @type {string}
     * @memberof SharedTemplateRequest
     */
    preview_url?: string;
}
/**
 * 
 * @export
 * @interface ShortLinkVO
 */
export interface ShortLinkVO {
    /**
     * 
     * @type {string}
     * @memberof ShortLinkVO
     */
    short_link_key?: string;
    /**
     * 对象ID，目前是订单ID
     * @type {string}
     * @memberof ShortLinkVO
     */
    object_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortLinkVO
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortLinkVO
     */
    acutal_link?: string;
}
/**
 * 
 * @export
 * @interface SignInAppleRequest
 */
export interface SignInAppleRequest {
    /**
     * 
     * @type {string}
     * @memberof SignInAppleRequest
     */
    identity_token?: string;
}
/**
 * 登录请求
 * @export
 * @interface SignInPhoneRequest
 */
export interface SignInPhoneRequest {
    /**
     * 指定手机登录的方式：`sms_code`为短信验证码；`onekey_aliyun`为阿里云SDK一键登录；
     * @type {string}
     * @memberof SignInPhoneRequest
     */
    method_name: SignInPhoneRequestMethodNameEnum;
    /**
     * 国家代码，中国为86。使用短信验证码时要求输入，使用一键登录时无需输入。
     * @type {number}
     * @memberof SignInPhoneRequest
     */
    country_code?: number;
    /**
     * 手机号。使用短信验证码时要求输入，使用一键登录时无需输入。
     * @type {string}
     * @memberof SignInPhoneRequest
     */
    phone_number?: string;
    /**
     * 苹果的OpenId。可选
     * @type {string}
     * @memberof SignInPhoneRequest
     */
    apple_open_id?: string;
    /**
     * 绑定第三方登录的凭证。可选
     * @type {string}
     * @memberof SignInPhoneRequest
     */
    bind_token?: string;
    /**
     * 验证码，或一键登录的token
     * @type {string}
     * @memberof SignInPhoneRequest
     */
    code: string;
    /**
     * QQ或微信的open_id
     * @type {string}
     * @memberof SignInPhoneRequest
     */
    open_id?: string;
    /**
     * WeChat | QQ
     * @type {string}
     * @memberof SignInPhoneRequest
     */
    third_party_channel?: string;
    /**
     * 是否是更换手机号的请求
     * @type {boolean}
     * @memberof SignInPhoneRequest
     */
    rebind_phone?: boolean;
    /**
     * 邀请人用户id，注意是userId而不是huaId
     * @type {number}
     * @memberof SignInPhoneRequest
     */
    pvt_inviter?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SignInPhoneRequestMethodNameEnum {
    SmsCode = 'sms_code',
    AliyunOnekey = 'aliyun_onekey'
}

/**
 * 登录成功的响应
 * @export
 * @interface SignInPhoneResponse
 */
export interface SignInPhoneResponse {
    /**
     * 回显的手机号的国家码
     * @type {number}
     * @memberof SignInPhoneResponse
     */
    country_code: number;
    /**
     * 回显的手机号（手机一键登录场景最需要）
     * @type {string}
     * @memberof SignInPhoneResponse
     */
    phone_number: string;
    /**
     * 是否是直接注册
     * @type {boolean}
     * @memberof SignInPhoneResponse
     */
    is_register?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignInPhoneResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof SignInPhoneResponse
     */
    expire_date?: string;
}
/**
 * 
 * @export
 * @interface SignInQQRequest
 */
export interface SignInQQRequest {
    /**
     * 新版本不再需要 open_id 了
     * @type {string}
     * @memberof SignInQQRequest
     */
    openid?: string;
    /**
     * （QQ H5登录适用），如果QQ H5登录，则请设置为true。此时的行为是不要求绑定手机号，直接登录（如果此openId已经是注册用户了），或直接创建一个新用户（并使用昵称、头像）。
     * @type {boolean}
     * @memberof SignInQQRequest
     */
    auto_create_account?: boolean;
    /**
     * 获得方法：https://wiki.open.qq.com/wiki/%E3%80%90QQ%E7%99%BB%E5%BD%95%E3%80%91%E7%BD%91%E7%AB%99%E6%8E%A5%E5%85%A5
     * @type {string}
     * @memberof SignInQQRequest
     */
    access_token?: string;
}
/**
 * 
 * @export
 * @interface SignInThirdPartyResponse
 */
export interface SignInThirdPartyResponse {
    /**
     * 如果第三方登录成功，则该字段不为用户的 user id；如果为null，可能是需要绑定手机号
     * @type {number}
     * @memberof SignInThirdPartyResponse
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SignInThirdPartyResponse
     */
    open_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SignInThirdPartyResponse
     */
    apple_open_id?: string;
    /**
     * 如果为true，则要求用户绑定手机号，需要进一步调用 signInByPhone
     * @type {boolean}
     * @memberof SignInThirdPartyResponse
     */
    need_bind_phone?: boolean;
    /**
     * 如果need_bind_phone=true，则这里有一个bind token，调用 signInByPhone 时要传入
     * @type {string}
     * @memberof SignInThirdPartyResponse
     */
    bind_token?: string;
    /**
     * 如果这个登录方式已经绑定了用户，或者当场注册了一个用户，则该字段为用户的 JWT token，表示登录成功
     * @type {string}
     * @memberof SignInThirdPartyResponse
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof SignInThirdPartyResponse
     */
    expire_date?: string;
}
/**
 * snsapi_userinfo_and_auto_create_account=false或为null时，与QQ、苹果登录没有区别；当为true时，则创建一个与微信同昵称、头像的账号
 * @export
 * @interface SignInWeChatRequest
 */
export interface SignInWeChatRequest {
    /**
     * 微信前端授权得到的code
     * @type {string}
     * @memberof SignInWeChatRequest
     */
    code?: string;
    /**
     * 微信账号类型，`mp`为公众号H5登录；`open`为微信开发平台，即APP中的微信登录。如果为null, 则为 `open`
     * @type {string}
     * @memberof SignInWeChatRequest
     */
    weixin_app_type?: string;
    /**
     * （微信H5登录适用），如果微信H5登录，且获得的scope为 `snsapi_userinfo`, 则请设置为true。此时的行为是不要求绑定手机号，直接登录（如果此openId已经是注册用户了），或直接创建一个新用户（并使用昵称、头像）。
     * @type {boolean}
     * @memberof SignInWeChatRequest
     */
    snsapi_userinfo_and_auto_create_account?: boolean;
}
/**
 * 单独付费购买
 * @export
 * @interface SingleBuySku
 */
export interface SingleBuySku {
    /**
     * 关联的优惠券对应的 sku
     * @type {string}
     * @memberof SingleBuySku
     */
    coupon_sku_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleBuySku
     */
    sku_id: string;
    /**
     * 单独购买中 订单所包含的 时间单位的数量
     * @type {number}
     * @memberof SingleBuySku
     */
    single_num: number;
    /**
     * 单期购买中，时间单位类型，包括： 月，天 ...
     * @type {string}
     * @memberof SingleBuySku
     */
    single_type: SingleBuySkuSingleTypeEnum;
    /**
     * 价格，单位：分
     * @type {number}
     * @memberof SingleBuySku
     */
    amount: number;
    /**
     * 客户端是否需要展示
     * @type {boolean}
     * @memberof SingleBuySku
     */
    display?: boolean;
    /**
     * 赠送的天数
     * @type {number}
     * @memberof SingleBuySku
     */
    gift_days?: number;
    /**
     * 
     * @type {string}
     * @memberof SingleBuySku
     */
    sku_name?: string;
    /**
     * 活动名称
     * @type {string}
     * @memberof SingleBuySku
     */
    campaign_name?: string;
    /**
     * 划线价格
     * @type {number}
     * @memberof SingleBuySku
     */
    strikethrough_amount?: number;
    /**
     * 气泡提示文字
     * @type {string}
     * @memberof SingleBuySku
     */
    popup_word?: string;
    /**
     * 底部描述文字
     * @type {string}
     * @memberof SingleBuySku
     */
    bottom_desc?: string;
    /**
     * 队伍会员人员上限
     * @type {number}
     * @memberof SingleBuySku
     */
    squad_member_limit?: number;
    /**
     * 
     * @type {string}
     * @memberof SingleBuySku
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleBuySku
     */
    end_date?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SingleBuySkuSingleTypeEnum {
    Day = 'DAY',
    Month = 'MONTH'
}

/**
 * 
 * @export
 * @interface SmsCodeResponse
 */
export interface SmsCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof SmsCodeResponse
     */
    sms_code: string;
}
/**
 * 统计图统一返回结果
 * @export
 * @interface StatisticResponse
 */
export interface StatisticResponse {
    /**
     * 横坐标纬度数据
     * @type {Array<string>}
     * @memberof StatisticResponse
     */
    date_series?: Array<string>;
    /**
     * 统计数据
     * @type {{ [key: string]: Array<number>; }}
     * @memberof StatisticResponse
     */
    dimension_and_its_series?: { [key: string]: Array<number>; };
}
/**
 * 
 * @export
 * @interface StatisticsRequest
 */
export interface StatisticsRequest {
    /**
     * {     \"新学习者\": \"new_learner\",     \"活跃学习者\": \"active_learner\",     \"学习完成率\": \"complete_rate\",     \"累计学习者\": \"total_learner\",     \"平均学习时长\": \"avg_study_length\",     \"平均学习卡片数\": \"avg_study_card\",     \"活跃留存率\": \"active_retention\" }
     * @type {string}
     * @memberof StatisticsRequest
     */
    event_type: string;
    /**
     * 若不需要，可为null
     * @type {number}
     * @memberof StatisticsRequest
     */
    book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsRequest
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticsRequest
     */
    end_date: string;
}
/**
 * 
 * @export
 * @interface StudyCountTodayRequest
 */
export interface StudyCountTodayRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyCountTodayRequest
     */
    deck_id?: number;
}
/**
 * 
 * @export
 * @interface StudyCountTodayResponse
 */
export interface StudyCountTodayResponse {
    /**
     * 今日计划要学习的总卡片量
     * @type {number}
     * @memberof StudyCountTodayResponse
     */
    study_count_planned_to_finish_today?: number;
    /**
     * 今日处于正在学习中的卡片量
     * @type {number}
     * @memberof StudyCountTodayResponse
     */
    learning_count_today?: number;
    /**
     * 今日已经学会的卡片量
     * @type {number}
     * @memberof StudyCountTodayResponse
     */
    study_count_today?: number;
}
/**
 * 
 * @export
 * @interface StudyDayRank
 */
export interface StudyDayRank {
    /**
     * 用户累计学习时长
     * @type {number}
     * @memberof StudyDayRank
     */
    study_days: number;
    /**
     * 
     * @type {number}
     * @memberof StudyDayRank
     */
    user_id: number;
    /**
     * 头像链接
     * @type {string}
     * @memberof StudyDayRank
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyDayRank
     */
    nick_name?: string;
}
/**
 * 
 * @export
 * @interface StudyHistoryRequest
 */
export interface StudyHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyHistoryRequest
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof StudyHistoryRequest
     */
    end_date: string;
    /**
     * 
     * @type {number}
     * @memberof StudyHistoryRequest
     */
    user_id: number;
}
/**
 * 
 * @export
 * @interface StudyPlanParam
 */
export interface StudyPlanParam {
    /**
     * 
     * @type {number}
     * @memberof StudyPlanParam
     */
    deck_size?: number;
    /**
     * 
     * @type {Array<StudyPlanParamLearnCardCountParam>}
     * @memberof StudyPlanParam
     */
    learn_card_count_param?: Array<StudyPlanParamLearnCardCountParam>;
}
/**
 * 
 * @export
 * @interface StudyPlanParamLearnCardCountParam
 */
export interface StudyPlanParamLearnCardCountParam {
    /**
     * 
     * @type {number}
     * @memberof StudyPlanParamLearnCardCountParam
     */
    days?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyPlanParamLearnCardCountParam
     */
    factor?: number;
}
/**
 * 
 * @export
 * @interface StudyRankResponse
 */
export interface StudyRankResponse {
    /**
     * 学习天数排行榜
     * @type {Array<StudyDayRank>}
     * @memberof StudyRankResponse
     */
    study_days_rank_list?: Array<StudyDayRank>;
    /**
     * 学习时长排行榜
     * @type {Array<StudyTimeRank>}
     * @memberof StudyRankResponse
     */
    study_time_rank_list?: Array<StudyTimeRank>;
    /**
     * 学习周排行
     * @type {Array<StudyTimeRank>}
     * @memberof StudyRankResponse
     */
    study_week_rank_list?: Array<StudyTimeRank>;
}
/**
 * 
 * @export
 * @interface StudyStat
 */
export interface StudyStat {
    /**
     * 
     * @type {StudyStatDaysCount}
     * @memberof StudyStat
     */
    days_count?: StudyStatDaysCount;
    /**
     * 
     * @type {Array<StudyStatDailyStudyStat>}
     * @memberof StudyStat
     */
    daily_study_stat?: Array<StudyStatDailyStudyStat>;
}
/**
 * 
 * @export
 * @interface StudyStatDailyStudyStat
 */
export interface StudyStatDailyStudyStat {
    /**
     * 
     * @type {string}
     * @memberof StudyStatDailyStudyStat
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyStatDailyStudyStat
     */
    today_start_time?: string;
    /**
     * 
     * @type {number}
     * @memberof StudyStatDailyStudyStat
     */
    total_study_time_taken?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyStatDailyStudyStat
     */
    again_button_click_times?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyStatDailyStudyStat
     */
    easy_button_click_times?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyStatDailyStudyStat
     */
    good_button_click_times?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyStatDailyStudyStat
     */
    hard_button_click_times?: number;
}
/**
 * 
 * @export
 * @interface StudyStatDaysCount
 */
export interface StudyStatDaysCount {
    /**
     * 
     * @type {number}
     * @memberof StudyStatDaysCount
     */
    max_consecutive_study_days?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyStatDaysCount
     */
    current_consecutive_study_days?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyStatDaysCount
     */
    total_study_days?: number;
}
/**
 * 
 * @export
 * @interface StudyTimeRank
 */
export interface StudyTimeRank {
    /**
     * 
     * @type {number}
     * @memberof StudyTimeRank
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof StudyTimeRank
     */
    study_times: number;
    /**
     * 
     * @type {string}
     * @memberof StudyTimeRank
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyTimeRank
     */
    nick_name?: string;
}
/**
 * 
 * @export
 * @interface SubscribeConfigItem
 */
export interface SubscribeConfigItem {
    /**
     * 设置价格 单位 分
     * @type {number}
     * @memberof SubscribeConfigItem
     */
    price?: number;
    /**
     * 是否展示
     * @type {boolean}
     * @memberof SubscribeConfigItem
     */
    show?: boolean;
}
/**
 * 
 * @export
 * @interface SubscribeConfigItemResponse
 */
export interface SubscribeConfigItemResponse {
    /**
     * 设置价格 单位 分
     * @type {number}
     * @memberof SubscribeConfigItemResponse
     */
    price?: number;
    /**
     * 订阅时长
     * @type {number}
     * @memberof SubscribeConfigItemResponse
     * @deprecated
     */
    day?: number;
    /**
     * 订阅时长 单位 秒
     * @type {number}
     * @memberof SubscribeConfigItemResponse
     */
    duration?: number;
    /**
     * 下单用的 订阅 key
     * @type {string}
     * @memberof SubscribeConfigItemResponse
     */
    subscribe_key?: string;
}
/**
 * 图书订阅配置信息
 * @export
 * @interface SubscribeConfigSettingRequest
 */
export interface SubscribeConfigSettingRequest {
    /**
     * 
     * @type {SubscribeConfigItem}
     * @memberof SubscribeConfigSettingRequest
     */
    subscribe_30?: SubscribeConfigItem;
    /**
     * 
     * @type {SubscribeConfigItem}
     * @memberof SubscribeConfigSettingRequest
     */
    subscribe_90?: SubscribeConfigItem;
    /**
     * 
     * @type {SubscribeConfigItem}
     * @memberof SubscribeConfigSettingRequest
     */
    subscribe_365?: SubscribeConfigItem;
    /**
     * 
     * @type {SubscribeConfigItem}
     * @memberof SubscribeConfigSettingRequest
     */
    subscribe_lifetime?: SubscribeConfigItem;
}
/**
 * 客户端展示图书订阅配置信息
 * @export
 * @interface SubscribeConfigSettingResponse
 */
export interface SubscribeConfigSettingResponse {
    /**
     * 
     * @type {Array<SubscribeConfigItemResponse>}
     * @memberof SubscribeConfigSettingResponse
     */
    settings?: Array<SubscribeConfigItemResponse>;
}
/**
 * 
 * @export
 * @interface SubscriptionInfo
 */
export interface SubscriptionInfo {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    period_type?: SubscriptionInfoPeriodTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInfo
     */
    period_num?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInfo
     */
    sku_id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionInfoPeriodTypeEnum {
    Month = 'MONTH',
    Day = 'DAY'
}

/**
 * 
 * @export
 * @interface SuspendCardRequest
 */
export interface SuspendCardRequest {
    /**
     * 
     * @type {number}
     * @memberof SuspendCardRequest
     */
    card_id?: number;
}
/**
 * 
 * @export
 * @interface SyncHpkgMetaResponse
 */
export interface SyncHpkgMetaResponse {
    /**
     * 
     * @type {Array<HpkgMeta>}
     * @memberof SyncHpkgMetaResponse
     */
    list?: Array<HpkgMeta>;
}
/**
 * 
 * @export
 * @interface SyncIdVersionsForTypeRequest
 */
export interface SyncIdVersionsForTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncIdVersionsForTypeRequest
     */
    doc_type?: string;
    /**
     * 
     * @type {Array<JsonDocIdVersion>}
     * @memberof SyncIdVersionsForTypeRequest
     */
    id_versions?: Array<JsonDocIdVersion>;
}
/**
 * 
 * @export
 * @interface SyncIdVersionsForTypeResponse
 */
export interface SyncIdVersionsForTypeResponse {
    /**
     * 服务端已删除的 jd id
     * @type {Array<string>}
     * @memberof SyncIdVersionsForTypeResponse
     */
    deleted?: Array<string>;
    /**
     * 服务端最新版本与客户端版本不一致的 jd id 和 version
     * @type {Array<JsonDocIdVersion>}
     * @memberof SyncIdVersionsForTypeResponse
     */
    updated?: Array<JsonDocIdVersion>;
    /**
     * 客户端缺失的 jd id 和 version
     * @type {Array<JsonDocIdVersion>}
     * @memberof SyncIdVersionsForTypeResponse
     */
    created?: Array<JsonDocIdVersion>;
}
/**
 * 同步meta信息请求体
 * @export
 * @interface SyncMetaRequest
 */
export interface SyncMetaRequest {
    /**
     * 卡包ID列表
     * @type {Array<string>}
     * @memberof SyncMetaRequest
     */
    package_uuid_list?: Array<string>;
}
/**
 * 
 * @export
 * @interface SyncUploadScheduleLogRequest
 */
export interface SyncUploadScheduleLogRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncUploadScheduleLogRequest
     */
    package_id?: string;
    /**
     * 日志内容 日志序列化后的结果
     * @type {string}
     * @memberof SyncUploadScheduleLogRequest
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface TTSRequest
 */
export interface TTSRequest {
    /**
     * 需要朗读的内容
     * @type {string}
     * @memberof TTSRequest
     */
    text?: string;
    /**
     * 对应的选项名称为 aijia - 中/英文（标准女声） aicheng - 中/英文（标准男声） tomoka - 日语（女声） tomoya - 日语（男声） indah - 印尼语（女声） farah - 马来语（女声） tala - 菲律宾语（女声）
     * @type {string}
     * @memberof TTSRequest
     */
    voice?: TTSRequestVoiceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TTSRequestVoiceEnum {
    Aijia = 'aijia',
    Aicheng = 'aicheng',
    Tomoka = 'tomoka',
    Tomoya = 'tomoya',
    Indah = 'indah',
    Farah = 'farah',
    Tala = 'tala'
}

/**
 * 
 * @export
 * @interface TTSResponse
 */
export interface TTSResponse {
    /**
     * 音频 OSS 链接
     * @type {string}
     * @memberof TTSResponse
     */
    link?: string;
}
/**
 * 
 * @export
 * @interface TagList
 */
export interface TagList {
    /**
     * 
     * @type {Array<string>}
     * @memberof TagList
     */
    tags?: Array<string>;
}
/**
 * 
 * @export
 * @interface TodayStudyFinishedRelatedDataRequest
 */
export interface TodayStudyFinishedRelatedDataRequest {
    /**
     * 
     * @type {number}
     * @memberof TodayStudyFinishedRelatedDataRequest
     */
    deck_id?: number;
}
/**
 * 
 * @export
 * @interface TodayStudyFinishedRelatedDataResponse
 */
export interface TodayStudyFinishedRelatedDataResponse {
    /**
     * 
     * @type {number}
     * @memberof TodayStudyFinishedRelatedDataResponse
     */
    total_study_time_taken?: number;
    /**
     * 
     * @type {number}
     * @memberof TodayStudyFinishedRelatedDataResponse
     */
    debut_cards_count_checked_today?: number;
    /**
     * 
     * @type {number}
     * @memberof TodayStudyFinishedRelatedDataResponse
     */
    review_phase_cards_count_checked_today?: number;
    /**
     * 
     * @type {number}
     * @memberof TodayStudyFinishedRelatedDataResponse
     */
    total_study_days_count?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TodayStudyFinishedRelatedDataResponse
     */
    recommended_extra_cards_count_list?: Array<number>;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface UgcBook
 */
export interface UgcBook {
    /**
     * 
     * @type {number}
     * @memberof UgcBook
     */
    ugc_book_id?: number;
    /**
     * 
     * @type {number}
     * @memberof UgcBook
     */
    library_book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    apkg_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    library_book_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    library_book_description?: string;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    library_book_cover?: string;
    /**
     * 
     * @type {number}
     * @memberof UgcBook
     */
    price60?: number;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof UgcBook
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    nick_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    phone_number?: string;
    /**
     * 创建时间
     * @type {string}
     * @memberof UgcBook
     */
    created_date?: string;
    /**
     * 
     * @type {number}
     * @memberof UgcBook
     */
    card_count?: number;
    /**
     * 状态
     * @type {string}
     * @memberof UgcBook
     */
    state?: string;
    /**
     * 卡册链接
     * @type {string}
     * @memberof UgcBook
     */
    card_book_link?: string;
    /**
     * 卡册制作
     * @type {string}
     * @memberof UgcBook
     */
    card_book_maker?: string;
    /**
     * 卡册校对
     * @type {string}
     * @memberof UgcBook
     */
    card_book_proofread?: string;
    /**
     * 版权
     * @type {string}
     * @memberof UgcBook
     */
    copyright?: string;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    review_comment?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UgcBook
     */
    image_url_list?: Array<string>;
    /**
     * 
     * @type {ListLibraryBookVO}
     * @memberof UgcBook
     */
    bundle_book_list?: ListLibraryBookVO;
    /**
     * 
     * @type {number}
     * @memberof UgcBook
     */
    anki_package_id?: number;
    /**
     * 图书原价
     * @type {number}
     * @memberof UgcBook
     */
    original_price?: number;
    /**
     * 营销图
     * @type {string}
     * @memberof UgcBook
     */
    marketing_img_url?: string;
    /**
     * v2版营销图字端
     * @type {Array<string>}
     * @memberof UgcBook
     */
    marketing_img_url_v2?: Array<string>;
    /**
     * 图书机器评分
     * @type {number}
     * @memberof UgcBook
     */
    rank_score?: number;
    /**
     * 图书的 人工评分
     * @type {number}
     * @memberof UgcBook
     */
    manual_rank_value?: number;
    /**
     * 累计曝光分
     * @type {number}
     * @memberof UgcBook
     */
    cumulative_exposure_score?: number;
    /**
     * 
     * @type {number}
     * @memberof UgcBook
     */
    recommend?: number;
    /**
     * 
     * @type {string}
     * @memberof UgcBook
     */
    last_modified_date?: string;
    /**
     * 最后发布更新时间
     * @type {string}
     * @memberof UgcBook
     */
    last_release_date?: string;
    /**
     * 卡包版本，1 为旧滑记卡包，2 为新版 hpkg
     * @type {number}
     * @memberof UgcBook
     */
    apkg_version?: number;
    /**
     * 1.x 新增，2.0 卡包内容有未发布的更新则为 true
     * @type {boolean}
     * @memberof UgcBook
     */
    has_unpublished_changes?: boolean;
    /**
     * 是否为 bundle类型图书
     * @type {boolean}
     * @memberof UgcBook
     */
    is_bundle?: boolean;
    /**
     * 是否开启了借阅
     * @type {boolean}
     * @memberof UgcBook
     */
    subscribe_status?: boolean;
    /**
     * 
     * @type {SubscribeConfigSettingRequest}
     * @memberof UgcBook
     */
    subscribe_config?: SubscribeConfigSettingRequest;
    /**
     * 下次借阅状态可修改时间 （开启订阅后30天内不能修改）
     * @type {string}
     * @memberof UgcBook
     */
    next_subscribe_modifiable_date?: string;
    /**
     * 付费用户转换成借阅后的借阅时长  lifetime，30，90，365
     * @type {string}
     * @memberof UgcBook
     */
    old_payment_user_convert?: string;
    /**
     * 免费用户转换成借阅后的借阅时长  lifetime，30，90，365
     * @type {string}
     * @memberof UgcBook
     */
    old_free_user_convert?: string;
    /**
     * 是否开启了会员免费学
     * @type {boolean}
     * @memberof UgcBook
     */
    vip_free?: boolean;
    /**
     * 是否hjml卡册
     * @type {boolean}
     * @memberof UgcBook
     */
    hjml?: boolean;
}
/**
 * 
 * @export
 * @interface UndoRateCardRequest
 */
export interface UndoRateCardRequest {
    /**
     * 
     * @type {number}
     * @memberof UndoRateCardRequest
     */
    deck_id?: number;
}
/**
 * 
 * @export
 * @interface UndoRateCardResponse
 */
export interface UndoRateCardResponse {
    /**
     * 
     * @type {number}
     * @memberof UndoRateCardResponse
     */
    card_id?: number;
}
/**
 * 
 * @export
 * @interface UnsuspendCardRequest
 */
export interface UnsuspendCardRequest {
    /**
     * 
     * @type {number}
     * @memberof UnsuspendCardRequest
     */
    card_id?: number;
}
/**
 * 增加/修改留言信息
 * @export
 * @interface UpdateChestRemarkRequest
 */
export interface UpdateChestRemarkRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateChestRemarkRequest
     */
    remark?: string;
}
/**
 * 
 * @export
 * @interface UpdateEggLetterRequest
 */
export interface UpdateEggLetterRequest {
    /**
     * 第一行加粗部分
     * @type {string}
     * @memberof UpdateEggLetterRequest
     */
    title?: string;
    /**
     * 正文
     * @type {string}
     * @memberof UpdateEggLetterRequest
     */
    content?: string;
    /**
     * 发送人
     * @type {string}
     * @memberof UpdateEggLetterRequest
     */
    from?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEggLetterRequest
     */
    visible?: boolean;
}
/**
 * 修改反馈状态
 * @export
 * @interface UpdateFeedbackStatueRequest
 */
export interface UpdateFeedbackStatueRequest {
    /**
     * 修改后的状态 可取值 read, replied, ignored, unread  unread表示撤回 且只能对已忽略的反馈操作
     * @type {string}
     * @memberof UpdateFeedbackStatueRequest
     */
    status?: string;
    /**
     * 回复的信息
     * @type {string}
     * @memberof UpdateFeedbackStatueRequest
     */
    reply_message?: string;
}
/**
 * 创建或修改Message请求体
 * @export
 * @interface UpdateInnerMessageRequest
 */
export interface UpdateInnerMessageRequest {
    /**
     * 消息类型 是否是全局消息,默认是true
     * @type {boolean}
     * @memberof UpdateInnerMessageRequest
     */
    global?: boolean;
    /**
     * 消息内容
     * @type {string}
     * @memberof UpdateInnerMessageRequest
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface UpdateMyInfoRequest
 */
export interface UpdateMyInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyInfoRequest
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyInfoRequest
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyInfoRequest
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyInfoRequest
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyInfoRequest
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyInfoRequest
     */
    profile?: string;
}
/**
 * 
 * @export
 * @interface UpdateSharedInfo
 */
export interface UpdateSharedInfo {
    /**
     * 标签，用竖线分割
     * @type {string}
     * @memberof UpdateSharedInfo
     */
    tags?: string;
    /**
     * 是否可以被公开列出
     * @type {boolean}
     * @memberof UpdateSharedInfo
     */
    is_public?: boolean;
    /**
     * 官方模板名称
     * @type {string}
     * @memberof UpdateSharedInfo
     */
    name?: string;
    /**
     * 用于在创建新卡片时，指定每个note字段的默认值
     * @type {{ [key: string]: string; }}
     * @memberof UpdateSharedInfo
     */
    fields_placeholder?: { [key: string]: string; };
    /**
     * 预览图 url
     * @type {string}
     * @memberof UpdateSharedInfo
     */
    preview_url?: string;
}
/**
 * 
 * @export
 * @interface UpdateSharedModelRequest
 */
export interface UpdateSharedModelRequest {
    /**
     * 模板名称
     * @type {string}
     * @memberof UpdateSharedModelRequest
     */
    model_name?: string;
    /**
     * 模板内容
     * @type {string}
     * @memberof UpdateSharedModelRequest
     */
    json_doc_content?: string;
    /**
     * 模板预览图
     * @type {string}
     * @memberof UpdateSharedModelRequest
     */
    preview_url?: string;
}
/**
 * 
 * @export
 * @interface UpdateStudyPlanRequest
 */
export interface UpdateStudyPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateStudyPlanRequest
     */
    deck_id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStudyPlanRequest
     */
    study_days_count?: number;
}
/**
 * 
 * @export
 * @interface UpdateUgcBookRequest
 */
export interface UpdateUgcBookRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateUgcBookRequest
     */
    anki_package_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    library_book_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUgcBookRequest
     */
    price60: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUgcBookRequest
     */
    image_url_list?: Array<string>;
    /**
     * 版权
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    copyright?: string;
    /**
     * 卡册制作
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    card_book_maker?: string;
    /**
     * 卡册校对
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    card_book_proofread?: string;
    /**
     * 卡册链接
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    card_book_link?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    library_book_description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    library_book_author?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUgcBookRequest
     */
    ugc_book_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    library_book_cover?: string;
    /**
     * 图书原价
     * @type {number}
     * @memberof UpdateUgcBookRequest
     */
    original_price?: number;
    /**
     * 营销图, 新版本弃用
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    marketing_img_url?: string;
    /**
     * 新版营销图使用该字端
     * @type {Array<string>}
     * @memberof UpdateUgcBookRequest
     */
    marketing_img_url_v2?: Array<string>;
    /**
     * 是否支持会员免费学
     * @type {boolean}
     * @memberof UpdateUgcBookRequest
     */
    vip_free?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    type?: UpdateUgcBookRequestTypeEnum;
    /**
     * bundle类型对应的ugc book id列表, 注意不是 library book id
     * @type {Array<number>}
     * @memberof UpdateUgcBookRequest
     */
    bundle_type_book_list?: Array<number>;
    /**
     * 默认使用 default
     * @type {string}
     * @memberof UpdateUgcBookRequest
     */
    dlc_key?: string;
    /**
     * dlc 所属 library book id
     * @type {number}
     * @memberof UpdateUgcBookRequest
     */
    dlc_belonging_book_id?: number;
    /**
     * 是否开启订阅
     * @type {boolean}
     * @memberof UpdateUgcBookRequest
     */
    subscribe_status?: boolean;
    /**
     * 
     * @type {SubscribeConfigSettingRequest}
     * @memberof UpdateUgcBookRequest
     */
    subscribe_config?: SubscribeConfigSettingRequest;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateUgcBookRequestTypeEnum {
    Bundle = 'Bundle',
    SingleLibraryBook = 'SingleLibraryBook',
    Dlc = 'Dlc'
}

/**
 * 
 * @export
 * @interface UpgradeStatusResponse
 */
export interface UpgradeStatusResponse {
    /**
     * 是否需要升级
     * @type {boolean}
     * @memberof UpgradeStatusResponse
     */
    need_upgrade?: boolean;
}
/**
 * 
 * @export
 * @interface UploadAnkiPackageRequest
 */
export interface UploadAnkiPackageRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadAnkiPackageRequest
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadAnkiPackageRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface UserCardInfoVO
 */
export interface UserCardInfoVO {
    /**
     * 
     * @type {number}
     * @memberof UserCardInfoVO
     */
    card_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCardInfoVO
     */
    note_preview?: string;
}
/**
 * 
 * @export
 * @interface UserCouponListResponse
 */
export interface UserCouponListResponse {
    /**
     * 
     * @type {Array<UserCouponListResponseCoupons>}
     * @memberof UserCouponListResponse
     */
    coupons?: Array<UserCouponListResponseCoupons>;
}
/**
 * 
 * @export
 * @interface UserCouponListResponseCoupons
 */
export interface UserCouponListResponseCoupons {
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    coupon_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCouponListResponseCoupons
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    library_book_id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    category_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCouponListResponseCoupons
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCouponListResponseCoupons
     */
    start_date?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCouponListResponseCoupons
     */
    end_date?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCouponListResponseCoupons
     */
    discount_type?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    discount_value?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    actual_discount_value?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    discount_min?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    discount_max?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    price_min?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCouponListResponseCoupons
     */
    price_max?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserCouponListResponseCoupons
     */
    is_consumed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCouponListResponseCoupons
     */
    consumed_date?: string;
}
/**
 * 关注者信息
 * @export
 * @interface UserFollowerInfo
 */
export interface UserFollowerInfo {
    /**
     * 
     * @type {number}
     * @memberof UserFollowerInfo
     */
    user_id?: number;
    /**
     * 用户昵称
     * @type {string}
     * @memberof UserFollowerInfo
     */
    nickname?: string;
    /**
     * 用户头像
     * @type {string}
     * @memberof UserFollowerInfo
     */
    avatar?: string;
    /**
     * 用户描述
     * @type {string}
     * @memberof UserFollowerInfo
     */
    profile?: string;
    /**
     * 关注状态
     * @type {boolean}
     * @memberof UserFollowerInfo
     */
    follow_status?: boolean;
    /**
     * 用户徽章
     * @type {Array<BadgeResponse>}
     * @memberof UserFollowerInfo
     */
    badge_list?: Array<BadgeResponse>;
    /**
     * 关注时间
     * @type {string}
     * @memberof UserFollowerInfo
     */
    follow_date?: string;
}
/**
 * 用户基本信息
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    user_id?: number;
    /**
     * 可能有多处引用，目前只保证 basic_info 接口有值
     * @type {string}
     * @memberof UserInfo
     */
    hua_id?: string;
    /**
     * 用户昵称
     * @type {string}
     * @memberof UserInfo
     */
    nickname?: string;
    /**
     * 用户头像
     * @type {string}
     * @memberof UserInfo
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    phone_number_e164?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    locatoin?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    gender?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    is_bind_apple?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    is_bind_wechat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    is_bind_qq?: boolean;
    /**
     * 第三方登录是否绑定手机号
     * @type {boolean}
     * @memberof UserInfo
     */
    is_bind_phone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    is_edu_verified?: boolean;
    /**
     * 
     * @type {Array<BadgeResponse>}
     * @memberof UserInfo
     */
    badge_list?: Array<BadgeResponse>;
    /**
     * 创作者主页banner
     * @type {string}
     * @memberof UserInfo
     */
    banner_url?: string;
    /**
     * 个人简介
     * @type {string}
     * @memberof UserInfo
     */
    profile?: string;
    /**
     * 粉丝数量
     * @type {number}
     * @memberof UserInfo
     */
    fans_count?: number;
    /**
     * 创作的图书数量
     * @type {number}
     * @memberof UserInfo
     */
    book_count?: number;
    /**
     * 学习人数
     * @type {any}
     * @memberof UserInfo
     */
    learner_count?: any | null;
    /**
     * 是否关注（查看其他用户主页时使用）
     * @type {boolean}
     * @memberof UserInfo
     */
    follow_status?: boolean;
    /**
     * 注册时间
     * @type {string}
     * @memberof UserInfo
     */
    register_date?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    source?: string;
    /**
     * 是否参与强化学习 ABTest
     * @type {boolean}
     * @memberof UserInfo
     */
    leanring_abtest?: boolean;
}
/**
 * 用户列表
 * @export
 * @interface UserInfoList
 */
export interface UserInfoList {
    /**
     * 
     * @type {Array<UserInfo>}
     * @memberof UserInfoList
     */
    list?: Array<UserInfo>;
}
/**
 * 
 * @export
 * @interface UserInviteCodeResponse
 */
export interface UserInviteCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof UserInviteCodeResponse
     */
    invite_code: string;
}
/**
 * 
 * @export
 * @interface UserInviteCodeStatusResponse
 */
export interface UserInviteCodeStatusResponse {
    /**
     * true表示有权限，false表示没有
     * @type {boolean}
     * @memberof UserInviteCodeStatusResponse
     */
    could_invite: boolean;
}
/**
 * 绑定支付请求体
 * @export
 * @interface UserPayBindRequest
 */
export interface UserPayBindRequest {
    /**
     * 支付宝绑定code
     * @type {string}
     * @memberof UserPayBindRequest
     */
    auth_code: string;
}
/**
 * 
 * @export
 * @interface UserPreference
 */
export interface UserPreference {
    /**
     * 
     * @type {string}
     * @memberof UserPreference
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface UserPreferenceVO
 */
export interface UserPreferenceVO {
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceVO
     */
    user_preference_id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceVO
     */
    preference_key?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceVO
     */
    preference_value?: string;
}
/**
 * 
 * @export
 * @interface UserTemplateIcpInfo
 */
export interface UserTemplateIcpInfo {
    /**
     * 
     * @type {number}
     * @memberof UserTemplateIcpInfo
     */
    order_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserTemplateIcpInfo
     */
    trial_unlocked_card_id_list?: Array<number>;
    /**
     * 
     * @type {UserTemplateIcpInfoTemplateIcpInfo}
     * @memberof UserTemplateIcpInfo
     */
    template_icp_info?: UserTemplateIcpInfoTemplateIcpInfo;
}
/**
 * 
 * @export
 * @interface UserTemplateIcpInfoTemplateIcpInfo
 */
export interface UserTemplateIcpInfoTemplateIcpInfo {
    /**
     * 
     * @type {number}
     * @memberof UserTemplateIcpInfoTemplateIcpInfo
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof UserTemplateIcpInfoTemplateIcpInfo
     */
    trail_unlock_limit?: number;
}
/**
 * 
 * @export
 * @interface UserTraceSpan
 */
export interface UserTraceSpan {
    /**
     * 
     * @type {string}
     * @memberof UserTraceSpan
     */
    device_fingerprint?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTraceSpan
     */
    user_token?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTraceSpan
     */
    event_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTraceSpan
     */
    event_detail?: string;
}
/**
 * 用户的会员信息
 * @export
 * @interface UserVipInfo
 */
export interface UserVipInfo {
    /**
     * 是否是信仰会员
     * @type {boolean}
     * @memberof UserVipInfo
     */
    is_lifetime_vip: boolean;
    /**
     * 是否购买过会员
     * @type {boolean}
     * @memberof UserVipInfo
     */
    is_bought?: boolean;
    /**
     * 
     * @type {Abtest202401}
     * @memberof UserVipInfo
     */
    abtest202401?: Abtest202401;
    /**
     * 会员的到期时间早于now，则会返回null，客户端会根据是否为null，判断当前是否为会员
     * @type {string}
     * @memberof UserVipInfo
     */
    expire_date?: string;
    /**
     * 会员的到期时间（如果用户没有充值过会员则会显示null 否则都会显示的），之所以不用expire_date 是为了兼容旧版APP
     * @type {string}
     * @memberof UserVipInfo
     */
    vip_deadline?: string;
    /**
     * 
     * @type {SubscriptionInfo}
     * @memberof UserVipInfo
     */
    subscription_info?: SubscriptionInfo;
    /**
     * 当前用户是否为公测期试用会员
     * @type {boolean}
     * @memberof UserVipInfo
     */
    is_public_beta_trial_vip?: boolean;
}
/**
 * 分为单位
 * @export
 * @interface UserWalletResponse
 */
export interface UserWalletResponse {
    /**
     * 
     * @type {number}
     * @memberof UserWalletResponse
     */
    ios_balance?: number;
    /**
     * 
     * @type {number}
     * @memberof UserWalletResponse
     */
    android_balance?: number;
}
/**
 * 会员免拼次数信息
 * @export
 * @interface VipGroupPassInfo
 */
export interface VipGroupPassInfo {
    /**
     * 剩余免拼次数
     * @type {number}
     * @memberof VipGroupPassInfo
     */
    left_pass_times?: number;
}
/**
 * 
 * @export
 * @interface VipMarketingIncomeDetailResponse
 */
export interface VipMarketingIncomeDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof VipMarketingIncomeDetailResponse
     */
    order_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof VipMarketingIncomeDetailResponse
     */
    purchase_order_id?: string;
    /**
     * 结算金额
     * @type {number}
     * @memberof VipMarketingIncomeDetailResponse
     */
    settlement_amount?: number;
    /**
     * 用户id
     * @type {number}
     * @memberof VipMarketingIncomeDetailResponse
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof VipMarketingIncomeDetailResponse
     */
    created_date?: string;
    /**
     * 会员名称
     * @type {string}
     * @memberof VipMarketingIncomeDetailResponse
     */
    sku_name?: string;
    /**
     * DELIVERED REFUNDED
     * @type {string}
     * @memberof VipMarketingIncomeDetailResponse
     */
    order_status?: VipMarketingIncomeDetailResponseOrderStatusEnum;
    /**
     * SETTLED 已入账 UNSETTLED 未入账
     * @type {string}
     * @memberof VipMarketingIncomeDetailResponse
     */
    settlement_status?: VipMarketingIncomeDetailResponseSettlementStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VipMarketingIncomeDetailResponse
     */
    invalid_reason?: string;
    /**
     * 结算时间
     * @type {string}
     * @memberof VipMarketingIncomeDetailResponse
     */
    settlement_date?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VipMarketingIncomeDetailResponseOrderStatusEnum {
    Delivered = 'DELIVERED',
    Refunded = 'REFUNDED'
}
/**
    * @export
    * @enum {string}
    */
export enum VipMarketingIncomeDetailResponseSettlementStatusEnum {
    Settled = 'SETTLED',
    Unsettled = 'UNSETTLED',
    Invalid = 'INVALID'
}

/**
 * 
 * @export
 * @interface VipRedeemLog
 */
export interface VipRedeemLog {
    /**
     * 
     * @type {string}
     * @memberof VipRedeemLog
     */
    created_time?: string;
    /**
     * 兑换码申请人
     * @type {string}
     * @memberof VipRedeemLog
     */
    redeem_creator?: string;
    /**
     * 会员持续事件
     * @type {string}
     * @memberof VipRedeemLog
     */
    vip_duration?: string;
    /**
     * 期望的授予对象
     * @type {string}
     * @memberof VipRedeemLog
     */
    target_user?: string;
    /**
     * 
     * @type {string}
     * @memberof VipRedeemLog
     */
    reason?: string;
    /**
     * 会员兑换码
     * @type {string}
     * @memberof VipRedeemLog
     */
    vip_redeem_code?: string;
    /**
     * 兑换人信息
     * @type {string}
     * @memberof VipRedeemLog
     */
    consumer?: string;
    /**
     * 会员兑换码的使用时间
     * @type {string}
     * @memberof VipRedeemLog
     */
    consume_time?: string;
    /**
     * 兑换码到期时间
     * @type {string}
     * @memberof VipRedeemLog
     */
    end_date?: string;
}
/**
 * 
 * @export
 * @interface VipSquadInfoResponse
 */
export interface VipSquadInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof VipSquadInfoResponse
     */
    squad_id?: number;
    /**
     * 
     * @type {number}
     * @memberof VipSquadInfoResponse
     */
    leader_id?: number;
    /**
     * 
     * @type {number}
     * @memberof VipSquadInfoResponse
     */
    member_count?: number;
    /**
     * 队员人数上限
     * @type {number}
     * @memberof VipSquadInfoResponse
     */
    member_limit?: number;
    /**
     * 
     * @type {string}
     * @memberof VipSquadInfoResponse
     */
    expire_date?: string;
    /**
     * 
     * @type {string}
     * @memberof VipSquadInfoResponse
     */
    created_date?: string;
}
/**
 * 
 * @export
 * @interface VipSquadInviteCodeListResponse
 */
export interface VipSquadInviteCodeListResponse {
    /**
     * 
     * @type {Array<VipSquadInviteCodeResponse>}
     * @memberof VipSquadInviteCodeListResponse
     */
    invite_code_list?: Array<VipSquadInviteCodeResponse>;
}
/**
 * 
 * @export
 * @interface VipSquadInviteCodeResponse
 */
export interface VipSquadInviteCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof VipSquadInviteCodeResponse
     */
    invite_code?: string;
    /**
     * 是否被使用过
     * @type {boolean}
     * @memberof VipSquadInviteCodeResponse
     */
    consumed?: boolean;
}
/**
 * 
 * @export
 * @interface VipSquadMemberInfoResponse
 */
export interface VipSquadMemberInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof VipSquadMemberInfoResponse
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof VipSquadMemberInfoResponse
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof VipSquadMemberInfoResponse
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof VipSquadMemberInfoResponse
     */
    join_date?: string;
    /**
     * 是否是 队长
     * @type {boolean}
     * @memberof VipSquadMemberInfoResponse
     */
    leader?: boolean;
}
/**
 * 
 * @export
 * @interface VipSquadMemberListResponse
 */
export interface VipSquadMemberListResponse {
    /**
     * 
     * @type {Array<VipSquadMemberInfoResponse>}
     * @memberof VipSquadMemberListResponse
     */
    members?: Array<VipSquadMemberInfoResponse>;
}
/**
 * 
 * @export
 * @interface VipSquadResponse
 */
export interface VipSquadResponse {
    /**
     * 
     * @type {VipSquadInfoResponse}
     * @memberof VipSquadResponse
     */
    squad?: VipSquadInfoResponse;
}
/**
 * 
 * @export
 * @interface VipStatusDetailResponse
 */
export interface VipStatusDetailResponse {
    /**
     * 签约中，最多有一个status 为ACTIVE
     * @type {Array<PeriodPayAgreementInfo>}
     * @memberof VipStatusDetailResponse
     */
    period_pay_agreement_list?: Array<PeriodPayAgreementInfo>;
}
/**
 * 
 * @export
 * @interface WalletSkuItem
 */
export interface WalletSkuItem {
    /**
     * 
     * @type {number}
     * @memberof WalletSkuItem
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof WalletSkuItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletSkuItem
     */
    sku_id?: string;
    /**
     * 
     * @type {number}
     * @memberof WalletSkuItem
     */
    delta?: number;
}
/**
 * 
 * @export
 * @interface WeixinJsApiSignatureRequest
 */
export interface WeixinJsApiSignatureRequest {
    /**
     * 
     * @type {string}
     * @memberof WeixinJsApiSignatureRequest
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface WeixinJsApiSignatureResponse
 */
export interface WeixinJsApiSignatureResponse {
    /**
     * 
     * @type {string}
     * @memberof WeixinJsApiSignatureResponse
     */
    appId?: string;
    /**
     * 
     * @type {number}
     * @memberof WeixinJsApiSignatureResponse
     */
    timestamp?: number;
    /**
     * 
     * @type {string}
     * @memberof WeixinJsApiSignatureResponse
     */
    nonceStr?: string;
    /**
     * 
     * @type {string}
     * @memberof WeixinJsApiSignatureResponse
     */
    signature?: string;
}
/**
 * 
 * @export
 * @interface WeixinMiniAuthTokenResponse
 */
export interface WeixinMiniAuthTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof WeixinMiniAuthTokenResponse
     */
    open_id?: string;
    /**
     * 
     * @type {string}
     * @memberof WeixinMiniAuthTokenResponse
     */
    union_id?: string;
    /**
     * 
     * @type {string}
     * @memberof WeixinMiniAuthTokenResponse
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof WeixinMiniAuthTokenResponse
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface WeixinMiniLoginRequest
 */
export interface WeixinMiniLoginRequest {
    /**
     * 登录授权码
     * @type {string}
     * @memberof WeixinMiniLoginRequest
     */
    code?: string;
    /**
     * 获取手机号授权码
     * @type {string}
     * @memberof WeixinMiniLoginRequest
     */
    phone_code?: string;
}
/**
 * 公众号网页授权登录的请求
 * @export
 * @interface WeixinMpCodeRequest
 */
export interface WeixinMpCodeRequest {
    /**
     * 指定哪个公众号，如果没有填写，则默认为 `huajifwh`, 如果是小程序 huaji_mini_program
     * @type {string}
     * @memberof WeixinMpCodeRequest
     */
    mp_id?: WeixinMpCodeRequestMpIdEnum;
    /**
     * 回调得到的code
     * @type {string}
     * @memberof WeixinMpCodeRequest
     */
    code?: string;
    /**
     * 向微信请求授权的scope，详见 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html#1
     * @type {string}
     * @memberof WeixinMpCodeRequest
     */
    scope: WeixinMpCodeRequestScopeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WeixinMpCodeRequestMpIdEnum {
    Huajifwh = 'huajifwh',
    Huajiczy = 'huajiczy',
    HuajiMiniProgram = 'huaji_mini_program'
}
/**
    * @export
    * @enum {string}
    */
export enum WeixinMpCodeRequestScopeEnum {
    Base = 'snsapi_base',
    Userinfo = 'snsapi_userinfo'
}

/**
 * 
 * @export
 * @interface WeixinMpCodeResponse
 */
export interface WeixinMpCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof WeixinMpCodeResponse
     */
    openId?: string;
    /**
     * 用户关注该公众号的时间，为时间戳。如果用户曾多次关注，则取最后关注时间；如果用户没有关注，则此字段为null
     * @type {number}
     * @memberof WeixinMpCodeResponse
     */
    subscribe_time?: number;
}
/**
 * 
 * @export
 * @interface WithdrawalApplicationRequest
 */
export interface WithdrawalApplicationRequest {
    /**
     * 单位为分
     * @type {number}
     * @memberof WithdrawalApplicationRequest
     */
    withdraw_amount: number;
    /**
     * CREATOR_WALLET, MARKETING_WALLET, CREATOR_REWARD_WALLET
     * @type {string}
     * @memberof WithdrawalApplicationRequest
     */
    wallet_type: string;
    /**
     * ALIPAY, WECHAT
     * @type {string}
     * @memberof WithdrawalApplicationRequest
     */
    withdraw_channel: string;
    /**
     * 是否使用免抽额度
     * @type {boolean}
     * @memberof WithdrawalApplicationRequest
     */
    use_tax_free?: boolean;
}
/**
 * 
 * @export
 * @interface WithdrawalApplicationVO
 */
export interface WithdrawalApplicationVO {
    /**
     * 
     * @type {number}
     * @memberof WithdrawalApplicationVO
     */
    user_withdrawal_requests_id?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalApplicationVO
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    user_phone?: string;
    /**
     * 提现金额
     * @type {number}
     * @memberof WithdrawalApplicationVO
     */
    withdraw_amount?: number;
    /**
     * 提现手续费
     * @type {number}
     * @memberof WithdrawalApplicationVO
     */
    service_charge?: number;
    /**
     * 审核时间
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    review_date?: string;
    /**
     * 提现状态
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    withdrawal_status?: string;
    /**
     * 提现渠道
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    withdrawal_channel?: string;
    /**
     * 用户昵称
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    nickname?: string;
    /**
     * 订单号
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    hua_gateway_order_id?: string;
    /**
     * 申请提现时间
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    created_date?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    review_comment?: string;
    /**
     * 到账时间
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    payment_date?: string;
    /**
     * 提现失败原因
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    withdrawal_error_msg?: string;
    /**
     * 提现账号
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    payment_nickname?: string;
    /**
     * CREATOR_WALLET（创作者收益）, MARKETING_WALLET（分享者收益）， CREATOR_REWARD_WALLET(激励金)
     * @type {string}
     * @memberof WithdrawalApplicationVO
     */
    withdrawal_wallet_type?: string;
}
/**
 * 用户提现信息
 * @export
 * @interface WithdrawalRequestInfo
 */
export interface WithdrawalRequestInfo {
    /**
     * 剩余可提现次数
     * @type {number}
     * @memberof WithdrawalRequestInfo
     */
    available_times?: number;
}
/**
 * 结算钱包
 * @export
 * @interface WithdrawalWalletVO
 */
export interface WithdrawalWalletVO {
    /**
     * balance = wallet_balance + unrecorded_balance 余额 = 钱包金额（已入账） + 未入账金额
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    balance?: number;
    /**
     * 待入账金额
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    un_record_amount?: number;
    /**
     * 提现中金额
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    withdraw_amount?: number;
    /**
     * 累计收入
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    accumulate_income?: number;
    /**
     * 邀请人数
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    invite_numbers?: number;
    /**
     * 已入账钱包余额
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    wallet_balance?: number;
    /**
     * 剩余免抽金额（免抽钱包的余额 - 提现中用过的免抽额度）
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    tax_free_amount?: number;
    /**
     * 2022-11-01前累计收入
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    accumulate_income1101?: number;
    /**
     * 2022-11-01前累计收入获得的免抽额度
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    tax_free_amount1101?: number;
    /**
     * 提现手续费
     * @type {number}
     * @memberof WithdrawalWalletVO
     */
    service_charge_ratio?: number;
}
/**
 * 微信APP支付返回结果集
 * @export
 * @interface WxAppPayOrderParam
 */
export interface WxAppPayOrderParam {
    /**
     * 
     * @type {string}
     * @memberof WxAppPayOrderParam
     */
    sign?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAppPayOrderParam
     */
    prepay_id?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAppPayOrderParam
     */
    partner_id?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAppPayOrderParam
     */
    app_id?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAppPayOrderParam
     */
    package_value?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAppPayOrderParam
     */
    time_stamp?: string;
    /**
     * 
     * @type {string}
     * @memberof WxAppPayOrderParam
     */
    nonce_str?: string;
}
/**
 * 微信内wap支付返回结果集
 * @export
 * @interface WxJsApiOrderParam
 */
export interface WxJsApiOrderParam {
    /**
     * 
     * @type {string}
     * @memberof WxJsApiOrderParam
     */
    sign_type?: string;
    /**
     * 
     * @type {string}
     * @memberof WxJsApiOrderParam
     */
    pay_sign?: string;
    /**
     * 
     * @type {string}
     * @memberof WxJsApiOrderParam
     */
    app_id?: string;
    /**
     * 
     * @type {string}
     * @memberof WxJsApiOrderParam
     */
    package_value?: string;
    /**
     * 
     * @type {string}
     * @memberof WxJsApiOrderParam
     */
    time_stamp?: string;
    /**
     * 
     * @type {string}
     * @memberof WxJsApiOrderParam
     */
    nonce_str?: string;
}

/**
 * AdApi - axios parameter creator
 * @export
 */
export const AdApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 本次 ad_position_key 在HUA-4249 中可以使用 “速记完成页面”
         * @param {string} adPositionKey 
         * @param {number} [libraryBookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdvertising: async (adPositionKey: string, libraryBookId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adPositionKey' is not null or undefined
            assertParamExists('generateAdvertising', 'adPositionKey', adPositionKey)
            const localVarPath = `/ad/v1/position/{ad_position_key}/item`
                .replace(`{${"ad_position_key"}}`, encodeURIComponent(String(adPositionKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (libraryBookId !== undefined) {
                localVarQueryParameter['library_book_id'] = libraryBookId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdApi - functional programming interface
 * @export
 */
export const AdApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdApiAxiosParamCreator(configuration)
    return {
        /**
         * 本次 ad_position_key 在HUA-4249 中可以使用 “速记完成页面”
         * @param {string} adPositionKey 
         * @param {number} [libraryBookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAdvertising(adPositionKey: string, libraryBookId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAdvertising(adPositionKey, libraryBookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdApi - factory interface
 * @export
 */
export const AdApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdApiFp(configuration)
    return {
        /**
         * 本次 ad_position_key 在HUA-4249 中可以使用 “速记完成页面”
         * @param {string} adPositionKey 
         * @param {number} [libraryBookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdvertising(adPositionKey: string, libraryBookId?: number, options?: any): AxiosPromise<AdvertisingResponse> {
            return localVarFp.generateAdvertising(adPositionKey, libraryBookId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdApi - object-oriented interface
 * @export
 * @class AdApi
 * @extends {BaseAPI}
 */
export class AdApi extends BaseAPI {
    /**
     * 本次 ad_position_key 在HUA-4249 中可以使用 “速记完成页面”
     * @param {string} adPositionKey 
     * @param {number} [libraryBookId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdApi
     */
    public generateAdvertising(adPositionKey: string, libraryBookId?: number, options?: any) {
        return AdApiFp(this.configuration).generateAdvertising(adPositionKey, libraryBookId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AlipayApi - axios parameter creator
 * @export
 */
export const AlipayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 绑定支付宝回调接口
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindCallback: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/alipay/v1/bind/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 生成回调地址
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCallbackUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/alipay/v1/bind/callback_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提供给app的授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAuth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/alipay/v1/bind/app/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlipayApi - functional programming interface
 * @export
 */
export const AlipayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlipayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 绑定支付宝回调接口
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bindCallback(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bindCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 生成回调地址
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCallbackUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCallbackUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提供给app的授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantAuth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlipayApi - factory interface
 * @export
 */
export const AlipayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlipayApiFp(configuration)
    return {
        /**
         * 
         * @summary 绑定支付宝回调接口
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindCallback(options?: any): AxiosPromise<void> {
            return localVarFp.bindCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 生成回调地址
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCallbackUrl(options?: any): AxiosPromise<string> {
            return localVarFp.generateCallbackUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提供给app的授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAuth(options?: any): AxiosPromise<string> {
            return localVarFp.grantAuth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlipayApi - object-oriented interface
 * @export
 * @class AlipayApi
 * @extends {BaseAPI}
 */
export class AlipayApi extends BaseAPI {
    /**
     * 
     * @summary 绑定支付宝回调接口
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlipayApi
     */
    public bindCallback(options?: any) {
        return AlipayApiFp(this.configuration).bindCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 生成回调地址
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlipayApi
     */
    public generateCallbackUrl(options?: any) {
        return AlipayApiFp(this.configuration).generateCallbackUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提供给app的授权码
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlipayApi
     */
    public grantAuth(options?: any) {
        return AlipayApiFp(this.configuration).grantAuth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApkgApi - axios parameter creator
 * @export
 */
export const ApkgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 批量获得cards
         * @param {string} packageUuid 
         * @param {ApkgCardBulkGetRequest} apkgCardBulkGetRequest 当query传入&#x60;?compress&#x3D;1&#x60;时，会以&#x60;text/plain&#x60;返回，以utf-8编码序列化json（客户端应将二进制反序列化为object再进行处理），这样的好处可以被阿里云压缩。
         * @param {number} [compress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgBulkGetCards: async (packageUuid: string, apkgCardBulkGetRequest: ApkgCardBulkGetRequest, compress?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgBulkGetCards', 'packageUuid', packageUuid)
            // verify required parameter 'apkgCardBulkGetRequest' is not null or undefined
            assertParamExists('apkgBulkGetCards', 'apkgCardBulkGetRequest', apkgCardBulkGetRequest)
            const localVarPath = `/apkg/v1/package/{package_uuid}/cards/bulk`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (compress !== undefined) {
                localVarQueryParameter['compress'] = compress;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apkgCardBulkGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得某个apkg的基本信息（模板、卡册等）
         * @param {string} packageUuid 
         * @param {boolean} [rawTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackageInfo: async (packageUuid: string, rawTemplate?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgGetPackageInfo', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/info`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rawTemplate !== undefined) {
                localVarQueryParameter['raw_template'] = rawTemplate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得一个资源包的所有媒体文件列表，包括sha1, size等
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackageMediaList: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgGetPackageMediaList', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/media_list`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得一个资源包的所有媒体文件映射
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackageMediaMapping: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgGetPackageMediaMapping', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/media_mapping`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改apkg的tag标签
         * @param {string} packageUuid 被修改的package_uuid
         * @param {ChangeApkgTagRequest} changeApkgTagRequest 新配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApkgTags: async (packageUuid: string, changeApkgTagRequest: ChangeApkgTagRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('changeApkgTags', 'packageUuid', packageUuid)
            // verify required parameter 'changeApkgTagRequest' is not null or undefined
            assertParamExists('changeApkgTags', 'changeApkgTagRequest', changeApkgTagRequest)
            const localVarPath = `/apkg_creator_tag/v1/package/{package_uuid}/tag`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeApkgTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改卡包名称
         * @param {string} packageUuid 卡包uuid
         * @param {RequestChangePackageName} requestChangePackageName 新名称
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApkgpPackageName: async (packageUuid: string, requestChangePackageName: RequestChangePackageName, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('changeApkgpPackageName', 'packageUuid', packageUuid)
            // verify required parameter 'requestChangePackageName' is not null or undefined
            assertParamExists('changeApkgpPackageName', 'requestChangePackageName', requestChangePackageName)
            const localVarPath = `/apkg/v1/package/{package_uuid}/rename`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestChangePackageName, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 上传anki卡包到数据库
         * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiteApkg: async (uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAnkiPackageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 为卡包创建 book，即可开始学习
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserBookForUploader: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('createUserBookForUploader', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/my/{package_uuid}/book`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得卡包内相关推荐卡片map
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRecMap: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getCardRecMap', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/cards/recommends`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户所有的标签
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorTagGetAllPackage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg_creator_tag/v1/package`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {string} keyword 
         * @param {QueryPageRequest} queryPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageCardsByKeyword: async (packageUuid: string, keyword: string, queryPageRequest: QueryPageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('searchPageCardsByKeyword', 'packageUuid', packageUuid)
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('searchPageCardsByKeyword', 'keyword', keyword)
            // verify required parameter 'queryPageRequest' is not null or undefined
            assertParamExists('searchPageCardsByKeyword', 'queryPageRequest', queryPageRequest)
            const localVarPath = `/apkg/v1/package/{package_uuid}/cards/search`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 上传anki卡包到数据库
         * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAnkiPackage: async (uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg/v1/package`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAnkiPackageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApkgApi - functional programming interface
 * @export
 */
export const ApkgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApkgApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 批量获得cards
         * @param {string} packageUuid 
         * @param {ApkgCardBulkGetRequest} apkgCardBulkGetRequest 当query传入&#x60;?compress&#x3D;1&#x60;时，会以&#x60;text/plain&#x60;返回，以utf-8编码序列化json（客户端应将二进制反序列化为object再进行处理），这样的好处可以被阿里云压缩。
         * @param {number} [compress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgBulkGetCards(packageUuid: string, apkgCardBulkGetRequest: ApkgCardBulkGetRequest, compress?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgCardBulkGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgBulkGetCards(packageUuid, apkgCardBulkGetRequest, compress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得某个apkg的基本信息（模板、卡册等）
         * @param {string} packageUuid 
         * @param {boolean} [rawTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgGetPackageInfo(packageUuid: string, rawTemplate?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnkiPackageVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgGetPackageInfo(packageUuid, rawTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得一个资源包的所有媒体文件列表，包括sha1, size等
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgGetPackageMediaList(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnkiMediaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgGetPackageMediaList(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得一个资源包的所有媒体文件映射
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgGetPackageMediaMapping(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnkiMediaMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgGetPackageMediaMapping(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改apkg的tag标签
         * @param {string} packageUuid 被修改的package_uuid
         * @param {ChangeApkgTagRequest} changeApkgTagRequest 新配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeApkgTags(packageUuid: string, changeApkgTagRequest: ChangeApkgTagRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeApkgTags(packageUuid, changeApkgTagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改卡包名称
         * @param {string} packageUuid 卡包uuid
         * @param {RequestChangePackageName} requestChangePackageName 新名称
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeApkgpPackageName(packageUuid: string, requestChangePackageName: RequestChangePackageName, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeApkgpPackageName(packageUuid, requestChangePackageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 上传anki卡包到数据库
         * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLiteApkg(uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnkiPackageVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLiteApkg(uploadAnkiPackageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 为卡包创建 book，即可开始学习
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserBookForUploader(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserBookForUploader(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得卡包内相关推荐卡片map
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardRecMap(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Array>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardRecMap(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户所有的标签
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreatorTagGetAllPackage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreatorTagGetAllPackage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {string} keyword 
         * @param {QueryPageRequest} queryPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPageCardsByKeyword(packageUuid: string, keyword: string, queryPageRequest: QueryPageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchPageCardsVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPageCardsByKeyword(packageUuid, keyword, queryPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 上传anki卡包到数据库
         * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAnkiPackage(uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAnkiPackage(uploadAnkiPackageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApkgApi - factory interface
 * @export
 */
export const ApkgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApkgApiFp(configuration)
    return {
        /**
         * 
         * @summary 批量获得cards
         * @param {string} packageUuid 
         * @param {ApkgCardBulkGetRequest} apkgCardBulkGetRequest 当query传入&#x60;?compress&#x3D;1&#x60;时，会以&#x60;text/plain&#x60;返回，以utf-8编码序列化json（客户端应将二进制反序列化为object再进行处理），这样的好处可以被阿里云压缩。
         * @param {number} [compress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgBulkGetCards(packageUuid: string, apkgCardBulkGetRequest: ApkgCardBulkGetRequest, compress?: number, options?: any): AxiosPromise<ApkgCardBulkGetResponse> {
            return localVarFp.apkgBulkGetCards(packageUuid, apkgCardBulkGetRequest, compress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得某个apkg的基本信息（模板、卡册等）
         * @param {string} packageUuid 
         * @param {boolean} [rawTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackageInfo(packageUuid: string, rawTemplate?: boolean, options?: any): AxiosPromise<AnkiPackageVo> {
            return localVarFp.apkgGetPackageInfo(packageUuid, rawTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得一个资源包的所有媒体文件列表，包括sha1, size等
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackageMediaList(packageUuid: string, options?: any): AxiosPromise<AnkiMediaList> {
            return localVarFp.apkgGetPackageMediaList(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得一个资源包的所有媒体文件映射
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackageMediaMapping(packageUuid: string, options?: any): AxiosPromise<AnkiMediaMappingResponse> {
            return localVarFp.apkgGetPackageMediaMapping(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改apkg的tag标签
         * @param {string} packageUuid 被修改的package_uuid
         * @param {ChangeApkgTagRequest} changeApkgTagRequest 新配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApkgTags(packageUuid: string, changeApkgTagRequest: ChangeApkgTagRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeApkgTags(packageUuid, changeApkgTagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改卡包名称
         * @param {string} packageUuid 卡包uuid
         * @param {RequestChangePackageName} requestChangePackageName 新名称
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeApkgpPackageName(packageUuid: string, requestChangePackageName: RequestChangePackageName, options?: any): AxiosPromise<void> {
            return localVarFp.changeApkgpPackageName(packageUuid, requestChangePackageName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 上传anki卡包到数据库
         * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiteApkg(uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options?: any): AxiosPromise<AnkiPackageVo> {
            return localVarFp.createLiteApkg(uploadAnkiPackageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 为卡包创建 book，即可开始学习
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserBookForUploader(packageUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.createUserBookForUploader(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得卡包内相关推荐卡片map
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRecMap(packageUuid: string, options?: any): AxiosPromise<{ [key: string]: Array<Array>; }> {
            return localVarFp.getCardRecMap(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户所有的标签
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorTagGetAllPackage(options?: any): AxiosPromise<ApkgTagResponse> {
            return localVarFp.getCreatorTagGetAllPackage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {string} keyword 
         * @param {QueryPageRequest} queryPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPageCardsByKeyword(packageUuid: string, keyword: string, queryPageRequest: QueryPageRequest, options?: any): AxiosPromise<SearchPageCardsVO> {
            return localVarFp.searchPageCardsByKeyword(packageUuid, keyword, queryPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 上传anki卡包到数据库
         * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAnkiPackage(uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.uploadAnkiPackage(uploadAnkiPackageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApkgApi - object-oriented interface
 * @export
 * @class ApkgApi
 * @extends {BaseAPI}
 */
export class ApkgApi extends BaseAPI {
    /**
     * 
     * @summary 批量获得cards
     * @param {string} packageUuid 
     * @param {ApkgCardBulkGetRequest} apkgCardBulkGetRequest 当query传入&#x60;?compress&#x3D;1&#x60;时，会以&#x60;text/plain&#x60;返回，以utf-8编码序列化json（客户端应将二进制反序列化为object再进行处理），这样的好处可以被阿里云压缩。
     * @param {number} [compress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public apkgBulkGetCards(packageUuid: string, apkgCardBulkGetRequest: ApkgCardBulkGetRequest, compress?: number, options?: any) {
        return ApkgApiFp(this.configuration).apkgBulkGetCards(packageUuid, apkgCardBulkGetRequest, compress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得某个apkg的基本信息（模板、卡册等）
     * @param {string} packageUuid 
     * @param {boolean} [rawTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public apkgGetPackageInfo(packageUuid: string, rawTemplate?: boolean, options?: any) {
        return ApkgApiFp(this.configuration).apkgGetPackageInfo(packageUuid, rawTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得一个资源包的所有媒体文件列表，包括sha1, size等
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public apkgGetPackageMediaList(packageUuid: string, options?: any) {
        return ApkgApiFp(this.configuration).apkgGetPackageMediaList(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得一个资源包的所有媒体文件映射
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public apkgGetPackageMediaMapping(packageUuid: string, options?: any) {
        return ApkgApiFp(this.configuration).apkgGetPackageMediaMapping(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改apkg的tag标签
     * @param {string} packageUuid 被修改的package_uuid
     * @param {ChangeApkgTagRequest} changeApkgTagRequest 新配置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public changeApkgTags(packageUuid: string, changeApkgTagRequest: ChangeApkgTagRequest, options?: any) {
        return ApkgApiFp(this.configuration).changeApkgTags(packageUuid, changeApkgTagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改卡包名称
     * @param {string} packageUuid 卡包uuid
     * @param {RequestChangePackageName} requestChangePackageName 新名称
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public changeApkgpPackageName(packageUuid: string, requestChangePackageName: RequestChangePackageName, options?: any) {
        return ApkgApiFp(this.configuration).changeApkgpPackageName(packageUuid, requestChangePackageName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 上传anki卡包到数据库
     * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public createLiteApkg(uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options?: any) {
        return ApkgApiFp(this.configuration).createLiteApkg(uploadAnkiPackageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 为卡包创建 book，即可开始学习
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public createUserBookForUploader(packageUuid: string, options?: any) {
        return ApkgApiFp(this.configuration).createUserBookForUploader(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得卡包内相关推荐卡片map
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public getCardRecMap(packageUuid: string, options?: any) {
        return ApkgApiFp(this.configuration).getCardRecMap(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户所有的标签
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public getCreatorTagGetAllPackage(options?: any) {
        return ApkgApiFp(this.configuration).getCreatorTagGetAllPackage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageUuid 
     * @param {string} keyword 
     * @param {QueryPageRequest} queryPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public searchPageCardsByKeyword(packageUuid: string, keyword: string, queryPageRequest: QueryPageRequest, options?: any) {
        return ApkgApiFp(this.configuration).searchPageCardsByKeyword(packageUuid, keyword, queryPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 上传anki卡包到数据库
     * @param {UploadAnkiPackageRequest} [uploadAnkiPackageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgApi
     */
    public uploadAnkiPackage(uploadAnkiPackageRequest?: UploadAnkiPackageRequest, options?: any) {
        return ApkgApiFp(this.configuration).uploadAnkiPackage(uploadAnkiPackageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApkgBatchMutateApi - axios parameter creator
 * @export
 */
export const ApkgBatchMutateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 只允许历史上没有job，或者前一个job处于cancelled 或 mutate_finished 才可以创建新的。如果前一个处于under_review，可以先取消（删除）这个，再创建新的。
         * @summary 给该package uuid创建一个新的mutate job, 没有变化的类型（如model）或者操作（如delete），可以置为null，后端可以接受。
         * @param {string} packageUuid 
         * @param {ApkgBatchMutateJob} apkgBatchMutateJob 
         * @param {boolean} [runNow] 如果是管理员，且为true，可以同步地执行变更。有助于测试和实时修改数据
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApkgBatchMutateJob: async (packageUuid: string, apkgBatchMutateJob: ApkgBatchMutateJob, runNow?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('createApkgBatchMutateJob', 'packageUuid', packageUuid)
            // verify required parameter 'apkgBatchMutateJob' is not null or undefined
            assertParamExists('createApkgBatchMutateJob', 'apkgBatchMutateJob', apkgBatchMutateJob)
            const localVarPath = `/apkg/v1/package/{package_uuid}/batch_mutate_job`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (runNow !== undefined) {
                localVarQueryParameter['run_now'] = runNow;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apkgBatchMutateJob, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 删除或取消一个mutate job。只允许在 under_review 的job被取消。
         * @summary delete ApkgBatchMutateJob
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApkgBatchMutateJob: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('deleteApkgBatchMutateJob', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/batch_mutate_job`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 如果没有则返回404。如果有，则总是返回最新的那个。
         * @summary 获得该package uuid的最后一个mutate job
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApkgBatchMutateJob: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getApkgBatchMutateJob', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/batch_mutate_job`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 如果没有则返回404。如果有，则总是返回最新的那个。
         * @summary （仅管理员可见）获得该 mutate job 的详细信息，用于审核
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApkgBatchMutateJobDetail: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getApkgBatchMutateJobDetail', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/batch_mutate_job/detail`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发送审核意见
         * @param {string} packageUuid 
         * @param {ReviewApkgBatchMutateJobRequest} reviewApkgBatchMutateJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewApkgBatchMutateJob: async (packageUuid: string, reviewApkgBatchMutateJobRequest: ReviewApkgBatchMutateJobRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('reviewApkgBatchMutateJob', 'packageUuid', packageUuid)
            // verify required parameter 'reviewApkgBatchMutateJobRequest' is not null or undefined
            assertParamExists('reviewApkgBatchMutateJob', 'reviewApkgBatchMutateJobRequest', reviewApkgBatchMutateJobRequest)
            const localVarPath = `/apkg/v1/package/{package_uuid}/batch_mutate_job/review`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewApkgBatchMutateJobRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApkgBatchMutateApi - functional programming interface
 * @export
 */
export const ApkgBatchMutateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApkgBatchMutateApiAxiosParamCreator(configuration)
    return {
        /**
         * 只允许历史上没有job，或者前一个job处于cancelled 或 mutate_finished 才可以创建新的。如果前一个处于under_review，可以先取消（删除）这个，再创建新的。
         * @summary 给该package uuid创建一个新的mutate job, 没有变化的类型（如model）或者操作（如delete），可以置为null，后端可以接受。
         * @param {string} packageUuid 
         * @param {ApkgBatchMutateJob} apkgBatchMutateJob 
         * @param {boolean} [runNow] 如果是管理员，且为true，可以同步地执行变更。有助于测试和实时修改数据
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApkgBatchMutateJob(packageUuid: string, apkgBatchMutateJob: ApkgBatchMutateJob, runNow?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgBatchMutateJobStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApkgBatchMutateJob(packageUuid, apkgBatchMutateJob, runNow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 删除或取消一个mutate job。只允许在 under_review 的job被取消。
         * @summary delete ApkgBatchMutateJob
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApkgBatchMutateJob(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgBatchMutateJobStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApkgBatchMutateJob(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 如果没有则返回404。如果有，则总是返回最新的那个。
         * @summary 获得该package uuid的最后一个mutate job
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApkgBatchMutateJob(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgBatchMutateJobStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApkgBatchMutateJob(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 如果没有则返回404。如果有，则总是返回最新的那个。
         * @summary （仅管理员可见）获得该 mutate job 的详细信息，用于审核
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApkgBatchMutateJobDetail(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgBatchMutateJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApkgBatchMutateJobDetail(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发送审核意见
         * @param {string} packageUuid 
         * @param {ReviewApkgBatchMutateJobRequest} reviewApkgBatchMutateJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewApkgBatchMutateJob(packageUuid: string, reviewApkgBatchMutateJobRequest: ReviewApkgBatchMutateJobRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgBatchMutateJobStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewApkgBatchMutateJob(packageUuid, reviewApkgBatchMutateJobRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApkgBatchMutateApi - factory interface
 * @export
 */
export const ApkgBatchMutateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApkgBatchMutateApiFp(configuration)
    return {
        /**
         * 只允许历史上没有job，或者前一个job处于cancelled 或 mutate_finished 才可以创建新的。如果前一个处于under_review，可以先取消（删除）这个，再创建新的。
         * @summary 给该package uuid创建一个新的mutate job, 没有变化的类型（如model）或者操作（如delete），可以置为null，后端可以接受。
         * @param {string} packageUuid 
         * @param {ApkgBatchMutateJob} apkgBatchMutateJob 
         * @param {boolean} [runNow] 如果是管理员，且为true，可以同步地执行变更。有助于测试和实时修改数据
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApkgBatchMutateJob(packageUuid: string, apkgBatchMutateJob: ApkgBatchMutateJob, runNow?: boolean, options?: any): AxiosPromise<ApkgBatchMutateJobStatus> {
            return localVarFp.createApkgBatchMutateJob(packageUuid, apkgBatchMutateJob, runNow, options).then((request) => request(axios, basePath));
        },
        /**
         * 删除或取消一个mutate job。只允许在 under_review 的job被取消。
         * @summary delete ApkgBatchMutateJob
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApkgBatchMutateJob(packageUuid: string, options?: any): AxiosPromise<ApkgBatchMutateJobStatus> {
            return localVarFp.deleteApkgBatchMutateJob(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 如果没有则返回404。如果有，则总是返回最新的那个。
         * @summary 获得该package uuid的最后一个mutate job
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApkgBatchMutateJob(packageUuid: string, options?: any): AxiosPromise<ApkgBatchMutateJobStatus> {
            return localVarFp.getApkgBatchMutateJob(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 如果没有则返回404。如果有，则总是返回最新的那个。
         * @summary （仅管理员可见）获得该 mutate job 的详细信息，用于审核
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApkgBatchMutateJobDetail(packageUuid: string, options?: any): AxiosPromise<ApkgBatchMutateJob> {
            return localVarFp.getApkgBatchMutateJobDetail(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发送审核意见
         * @param {string} packageUuid 
         * @param {ReviewApkgBatchMutateJobRequest} reviewApkgBatchMutateJobRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewApkgBatchMutateJob(packageUuid: string, reviewApkgBatchMutateJobRequest: ReviewApkgBatchMutateJobRequest, options?: any): AxiosPromise<ApkgBatchMutateJobStatus> {
            return localVarFp.reviewApkgBatchMutateJob(packageUuid, reviewApkgBatchMutateJobRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApkgBatchMutateApi - object-oriented interface
 * @export
 * @class ApkgBatchMutateApi
 * @extends {BaseAPI}
 */
export class ApkgBatchMutateApi extends BaseAPI {
    /**
     * 只允许历史上没有job，或者前一个job处于cancelled 或 mutate_finished 才可以创建新的。如果前一个处于under_review，可以先取消（删除）这个，再创建新的。
     * @summary 给该package uuid创建一个新的mutate job, 没有变化的类型（如model）或者操作（如delete），可以置为null，后端可以接受。
     * @param {string} packageUuid 
     * @param {ApkgBatchMutateJob} apkgBatchMutateJob 
     * @param {boolean} [runNow] 如果是管理员，且为true，可以同步地执行变更。有助于测试和实时修改数据
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgBatchMutateApi
     */
    public createApkgBatchMutateJob(packageUuid: string, apkgBatchMutateJob: ApkgBatchMutateJob, runNow?: boolean, options?: any) {
        return ApkgBatchMutateApiFp(this.configuration).createApkgBatchMutateJob(packageUuid, apkgBatchMutateJob, runNow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 删除或取消一个mutate job。只允许在 under_review 的job被取消。
     * @summary delete ApkgBatchMutateJob
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgBatchMutateApi
     */
    public deleteApkgBatchMutateJob(packageUuid: string, options?: any) {
        return ApkgBatchMutateApiFp(this.configuration).deleteApkgBatchMutateJob(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 如果没有则返回404。如果有，则总是返回最新的那个。
     * @summary 获得该package uuid的最后一个mutate job
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgBatchMutateApi
     */
    public getApkgBatchMutateJob(packageUuid: string, options?: any) {
        return ApkgBatchMutateApiFp(this.configuration).getApkgBatchMutateJob(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 如果没有则返回404。如果有，则总是返回最新的那个。
     * @summary （仅管理员可见）获得该 mutate job 的详细信息，用于审核
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgBatchMutateApi
     */
    public getApkgBatchMutateJobDetail(packageUuid: string, options?: any) {
        return ApkgBatchMutateApiFp(this.configuration).getApkgBatchMutateJobDetail(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发送审核意见
     * @param {string} packageUuid 
     * @param {ReviewApkgBatchMutateJobRequest} reviewApkgBatchMutateJobRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgBatchMutateApi
     */
    public reviewApkgBatchMutateJob(packageUuid: string, reviewApkgBatchMutateJobRequest: ReviewApkgBatchMutateJobRequest, options?: any) {
        return ApkgBatchMutateApiFp(this.configuration).reviewApkgBatchMutateJob(packageUuid, reviewApkgBatchMutateJobRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApkgEditApi - axios parameter creator
 * @export
 */
export const ApkgEditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgUploadMedia: async (packageUuid: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgUploadMedia', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/media`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new instance of a `AnkiModelVo`.
         * @summary Create a AnkiModelVo
         * @param {string} packageUuid 
         * @param {AnkiModelVo} ankiModelVo A new &#x60;AnkiModelVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiModelVo: async (packageUuid: string, ankiModelVo: AnkiModelVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('createAnkiModelVo', 'packageUuid', packageUuid)
            // verify required parameter 'ankiModelVo' is not null or undefined
            assertParamExists('createAnkiModelVo', 'ankiModelVo', ankiModelVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiModelVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new instance of a `AnkiNoteVo`.
         * @summary Create a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {AnkiNoteVo} ankiNoteVo A new &#x60;AnkiNoteVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiNoteVo: async (packageUuid: string, modelId: number, ankiNoteVo: AnkiNoteVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('createAnkiNoteVo', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('createAnkiNoteVo', 'modelId', modelId)
            // verify required parameter 'ankiNoteVo' is not null or undefined
            assertParamExists('createAnkiNoteVo', 'ankiNoteVo', ankiNoteVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/notes`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiNoteVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing `AnkiModelVo`.
         * @summary Delete a AnkiModelVo
         * @param {string} packageUuid 
         * @param {number} apkgModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiModelVo: async (packageUuid: string, apkgModelId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('deleteAnkiModelVo', 'packageUuid', packageUuid)
            // verify required parameter 'apkgModelId' is not null or undefined
            assertParamExists('deleteAnkiModelVo', 'apkgModelId', apkgModelId)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{apkg_model_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"apkg_model_id"}}`, encodeURIComponent(String(apkgModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing `AnkiNoteVo`.
         * @summary Delete a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {number} ankiNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiNoteVo: async (packageUuid: string, modelId: number, ankiNoteId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('deleteAnkiNoteVo', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('deleteAnkiNoteVo', 'modelId', modelId)
            // verify required parameter 'ankiNoteId' is not null or undefined
            assertParamExists('deleteAnkiNoteVo', 'ankiNoteId', ankiNoteId)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/notes/{anki_note_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"anki_note_id"}}`, encodeURIComponent(String(ankiNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all `AnkiModelVo` entities.
         * @summary List All AnkiModelVos
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiModelVos: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getAnkiModelVos', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all `AnkiNoteVo` entities.
         * @summary List All AnkiNoteVos
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiNoteVos: async (packageUuid: string, modelId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getAnkiNoteVos', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getAnkiNoteVos', 'modelId', modelId)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/notes`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing `AnkiModelVo`.
         * @summary Update a AnkiModelVo
         * @param {string} packageUuid 
         * @param {number} apkgModelId 
         * @param {AnkiModelVo} ankiModelVo Updated &#x60;AnkiModelVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiModelVo: async (packageUuid: string, apkgModelId: number, ankiModelVo: AnkiModelVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('updateAnkiModelVo', 'packageUuid', packageUuid)
            // verify required parameter 'apkgModelId' is not null or undefined
            assertParamExists('updateAnkiModelVo', 'apkgModelId', apkgModelId)
            // verify required parameter 'ankiModelVo' is not null or undefined
            assertParamExists('updateAnkiModelVo', 'ankiModelVo', ankiModelVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{apkg_model_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"apkg_model_id"}}`, encodeURIComponent(String(apkgModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiModelVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing `AnkiNoteVo`.
         * @summary Update a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {number} ankiNoteId 
         * @param {AnkiNoteVo} ankiNoteVo Updated &#x60;AnkiNoteVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiNoteVo: async (packageUuid: string, modelId: number, ankiNoteId: number, ankiNoteVo: AnkiNoteVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('updateAnkiNoteVo', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('updateAnkiNoteVo', 'modelId', modelId)
            // verify required parameter 'ankiNoteId' is not null or undefined
            assertParamExists('updateAnkiNoteVo', 'ankiNoteId', ankiNoteId)
            // verify required parameter 'ankiNoteVo' is not null or undefined
            assertParamExists('updateAnkiNoteVo', 'ankiNoteVo', ankiNoteVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/notes/{anki_note_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"anki_note_id"}}`, encodeURIComponent(String(ankiNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiNoteVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApkgEditApi - functional programming interface
 * @export
 */
export const ApkgEditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApkgEditApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgUploadMedia(packageUuid: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgUploadMedia(packageUuid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new instance of a `AnkiModelVo`.
         * @summary Create a AnkiModelVo
         * @param {string} packageUuid 
         * @param {AnkiModelVo} ankiModelVo A new &#x60;AnkiModelVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnkiModelVo(packageUuid: string, ankiModelVo: AnkiModelVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnkiModelVo(packageUuid, ankiModelVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new instance of a `AnkiNoteVo`.
         * @summary Create a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {AnkiNoteVo} ankiNoteVo A new &#x60;AnkiNoteVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteVo: AnkiNoteVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnkiNoteVo(packageUuid, modelId, ankiNoteVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing `AnkiModelVo`.
         * @summary Delete a AnkiModelVo
         * @param {string} packageUuid 
         * @param {number} apkgModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnkiModelVo(packageUuid: string, apkgModelId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnkiModelVo(packageUuid, apkgModelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing `AnkiNoteVo`.
         * @summary Delete a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {number} ankiNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnkiNoteVo(packageUuid, modelId, ankiNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all `AnkiModelVo` entities.
         * @summary List All AnkiModelVos
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnkiModelVos(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnkiModelVo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnkiModelVos(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all `AnkiNoteVo` entities.
         * @summary List All AnkiNoteVos
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnkiNoteVos(packageUuid: string, modelId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnkiNoteVo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnkiNoteVos(packageUuid, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing `AnkiModelVo`.
         * @summary Update a AnkiModelVo
         * @param {string} packageUuid 
         * @param {number} apkgModelId 
         * @param {AnkiModelVo} ankiModelVo Updated &#x60;AnkiModelVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnkiModelVo(packageUuid: string, apkgModelId: number, ankiModelVo: AnkiModelVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnkiModelVo(packageUuid, apkgModelId, ankiModelVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing `AnkiNoteVo`.
         * @summary Update a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {number} ankiNoteId 
         * @param {AnkiNoteVo} ankiNoteVo Updated &#x60;AnkiNoteVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteId: number, ankiNoteVo: AnkiNoteVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnkiNoteVo(packageUuid, modelId, ankiNoteId, ankiNoteVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApkgEditApi - factory interface
 * @export
 */
export const ApkgEditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApkgEditApiFp(configuration)
    return {
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgUploadMedia(packageUuid: string, file?: any, options?: any): AxiosPromise<object> {
            return localVarFp.apkgUploadMedia(packageUuid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new instance of a `AnkiModelVo`.
         * @summary Create a AnkiModelVo
         * @param {string} packageUuid 
         * @param {AnkiModelVo} ankiModelVo A new &#x60;AnkiModelVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiModelVo(packageUuid: string, ankiModelVo: AnkiModelVo, options?: any): AxiosPromise<void> {
            return localVarFp.createAnkiModelVo(packageUuid, ankiModelVo, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new instance of a `AnkiNoteVo`.
         * @summary Create a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {AnkiNoteVo} ankiNoteVo A new &#x60;AnkiNoteVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteVo: AnkiNoteVo, options?: any): AxiosPromise<void> {
            return localVarFp.createAnkiNoteVo(packageUuid, modelId, ankiNoteVo, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing `AnkiModelVo`.
         * @summary Delete a AnkiModelVo
         * @param {string} packageUuid 
         * @param {number} apkgModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiModelVo(packageUuid: string, apkgModelId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnkiModelVo(packageUuid, apkgModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing `AnkiNoteVo`.
         * @summary Delete a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {number} ankiNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnkiNoteVo(packageUuid, modelId, ankiNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all `AnkiModelVo` entities.
         * @summary List All AnkiModelVos
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiModelVos(packageUuid: string, options?: any): AxiosPromise<Array<AnkiModelVo>> {
            return localVarFp.getAnkiModelVos(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all `AnkiNoteVo` entities.
         * @summary List All AnkiNoteVos
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiNoteVos(packageUuid: string, modelId: number, options?: any): AxiosPromise<Array<AnkiNoteVo>> {
            return localVarFp.getAnkiNoteVos(packageUuid, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing `AnkiModelVo`.
         * @summary Update a AnkiModelVo
         * @param {string} packageUuid 
         * @param {number} apkgModelId 
         * @param {AnkiModelVo} ankiModelVo Updated &#x60;AnkiModelVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiModelVo(packageUuid: string, apkgModelId: number, ankiModelVo: AnkiModelVo, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnkiModelVo(packageUuid, apkgModelId, ankiModelVo, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing `AnkiNoteVo`.
         * @summary Update a AnkiNoteVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {number} ankiNoteId 
         * @param {AnkiNoteVo} ankiNoteVo Updated &#x60;AnkiNoteVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteId: number, ankiNoteVo: AnkiNoteVo, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnkiNoteVo(packageUuid, modelId, ankiNoteId, ankiNoteVo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApkgEditApi - object-oriented interface
 * @export
 * @class ApkgEditApi
 * @extends {BaseAPI}
 */
export class ApkgEditApi extends BaseAPI {
    /**
     * 
     * @param {string} packageUuid 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public apkgUploadMedia(packageUuid: string, file?: any, options?: any) {
        return ApkgEditApiFp(this.configuration).apkgUploadMedia(packageUuid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new instance of a `AnkiModelVo`.
     * @summary Create a AnkiModelVo
     * @param {string} packageUuid 
     * @param {AnkiModelVo} ankiModelVo A new &#x60;AnkiModelVo&#x60; to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public createAnkiModelVo(packageUuid: string, ankiModelVo: AnkiModelVo, options?: any) {
        return ApkgEditApiFp(this.configuration).createAnkiModelVo(packageUuid, ankiModelVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new instance of a `AnkiNoteVo`.
     * @summary Create a AnkiNoteVo
     * @param {string} packageUuid 
     * @param {number} modelId 
     * @param {AnkiNoteVo} ankiNoteVo A new &#x60;AnkiNoteVo&#x60; to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public createAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteVo: AnkiNoteVo, options?: any) {
        return ApkgEditApiFp(this.configuration).createAnkiNoteVo(packageUuid, modelId, ankiNoteVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing `AnkiModelVo`.
     * @summary Delete a AnkiModelVo
     * @param {string} packageUuid 
     * @param {number} apkgModelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public deleteAnkiModelVo(packageUuid: string, apkgModelId: number, options?: any) {
        return ApkgEditApiFp(this.configuration).deleteAnkiModelVo(packageUuid, apkgModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing `AnkiNoteVo`.
     * @summary Delete a AnkiNoteVo
     * @param {string} packageUuid 
     * @param {number} modelId 
     * @param {number} ankiNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public deleteAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteId: number, options?: any) {
        return ApkgEditApiFp(this.configuration).deleteAnkiNoteVo(packageUuid, modelId, ankiNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all `AnkiModelVo` entities.
     * @summary List All AnkiModelVos
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public getAnkiModelVos(packageUuid: string, options?: any) {
        return ApkgEditApiFp(this.configuration).getAnkiModelVos(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all `AnkiNoteVo` entities.
     * @summary List All AnkiNoteVos
     * @param {string} packageUuid 
     * @param {number} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public getAnkiNoteVos(packageUuid: string, modelId: number, options?: any) {
        return ApkgEditApiFp(this.configuration).getAnkiNoteVos(packageUuid, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing `AnkiModelVo`.
     * @summary Update a AnkiModelVo
     * @param {string} packageUuid 
     * @param {number} apkgModelId 
     * @param {AnkiModelVo} ankiModelVo Updated &#x60;AnkiModelVo&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public updateAnkiModelVo(packageUuid: string, apkgModelId: number, ankiModelVo: AnkiModelVo, options?: any) {
        return ApkgEditApiFp(this.configuration).updateAnkiModelVo(packageUuid, apkgModelId, ankiModelVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing `AnkiNoteVo`.
     * @summary Update a AnkiNoteVo
     * @param {string} packageUuid 
     * @param {number} modelId 
     * @param {number} ankiNoteId 
     * @param {AnkiNoteVo} ankiNoteVo Updated &#x60;AnkiNoteVo&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgEditApi
     */
    public updateAnkiNoteVo(packageUuid: string, modelId: number, ankiNoteId: number, ankiNoteVo: AnkiNoteVo, options?: any) {
        return ApkgEditApiFp(this.configuration).updateAnkiNoteVo(packageUuid, modelId, ankiNoteId, ankiNoteVo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApkgImportApi - axios parameter creator
 * @export
 */
export const ApkgImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 上传excel并导入
         * @param {string} [name] 
         * @param {any} [excel1] 
         * @param {any} [metadata1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgImportExcel: async (name?: string, excel1?: any, metadata1?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg_import/v1/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (excel1 !== undefined) { 
                localVarFormParams.append('excel1', excel1 as any);
            }
    
            if (metadata1 !== undefined) { 
                localVarFormParams.append('metadata1', metadata1 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApkgImportApi - functional programming interface
 * @export
 */
export const ApkgImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApkgImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 上传excel并导入
         * @param {string} [name] 
         * @param {any} [excel1] 
         * @param {any} [metadata1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgImportExcel(name?: string, excel1?: any, metadata1?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnkiPackageVo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgImportExcel(name, excel1, metadata1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApkgImportApi - factory interface
 * @export
 */
export const ApkgImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApkgImportApiFp(configuration)
    return {
        /**
         * 
         * @summary 上传excel并导入
         * @param {string} [name] 
         * @param {any} [excel1] 
         * @param {any} [metadata1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgImportExcel(name?: string, excel1?: any, metadata1?: any, options?: any): AxiosPromise<AnkiPackageVo> {
            return localVarFp.apkgImportExcel(name, excel1, metadata1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApkgImportApi - object-oriented interface
 * @export
 * @class ApkgImportApi
 * @extends {BaseAPI}
 */
export class ApkgImportApi extends BaseAPI {
    /**
     * 
     * @summary 上传excel并导入
     * @param {string} [name] 
     * @param {any} [excel1] 
     * @param {any} [metadata1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgImportApi
     */
    public apkgImportExcel(name?: string, excel1?: any, metadata1?: any, options?: any) {
        return ApkgImportApiFp(this.configuration).apkgImportExcel(name, excel1, metadata1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApkgLiteApi - axios parameter creator
 * @export
 */
export const ApkgLiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 批量添加notes, 会自动帮忙创建cards
         * @param {string} packageUuid 
         * @param {number} deckId 
         * @param {Array<AnkiNoteVo>} ankiNoteVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgLiteBulkAddNotes: async (packageUuid: string, deckId: number, ankiNoteVo: Array<AnkiNoteVo>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgLiteBulkAddNotes', 'packageUuid', packageUuid)
            // verify required parameter 'deckId' is not null or undefined
            assertParamExists('apkgLiteBulkAddNotes', 'deckId', deckId)
            // verify required parameter 'ankiNoteVo' is not null or undefined
            assertParamExists('apkgLiteBulkAddNotes', 'ankiNoteVo', ankiNoteVo)
            const localVarPath = `/apkg_lite/v1/package/{package_uuid}/decks/{deck_id}/bulk_add_notes`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"deck_id"}}`, encodeURIComponent(String(deckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiNoteVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量删除卡片，输入note id list
         * @param {string} packageUuid 
         * @param {number} deckId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgLiteBulkDeleteNotes: async (packageUuid: string, deckId: number, requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgLiteBulkDeleteNotes', 'packageUuid', packageUuid)
            // verify required parameter 'deckId' is not null or undefined
            assertParamExists('apkgLiteBulkDeleteNotes', 'deckId', deckId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('apkgLiteBulkDeleteNotes', 'requestBody', requestBody)
            const localVarPath = `/apkg_lite/v1/package/{package_uuid}/decks/{deck_id}/bulk_delete_notes`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"deck_id"}}`, encodeURIComponent(String(deckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建一个极简的空卡包（可能有一张卡）
         * @param {ApkgLitePackageCreateRequest} [apkgLitePackageCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgLiteCreatePackage: async (apkgLitePackageCreateRequest?: ApkgLitePackageCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg_lite/v1/package`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apkgLitePackageCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApkgLiteApi - functional programming interface
 * @export
 */
export const ApkgLiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApkgLiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 批量添加notes, 会自动帮忙创建cards
         * @param {string} packageUuid 
         * @param {number} deckId 
         * @param {Array<AnkiNoteVo>} ankiNoteVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgLiteBulkAddNotes(packageUuid: string, deckId: number, ankiNoteVo: Array<AnkiNoteVo>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgLiteBulkAddNotes(packageUuid, deckId, ankiNoteVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量删除卡片，输入note id list
         * @param {string} packageUuid 
         * @param {number} deckId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgLiteBulkDeleteNotes(packageUuid: string, deckId: number, requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgLiteBulkDeleteNotes(packageUuid, deckId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建一个极简的空卡包（可能有一张卡）
         * @param {ApkgLitePackageCreateRequest} [apkgLitePackageCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgLiteCreatePackage(apkgLitePackageCreateRequest?: ApkgLitePackageCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnkiPackageVo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgLiteCreatePackage(apkgLitePackageCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApkgLiteApi - factory interface
 * @export
 */
export const ApkgLiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApkgLiteApiFp(configuration)
    return {
        /**
         * 
         * @summary 批量添加notes, 会自动帮忙创建cards
         * @param {string} packageUuid 
         * @param {number} deckId 
         * @param {Array<AnkiNoteVo>} ankiNoteVo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgLiteBulkAddNotes(packageUuid: string, deckId: number, ankiNoteVo: Array<AnkiNoteVo>, options?: any): AxiosPromise<void> {
            return localVarFp.apkgLiteBulkAddNotes(packageUuid, deckId, ankiNoteVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量删除卡片，输入note id list
         * @param {string} packageUuid 
         * @param {number} deckId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgLiteBulkDeleteNotes(packageUuid: string, deckId: number, requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apkgLiteBulkDeleteNotes(packageUuid, deckId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建一个极简的空卡包（可能有一张卡）
         * @param {ApkgLitePackageCreateRequest} [apkgLitePackageCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgLiteCreatePackage(apkgLitePackageCreateRequest?: ApkgLitePackageCreateRequest, options?: any): AxiosPromise<Array<AnkiPackageVo>> {
            return localVarFp.apkgLiteCreatePackage(apkgLitePackageCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApkgLiteApi - object-oriented interface
 * @export
 * @class ApkgLiteApi
 * @extends {BaseAPI}
 */
export class ApkgLiteApi extends BaseAPI {
    /**
     * 
     * @summary 批量添加notes, 会自动帮忙创建cards
     * @param {string} packageUuid 
     * @param {number} deckId 
     * @param {Array<AnkiNoteVo>} ankiNoteVo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgLiteApi
     */
    public apkgLiteBulkAddNotes(packageUuid: string, deckId: number, ankiNoteVo: Array<AnkiNoteVo>, options?: any) {
        return ApkgLiteApiFp(this.configuration).apkgLiteBulkAddNotes(packageUuid, deckId, ankiNoteVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量删除卡片，输入note id list
     * @param {string} packageUuid 
     * @param {number} deckId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgLiteApi
     */
    public apkgLiteBulkDeleteNotes(packageUuid: string, deckId: number, requestBody: Array<number>, options?: any) {
        return ApkgLiteApiFp(this.configuration).apkgLiteBulkDeleteNotes(packageUuid, deckId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建一个极简的空卡包（可能有一张卡）
     * @param {ApkgLitePackageCreateRequest} [apkgLitePackageCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgLiteApi
     */
    public apkgLiteCreatePackage(apkgLitePackageCreateRequest?: ApkgLitePackageCreateRequest, options?: any) {
        return ApkgLiteApiFp(this.configuration).apkgLiteCreatePackage(apkgLitePackageCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApkgPreviewImageApi - axios parameter creator
 * @export
 */
export const ApkgPreviewImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary (已废弃)返回缓存的卡片预览图, 用于ugc book的挑选
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetCardPreviewImages: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('apkgGetCardPreviewImages', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/card_preview_images`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (已废弃)清空缓存的卡片预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearApkgPreviewImages: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('clearApkgPreviewImages', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/card_preview_images`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (已废弃)生成缓存的卡片预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateApkgPreviewImages: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('generateApkgPreviewImages', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/card_preview_images/generate`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApkgPreviewImageApi - functional programming interface
 * @export
 */
export const ApkgPreviewImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApkgPreviewImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary (已废弃)返回缓存的卡片预览图, 用于ugc book的挑选
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgGetCardPreviewImages(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgGetCardPreviewImages(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary (已废弃)清空缓存的卡片预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearApkgPreviewImages(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearApkgPreviewImages(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary (已废弃)生成缓存的卡片预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateApkgPreviewImages(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateApkgPreviewImages(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApkgPreviewImageApi - factory interface
 * @export
 */
export const ApkgPreviewImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApkgPreviewImageApiFp(configuration)
    return {
        /**
         * 
         * @summary (已废弃)返回缓存的卡片预览图, 用于ugc book的挑选
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetCardPreviewImages(packageUuid: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apkgGetCardPreviewImages(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (已废弃)清空缓存的卡片预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearApkgPreviewImages(packageUuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.clearApkgPreviewImages(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (已废弃)生成缓存的卡片预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateApkgPreviewImages(packageUuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.generateApkgPreviewImages(packageUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApkgPreviewImageApi - object-oriented interface
 * @export
 * @class ApkgPreviewImageApi
 * @extends {BaseAPI}
 */
export class ApkgPreviewImageApi extends BaseAPI {
    /**
     * 
     * @summary (已废弃)返回缓存的卡片预览图, 用于ugc book的挑选
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgPreviewImageApi
     */
    public apkgGetCardPreviewImages(packageUuid: string, options?: any) {
        return ApkgPreviewImageApiFp(this.configuration).apkgGetCardPreviewImages(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (已废弃)清空缓存的卡片预览图
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgPreviewImageApi
     */
    public clearApkgPreviewImages(packageUuid: string, options?: any) {
        return ApkgPreviewImageApiFp(this.configuration).clearApkgPreviewImages(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (已废弃)生成缓存的卡片预览图
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgPreviewImageApi
     */
    public generateApkgPreviewImages(packageUuid: string, options?: any) {
        return ApkgPreviewImageApiFp(this.configuration).generateApkgPreviewImages(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApkgSharedTemplateApi - axios parameter creator
 * @export
 */
export const ApkgSharedTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加新的共享template
         * @param {SharedTemplateRequest} sharedTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedTemplate: async (sharedTemplateRequest: SharedTemplateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sharedTemplateRequest' is not null or undefined
            assertParamExists('createSharedTemplate', 'sharedTemplateRequest', sharedTemplateRequest)
            const localVarPath = `/apkg_shared_template/v1/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sharedTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取公开的templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSharedTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg_shared_template/v1/templates/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得共享模板的所有tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedTemplateTags: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg_shared_template/v1/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改共享template 仅限于tags和ispublic属性
         * @param {number} templateId 
         * @param {UpdateSharedInfo} [updateSharedInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedTemplate: async (templateId: number, updateSharedInfo?: UpdateSharedInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateSharedTemplate', 'templateId', templateId)
            const localVarPath = `/apkg_shared_template/v1/template/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSharedInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApkgSharedTemplateApi - functional programming interface
 * @export
 */
export const ApkgSharedTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApkgSharedTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加新的共享template
         * @param {SharedTemplateRequest} sharedTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSharedTemplate(sharedTemplateRequest: SharedTemplateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSharedTemplate(sharedTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取公开的templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicSharedTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnkiSharedTemplateVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicSharedTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得共享模板的所有tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedTemplateTags(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedTemplateTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改共享template 仅限于tags和ispublic属性
         * @param {number} templateId 
         * @param {UpdateSharedInfo} [updateSharedInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSharedTemplate(templateId: number, updateSharedInfo?: UpdateSharedInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSharedTemplate(templateId, updateSharedInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApkgSharedTemplateApi - factory interface
 * @export
 */
export const ApkgSharedTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApkgSharedTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加新的共享template
         * @param {SharedTemplateRequest} sharedTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedTemplate(sharedTemplateRequest: SharedTemplateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createSharedTemplate(sharedTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取公开的templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSharedTemplates(options?: any): AxiosPromise<Array<AnkiSharedTemplateVO>> {
            return localVarFp.getPublicSharedTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得共享模板的所有tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedTemplateTags(options?: any): AxiosPromise<TagList> {
            return localVarFp.getSharedTemplateTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改共享template 仅限于tags和ispublic属性
         * @param {number} templateId 
         * @param {UpdateSharedInfo} [updateSharedInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedTemplate(templateId: number, updateSharedInfo?: UpdateSharedInfo, options?: any): AxiosPromise<void> {
            return localVarFp.updateSharedTemplate(templateId, updateSharedInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApkgSharedTemplateApi - object-oriented interface
 * @export
 * @class ApkgSharedTemplateApi
 * @extends {BaseAPI}
 */
export class ApkgSharedTemplateApi extends BaseAPI {
    /**
     * 
     * @summary 添加新的共享template
     * @param {SharedTemplateRequest} sharedTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgSharedTemplateApi
     */
    public createSharedTemplate(sharedTemplateRequest: SharedTemplateRequest, options?: any) {
        return ApkgSharedTemplateApiFp(this.configuration).createSharedTemplate(sharedTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取公开的templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgSharedTemplateApi
     */
    public getPublicSharedTemplates(options?: any) {
        return ApkgSharedTemplateApiFp(this.configuration).getPublicSharedTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得共享模板的所有tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgSharedTemplateApi
     */
    public getSharedTemplateTags(options?: any) {
        return ApkgSharedTemplateApiFp(this.configuration).getSharedTemplateTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改共享template 仅限于tags和ispublic属性
     * @param {number} templateId 
     * @param {UpdateSharedInfo} [updateSharedInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApkgSharedTemplateApi
     */
    public updateSharedTemplate(templateId: number, updateSharedInfo?: UpdateSharedInfo, options?: any) {
        return ApkgSharedTemplateApiFp(this.configuration).updateSharedTemplate(templateId, updateSharedInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取系统配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/v1/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 来自APP 上的问题反馈，将会保存到飞书中
         * @param {AppFeedbackRequest} [appFeedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppFeedback: async (appFeedbackRequest?: AppFeedbackRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/v1/problem/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取系统配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 来自APP 上的问题反馈，将会保存到飞书中
         * @param {AppFeedbackRequest} [appFeedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAppFeedback(appFeedbackRequest?: AppFeedbackRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAppFeedback(appFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取系统配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfig(options?: any): AxiosPromise<AppConfigResponse> {
            return localVarFp.getAppConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 来自APP 上的问题反馈，将会保存到飞书中
         * @param {AppFeedbackRequest} [appFeedbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppFeedback(appFeedbackRequest?: AppFeedbackRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveAppFeedback(appFeedbackRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary 获取系统配置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppConfig(options?: any) {
        return AppApiFp(this.configuration).getAppConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 来自APP 上的问题反馈，将会保存到飞书中
     * @param {AppFeedbackRequest} [appFeedbackRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public saveAppFeedback(appFeedbackRequest?: AppFeedbackRequest, options?: any) {
        return AppApiFp(this.configuration).saveAppFeedback(appFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获得当前用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetMyInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/my_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取token对应的code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/token/auth_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户下载的token，`/order/v1/creator/my/download`会用到
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/generate/download_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取滑块验证的拼图
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockPuzzle: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/signin/aj/get_block_puzzle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据code获取对应的token
         * @param {string} code 请求token的授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenByCode: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getTokenByCode', 'code', code)
            const localVarPath = `/auth/v1/code_token/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户token
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserToken: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserToken', 'userId', userId)
            const localVarPath = `/auth/v1/user/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (已废弃，转移到 /auth/v1/signin/phone) 本机手机号一键登录（）
         * @param {OneStepLoginRequest} oneStepLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneStepLoginUseMobile: async (oneStepLoginRequest: OneStepLoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneStepLoginRequest' is not null or undefined
            assertParamExists('oneStepLoginUseMobile', 'oneStepLoginRequest', oneStepLoginRequest)
            const localVarPath = `/auth/v1/signin/one-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oneStepLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 刷新JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 使token失效
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 如果传入了 user id，则尝试将 user id 与输入的手机号绑定
         * @summary 手机号登录（短信、一键登录）
         * @param {SignInPhoneRequest} signInPhoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByPhone: async (signInPhoneRequest: SignInPhoneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInPhoneRequest' is not null or undefined
            assertParamExists('signInByPhone', 'signInPhoneRequest', signInPhoneRequest)
            const localVarPath = `/auth/v1/signin/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInPhoneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信小程序登录
         * @param {WeixinMiniLoginRequest} weixinMiniLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByWeChatMini: async (weixinMiniLoginRequest: WeixinMiniLoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'weixinMiniLoginRequest' is not null or undefined
            assertParamExists('signInByWeChatMini', 'weixinMiniLoginRequest', weixinMiniLoginRequest)
            const localVarPath = `/auth/v1/signin/wechat/mini`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weixinMiniLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 输入openId，根据微信公众号绑定进行登录；仅用于创作营绑定，见 https://qianmojiaoyu.feishu.cn/docs/doccnMD3xsBbpBtR8YgUTWRtJkc#stWmDP
         * @param {string} mpId 
         * @param {PostWeixinMpBindingRequest} postWeixinMpBindingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByWeChatMp: async (mpId: string, postWeixinMpBindingRequest: PostWeixinMpBindingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mpId' is not null or undefined
            assertParamExists('signInByWeChatMp', 'mpId', mpId)
            // verify required parameter 'postWeixinMpBindingRequest' is not null or undefined
            assertParamExists('signInByWeChatMp', 'postWeixinMpBindingRequest', postWeixinMpBindingRequest)
            const localVarPath = `/auth/v1/signin/wechat/mp/{mp_id}`
                .replace(`{${"mp_id"}}`, encodeURIComponent(String(mpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWeixinMpBindingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发送短信，附带阿里云验证
         * @param {SendSmsCodeRequest} sendSmsCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInSendSmsCode: async (sendSmsCodeRequest: SendSmsCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendSmsCodeRequest' is not null or undefined
            assertParamExists('signInSendSmsCode', 'sendSmsCodeRequest', sendSmsCodeRequest)
            const localVarPath = `/auth/v1/signin/send_sms_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSmsCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获得当前用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authGetMyInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authGetMyInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取token对应的code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTokenCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTokenCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTokenCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户下载的token，`/order/v1/creator/my/download`会用到
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取滑块验证的拼图
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockPuzzle(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockPuzzleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockPuzzle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据code获取对应的token
         * @param {string} code 请求token的授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenByCode(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenByCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户token
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserToken(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserToken(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary (已废弃，转移到 /auth/v1/signin/phone) 本机手机号一键登录（）
         * @param {OneStepLoginRequest} oneStepLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oneStepLoginUseMobile(oneStepLoginRequest: OneStepLoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInPhoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oneStepLoginUseMobile(oneStepLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 刷新JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 使token失效
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 如果传入了 user id，则尝试将 user id 与输入的手机号绑定
         * @summary 手机号登录（短信、一键登录）
         * @param {SignInPhoneRequest} signInPhoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInByPhone(signInPhoneRequest: SignInPhoneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInPhoneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInByPhone(signInPhoneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信小程序登录
         * @param {WeixinMiniLoginRequest} weixinMiniLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInByWeChatMini(weixinMiniLoginRequest: WeixinMiniLoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeixinMiniAuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInByWeChatMini(weixinMiniLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 输入openId，根据微信公众号绑定进行登录；仅用于创作营绑定，见 https://qianmojiaoyu.feishu.cn/docs/doccnMD3xsBbpBtR8YgUTWRtJkc#stWmDP
         * @param {string} mpId 
         * @param {PostWeixinMpBindingRequest} postWeixinMpBindingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInByWeChatMp(mpId: string, postWeixinMpBindingRequest: PostWeixinMpBindingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInByWeChatMp(mpId, postWeixinMpBindingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发送短信，附带阿里云验证
         * @param {SendSmsCodeRequest} sendSmsCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInSendSmsCode(sendSmsCodeRequest: SendSmsCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptchaActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInSendSmsCode(sendSmsCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary 获得当前用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetMyInfo(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.authGetMyInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取token对应的code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenCode(options?: any): AxiosPromise<GenerateTokenCodeResponse> {
            return localVarFp.generateTokenCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户下载的token，`/order/v1/creator/my/download`会用到
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuth(options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.getAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取滑块验证的拼图
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockPuzzle(options?: any): AxiosPromise<BlockPuzzleResponse> {
            return localVarFp.getBlockPuzzle(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据code获取对应的token
         * @param {string} code 请求token的授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenByCode(code: string, options?: any): AxiosPromise<AuthTokenResponse> {
            return localVarFp.getTokenByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户token
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserToken(userId: number, options?: any): AxiosPromise<RefreshTokenResponse> {
            return localVarFp.getUserToken(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary (已废弃，转移到 /auth/v1/signin/phone) 本机手机号一键登录（）
         * @param {OneStepLoginRequest} oneStepLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneStepLoginUseMobile(oneStepLoginRequest: OneStepLoginRequest, options?: any): AxiosPromise<SignInPhoneResponse> {
            return localVarFp.oneStepLoginUseMobile(oneStepLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 刷新JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(options?: any): AxiosPromise<RefreshTokenResponse> {
            return localVarFp.refreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 使token失效
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken(options?: any): AxiosPromise<void> {
            return localVarFp.revokeToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 如果传入了 user id，则尝试将 user id 与输入的手机号绑定
         * @summary 手机号登录（短信、一键登录）
         * @param {SignInPhoneRequest} signInPhoneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByPhone(signInPhoneRequest: SignInPhoneRequest, options?: any): AxiosPromise<SignInPhoneResponse> {
            return localVarFp.signInByPhone(signInPhoneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信小程序登录
         * @param {WeixinMiniLoginRequest} weixinMiniLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByWeChatMini(weixinMiniLoginRequest: WeixinMiniLoginRequest, options?: any): AxiosPromise<WeixinMiniAuthTokenResponse> {
            return localVarFp.signInByWeChatMini(weixinMiniLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 输入openId，根据微信公众号绑定进行登录；仅用于创作营绑定，见 https://qianmojiaoyu.feishu.cn/docs/doccnMD3xsBbpBtR8YgUTWRtJkc#stWmDP
         * @param {string} mpId 
         * @param {PostWeixinMpBindingRequest} postWeixinMpBindingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByWeChatMp(mpId: string, postWeixinMpBindingRequest: PostWeixinMpBindingRequest, options?: any): AxiosPromise<AuthTokenResponse> {
            return localVarFp.signInByWeChatMp(mpId, postWeixinMpBindingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发送短信，附带阿里云验证
         * @param {SendSmsCodeRequest} sendSmsCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInSendSmsCode(sendSmsCodeRequest: SendSmsCodeRequest, options?: any): AxiosPromise<CaptchaActionResponse> {
            return localVarFp.signInSendSmsCode(sendSmsCodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary 获得当前用户信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authGetMyInfo(options?: any) {
        return AuthApiFp(this.configuration).authGetMyInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取token对应的code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public generateTokenCode(options?: any) {
        return AuthApiFp(this.configuration).generateTokenCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户下载的token，`/order/v1/creator/my/download`会用到
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuth(options?: any) {
        return AuthApiFp(this.configuration).getAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取滑块验证的拼图
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getBlockPuzzle(options?: any) {
        return AuthApiFp(this.configuration).getBlockPuzzle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据code获取对应的token
     * @param {string} code 请求token的授权码
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getTokenByCode(code: string, options?: any) {
        return AuthApiFp(this.configuration).getTokenByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户token
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getUserToken(userId: number, options?: any) {
        return AuthApiFp(this.configuration).getUserToken(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary (已废弃，转移到 /auth/v1/signin/phone) 本机手机号一键登录（）
     * @param {OneStepLoginRequest} oneStepLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public oneStepLoginUseMobile(oneStepLoginRequest: OneStepLoginRequest, options?: any) {
        return AuthApiFp(this.configuration).oneStepLoginUseMobile(oneStepLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 刷新JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(options?: any) {
        return AuthApiFp(this.configuration).refreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 使token失效
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public revokeToken(options?: any) {
        return AuthApiFp(this.configuration).revokeToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 如果传入了 user id，则尝试将 user id 与输入的手机号绑定
     * @summary 手机号登录（短信、一键登录）
     * @param {SignInPhoneRequest} signInPhoneRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signInByPhone(signInPhoneRequest: SignInPhoneRequest, options?: any) {
        return AuthApiFp(this.configuration).signInByPhone(signInPhoneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信小程序登录
     * @param {WeixinMiniLoginRequest} weixinMiniLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signInByWeChatMini(weixinMiniLoginRequest: WeixinMiniLoginRequest, options?: any) {
        return AuthApiFp(this.configuration).signInByWeChatMini(weixinMiniLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 输入openId，根据微信公众号绑定进行登录；仅用于创作营绑定，见 https://qianmojiaoyu.feishu.cn/docs/doccnMD3xsBbpBtR8YgUTWRtJkc#stWmDP
     * @param {string} mpId 
     * @param {PostWeixinMpBindingRequest} postWeixinMpBindingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signInByWeChatMp(mpId: string, postWeixinMpBindingRequest: PostWeixinMpBindingRequest, options?: any) {
        return AuthApiFp(this.configuration).signInByWeChatMp(mpId, postWeixinMpBindingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发送短信，附带阿里云验证
     * @param {SendSmsCodeRequest} sendSmsCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signInSendSmsCode(sendSmsCodeRequest: SendSmsCodeRequest, options?: any) {
        return AuthApiFp(this.configuration).signInSendSmsCode(sendSmsCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BannerApi - axios parameter creator
 * @export
 */
export const BannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 配置app banner图
         * @param {BannerConfigRequest} [bannerConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureAppBanner: async (bannerConfigRequest?: BannerConfigRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/marketing/v1/banner/app_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bannerConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得banner图
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBannerConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/marketing/v1/banner/app_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerApi - functional programming interface
 * @export
 */
export const BannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 配置app banner图
         * @param {BannerConfigRequest} [bannerConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configureAppBanner(bannerConfigRequest?: BannerConfigRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configureAppBanner(bannerConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得banner图
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppBannerConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerConfigVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppBannerConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerApi - factory interface
 * @export
 */
export const BannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerApiFp(configuration)
    return {
        /**
         * 
         * @summary 配置app banner图
         * @param {BannerConfigRequest} [bannerConfigRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureAppBanner(bannerConfigRequest?: BannerConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.configureAppBanner(bannerConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得banner图
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBannerConfig(options?: any): AxiosPromise<BannerConfigVO> {
            return localVarFp.getAppBannerConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannerApi - object-oriented interface
 * @export
 * @class BannerApi
 * @extends {BaseAPI}
 */
export class BannerApi extends BaseAPI {
    /**
     * 
     * @summary 配置app banner图
     * @param {BannerConfigRequest} [bannerConfigRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public configureAppBanner(bannerConfigRequest?: BannerConfigRequest, options?: any) {
        return BannerApiFp(this.configuration).configureAppBanner(bannerConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得banner图
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerApi
     */
    public getAppBannerConfig(options?: any) {
        return BannerApiFp(this.configuration).getAppBannerConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BooksApi - axios parameter creator
 * @export
 */
export const BooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 管理员修改 图书的人工评分
         * @param {ManualScoreRequest} manualScoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeManualScore: async (manualScoreRequest: ManualScoreRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'manualScoreRequest' is not null or undefined
            assertParamExists('changeManualScore', 'manualScoreRequest', manualScoreRequest)
            const localVarPath = `/books/v1/admin/manual_score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualScoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取创作者 图书分类列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthorBookCategories: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('findAuthorBookCategories', 'userId', userId)
            const localVarPath = `/books/v1/author/{user_id}/categories`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [position] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyRecommendBooks: async (position?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/books/v1/library/recommends/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (position !== undefined) {
                localVarQueryParameter['position'] = position;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} libraryBookId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [position] 当前页面信息（HUA-2212中不会使用该信息）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendBookList: async (libraryBookId: number, pageNumber: number, pageSize: number, position?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('getRecommendBookList', 'libraryBookId', libraryBookId)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getRecommendBookList', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getRecommendBookList', 'pageSize', pageSize)
            const localVarPath = `/books/v1/library/recommends/{library_book_id}`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (position !== undefined) {
                localVarQueryParameter['position'] = position;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 搜索关键词提示
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchHints: async (keyword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getSearchHints', 'keyword', keyword)
            const localVarPath = `/books/v1/search/hint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取卡册学习排名数据
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyRankList: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getStudyRankList', 'packageUuid', packageUuid)
            const localVarPath = `/books/v1/package/{package_uuid}/study/rank_list`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorBooks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/books/v1/creator/books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询关注人的图书列表
         * @param {QueryFollowerBooksRequest} [queryFollowerBooksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFollowerBooks: async (queryFollowerBooksRequest?: QueryFollowerBooksRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/books/v1/follower/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryFollowerBooksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询所有返利图书
         * @param {QueryMarketingBooksRequest} [queryMarketingBooksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketingBooks: async (queryMarketingBooksRequest?: QueryMarketingBooksRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/books/v1/marketing/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryMarketingBooksRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 全文搜索上架卡册，包括图书信息和notes
         * @param {string} keyword 
         * @param {number} [notes] 是否搜索notes，设置notes&#x3D;0只会搜索图书
         * @param {number} [notesScoreWeight] 测试配置notes_score的权重
         * @param {number} [rankScoreWeight] 测试配置rank_score的权重
         * @param {boolean} [scoreDetail] 测试环境是否返回详细得分结果
         * @param {number} [qmTitleMatchBoost] 配置es搜索中qm_title的match（分词搜索）权重
         * @param {number} [publisherNameMatchBoost] 配置es搜索中publisher_name的match（分词搜索）权重
         * @param {number} [qmDescriptionMatchBoost] 配置es搜索中qm_description的match（分词搜索）权重
         * @param {number} [categoryMatchBoost] 配置es搜索中category的match（分词搜索）权重
         * @param {number} [qmTitleTermBoost] 配置es搜索中qm_title的term（精确搜索）权重
         * @param {number} [publisherNameTermBoost] 配置es搜索中publisher_name的term（精确搜索）权重
         * @param {number} [qmDescriptionTermBoost] 配置es搜索中qm_description的term（精确搜索）权重
         * @param {number} [categoryTermBoost] 配置es搜索中category的term（精确搜索）权重
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNotesContentByKeyword: async (keyword: string, notes?: number, notesScoreWeight?: number, rankScoreWeight?: number, scoreDetail?: boolean, qmTitleMatchBoost?: number, publisherNameMatchBoost?: number, qmDescriptionMatchBoost?: number, categoryMatchBoost?: number, qmTitleTermBoost?: number, publisherNameTermBoost?: number, qmDescriptionTermBoost?: number, categoryTermBoost?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('searchNotesContentByKeyword', 'keyword', keyword)
            const localVarPath = `/books/v1/notes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }

            if (notesScoreWeight !== undefined) {
                localVarQueryParameter['notes_score_weight'] = notesScoreWeight;
            }

            if (rankScoreWeight !== undefined) {
                localVarQueryParameter['rank_score_weight'] = rankScoreWeight;
            }

            if (scoreDetail !== undefined) {
                localVarQueryParameter['score_detail'] = scoreDetail;
            }

            if (qmTitleMatchBoost !== undefined) {
                localVarQueryParameter['qm_title_match_boost'] = qmTitleMatchBoost;
            }

            if (publisherNameMatchBoost !== undefined) {
                localVarQueryParameter['publisher_name_match_boost'] = publisherNameMatchBoost;
            }

            if (qmDescriptionMatchBoost !== undefined) {
                localVarQueryParameter['qm_description_match_boost'] = qmDescriptionMatchBoost;
            }

            if (categoryMatchBoost !== undefined) {
                localVarQueryParameter['category_match_boost'] = categoryMatchBoost;
            }

            if (qmTitleTermBoost !== undefined) {
                localVarQueryParameter['qm_title_term_boost'] = qmTitleTermBoost;
            }

            if (publisherNameTermBoost !== undefined) {
                localVarQueryParameter['publisher_name_term_boost'] = publisherNameTermBoost;
            }

            if (qmDescriptionTermBoost !== undefined) {
                localVarQueryParameter['qm_description_term_boost'] = qmDescriptionTermBoost;
            }

            if (categoryTermBoost !== undefined) {
                localVarQueryParameter['category_term_boost'] = categoryTermBoost;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BooksApi - functional programming interface
 * @export
 */
export const BooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 管理员修改 图书的人工评分
         * @param {ManualScoreRequest} manualScoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeManualScore(manualScoreRequest: ManualScoreRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeManualScore(manualScoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取创作者 图书分类列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAuthorBookCategories(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAuthorBookCategories(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [position] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyRecommendBooks(position?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLibraryBookVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyRecommendBooks(position, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} libraryBookId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [position] 当前页面信息（HUA-2212中不会使用该信息）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendBookList(libraryBookId: number, pageNumber: number, pageSize: number, position?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLibraryBookVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendBookList(libraryBookId, pageNumber, pageSize, position, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 搜索关键词提示
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchHints(keyword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchHintResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchHints(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取卡册学习排名数据
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyRankList(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyRankList(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCreatorBooks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreatorBookListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCreatorBooks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询关注人的图书列表
         * @param {QueryFollowerBooksRequest} [queryFollowerBooksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFollowerBooks(queryFollowerBooksRequest?: QueryFollowerBooksRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLibraryBookVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFollowerBooks(queryFollowerBooksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询所有返利图书
         * @param {QueryMarketingBooksRequest} [queryMarketingBooksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMarketingBooks(queryMarketingBooksRequest?: QueryMarketingBooksRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLibraryBookVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMarketingBooks(queryMarketingBooksRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 全文搜索上架卡册，包括图书信息和notes
         * @param {string} keyword 
         * @param {number} [notes] 是否搜索notes，设置notes&#x3D;0只会搜索图书
         * @param {number} [notesScoreWeight] 测试配置notes_score的权重
         * @param {number} [rankScoreWeight] 测试配置rank_score的权重
         * @param {boolean} [scoreDetail] 测试环境是否返回详细得分结果
         * @param {number} [qmTitleMatchBoost] 配置es搜索中qm_title的match（分词搜索）权重
         * @param {number} [publisherNameMatchBoost] 配置es搜索中publisher_name的match（分词搜索）权重
         * @param {number} [qmDescriptionMatchBoost] 配置es搜索中qm_description的match（分词搜索）权重
         * @param {number} [categoryMatchBoost] 配置es搜索中category的match（分词搜索）权重
         * @param {number} [qmTitleTermBoost] 配置es搜索中qm_title的term（精确搜索）权重
         * @param {number} [publisherNameTermBoost] 配置es搜索中publisher_name的term（精确搜索）权重
         * @param {number} [qmDescriptionTermBoost] 配置es搜索中qm_description的term（精确搜索）权重
         * @param {number} [categoryTermBoost] 配置es搜索中category的term（精确搜索）权重
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchNotesContentByKeyword(keyword: string, notes?: number, notesScoreWeight?: number, rankScoreWeight?: number, scoreDetail?: boolean, qmTitleMatchBoost?: number, publisherNameMatchBoost?: number, qmDescriptionMatchBoost?: number, categoryMatchBoost?: number, qmTitleTermBoost?: number, publisherNameTermBoost?: number, qmDescriptionTermBoost?: number, categoryTermBoost?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchNotesContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchNotesContentByKeyword(keyword, notes, notesScoreWeight, rankScoreWeight, scoreDetail, qmTitleMatchBoost, publisherNameMatchBoost, qmDescriptionMatchBoost, categoryMatchBoost, qmTitleTermBoost, publisherNameTermBoost, qmDescriptionTermBoost, categoryTermBoost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BooksApi - factory interface
 * @export
 */
export const BooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BooksApiFp(configuration)
    return {
        /**
         * 
         * @summary 管理员修改 图书的人工评分
         * @param {ManualScoreRequest} manualScoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeManualScore(manualScoreRequest: ManualScoreRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeManualScore(manualScoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取创作者 图书分类列表
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuthorBookCategories(userId: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.findAuthorBookCategories(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [position] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyRecommendBooks(position?: string, options?: any): AxiosPromise<ListLibraryBookVO> {
            return localVarFp.getMyRecommendBooks(position, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} libraryBookId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [position] 当前页面信息（HUA-2212中不会使用该信息）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendBookList(libraryBookId: number, pageNumber: number, pageSize: number, position?: string, options?: any): AxiosPromise<PageLibraryBookVO> {
            return localVarFp.getRecommendBookList(libraryBookId, pageNumber, pageSize, position, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 搜索关键词提示
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchHints(keyword: string, options?: any): AxiosPromise<SearchHintResponse> {
            return localVarFp.getSearchHints(keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取卡册学习排名数据
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyRankList(packageUuid: string, options?: any): AxiosPromise<StudyRankResponse> {
            return localVarFp.getStudyRankList(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorBooks(options?: any): AxiosPromise<GetCreatorBookListResponse> {
            return localVarFp.listCreatorBooks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询关注人的图书列表
         * @param {QueryFollowerBooksRequest} [queryFollowerBooksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFollowerBooks(queryFollowerBooksRequest?: QueryFollowerBooksRequest, options?: any): AxiosPromise<PageLibraryBookVO> {
            return localVarFp.listFollowerBooks(queryFollowerBooksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询所有返利图书
         * @param {QueryMarketingBooksRequest} [queryMarketingBooksRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMarketingBooks(queryMarketingBooksRequest?: QueryMarketingBooksRequest, options?: any): AxiosPromise<PageLibraryBookVO> {
            return localVarFp.listMarketingBooks(queryMarketingBooksRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 全文搜索上架卡册，包括图书信息和notes
         * @param {string} keyword 
         * @param {number} [notes] 是否搜索notes，设置notes&#x3D;0只会搜索图书
         * @param {number} [notesScoreWeight] 测试配置notes_score的权重
         * @param {number} [rankScoreWeight] 测试配置rank_score的权重
         * @param {boolean} [scoreDetail] 测试环境是否返回详细得分结果
         * @param {number} [qmTitleMatchBoost] 配置es搜索中qm_title的match（分词搜索）权重
         * @param {number} [publisherNameMatchBoost] 配置es搜索中publisher_name的match（分词搜索）权重
         * @param {number} [qmDescriptionMatchBoost] 配置es搜索中qm_description的match（分词搜索）权重
         * @param {number} [categoryMatchBoost] 配置es搜索中category的match（分词搜索）权重
         * @param {number} [qmTitleTermBoost] 配置es搜索中qm_title的term（精确搜索）权重
         * @param {number} [publisherNameTermBoost] 配置es搜索中publisher_name的term（精确搜索）权重
         * @param {number} [qmDescriptionTermBoost] 配置es搜索中qm_description的term（精确搜索）权重
         * @param {number} [categoryTermBoost] 配置es搜索中category的term（精确搜索）权重
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNotesContentByKeyword(keyword: string, notes?: number, notesScoreWeight?: number, rankScoreWeight?: number, scoreDetail?: boolean, qmTitleMatchBoost?: number, publisherNameMatchBoost?: number, qmDescriptionMatchBoost?: number, categoryMatchBoost?: number, qmTitleTermBoost?: number, publisherNameTermBoost?: number, qmDescriptionTermBoost?: number, categoryTermBoost?: number, options?: any): AxiosPromise<SearchNotesContentResponse> {
            return localVarFp.searchNotesContentByKeyword(keyword, notes, notesScoreWeight, rankScoreWeight, scoreDetail, qmTitleMatchBoost, publisherNameMatchBoost, qmDescriptionMatchBoost, categoryMatchBoost, qmTitleTermBoost, publisherNameTermBoost, qmDescriptionTermBoost, categoryTermBoost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BooksApi - object-oriented interface
 * @export
 * @class BooksApi
 * @extends {BaseAPI}
 */
export class BooksApi extends BaseAPI {
    /**
     * 
     * @summary 管理员修改 图书的人工评分
     * @param {ManualScoreRequest} manualScoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public changeManualScore(manualScoreRequest: ManualScoreRequest, options?: any) {
        return BooksApiFp(this.configuration).changeManualScore(manualScoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取创作者 图书分类列表
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public findAuthorBookCategories(userId: number, options?: any) {
        return BooksApiFp(this.configuration).findAuthorBookCategories(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [position] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public getMyRecommendBooks(position?: string, options?: any) {
        return BooksApiFp(this.configuration).getMyRecommendBooks(position, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} libraryBookId 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [position] 当前页面信息（HUA-2212中不会使用该信息）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public getRecommendBookList(libraryBookId: number, pageNumber: number, pageSize: number, position?: string, options?: any) {
        return BooksApiFp(this.configuration).getRecommendBookList(libraryBookId, pageNumber, pageSize, position, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 搜索关键词提示
     * @param {string} keyword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public getSearchHints(keyword: string, options?: any) {
        return BooksApiFp(this.configuration).getSearchHints(keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取卡册学习排名数据
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public getStudyRankList(packageUuid: string, options?: any) {
        return BooksApiFp(this.configuration).getStudyRankList(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public listCreatorBooks(options?: any) {
        return BooksApiFp(this.configuration).listCreatorBooks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询关注人的图书列表
     * @param {QueryFollowerBooksRequest} [queryFollowerBooksRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public listFollowerBooks(queryFollowerBooksRequest?: QueryFollowerBooksRequest, options?: any) {
        return BooksApiFp(this.configuration).listFollowerBooks(queryFollowerBooksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询所有返利图书
     * @param {QueryMarketingBooksRequest} [queryMarketingBooksRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public listMarketingBooks(queryMarketingBooksRequest?: QueryMarketingBooksRequest, options?: any) {
        return BooksApiFp(this.configuration).listMarketingBooks(queryMarketingBooksRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 全文搜索上架卡册，包括图书信息和notes
     * @param {string} keyword 
     * @param {number} [notes] 是否搜索notes，设置notes&#x3D;0只会搜索图书
     * @param {number} [notesScoreWeight] 测试配置notes_score的权重
     * @param {number} [rankScoreWeight] 测试配置rank_score的权重
     * @param {boolean} [scoreDetail] 测试环境是否返回详细得分结果
     * @param {number} [qmTitleMatchBoost] 配置es搜索中qm_title的match（分词搜索）权重
     * @param {number} [publisherNameMatchBoost] 配置es搜索中publisher_name的match（分词搜索）权重
     * @param {number} [qmDescriptionMatchBoost] 配置es搜索中qm_description的match（分词搜索）权重
     * @param {number} [categoryMatchBoost] 配置es搜索中category的match（分词搜索）权重
     * @param {number} [qmTitleTermBoost] 配置es搜索中qm_title的term（精确搜索）权重
     * @param {number} [publisherNameTermBoost] 配置es搜索中publisher_name的term（精确搜索）权重
     * @param {number} [qmDescriptionTermBoost] 配置es搜索中qm_description的term（精确搜索）权重
     * @param {number} [categoryTermBoost] 配置es搜索中category的term（精确搜索）权重
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BooksApi
     */
    public searchNotesContentByKeyword(keyword: string, notes?: number, notesScoreWeight?: number, rankScoreWeight?: number, scoreDetail?: boolean, qmTitleMatchBoost?: number, publisherNameMatchBoost?: number, qmDescriptionMatchBoost?: number, categoryMatchBoost?: number, qmTitleTermBoost?: number, publisherNameTermBoost?: number, qmDescriptionTermBoost?: number, categoryTermBoost?: number, options?: any) {
        return BooksApiFp(this.configuration).searchNotesContentByKeyword(keyword, notes, notesScoreWeight, rankScoreWeight, scoreDetail, qmTitleMatchBoost, publisherNameMatchBoost, qmDescriptionMatchBoost, categoryMatchBoost, qmTitleTermBoost, publisherNameTermBoost, qmDescriptionTermBoost, categoryTermBoost, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取预约、预购状态
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaign202104GetStatus: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('campaign202104GetStatus', 'itemId', itemId)
            const localVarPath = `/campaign/v1/202104/item/{item_id}/preorder`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发起预约或者预购请求
         * @param {string} itemId 
         * @param {Campaign202104OrderReqeust} campaign202104OrderReqeust 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaign202104Post: async (itemId: string, campaign202104OrderReqeust: Campaign202104OrderReqeust, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('campaign202104Post', 'itemId', itemId)
            // verify required parameter 'campaign202104OrderReqeust' is not null or undefined
            assertParamExists('campaign202104Post', 'campaign202104OrderReqeust', campaign202104OrderReqeust)
            const localVarPath = `/campaign/v1/202104/item/{item_id}/preorder`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaign202104OrderReqeust, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取预约、预购状态
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaign202104GetStatus(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign202104StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaign202104GetStatus(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发起预约或者预购请求
         * @param {string} itemId 
         * @param {Campaign202104OrderReqeust} campaign202104OrderReqeust 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaign202104Post(itemId: string, campaign202104OrderReqeust: Campaign202104OrderReqeust, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign202104StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaign202104Post(itemId, campaign202104OrderReqeust, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取预约、预购状态
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaign202104GetStatus(itemId: string, options?: any): AxiosPromise<Campaign202104StatusResponse> {
            return localVarFp.campaign202104GetStatus(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发起预约或者预购请求
         * @param {string} itemId 
         * @param {Campaign202104OrderReqeust} campaign202104OrderReqeust 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaign202104Post(itemId: string, campaign202104OrderReqeust: Campaign202104OrderReqeust, options?: any): AxiosPromise<Campaign202104StatusResponse> {
            return localVarFp.campaign202104Post(itemId, campaign202104OrderReqeust, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary 获取预约、预购状态
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaign202104GetStatus(itemId: string, options?: any) {
        return CampaignApiFp(this.configuration).campaign202104GetStatus(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发起预约或者预购请求
     * @param {string} itemId 
     * @param {Campaign202104OrderReqeust} campaign202104OrderReqeust 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaign202104Post(itemId: string, campaign202104OrderReqeust: Campaign202104OrderReqeust, options?: any) {
        return CampaignApiFp(this.configuration).campaign202104Post(itemId, campaign202104OrderReqeust, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Campaign202111Api - axios parameter creator
 * @export
 */
export const Campaign202111ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 消耗金箔给菩萨镀金
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeGild: async (inlineObject3: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject3' is not null or undefined
            assertParamExists('consumeGild', 'inlineObject3', inlineObject3)
            const localVarPath = `/campaign202111/v1/gild/consume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建活动捐款订单
         * @param {CreateDonationOrderRequest} [createDonationOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign202111Order: async (createDonationOrderRequest?: CreateDonationOrderRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaign202111/v1/order/donation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDonationOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取当前的金箔数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGildCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaign202111/v1/gild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得成就列表（用于任务列表）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAchievements: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaign202111/v1/achievement/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 打开祈福页面，本API不做任何操作，只用于记录事件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openPrayPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaign202111/v1/pray_page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Campaign202111Api - functional programming interface
 * @export
 */
export const Campaign202111ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Campaign202111ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 消耗金箔给菩萨镀金
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeGild(inlineObject3: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GildConsumeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeGild(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建活动捐款订单
         * @param {CreateDonationOrderRequest} [createDonationOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign202111Order(createDonationOrderRequest?: CreateDonationOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDonationOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign202111Order(createDonationOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取当前的金箔数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGildCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GildConsumeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGildCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得成就列表（用于任务列表）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAchievements(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAchievements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 打开祈福页面，本API不做任何操作，只用于记录事件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openPrayPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openPrayPage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Campaign202111Api - factory interface
 * @export
 */
export const Campaign202111ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Campaign202111ApiFp(configuration)
    return {
        /**
         * 
         * @summary 消耗金箔给菩萨镀金
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeGild(inlineObject3: InlineObject3, options?: any): AxiosPromise<GildConsumeResponse> {
            return localVarFp.consumeGild(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建活动捐款订单
         * @param {CreateDonationOrderRequest} [createDonationOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign202111Order(createDonationOrderRequest?: CreateDonationOrderRequest, options?: any): AxiosPromise<CreateDonationOrderResponse> {
            return localVarFp.createCampaign202111Order(createDonationOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取当前的金箔数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGildCount(options?: any): AxiosPromise<GildConsumeResponse> {
            return localVarFp.getGildCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得成就列表（用于任务列表）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAchievements(options?: any): AxiosPromise<AchievementVO> {
            return localVarFp.listAchievements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 打开祈福页面，本API不做任何操作，只用于记录事件
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openPrayPage(options?: any): AxiosPromise<void> {
            return localVarFp.openPrayPage(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Campaign202111Api - object-oriented interface
 * @export
 * @class Campaign202111Api
 * @extends {BaseAPI}
 */
export class Campaign202111Api extends BaseAPI {
    /**
     * 
     * @summary 消耗金箔给菩萨镀金
     * @param {InlineObject3} inlineObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Campaign202111Api
     */
    public consumeGild(inlineObject3: InlineObject3, options?: any) {
        return Campaign202111ApiFp(this.configuration).consumeGild(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建活动捐款订单
     * @param {CreateDonationOrderRequest} [createDonationOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Campaign202111Api
     */
    public createCampaign202111Order(createDonationOrderRequest?: CreateDonationOrderRequest, options?: any) {
        return Campaign202111ApiFp(this.configuration).createCampaign202111Order(createDonationOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取当前的金箔数量
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Campaign202111Api
     */
    public getGildCount(options?: any) {
        return Campaign202111ApiFp(this.configuration).getGildCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得成就列表（用于任务列表）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Campaign202111Api
     */
    public listAchievements(options?: any) {
        return Campaign202111ApiFp(this.configuration).listAchievements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 打开祈福页面，本API不做任何操作，只用于记录事件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Campaign202111Api
     */
    public openPrayPage(options?: any) {
        return Campaign202111ApiFp(this.configuration).openPrayPage(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CardcommentApi - axios parameter creator
 * @export
 */
export const CardcommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 创建新评论
         * @param {number} cardId 
         * @param {CardCommentRequest} [cardCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCardComment: async (cardId: number, cardCommentRequest?: CardCommentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('createCardComment', 'cardId', cardId)
            const localVarPath = `/cardcomment/v1/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改评论
         * @param {number} cardId 
         * @param {CardCommentRequest} [cardCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCardComment: async (cardId: number, cardCommentRequest?: CardCommentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('editCardComment', 'cardId', cardId)
            const localVarPath = `/cardcomment/v1/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询卡片的评论
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCommentByCardId: async (cardId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('queryCommentByCardId', 'cardId', cardId)
            const localVarPath = `/cardcomment/v1/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询卡册的所有评论
         * @param {string} ankiPackageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCommentByPackageUuid: async (ankiPackageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ankiPackageUuid' is not null or undefined
            assertParamExists('queryCommentByPackageUuid', 'ankiPackageUuid', ankiPackageUuid)
            const localVarPath = `/cardcomment/v1/query/{anki_package_uuid}`
                .replace(`{${"anki_package_uuid"}}`, encodeURIComponent(String(ankiPackageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardcommentApi - functional programming interface
 * @export
 */
export const CardcommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardcommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 创建新评论
         * @param {number} cardId 
         * @param {CardCommentRequest} [cardCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCardComment(cardId: number, cardCommentRequest?: CardCommentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCardComment(cardId, cardCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改评论
         * @param {number} cardId 
         * @param {CardCommentRequest} [cardCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCardComment(cardId: number, cardCommentRequest?: CardCommentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editCardComment(cardId, cardCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询卡片的评论
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryCommentByCardId(cardId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryCommentByCardId(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询卡册的所有评论
         * @param {string} ankiPackageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryCommentByPackageUuid(ankiPackageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryCommentByPackageUuid(ankiPackageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CardcommentApi - factory interface
 * @export
 */
export const CardcommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardcommentApiFp(configuration)
    return {
        /**
         * 
         * @summary 创建新评论
         * @param {number} cardId 
         * @param {CardCommentRequest} [cardCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCardComment(cardId: number, cardCommentRequest?: CardCommentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createCardComment(cardId, cardCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改评论
         * @param {number} cardId 
         * @param {CardCommentRequest} [cardCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCardComment(cardId: number, cardCommentRequest?: CardCommentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.editCardComment(cardId, cardCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询卡片的评论
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCommentByCardId(cardId: number, options?: any): AxiosPromise<CardCommentResponse> {
            return localVarFp.queryCommentByCardId(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询卡册的所有评论
         * @param {string} ankiPackageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCommentByPackageUuid(ankiPackageUuid: string, options?: any): AxiosPromise<CardCommentResponse> {
            return localVarFp.queryCommentByPackageUuid(ankiPackageUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardcommentApi - object-oriented interface
 * @export
 * @class CardcommentApi
 * @extends {BaseAPI}
 */
export class CardcommentApi extends BaseAPI {
    /**
     * 
     * @summary 创建新评论
     * @param {number} cardId 
     * @param {CardCommentRequest} [cardCommentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardcommentApi
     */
    public createCardComment(cardId: number, cardCommentRequest?: CardCommentRequest, options?: any) {
        return CardcommentApiFp(this.configuration).createCardComment(cardId, cardCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改评论
     * @param {number} cardId 
     * @param {CardCommentRequest} [cardCommentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardcommentApi
     */
    public editCardComment(cardId: number, cardCommentRequest?: CardCommentRequest, options?: any) {
        return CardcommentApiFp(this.configuration).editCardComment(cardId, cardCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询卡片的评论
     * @param {number} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardcommentApi
     */
    public queryCommentByCardId(cardId: number, options?: any) {
        return CardcommentApiFp(this.configuration).queryCommentByCardId(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询卡册的所有评论
     * @param {string} ankiPackageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardcommentApi
     */
    public queryCommentByPackageUuid(ankiPackageUuid: string, options?: any) {
        return CardcommentApiFp(this.configuration).queryCommentByPackageUuid(ankiPackageUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取图书馆分类列表
         * @param {string} [keyword] 搜索分类
         * @param {boolean} [search] 是否是在搜索，搜索时该字段置为true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryCategoryList: async (keyword?: string, search?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/library/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取推荐分类列表
         * @param {number} [userId] 用户id，当传入这个参数时，就按照这个user_id去查询而不去管当前登录的用户Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendCategoryList: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/library/v1/category/recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取图书馆分类列表
         * @param {string} [keyword] 搜索分类
         * @param {boolean} [search] 是否是在搜索，搜索时该字段置为true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryCategoryList(keyword?: string, search?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLibraryCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLibraryCategoryList(keyword, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取推荐分类列表
         * @param {number} [userId] 用户id，当传入这个参数时，就按照这个user_id去查询而不去管当前登录的用户Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendCategoryList(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLibraryCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendCategoryList(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取图书馆分类列表
         * @param {string} [keyword] 搜索分类
         * @param {boolean} [search] 是否是在搜索，搜索时该字段置为true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryCategoryList(keyword?: string, search?: boolean, options?: any): AxiosPromise<GetLibraryCategoryResponse> {
            return localVarFp.getLibraryCategoryList(keyword, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取推荐分类列表
         * @param {number} [userId] 用户id，当传入这个参数时，就按照这个user_id去查询而不去管当前登录的用户Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendCategoryList(userId?: number, options?: any): AxiosPromise<GetLibraryCategoryResponse> {
            return localVarFp.getRecommendCategoryList(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @summary 获取图书馆分类列表
     * @param {string} [keyword] 搜索分类
     * @param {boolean} [search] 是否是在搜索，搜索时该字段置为true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getLibraryCategoryList(keyword?: string, search?: boolean, options?: any) {
        return CategoryApiFp(this.configuration).getLibraryCategoryList(keyword, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取推荐分类列表
     * @param {number} [userId] 用户id，当传入这个参数时，就按照这个user_id去查询而不去管当前登录的用户Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getRecommendCategoryList(userId?: number, options?: any) {
        return CategoryApiFp(this.configuration).getRecommendCategoryList(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChestApi - axios parameter creator
 * @export
 */
export const ChestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} chestId 
         * @param {UpdateChestRemarkRequest} [updateChestRemarkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateRemark: async (chestId: number, updateChestRemarkRequest?: UpdateChestRemarkRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chestId' is not null or undefined
            assertParamExists('addOrUpdateRemark', 'chestId', chestId)
            const localVarPath = `/chest/v1/remark/{chest_id}`
                .replace(`{${"chest_id"}}`, encodeURIComponent(String(chestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChestRemarkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 购买礼物用户视角的礼物详情
         * @param {number} chestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChestInfo: async (chestId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chestId' is not null or undefined
            assertParamExists('getChestInfo', 'chestId', chestId)
            const localVarPath = `/chest/v1/detail/{chest_id}`
                .replace(`{${"chest_id"}}`, encodeURIComponent(String(chestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 购买礼物用户视角的礼物详情,通过orderId查询
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChestOrderInfo: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getChestOrderInfo', 'orderId', orderId)
            const localVarPath = `/chest/v1/order_detail/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取绑定关系
         * @param {string} chestType 目前取值 VIP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftBindInfo: async (chestType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chestType' is not null or undefined
            assertParamExists('getGiftBindInfo', 'chestType', chestType)
            const localVarPath = `/chest/v1/bind_info/{chest_type}`
                .replace(`{${"chest_type"}}`, encodeURIComponent(String(chestType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取礼物列表
         * @param {string} chestType 取值 VIP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChest: async (chestType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chestType' is not null or undefined
            assertParamExists('listChest', 'chestType', chestType)
            const localVarPath = `/chest/v1/list/{chest_type}`
                .replace(`{${"chest_type"}}`, encodeURIComponent(String(chestType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 接收礼物
         * @param {number} chestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveChest: async (chestId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chestId' is not null or undefined
            assertParamExists('receiveChest', 'chestId', chestId)
            const localVarPath = `/chest/v1/receive/{chest_id}`
                .replace(`{${"chest_id"}}`, encodeURIComponent(String(chestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChestApi - functional programming interface
 * @export
 */
export const ChestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} chestId 
         * @param {UpdateChestRemarkRequest} [updateChestRemarkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateRemark(chestId: number, updateChestRemarkRequest?: UpdateChestRemarkRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateRemark(chestId, updateChestRemarkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 购买礼物用户视角的礼物详情
         * @param {number} chestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChestInfo(chestId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChestDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChestInfo(chestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 购买礼物用户视角的礼物详情,通过orderId查询
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChestOrderInfo(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChestDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChestOrderInfo(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取绑定关系
         * @param {string} chestType 目前取值 VIP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftBindInfo(chestType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChestBindInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftBindInfo(chestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取礼物列表
         * @param {string} chestType 取值 VIP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listChest(chestType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChestListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listChest(chestType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 接收礼物
         * @param {number} chestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveChest(chestId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveChest(chestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChestApi - factory interface
 * @export
 */
export const ChestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} chestId 
         * @param {UpdateChestRemarkRequest} [updateChestRemarkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateRemark(chestId: number, updateChestRemarkRequest?: UpdateChestRemarkRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addOrUpdateRemark(chestId, updateChestRemarkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 购买礼物用户视角的礼物详情
         * @param {number} chestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChestInfo(chestId: number, options?: any): AxiosPromise<ChestDetailResponse> {
            return localVarFp.getChestInfo(chestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 购买礼物用户视角的礼物详情,通过orderId查询
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChestOrderInfo(orderId: number, options?: any): AxiosPromise<ChestDetailResponse> {
            return localVarFp.getChestOrderInfo(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取绑定关系
         * @param {string} chestType 目前取值 VIP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftBindInfo(chestType: string, options?: any): AxiosPromise<ChestBindInfoResponse> {
            return localVarFp.getGiftBindInfo(chestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取礼物列表
         * @param {string} chestType 取值 VIP
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listChest(chestType: string, options?: any): AxiosPromise<ChestListResponse> {
            return localVarFp.listChest(chestType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 接收礼物
         * @param {number} chestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveChest(chestId: number, options?: any): AxiosPromise<void> {
            return localVarFp.receiveChest(chestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChestApi - object-oriented interface
 * @export
 * @class ChestApi
 * @extends {BaseAPI}
 */
export class ChestApi extends BaseAPI {
    /**
     * 
     * @param {number} chestId 
     * @param {UpdateChestRemarkRequest} [updateChestRemarkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChestApi
     */
    public addOrUpdateRemark(chestId: number, updateChestRemarkRequest?: UpdateChestRemarkRequest, options?: any) {
        return ChestApiFp(this.configuration).addOrUpdateRemark(chestId, updateChestRemarkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 购买礼物用户视角的礼物详情
     * @param {number} chestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChestApi
     */
    public getChestInfo(chestId: number, options?: any) {
        return ChestApiFp(this.configuration).getChestInfo(chestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 购买礼物用户视角的礼物详情,通过orderId查询
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChestApi
     */
    public getChestOrderInfo(orderId: number, options?: any) {
        return ChestApiFp(this.configuration).getChestOrderInfo(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取绑定关系
     * @param {string} chestType 目前取值 VIP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChestApi
     */
    public getGiftBindInfo(chestType: string, options?: any) {
        return ChestApiFp(this.configuration).getGiftBindInfo(chestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取礼物列表
     * @param {string} chestType 取值 VIP
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChestApi
     */
    public listChest(chestType: string, options?: any) {
        return ChestApiFp(this.configuration).listChest(chestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 接收礼物
     * @param {number} chestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChestApi
     */
    public receiveChest(chestId: number, options?: any) {
        return ChestApiFp(this.configuration).receiveChest(chestId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CollectionApi - axios parameter creator
 * @export
 */
export const CollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 查看图书收藏状态
         * @param {string} objectType 收藏图书时填充 BOOK
         * @param {number} objectId object_type图书时填充bookId
         * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCollectionStatus: async (objectType: string, objectId: number, operation: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('checkCollectionStatus', 'objectType', objectType)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('checkCollectionStatus', 'objectId', objectId)
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('checkCollectionStatus', 'operation', operation)
            const localVarPath = `/collection/v1/{object_type}/{object_id}/{operation}`
                .replace(`{${"object_type"}}`, encodeURIComponent(String(objectType)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"operation"}}`, encodeURIComponent(String(operation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 收藏或取消收藏
         * @param {string} objectType 收藏图书时填充 BOOK
         * @param {number} objectId object_type图书时填充bookId
         * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectOrCancel: async (objectType: string, objectId: number, operation: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('collectOrCancel', 'objectType', objectType)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('collectOrCancel', 'objectId', objectId)
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('collectOrCancel', 'operation', operation)
            const localVarPath = `/collection/v1/{object_type}/{object_id}/{operation}`
                .replace(`{${"object_type"}}`, encodeURIComponent(String(objectType)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"operation"}}`, encodeURIComponent(String(operation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 列出我所有的图书收藏
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyBookCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collection/v1/book/my/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionApi - functional programming interface
 * @export
 */
export const CollectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 查看图书收藏状态
         * @param {string} objectType 收藏图书时填充 BOOK
         * @param {number} objectId object_type图书时填充bookId
         * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCollectionStatus(objectType: string, objectId: number, operation: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCollectionStatus(objectType, objectId, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 收藏或取消收藏
         * @param {string} objectType 收藏图书时填充 BOOK
         * @param {number} objectId object_type图书时填充bookId
         * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectOrCancel(objectType: string, objectId: number, operation: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectOrCancel(objectType, objectId, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 列出我所有的图书收藏
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyBookCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionBookListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyBookCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollectionApi - factory interface
 * @export
 */
export const CollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionApiFp(configuration)
    return {
        /**
         * 
         * @summary 查看图书收藏状态
         * @param {string} objectType 收藏图书时填充 BOOK
         * @param {number} objectId object_type图书时填充bookId
         * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCollectionStatus(objectType: string, objectId: number, operation: string, options?: any): AxiosPromise<CollectionStatusResponse> {
            return localVarFp.checkCollectionStatus(objectType, objectId, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 收藏或取消收藏
         * @param {string} objectType 收藏图书时填充 BOOK
         * @param {number} objectId object_type图书时填充bookId
         * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectOrCancel(objectType: string, objectId: number, operation: string, options?: any): AxiosPromise<void> {
            return localVarFp.collectOrCancel(objectType, objectId, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 列出我所有的图书收藏
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyBookCollection(options?: any): AxiosPromise<CollectionBookListResponse> {
            return localVarFp.listMyBookCollection(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CollectionApi - object-oriented interface
 * @export
 * @class CollectionApi
 * @extends {BaseAPI}
 */
export class CollectionApi extends BaseAPI {
    /**
     * 
     * @summary 查看图书收藏状态
     * @param {string} objectType 收藏图书时填充 BOOK
     * @param {number} objectId object_type图书时填充bookId
     * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public checkCollectionStatus(objectType: string, objectId: number, operation: string, options?: any) {
        return CollectionApiFp(this.configuration).checkCollectionStatus(objectType, objectId, operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 收藏或取消收藏
     * @param {string} objectType 收藏图书时填充 BOOK
     * @param {number} objectId object_type图书时填充bookId
     * @param {string} operation ADD/CANCEL 收藏。取消收藏, 校验图书是否已收藏时使用 CHECK
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectOrCancel(objectType: string, objectId: number, operation: string, options?: any) {
        return CollectionApiFp(this.configuration).collectOrCancel(objectType, objectId, operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 列出我所有的图书收藏
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public listMyBookCollection(options?: any) {
        return CollectionApiFp(this.configuration).listMyBookCollection(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Compensation202111Api - axios parameter creator
 * @export
 */
export const Compensation202111ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 导出差额订单excel
         * @summary 导出差额订单excel
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExcel: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/compensation202111/v1/download/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取差值
         * @summary 获取差值
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDifferentAmount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/compensation202111/v1/amount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Compensation202111Api - functional programming interface
 * @export
 */
export const Compensation202111ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Compensation202111ApiAxiosParamCreator(configuration)
    return {
        /**
         * 导出差额订单excel
         * @summary 导出差额订单excel
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadExcel(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadExcel(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取差值
         * @summary 获取差值
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDifferentAmount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Compensation202111OrderVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDifferentAmount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Compensation202111Api - factory interface
 * @export
 */
export const Compensation202111ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Compensation202111ApiFp(configuration)
    return {
        /**
         * 导出差额订单excel
         * @summary 导出差额订单excel
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExcel(token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadExcel(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取差值
         * @summary 获取差值
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDifferentAmount(options?: any): AxiosPromise<Compensation202111OrderVO> {
            return localVarFp.getDifferentAmount(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Compensation202111Api - object-oriented interface
 * @export
 * @class Compensation202111Api
 * @extends {BaseAPI}
 */
export class Compensation202111Api extends BaseAPI {
    /**
     * 导出差额订单excel
     * @summary 导出差额订单excel
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Compensation202111Api
     */
    public downloadExcel(token?: string, options?: any) {
        return Compensation202111ApiFp(this.configuration).downloadExcel(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取差值
     * @summary 获取差值
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Compensation202111Api
     */
    public getDifferentAmount(options?: any) {
        return Compensation202111ApiFp(this.configuration).getDifferentAmount(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Contest202208Api - axios parameter creator
 * @export
 */
export const Contest202208ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 查询制卡大赛个人信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyContest202208Info: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contest202208/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Contest202208Api - functional programming interface
 * @export
 */
export const Contest202208ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Contest202208ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 查询制卡大赛个人信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyContest202208Info(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contest202208InfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyContest202208Info(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Contest202208Api - factory interface
 * @export
 */
export const Contest202208ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Contest202208ApiFp(configuration)
    return {
        /**
         * 
         * @summary 查询制卡大赛个人信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyContest202208Info(options?: any): AxiosPromise<Contest202208InfoResponse> {
            return localVarFp.getMyContest202208Info(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Contest202208Api - object-oriented interface
 * @export
 * @class Contest202208Api
 * @extends {BaseAPI}
 */
export class Contest202208Api extends BaseAPI {
    /**
     * 
     * @summary 查询制卡大赛个人信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Contest202208Api
     */
    public getMyContest202208Info(options?: any) {
        return Contest202208ApiFp(this.configuration).getMyContest202208Info(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CreatorApi - axios parameter creator
 * @export
 */
export const CreatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 设置创作者认证
         * @param {CertifiedCreatorVO} certifiedCreatorVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifyCreator: async (certifiedCreatorVO: CertifiedCreatorVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'certifiedCreatorVO' is not null or undefined
            assertParamExists('certifyCreator', 'certifiedCreatorVO', certifiedCreatorVO)
            const localVarPath = `/creator/v1/certification/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(certifiedCreatorVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检验创作者认证信息是否合法
         * @param {string} [creatorName] 
         * @param {string} [domainName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCertifyCreatorInfoValid: async (creatorName?: string, domainName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/creator/v1/certification/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (creatorName !== undefined) {
                localVarQueryParameter['creator_name'] = creatorName;
            }

            if (domainName !== undefined) {
                localVarQueryParameter['domain_name'] = domainName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取创作者的认证信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertifiedCreatorInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/creator/v1/certification/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 通过创作者域名获取创作者信息
         * @param {string} domainName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertifiedCreatorInfoByDomainName: async (domainName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainName' is not null or undefined
            assertParamExists('getCertifiedCreatorInfoByDomainName', 'domainName', domainName)
            const localVarPath = `/creator/v1/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (domainName !== undefined) {
                localVarQueryParameter['domain_name'] = domainName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreatorApi - functional programming interface
 * @export
 */
export const CreatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 设置创作者认证
         * @param {CertifiedCreatorVO} certifiedCreatorVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async certifyCreator(certifiedCreatorVO: CertifiedCreatorVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.certifyCreator(certifiedCreatorVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检验创作者认证信息是否合法
         * @param {string} [creatorName] 
         * @param {string} [domainName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCertifyCreatorInfoValid(creatorName?: string, domainName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckValidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCertifyCreatorInfoValid(creatorName, domainName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取创作者的认证信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertifiedCreatorInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertifiedCreatorVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertifiedCreatorInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 通过创作者域名获取创作者信息
         * @param {string} domainName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertifiedCreatorInfoByDomainName(domainName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertifiedCreatorInfoByDomainName(domainName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreatorApi - factory interface
 * @export
 */
export const CreatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreatorApiFp(configuration)
    return {
        /**
         * 
         * @summary 设置创作者认证
         * @param {CertifiedCreatorVO} certifiedCreatorVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifyCreator(certifiedCreatorVO: CertifiedCreatorVO, options?: any): AxiosPromise<void> {
            return localVarFp.certifyCreator(certifiedCreatorVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检验创作者认证信息是否合法
         * @param {string} [creatorName] 
         * @param {string} [domainName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCertifyCreatorInfoValid(creatorName?: string, domainName?: string, options?: any): AxiosPromise<CheckValidResponse> {
            return localVarFp.checkCertifyCreatorInfoValid(creatorName, domainName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取创作者的认证信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertifiedCreatorInfo(options?: any): AxiosPromise<CertifiedCreatorVO> {
            return localVarFp.getCertifiedCreatorInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 通过创作者域名获取创作者信息
         * @param {string} domainName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertifiedCreatorInfoByDomainName(domainName: string, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getCertifiedCreatorInfoByDomainName(domainName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreatorApi - object-oriented interface
 * @export
 * @class CreatorApi
 * @extends {BaseAPI}
 */
export class CreatorApi extends BaseAPI {
    /**
     * 
     * @summary 设置创作者认证
     * @param {CertifiedCreatorVO} certifiedCreatorVO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorApi
     */
    public certifyCreator(certifiedCreatorVO: CertifiedCreatorVO, options?: any) {
        return CreatorApiFp(this.configuration).certifyCreator(certifiedCreatorVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检验创作者认证信息是否合法
     * @param {string} [creatorName] 
     * @param {string} [domainName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorApi
     */
    public checkCertifyCreatorInfoValid(creatorName?: string, domainName?: string, options?: any) {
        return CreatorApiFp(this.configuration).checkCertifyCreatorInfoValid(creatorName, domainName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取创作者的认证信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorApi
     */
    public getCertifiedCreatorInfo(options?: any) {
        return CreatorApiFp(this.configuration).getCertifiedCreatorInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 通过创作者域名获取创作者信息
     * @param {string} domainName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorApi
     */
    public getCertifiedCreatorInfoByDomainName(domainName: string, options?: any) {
        return CreatorApiFp(this.configuration).getCertifiedCreatorInfoByDomainName(domainName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdateUgcBookRequest} updateUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUgcBook: async (updateUgcBookRequest: UpdateUgcBookRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUgcBookRequest' is not null or undefined
            assertParamExists('addUgcBook', 'updateUgcBookRequest', updateUgcBookRequest)
            const localVarPath = `/ugc/v1/books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUgcBookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 根据ankiPackageId获得ankiPackage信息
         * @summary 获得package上传的基本信息
         * @param {number} ankiPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackage: async (ankiPackageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ankiPackageId' is not null or undefined
            assertParamExists('apkgGetPackage', 'ankiPackageId', ankiPackageId)
            const localVarPath = `/apkg/v1/package/{anki_package_id}`
                .replace(`{${"anki_package_id"}}`, encodeURIComponent(String(ankiPackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConsumeRedeemCodeRequest} [consumeRedeemCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeRedeemCode: async (consumeRedeemCodeRequest?: ConsumeRedeemCodeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/redeem/v1/consume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consumeRedeemCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new instance of a `AnkiDeckVo`.
         * @summary Create a AnkiDeckVo
         * @param {string} packageUuid 
         * @param {AnkiDeckVo} ankiDeckVo A new &#x60;AnkiDeckVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiDeckVo: async (packageUuid: string, ankiDeckVo: AnkiDeckVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('createAnkiDeckVo', 'packageUuid', packageUuid)
            // verify required parameter 'ankiDeckVo' is not null or undefined
            assertParamExists('createAnkiDeckVo', 'ankiDeckVo', ankiDeckVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/decks`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiDeckVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new instance of a `AnkiTemplateVo`.
         * @summary Create a AnkiTemplateVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {AnkiTemplateVo} ankiTemplateVo A new &#x60;AnkiTemplateVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiTemplateVo: async (packageUuid: string, modelId: number, ankiTemplateVo: AnkiTemplateVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('createAnkiTemplateVo', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('createAnkiTemplateVo', 'modelId', modelId)
            // verify required parameter 'ankiTemplateVo' is not null or undefined
            assertParamExists('createAnkiTemplateVo', 'ankiTemplateVo', ankiTemplateVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/templates`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiTemplateVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建为\"购买订单\"创建”支付凭据“
         * @param {number} orderId 
         * @param {CreatePaymentRequest} createPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionForOrder: async (orderId: number, createPaymentRequest: CreatePaymentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createTransactionForOrder', 'orderId', orderId)
            // verify required parameter 'createPaymentRequest' is not null or undefined
            assertParamExists('createTransactionForOrder', 'createPaymentRequest', createPaymentRequest)
            const localVarPath = `/payment/v1/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing `AnkiDeckVo`.
         * @summary Delete a AnkiDeckVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiDeckVo: async (packageUuid: string, deckId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('deleteAnkiDeckVo', 'packageUuid', packageUuid)
            // verify required parameter 'deckId' is not null or undefined
            assertParamExists('deleteAnkiDeckVo', 'deckId', deckId)
            const localVarPath = `/apkg/v1/package/{package_uuid}/decks/{deck_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"deck_id"}}`, encodeURIComponent(String(deckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing `AnkiTemplateVo`.
         * @summary Delete a AnkiTemplateVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
         * @param {number} modelId 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiTemplateVo: async (packageUuid: string, modelId: number, templateId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('deleteAnkiTemplateVo', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('deleteAnkiTemplateVo', 'modelId', modelId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteAnkiTemplateVo', 'templateId', templateId)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/templates/{template_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除我的卡包
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyApkg: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('deleteMyApkg', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/my/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUgcBookRequest} [deleteUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyBooks: async (deleteUgcBookRequest?: DeleteUgcBookRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ugc/v1/books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUgcBookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 对应的指令是\'jstack -l\'会试图保存所有的线程相关信息
         * @summary 获取服务的thread dump信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDump: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sre/v1/dump/thread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 延期学习计划（但是不更新每天学习卡数）
         * @param {ExtendStudyPlanRequest} extendStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendStudyPlan: async (extendStudyPlanRequest: ExtendStudyPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'extendStudyPlanRequest' is not null or undefined
            assertParamExists('extendStudyPlan', 'extendStudyPlanRequest', extendStudyPlanRequest)
            const localVarPath = `/studyplan/v1/extend_study_plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendStudyPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分页获取图书馆
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] page_size
         * @param {string} [category] 图书分类；如果传入，则显示该分类下的图书
         * @param {string} [categoryLabel] 分类对应的关键词标签
         * @param {number} [userId] 创建者user id。如果传入，则显示创作者名下的图书
         * @param {string} [orderBy] 说明排序的优先级 其中 impressionScoreDesc 印象分降序 impressionScoreAsc 印象分升序  priceDesc表示价格（单买价格和团购价格中最低的一个）降序 priceAsc表明价格升序， learnerNumDesc表示按照学习人数从高到底进行排序，studyTimeDesc表示按照学习时间从多到少，如果为空表示按照默认的 评分（rank_value+manual_value）
         * @param {string} [filterBy] 表明当前过滤的类型， free表示查询单买或拼团价格为0的图书,  vipGroupPass代表拼团图书（对应会员的免拼特权），bundle表明查询合集图书， vipFree 表示查询会员免费学的商品
         * @param {boolean} [showOfficialBook] 是否只展示官方推荐的卡册, 默认是false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLibraryBooks: async (pageNumber?: number, pageSize?: number, category?: string, categoryLabel?: string, userId?: number, orderBy?: string, filterBy?: string, showOfficialBook?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/books/v1/library`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (categoryLabel !== undefined) {
                localVarQueryParameter['category_label'] = categoryLabel;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filter_by'] = filterBy;
            }

            if (showOfficialBook !== undefined) {
                localVarQueryParameter['show_official_book'] = showOfficialBook;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取我购买的所有图书
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] page_size
         * @param {number} [version] 图书版本号，1表示原来只能在线学习的apkg，2表示可以在线离线学习的hpkg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyPaidBooks: async (pageNumber?: number, pageSize?: number, version?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/books/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 生成兑换码接口
         * @summary 生成随机码
         * @param {CreateRedeemRequest} [createRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRedeem: async (createRedeemRequest?: CreateRedeemRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/redeem/v1/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRedeemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 默认返回二十个，返回最旧的 resourceId ，可以继续前翻。订单状态详见任务 HUA-2194
         * @summary 获取我的所有租借订单
         * @param {number} [resourceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMyLease: async (resourceId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/books/v1/library/lease`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all `AnkiDeckVo` entities.
         * @summary List All AnkiDeckVos
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiDeckVos: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getAnkiDeckVos', 'packageUuid', packageUuid)
            const localVarPath = `/apkg/v1/package/{package_uuid}/decks`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all `AnkiTemplateVo` entities.
         * @summary List All AnkiTemplateVos
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiTemplateVos: async (packageUuid: string, modelId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getAnkiTemplateVos', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('getAnkiTemplateVos', 'modelId', modelId)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/templates`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取APP信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/v1/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取文本对应的音频 OSS 链接
         * @summary 获取文本对应的音频 OSS 链接
         * @param {TTSRequest} [tTSRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioLink: async (tTSRequest?: TTSRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tts/v1/audio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tTSRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取学习计划
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeckStudyPlan: async (deckId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deckId' is not null or undefined
            assertParamExists('getDeckStudyPlan', 'deckId', deckId)
            const localVarPath = `/studyplan/v1/decks/{deckId}`
                .replace(`{${"deckId"}}`, encodeURIComponent(String(deckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取续期天数
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtendDaysCount: async (deckId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deckId' is not null or undefined
            assertParamExists('getExtendDaysCount', 'deckId', deckId)
            const localVarPath = `/studyplan/v1/decks/{deckId}/extend`
                .replace(`{${"deckId"}}`, encodeURIComponent(String(deckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 遗忘曲线数据点
         * @param {ForgettingCurveDotsDataRequest} forgettingCurveDotsDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForgettingCurveDotsData: async (forgettingCurveDotsDataRequest: ForgettingCurveDotsDataRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgettingCurveDotsDataRequest' is not null or undefined
            assertParamExists('getForgettingCurveDotsData', 'forgettingCurveDotsDataRequest', forgettingCurveDotsDataRequest)
            const localVarPath = `/studystat/v1/get_forgetting_curve_dots_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgettingCurveDotsDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取当前用户对于这个模板的付款信息
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcpInfoByTemplateId: async (templateId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getIcpInfoByTemplateId', 'templateId', templateId)
            const localVarPath = `/icp/v1/templates/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取当前图书的租约以及价格
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryBookSku: async (libraryBookId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('getLibraryBookSku', 'libraryBookId', libraryBookId)
            const localVarPath = `/books/v1/library/lease/{library_book_id}`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取图书的详细信息
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryInfo: async (libraryBookId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('getLibraryInfo', 'libraryBookId', libraryBookId)
            const localVarPath = `/books/v1/library/info/{library_book_id}`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户制作的卡包
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyApkg: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取我的所有优惠券
         * @param {number} [libraryBookId] 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCouponList: async (libraryBookId?: number, orderId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/coupon/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (libraryBookId !== undefined) {
                localVarQueryParameter['library_book_id'] = libraryBookId;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取我的学习计划统计数据
         * @param {number} startDate 
         * @param {number} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyStudyStat: async (startDate: number, endDate: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getMyStudyStat', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getMyStudyStat', 'endDate', endDate)
            const localVarPath = `/studystat/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary （新版）获取学习计划
         * @param {GetStudyPlanRequest} getStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyPlan: async (getStudyPlanRequest: GetStudyPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getStudyPlanRequest' is not null or undefined
            assertParamExists('getStudyPlan', 'getStudyPlanRequest', getStudyPlanRequest)
            const localVarPath = `/studyplan/v1/get_study_plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStudyPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ugcBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUgcBookInfo: async (ugcBookId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ugcBookId' is not null or undefined
            assertParamExists('getUgcBookInfo', 'ugcBookId', ugcBookId)
            const localVarPath = `/ugc/v1/books/{ugc_book_id}`
                .replace(`{${"ugc_book_id"}}`, encodeURIComponent(String(ugcBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 根据cardId获得用户评论
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCardComment: async (cardId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getUserCardComment', 'cardId', cardId)
            const localVarPath = `/usercard/v1/comment/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户偏好设置
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreference: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getUserPreference', 'key', key)
            const localVarPath = `/user/v1/preference/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取服务器环境目录
         * @summary 获取目录
         * @param {string} [path] 当前路径名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFiles: async (path?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sre/v1/browse/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyCreateBook: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ugc/v1/books`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueryUgcBookRequest} [queryUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageMyCreateBook: async (queryUgcBookRequest?: QueryUgcBookRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ugc/v1/books/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryUgcBookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPreferenceReview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/preference/review/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueryRedeemConsumeLogRequest} [queryRedeemConsumeLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedeemConsumeLogPage: async (queryRedeemConsumeLogRequest?: QueryRedeemConsumeLogRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/redeem/v1/consume_log/list/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryRedeemConsumeLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueryRedeemGenerationLogRequest} [queryRedeemGenerationLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedeemGenerateLogPage: async (queryRedeemGenerationLogRequest?: QueryRedeemGenerationLogRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/redeem/v1/generation_log/list/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryRedeemGenerationLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueryRedeemRequest} [queryRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedeemPage: async (queryRedeemRequest?: QueryRedeemRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/redeem/v1/list/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryRedeemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ankiPackageUuid 
         * @param {string} type type值: STAR 标星, COMMENT 评论, RECENT_LEARNING 最近学习, MASTERED 已掌握, LEARNING 学习中, NEW 未学习, SUSPENDED 已隐藏
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserCardInfoPage: async (ankiPackageUuid: string, type: string, pageNumber: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ankiPackageUuid' is not null or undefined
            assertParamExists('listUserCardInfoPage', 'ankiPackageUuid', ankiPackageUuid)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('listUserCardInfoPage', 'type', type)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('listUserCardInfoPage', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listUserCardInfoPage', 'pageSize', pageSize)
            const localVarPath = `/usercard/v1/package/{anki_package_uuid}/query`
                .replace(`{${"anki_package_uuid"}}`, encodeURIComponent(String(ankiPackageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 下单order - 租借图书
         * @param {number} libraryBookId 
         * @param {CreateOrderRequest} [createOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLibraryBookLease: async (libraryBookId: number, createOrderRequest?: CreateOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('makeLibraryBookLease', 'libraryBookId', libraryBookId)
            const localVarPath = `/books/v1/library/lease/{library_book_id}`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 下单order - 租借图书, 根据AI推荐来制定图书
         * @param {number} libraryBookId 
         * @param {CreateOrderRequest} [createOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLibraryBookLeaseWithAiPlan: async (libraryBookId: number, createOrderRequest?: CreateOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('makeLibraryBookLeaseWithAiPlan', 'libraryBookId', libraryBookId)
            const localVarPath = `/books/v1/library/lease/{library_book_id}/withplan`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分页获取ankiPackage
         * @param {QueryAnkiPackageRequest} [queryAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAnkiPackageList: async (queryAnkiPackageRequest?: QueryAnkiPackageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg/v1/package/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryAnkiPackageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 追踪用户行为数据
         * @param {UserTraceSpan} userTraceSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSpan: async (userTraceSpan: UserTraceSpan, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userTraceSpan' is not null or undefined
            assertParamExists('postUserSpan', 'userTraceSpan', userTraceSpan)
            const localVarPath = `/log/v1/spans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTraceSpan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 随机获得一句警世名言
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        randomQuote: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/quote/v1/random`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理员修改ugc图书的排序参考和推荐
         * @param {number} ugcBookId 
         * @param {RankUgcBookRequest} rankUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rankUgcBook: async (ugcBookId: number, rankUgcBookRequest: RankUgcBookRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ugcBookId' is not null or undefined
            assertParamExists('rankUgcBook', 'ugcBookId', ugcBookId)
            // verify required parameter 'rankUgcBookRequest' is not null or undefined
            assertParamExists('rankUgcBook', 'rankUgcBookRequest', rankUgcBookRequest)
            const localVarPath = `/ugc/v1/books/{ugc_book_id}/rank`
                .replace(`{${"ugc_book_id"}}`, encodeURIComponent(String(ugcBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rankUgcBookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 举报图书
         * @param {number} libraryBookId 
         * @param {LibraryBookReportRequest} [libraryBookReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBook: async (libraryBookId: number, libraryBookReportRequest?: LibraryBookReportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('reportBook', 'libraryBookId', libraryBookId)
            const localVarPath = `/books/v1/library/report/{library_book_id}`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(libraryBookReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 清空并重置学习计划
         * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetStudyPlan: async (updateStudyPlanRequest: UpdateStudyPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStudyPlanRequest' is not null or undefined
            assertParamExists('resetStudyPlan', 'updateStudyPlanRequest', updateStudyPlanRequest)
            const localVarPath = `/studyplan/v1/reset_study_plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStudyPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ugcBookId 
         * @param {ReviewUgcBookRequest} reviewUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewUgcBook: async (ugcBookId: number, reviewUgcBookRequest: ReviewUgcBookRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ugcBookId' is not null or undefined
            assertParamExists('reviewUgcBook', 'ugcBookId', ugcBookId)
            // verify required parameter 'reviewUgcBookRequest' is not null or undefined
            assertParamExists('reviewUgcBook', 'reviewUgcBookRequest', reviewUgcBookRequest)
            const localVarPath = `/ugc/v1/books/{ugcBookId}/review`
                .replace(`{${"ugcBookId"}}`, encodeURIComponent(String(ugcBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewUgcBookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary APP 首页搜索
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (q?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/v1/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchClickEventRequest} [searchClickEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSearchClickEvent: async (searchClickEventRequest?: SearchClickEventRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/v1/search_click_event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchClickEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设置用户偏好
         * @param {string} key 
         * @param {UserPreference} userPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPreference: async (key: string, userPreference: UserPreference, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('setUserPreference', 'key', key)
            // verify required parameter 'userPreference' is not null or undefined
            assertParamExists('setUserPreference', 'userPreference', userPreference)
            const localVarPath = `/user/v1/preference/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreference, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 苹果登录或绑定至登录中的账号
         * @param {SignInAppleRequest} signInAppleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByApple: async (signInAppleRequest: SignInAppleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInAppleRequest' is not null or undefined
            assertParamExists('signInByApple', 'signInAppleRequest', signInAppleRequest)
            const localVarPath = `/auth/v1/signin/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInAppleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 通过QQ登录或绑定至登录中的账号
         * @param {SignInQQRequest} [signInQQRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByQQ: async (signInQQRequest?: SignInQQRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/signin/qq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInQQRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 微信登录或绑定至登录中的账号；或直接根据微信user_info创建一个新账号
         * @param {SignInWeChatRequest} signInWeChatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByWeChat: async (signInWeChatRequest: SignInWeChatRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInWeChatRequest' is not null or undefined
            assertParamExists('signInByWeChat', 'signInWeChatRequest', signInWeChatRequest)
            const localVarPath = `/auth/v1/signin/wechat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInWeChatRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取消苹果登录的绑定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindAppleId: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/signin/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 解除QQ绑定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindQQ: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/signin/qq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 解除微信登录
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindWeChat: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/v1/signin/wechat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 解锁单张卡片
         * @param {number} templateId 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockSingleCard: async (templateId: number, cardId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('unlockSingleCard', 'templateId', templateId)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('unlockSingleCard', 'cardId', cardId)
            const localVarPath = `/icp/v1/templates/{template_id}/cards/{card_id}/unlock`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 请求解锁该卡包模板
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockTemplateIcp: async (templateId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('unlockTemplateIcp', 'templateId', templateId)
            const localVarPath = `/icp/v1/templates/{template_id}/unlock`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing `AnkiDeckVo`.
         * @summary Update a AnkiDeckVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
         * @param {number} deckId 
         * @param {AnkiDeckVo} ankiDeckVo Updated &#x60;AnkiDeckVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiDeckVo: async (packageUuid: string, deckId: number, ankiDeckVo: AnkiDeckVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('updateAnkiDeckVo', 'packageUuid', packageUuid)
            // verify required parameter 'deckId' is not null or undefined
            assertParamExists('updateAnkiDeckVo', 'deckId', deckId)
            // verify required parameter 'ankiDeckVo' is not null or undefined
            assertParamExists('updateAnkiDeckVo', 'ankiDeckVo', ankiDeckVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/decks/{deck_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"deck_id"}}`, encodeURIComponent(String(deckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiDeckVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing `AnkiTemplateVo`.
         * @summary Update a AnkiTemplateVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
         * @param {number} modelId 
         * @param {number} templateId 
         * @param {AnkiTemplateVo} ankiTemplateVo Updated &#x60;AnkiTemplateVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiTemplateVo: async (packageUuid: string, modelId: number, templateId: number, ankiTemplateVo: AnkiTemplateVo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('updateAnkiTemplateVo', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('updateAnkiTemplateVo', 'modelId', modelId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateAnkiTemplateVo', 'templateId', templateId)
            // verify required parameter 'ankiTemplateVo' is not null or undefined
            assertParamExists('updateAnkiTemplateVo', 'ankiTemplateVo', ankiTemplateVo)
            const localVarPath = `/apkg/v1/package/{package_uuid}/models/{model_id}/templates/{template_id}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ankiTemplateVo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改个人信息
         * @param {UpdateMyInfoRequest} updateMyInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyInfo: async (updateMyInfoRequest: UpdateMyInfoRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMyInfoRequest' is not null or undefined
            assertParamExists('updateMyInfo', 'updateMyInfoRequest', updateMyInfoRequest)
            const localVarPath = `/auth/v1/my_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UserPreference} [userPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferenceValue: async (id: number, userPreference?: UserPreference, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePreferenceValue', 'id', id)
            const localVarPath = `/user/v1/preference/review/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreference, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新学习计划
         * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudyPlan: async (updateStudyPlanRequest: UpdateStudyPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStudyPlanRequest' is not null or undefined
            assertParamExists('updateStudyPlan', 'updateStudyPlanRequest', updateStudyPlanRequest)
            const localVarPath = `/studyplan/v1/update_study_plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStudyPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 编辑ugcBook
         * @param {number} ugcBookId 
         * @param {UpdateUgcBookRequest} [updateUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUgcBook: async (ugcBookId: number, updateUgcBookRequest?: UpdateUgcBookRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ugcBookId' is not null or undefined
            assertParamExists('updateUgcBook', 'ugcBookId', ugcBookId)
            const localVarPath = `/ugc/v1/books/{ugc_book_id}`
                .replace(`{${"ugc_book_id"}}`, encodeURIComponent(String(ugcBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUgcBookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdateUgcBookRequest} updateUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUgcBook(updateUgcBookRequest: UpdateUgcBookRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUgcBook(updateUgcBookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 根据ankiPackageId获得ankiPackage信息
         * @summary 获得package上传的基本信息
         * @param {number} ankiPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apkgGetPackage(ankiPackageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnkiPackageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apkgGetPackage(ankiPackageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConsumeRedeemCodeRequest} [consumeRedeemCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeRedeemCode(consumeRedeemCodeRequest?: ConsumeRedeemCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumeRedeemCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeRedeemCode(consumeRedeemCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new instance of a `AnkiDeckVo`.
         * @summary Create a AnkiDeckVo
         * @param {string} packageUuid 
         * @param {AnkiDeckVo} ankiDeckVo A new &#x60;AnkiDeckVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnkiDeckVo(packageUuid: string, ankiDeckVo: AnkiDeckVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnkiDeckVo(packageUuid, ankiDeckVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new instance of a `AnkiTemplateVo`.
         * @summary Create a AnkiTemplateVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {AnkiTemplateVo} ankiTemplateVo A new &#x60;AnkiTemplateVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnkiTemplateVo(packageUuid: string, modelId: number, ankiTemplateVo: AnkiTemplateVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnkiTemplateVo(packageUuid, modelId, ankiTemplateVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建为\"购买订单\"创建”支付凭据“
         * @param {number} orderId 
         * @param {CreatePaymentRequest} createPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransactionForOrder(orderId: number, createPaymentRequest: CreatePaymentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransactionForOrder(orderId, createPaymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing `AnkiDeckVo`.
         * @summary Delete a AnkiDeckVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnkiDeckVo(packageUuid: string, deckId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnkiDeckVo(packageUuid, deckId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing `AnkiTemplateVo`.
         * @summary Delete a AnkiTemplateVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
         * @param {number} modelId 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnkiTemplateVo(packageUuid: string, modelId: number, templateId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnkiTemplateVo(packageUuid, modelId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除我的卡包
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMyApkg(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMyApkg(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUgcBookRequest} [deleteUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMyBooks(deleteUgcBookRequest?: DeleteUgcBookRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMyBooks(deleteUgcBookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 对应的指令是\'jstack -l\'会试图保存所有的线程相关信息
         * @summary 获取服务的thread dump信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDump(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDump(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 延期学习计划（但是不更新每天学习卡数）
         * @param {ExtendStudyPlanRequest} extendStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendStudyPlan(extendStudyPlanRequest: ExtendStudyPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendStudyPlan(extendStudyPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分页获取图书馆
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] page_size
         * @param {string} [category] 图书分类；如果传入，则显示该分类下的图书
         * @param {string} [categoryLabel] 分类对应的关键词标签
         * @param {number} [userId] 创建者user id。如果传入，则显示创作者名下的图书
         * @param {string} [orderBy] 说明排序的优先级 其中 impressionScoreDesc 印象分降序 impressionScoreAsc 印象分升序  priceDesc表示价格（单买价格和团购价格中最低的一个）降序 priceAsc表明价格升序， learnerNumDesc表示按照学习人数从高到底进行排序，studyTimeDesc表示按照学习时间从多到少，如果为空表示按照默认的 评分（rank_value+manual_value）
         * @param {string} [filterBy] 表明当前过滤的类型， free表示查询单买或拼团价格为0的图书,  vipGroupPass代表拼团图书（对应会员的免拼特权），bundle表明查询合集图书， vipFree 表示查询会员免费学的商品
         * @param {boolean} [showOfficialBook] 是否只展示官方推荐的卡册, 默认是false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLibraryBooks(pageNumber?: number, pageSize?: number, category?: string, categoryLabel?: string, userId?: number, orderBy?: string, filterBy?: string, showOfficialBook?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLibraryBookVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLibraryBooks(pageNumber, pageSize, category, categoryLabel, userId, orderBy, filterBy, showOfficialBook, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取我购买的所有图书
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] page_size
         * @param {number} [version] 图书版本号，1表示原来只能在线学习的apkg，2表示可以在线离线学习的hpkg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMyPaidBooks(pageNumber?: number, pageSize?: number, version?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLibraryBookVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMyPaidBooks(pageNumber, pageSize, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 生成兑换码接口
         * @summary 生成随机码
         * @param {CreateRedeemRequest} [createRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateRedeem(createRedeemRequest?: CreateRedeemRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRedeemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateRedeem(createRedeemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 默认返回二十个，返回最旧的 resourceId ，可以继续前翻。订单状态详见任务 HUA-2194
         * @summary 获取我的所有租借订单
         * @param {number} [resourceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMyLease(resourceId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLibraryLeaseListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMyLease(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all `AnkiDeckVo` entities.
         * @summary List All AnkiDeckVos
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnkiDeckVos(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnkiDeckVo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnkiDeckVos(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all `AnkiTemplateVo` entities.
         * @summary List All AnkiTemplateVos
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnkiTemplateVos(packageUuid: string, modelId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnkiTemplateVo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnkiTemplateVos(packageUuid, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取APP信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取文本对应的音频 OSS 链接
         * @summary 获取文本对应的音频 OSS 链接
         * @param {TTSRequest} [tTSRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAudioLink(tTSRequest?: TTSRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TTSResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAudioLink(tTSRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取学习计划
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeckStudyPlan(deckId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyPlanParam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeckStudyPlan(deckId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取续期天数
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExtendDaysCount(deckId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDaysExtensionCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExtendDaysCount(deckId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 遗忘曲线数据点
         * @param {ForgettingCurveDotsDataRequest} forgettingCurveDotsDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForgettingCurveDotsData(forgettingCurveDotsDataRequest: ForgettingCurveDotsDataRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForgettingCurveDotsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForgettingCurveDotsData(forgettingCurveDotsDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取当前用户对于这个模板的付款信息
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIcpInfoByTemplateId(templateId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTemplateIcpInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIcpInfoByTemplateId(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取当前图书的租约以及价格
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryBookSku(libraryBookId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LibraryBookLeaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLibraryBookSku(libraryBookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取图书的详细信息
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryInfo(libraryBookId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLibraryBookInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLibraryInfo(libraryBookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户制作的卡包
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyApkg(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudyStat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyApkg(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取我的所有优惠券
         * @param {number} [libraryBookId] 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyCouponList(libraryBookId?: number, orderId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCouponListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyCouponList(libraryBookId, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取我的学习计划统计数据
         * @param {number} startDate 
         * @param {number} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyStudyStat(startDate: number, endDate: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyStat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyStudyStat(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary （新版）获取学习计划
         * @param {GetStudyPlanRequest} getStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyPlan(getStudyPlanRequest: GetStudyPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudyPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyPlan(getStudyPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ugcBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUgcBookInfo(ugcBookId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UgcBook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUgcBookInfo(ugcBookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 根据cardId获得用户评论
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCardComment(cardId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCardComment(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户偏好设置
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreference(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreference(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取服务器环境目录
         * @summary 获取目录
         * @param {string} [path] 当前路径名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFiles(path?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFiles(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyCreateBook(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UgcBook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyCreateBook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QueryUgcBookRequest} [queryUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPageMyCreateBook(queryUgcBookRequest?: QueryUgcBookRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUgcBook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPageMyCreateBook(queryUgcBookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPreferenceReview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPreferenceVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPreferenceReview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QueryRedeemConsumeLogRequest} [queryRedeemConsumeLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRedeemConsumeLogPage(queryRedeemConsumeLogRequest?: QueryRedeemConsumeLogRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageRedeemConsumeLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRedeemConsumeLogPage(queryRedeemConsumeLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QueryRedeemGenerationLogRequest} [queryRedeemGenerationLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRedeemGenerateLogPage(queryRedeemGenerationLogRequest?: QueryRedeemGenerationLogRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRedeemGenerationLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRedeemGenerateLogPage(queryRedeemGenerationLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QueryRedeemRequest} [queryRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRedeemPage(queryRedeemRequest?: QueryRedeemRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRedeem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRedeemPage(queryRedeemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ankiPackageUuid 
         * @param {string} type type值: STAR 标星, COMMENT 评论, RECENT_LEARNING 最近学习, MASTERED 已掌握, LEARNING 学习中, NEW 未学习, SUSPENDED 已隐藏
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserCardInfoPage(ankiPackageUuid: string, type: string, pageNumber: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserCardInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserCardInfoPage(ankiPackageUuid, type, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 下单order - 租借图书
         * @param {number} libraryBookId 
         * @param {CreateOrderRequest} [createOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeLibraryBookLease(libraryBookId: number, createOrderRequest?: CreateOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeLibraryBookLease(libraryBookId, createOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 下单order - 租借图书, 根据AI推荐来制定图书
         * @param {number} libraryBookId 
         * @param {CreateOrderRequest} [createOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeLibraryBookLeaseWithAiPlan(libraryBookId: number, createOrderRequest?: CreateOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeLibraryBookLeaseWithAiPlan(libraryBookId, createOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分页获取ankiPackage
         * @param {QueryAnkiPackageRequest} [queryAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageAnkiPackageList(queryAnkiPackageRequest?: QueryAnkiPackageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAnkiPackage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageAnkiPackageList(queryAnkiPackageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 追踪用户行为数据
         * @param {UserTraceSpan} userTraceSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserSpan(userTraceSpan: UserTraceSpan, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserSpan(userTraceSpan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 随机获得一句警世名言
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async randomQuote(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.randomQuote(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理员修改ugc图书的排序参考和推荐
         * @param {number} ugcBookId 
         * @param {RankUgcBookRequest} rankUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rankUgcBook(ugcBookId: number, rankUgcBookRequest: RankUgcBookRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rankUgcBook(ugcBookId, rankUgcBookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 举报图书
         * @param {number} libraryBookId 
         * @param {LibraryBookReportRequest} [libraryBookReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportBook(libraryBookId: number, libraryBookReportRequest?: LibraryBookReportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportBook(libraryBookId, libraryBookReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 清空并重置学习计划
         * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetStudyPlan(updateStudyPlanRequest: UpdateStudyPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetStudyPlan(updateStudyPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ugcBookId 
         * @param {ReviewUgcBookRequest} reviewUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewUgcBook(ugcBookId: number, reviewUgcBookRequest: ReviewUgcBookRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewUgcBook(ugcBookId, reviewUgcBookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary APP 首页搜索
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchClickEventRequest} [searchClickEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSearchClickEvent(searchClickEventRequest?: SearchClickEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSearchClickEvent(searchClickEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设置用户偏好
         * @param {string} key 
         * @param {UserPreference} userPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserPreference(key: string, userPreference: UserPreference, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserPreference(key, userPreference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 苹果登录或绑定至登录中的账号
         * @param {SignInAppleRequest} signInAppleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInByApple(signInAppleRequest: SignInAppleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInThirdPartyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInByApple(signInAppleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 通过QQ登录或绑定至登录中的账号
         * @param {SignInQQRequest} [signInQQRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInByQQ(signInQQRequest?: SignInQQRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInThirdPartyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInByQQ(signInQQRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 微信登录或绑定至登录中的账号；或直接根据微信user_info创建一个新账号
         * @param {SignInWeChatRequest} signInWeChatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInByWeChat(signInWeChatRequest: SignInWeChatRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInThirdPartyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInByWeChat(signInWeChatRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取消苹果登录的绑定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unbindAppleId(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unbindAppleId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 解除QQ绑定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unbindQQ(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unbindQQ(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 解除微信登录
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unbindWeChat(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unbindWeChat(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 解锁单张卡片
         * @param {number} templateId 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockSingleCard(templateId: number, cardId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockSingleCard(templateId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 请求解锁该卡包模板
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockTemplateIcp(templateId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockTemplateIcp(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing `AnkiDeckVo`.
         * @summary Update a AnkiDeckVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
         * @param {number} deckId 
         * @param {AnkiDeckVo} ankiDeckVo Updated &#x60;AnkiDeckVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnkiDeckVo(packageUuid: string, deckId: number, ankiDeckVo: AnkiDeckVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnkiDeckVo(packageUuid, deckId, ankiDeckVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing `AnkiTemplateVo`.
         * @summary Update a AnkiTemplateVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
         * @param {number} modelId 
         * @param {number} templateId 
         * @param {AnkiTemplateVo} ankiTemplateVo Updated &#x60;AnkiTemplateVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnkiTemplateVo(packageUuid: string, modelId: number, templateId: number, ankiTemplateVo: AnkiTemplateVo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnkiTemplateVo(packageUuid, modelId, templateId, ankiTemplateVo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改个人信息
         * @param {UpdateMyInfoRequest} updateMyInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyInfo(updateMyInfoRequest: UpdateMyInfoRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyInfo(updateMyInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UserPreference} [userPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreferenceValue(id: number, userPreference?: UserPreference, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreferenceValue(id, userPreference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新学习计划
         * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudyPlan(updateStudyPlanRequest: UpdateStudyPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudyPlan(updateStudyPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 编辑ugcBook
         * @param {number} ugcBookId 
         * @param {UpdateUgcBookRequest} [updateUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUgcBook(ugcBookId: number, updateUgcBookRequest?: UpdateUgcBookRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUgcBook(ugcBookId, updateUgcBookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdateUgcBookRequest} updateUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUgcBook(updateUgcBookRequest: UpdateUgcBookRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addUgcBook(updateUgcBookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 根据ankiPackageId获得ankiPackage信息
         * @summary 获得package上传的基本信息
         * @param {number} ankiPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apkgGetPackage(ankiPackageId: number, options?: any): AxiosPromise<AnkiPackageResponse> {
            return localVarFp.apkgGetPackage(ankiPackageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConsumeRedeemCodeRequest} [consumeRedeemCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeRedeemCode(consumeRedeemCodeRequest?: ConsumeRedeemCodeRequest, options?: any): AxiosPromise<ConsumeRedeemCodeResponse> {
            return localVarFp.consumeRedeemCode(consumeRedeemCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new instance of a `AnkiDeckVo`.
         * @summary Create a AnkiDeckVo
         * @param {string} packageUuid 
         * @param {AnkiDeckVo} ankiDeckVo A new &#x60;AnkiDeckVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiDeckVo(packageUuid: string, ankiDeckVo: AnkiDeckVo, options?: any): AxiosPromise<void> {
            return localVarFp.createAnkiDeckVo(packageUuid, ankiDeckVo, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new instance of a `AnkiTemplateVo`.
         * @summary Create a AnkiTemplateVo
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {AnkiTemplateVo} ankiTemplateVo A new &#x60;AnkiTemplateVo&#x60; to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnkiTemplateVo(packageUuid: string, modelId: number, ankiTemplateVo: AnkiTemplateVo, options?: any): AxiosPromise<void> {
            return localVarFp.createAnkiTemplateVo(packageUuid, modelId, ankiTemplateVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建为\"购买订单\"创建”支付凭据“
         * @param {number} orderId 
         * @param {CreatePaymentRequest} createPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionForOrder(orderId: number, createPaymentRequest: CreatePaymentRequest, options?: any): AxiosPromise<CreateTransactionResponse> {
            return localVarFp.createTransactionForOrder(orderId, createPaymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing `AnkiDeckVo`.
         * @summary Delete a AnkiDeckVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiDeckVo(packageUuid: string, deckId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnkiDeckVo(packageUuid, deckId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing `AnkiTemplateVo`.
         * @summary Delete a AnkiTemplateVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
         * @param {number} modelId 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnkiTemplateVo(packageUuid: string, modelId: number, templateId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnkiTemplateVo(packageUuid, modelId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除我的卡包
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyApkg(packageUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMyApkg(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUgcBookRequest} [deleteUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyBooks(deleteUgcBookRequest?: DeleteUgcBookRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMyBooks(deleteUgcBookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 对应的指令是\'jstack -l\'会试图保存所有的线程相关信息
         * @summary 获取服务的thread dump信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDump(options?: any): AxiosPromise<void> {
            return localVarFp.downloadDump(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 延期学习计划（但是不更新每天学习卡数）
         * @param {ExtendStudyPlanRequest} extendStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendStudyPlan(extendStudyPlanRequest: ExtendStudyPlanRequest, options?: any): AxiosPromise<void> {
            return localVarFp.extendStudyPlan(extendStudyPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分页获取图书馆
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] page_size
         * @param {string} [category] 图书分类；如果传入，则显示该分类下的图书
         * @param {string} [categoryLabel] 分类对应的关键词标签
         * @param {number} [userId] 创建者user id。如果传入，则显示创作者名下的图书
         * @param {string} [orderBy] 说明排序的优先级 其中 impressionScoreDesc 印象分降序 impressionScoreAsc 印象分升序  priceDesc表示价格（单买价格和团购价格中最低的一个）降序 priceAsc表明价格升序， learnerNumDesc表示按照学习人数从高到底进行排序，studyTimeDesc表示按照学习时间从多到少，如果为空表示按照默认的 评分（rank_value+manual_value）
         * @param {string} [filterBy] 表明当前过滤的类型， free表示查询单买或拼团价格为0的图书,  vipGroupPass代表拼团图书（对应会员的免拼特权），bundle表明查询合集图书， vipFree 表示查询会员免费学的商品
         * @param {boolean} [showOfficialBook] 是否只展示官方推荐的卡册, 默认是false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLibraryBooks(pageNumber?: number, pageSize?: number, category?: string, categoryLabel?: string, userId?: number, orderBy?: string, filterBy?: string, showOfficialBook?: boolean, options?: any): AxiosPromise<PageLibraryBookVO> {
            return localVarFp.findLibraryBooks(pageNumber, pageSize, category, categoryLabel, userId, orderBy, filterBy, showOfficialBook, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取我购买的所有图书
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] page_size
         * @param {number} [version] 图书版本号，1表示原来只能在线学习的apkg，2表示可以在线离线学习的hpkg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMyPaidBooks(pageNumber?: number, pageSize?: number, version?: number, options?: any): AxiosPromise<PageLibraryBookVO> {
            return localVarFp.findMyPaidBooks(pageNumber, pageSize, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 生成兑换码接口
         * @summary 生成随机码
         * @param {CreateRedeemRequest} [createRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateRedeem(createRedeemRequest?: CreateRedeemRequest, options?: any): AxiosPromise<CreateRedeemResponse> {
            return localVarFp.generateRedeem(createRedeemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 默认返回二十个，返回最旧的 resourceId ，可以继续前翻。订单状态详见任务 HUA-2194
         * @summary 获取我的所有租借订单
         * @param {number} [resourceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMyLease(resourceId?: number, options?: any): AxiosPromise<GetLibraryLeaseListResponse> {
            return localVarFp.getAllMyLease(resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all `AnkiDeckVo` entities.
         * @summary List All AnkiDeckVos
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiDeckVos(packageUuid: string, options?: any): AxiosPromise<Array<AnkiDeckVo>> {
            return localVarFp.getAnkiDeckVos(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all `AnkiTemplateVo` entities.
         * @summary List All AnkiTemplateVos
         * @param {string} packageUuid 
         * @param {number} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnkiTemplateVos(packageUuid: string, modelId: number, options?: any): AxiosPromise<Array<AnkiTemplateVo>> {
            return localVarFp.getAnkiTemplateVos(packageUuid, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取APP信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppInfo(options?: any): AxiosPromise<AppInfoResponse> {
            return localVarFp.getAppInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取文本对应的音频 OSS 链接
         * @summary 获取文本对应的音频 OSS 链接
         * @param {TTSRequest} [tTSRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioLink(tTSRequest?: TTSRequest, options?: any): AxiosPromise<TTSResponse> {
            return localVarFp.getAudioLink(tTSRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取学习计划
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeckStudyPlan(deckId: number, options?: any): AxiosPromise<StudyPlanParam> {
            return localVarFp.getDeckStudyPlan(deckId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取续期天数
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtendDaysCount(deckId: number, options?: any): AxiosPromise<GetDaysExtensionCountResponse> {
            return localVarFp.getExtendDaysCount(deckId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 遗忘曲线数据点
         * @param {ForgettingCurveDotsDataRequest} forgettingCurveDotsDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForgettingCurveDotsData(forgettingCurveDotsDataRequest: ForgettingCurveDotsDataRequest, options?: any): AxiosPromise<ForgettingCurveDotsDataResponse> {
            return localVarFp.getForgettingCurveDotsData(forgettingCurveDotsDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取当前用户对于这个模板的付款信息
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcpInfoByTemplateId(templateId: number, options?: any): AxiosPromise<UserTemplateIcpInfo> {
            return localVarFp.getIcpInfoByTemplateId(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取当前图书的租约以及价格
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryBookSku(libraryBookId: number, options?: any): AxiosPromise<LibraryBookLeaseResponse> {
            return localVarFp.getLibraryBookSku(libraryBookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取图书的详细信息
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryInfo(libraryBookId: number, options?: any): AxiosPromise<GetLibraryBookInfo> {
            return localVarFp.getLibraryInfo(libraryBookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户制作的卡包
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyApkg(options?: any): AxiosPromise<Array<StudyStat>> {
            return localVarFp.getMyApkg(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取我的所有优惠券
         * @param {number} [libraryBookId] 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCouponList(libraryBookId?: number, orderId?: number, options?: any): AxiosPromise<UserCouponListResponse> {
            return localVarFp.getMyCouponList(libraryBookId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取我的学习计划统计数据
         * @param {number} startDate 
         * @param {number} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyStudyStat(startDate: number, endDate: number, options?: any): AxiosPromise<StudyStat> {
            return localVarFp.getMyStudyStat(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary （新版）获取学习计划
         * @param {GetStudyPlanRequest} getStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyPlan(getStudyPlanRequest: GetStudyPlanRequest, options?: any): AxiosPromise<GetStudyPlanResponse> {
            return localVarFp.getStudyPlan(getStudyPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ugcBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUgcBookInfo(ugcBookId: number, options?: any): AxiosPromise<UgcBook> {
            return localVarFp.getUgcBookInfo(ugcBookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 根据cardId获得用户评论
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCardComment(cardId: number, options?: any): AxiosPromise<CardCommentResponse> {
            return localVarFp.getUserCardComment(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户偏好设置
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreference(key: string, options?: any): AxiosPromise<UserPreference> {
            return localVarFp.getUserPreference(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取服务器环境目录
         * @summary 获取目录
         * @param {string} [path] 当前路径名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFiles(path?: string, options?: any): AxiosPromise<void> {
            return localVarFp.listFiles(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyCreateBook(options?: any): AxiosPromise<Array<UgcBook>> {
            return localVarFp.listMyCreateBook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueryUgcBookRequest} [queryUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageMyCreateBook(queryUgcBookRequest?: QueryUgcBookRequest, options?: any): AxiosPromise<PageUgcBook> {
            return localVarFp.listPageMyCreateBook(queryUgcBookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPreferenceReview(options?: any): AxiosPromise<Array<UserPreferenceVO>> {
            return localVarFp.listPreferenceReview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueryRedeemConsumeLogRequest} [queryRedeemConsumeLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedeemConsumeLogPage(queryRedeemConsumeLogRequest?: QueryRedeemConsumeLogRequest, options?: any): AxiosPromise<Array<PageRedeemConsumeLog>> {
            return localVarFp.listRedeemConsumeLogPage(queryRedeemConsumeLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueryRedeemGenerationLogRequest} [queryRedeemGenerationLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedeemGenerateLogPage(queryRedeemGenerationLogRequest?: QueryRedeemGenerationLogRequest, options?: any): AxiosPromise<PageRedeemGenerationLog> {
            return localVarFp.listRedeemGenerateLogPage(queryRedeemGenerationLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueryRedeemRequest} [queryRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRedeemPage(queryRedeemRequest?: QueryRedeemRequest, options?: any): AxiosPromise<PageRedeem> {
            return localVarFp.listRedeemPage(queryRedeemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ankiPackageUuid 
         * @param {string} type type值: STAR 标星, COMMENT 评论, RECENT_LEARNING 最近学习, MASTERED 已掌握, LEARNING 学习中, NEW 未学习, SUSPENDED 已隐藏
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserCardInfoPage(ankiPackageUuid: string, type: string, pageNumber: number, pageSize: number, options?: any): AxiosPromise<PageUserCardInfo> {
            return localVarFp.listUserCardInfoPage(ankiPackageUuid, type, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 下单order - 租借图书
         * @param {number} libraryBookId 
         * @param {CreateOrderRequest} [createOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLibraryBookLease(libraryBookId: number, createOrderRequest?: CreateOrderRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.makeLibraryBookLease(libraryBookId, createOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 下单order - 租借图书, 根据AI推荐来制定图书
         * @param {number} libraryBookId 
         * @param {CreateOrderRequest} [createOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLibraryBookLeaseWithAiPlan(libraryBookId: number, createOrderRequest?: CreateOrderRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.makeLibraryBookLeaseWithAiPlan(libraryBookId, createOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分页获取ankiPackage
         * @param {QueryAnkiPackageRequest} [queryAnkiPackageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageAnkiPackageList(queryAnkiPackageRequest?: QueryAnkiPackageRequest, options?: any): AxiosPromise<PageAnkiPackage> {
            return localVarFp.pageAnkiPackageList(queryAnkiPackageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 追踪用户行为数据
         * @param {UserTraceSpan} userTraceSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserSpan(userTraceSpan: UserTraceSpan, options?: any): AxiosPromise<void> {
            return localVarFp.postUserSpan(userTraceSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 随机获得一句警世名言
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        randomQuote(options?: any): AxiosPromise<QuoteResponse> {
            return localVarFp.randomQuote(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理员修改ugc图书的排序参考和推荐
         * @param {number} ugcBookId 
         * @param {RankUgcBookRequest} rankUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rankUgcBook(ugcBookId: number, rankUgcBookRequest: RankUgcBookRequest, options?: any): AxiosPromise<void> {
            return localVarFp.rankUgcBook(ugcBookId, rankUgcBookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 举报图书
         * @param {number} libraryBookId 
         * @param {LibraryBookReportRequest} [libraryBookReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportBook(libraryBookId: number, libraryBookReportRequest?: LibraryBookReportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reportBook(libraryBookId, libraryBookReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 清空并重置学习计划
         * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetStudyPlan(updateStudyPlanRequest: UpdateStudyPlanRequest, options?: any): AxiosPromise<object> {
            return localVarFp.resetStudyPlan(updateStudyPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ugcBookId 
         * @param {ReviewUgcBookRequest} reviewUgcBookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewUgcBook(ugcBookId: number, reviewUgcBookRequest: ReviewUgcBookRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reviewUgcBook(ugcBookId, reviewUgcBookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary APP 首页搜索
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(q?: string, options?: any): AxiosPromise<AppSearchResponse> {
            return localVarFp.search(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchClickEventRequest} [searchClickEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSearchClickEvent(searchClickEventRequest?: SearchClickEventRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendSearchClickEvent(searchClickEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设置用户偏好
         * @param {string} key 
         * @param {UserPreference} userPreference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPreference(key: string, userPreference: UserPreference, options?: any): AxiosPromise<void> {
            return localVarFp.setUserPreference(key, userPreference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 苹果登录或绑定至登录中的账号
         * @param {SignInAppleRequest} signInAppleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByApple(signInAppleRequest: SignInAppleRequest, options?: any): AxiosPromise<SignInThirdPartyResponse> {
            return localVarFp.signInByApple(signInAppleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 通过QQ登录或绑定至登录中的账号
         * @param {SignInQQRequest} [signInQQRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByQQ(signInQQRequest?: SignInQQRequest, options?: any): AxiosPromise<SignInThirdPartyResponse> {
            return localVarFp.signInByQQ(signInQQRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 微信登录或绑定至登录中的账号；或直接根据微信user_info创建一个新账号
         * @param {SignInWeChatRequest} signInWeChatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInByWeChat(signInWeChatRequest: SignInWeChatRequest, options?: any): AxiosPromise<SignInThirdPartyResponse> {
            return localVarFp.signInByWeChat(signInWeChatRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取消苹果登录的绑定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindAppleId(options?: any): AxiosPromise<void> {
            return localVarFp.unbindAppleId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 解除QQ绑定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindQQ(options?: any): AxiosPromise<void> {
            return localVarFp.unbindQQ(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 解除微信登录
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindWeChat(options?: any): AxiosPromise<void> {
            return localVarFp.unbindWeChat(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 解锁单张卡片
         * @param {number} templateId 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockSingleCard(templateId: number, cardId: number, options?: any): AxiosPromise<void> {
            return localVarFp.unlockSingleCard(templateId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 请求解锁该卡包模板
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockTemplateIcp(templateId: number, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.unlockTemplateIcp(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing `AnkiDeckVo`.
         * @summary Update a AnkiDeckVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
         * @param {number} deckId 
         * @param {AnkiDeckVo} ankiDeckVo Updated &#x60;AnkiDeckVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiDeckVo(packageUuid: string, deckId: number, ankiDeckVo: AnkiDeckVo, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnkiDeckVo(packageUuid, deckId, ankiDeckVo, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing `AnkiTemplateVo`.
         * @summary Update a AnkiTemplateVo
         * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
         * @param {number} modelId 
         * @param {number} templateId 
         * @param {AnkiTemplateVo} ankiTemplateVo Updated &#x60;AnkiTemplateVo&#x60; information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnkiTemplateVo(packageUuid: string, modelId: number, templateId: number, ankiTemplateVo: AnkiTemplateVo, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnkiTemplateVo(packageUuid, modelId, templateId, ankiTemplateVo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改个人信息
         * @param {UpdateMyInfoRequest} updateMyInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyInfo(updateMyInfoRequest: UpdateMyInfoRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateMyInfo(updateMyInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UserPreference} [userPreference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferenceValue(id: number, userPreference?: UserPreference, options?: any): AxiosPromise<void> {
            return localVarFp.updatePreferenceValue(id, userPreference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新学习计划
         * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudyPlan(updateStudyPlanRequest: UpdateStudyPlanRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateStudyPlan(updateStudyPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 编辑ugcBook
         * @param {number} ugcBookId 
         * @param {UpdateUgcBookRequest} [updateUgcBookRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUgcBook(ugcBookId: number, updateUgcBookRequest?: UpdateUgcBookRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateUgcBook(ugcBookId, updateUgcBookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {UpdateUgcBookRequest} updateUgcBookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addUgcBook(updateUgcBookRequest: UpdateUgcBookRequest, options?: any) {
        return DefaultApiFp(this.configuration).addUgcBook(updateUgcBookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 根据ankiPackageId获得ankiPackage信息
     * @summary 获得package上传的基本信息
     * @param {number} ankiPackageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apkgGetPackage(ankiPackageId: number, options?: any) {
        return DefaultApiFp(this.configuration).apkgGetPackage(ankiPackageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConsumeRedeemCodeRequest} [consumeRedeemCodeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public consumeRedeemCode(consumeRedeemCodeRequest?: ConsumeRedeemCodeRequest, options?: any) {
        return DefaultApiFp(this.configuration).consumeRedeemCode(consumeRedeemCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new instance of a `AnkiDeckVo`.
     * @summary Create a AnkiDeckVo
     * @param {string} packageUuid 
     * @param {AnkiDeckVo} ankiDeckVo A new &#x60;AnkiDeckVo&#x60; to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAnkiDeckVo(packageUuid: string, ankiDeckVo: AnkiDeckVo, options?: any) {
        return DefaultApiFp(this.configuration).createAnkiDeckVo(packageUuid, ankiDeckVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new instance of a `AnkiTemplateVo`.
     * @summary Create a AnkiTemplateVo
     * @param {string} packageUuid 
     * @param {number} modelId 
     * @param {AnkiTemplateVo} ankiTemplateVo A new &#x60;AnkiTemplateVo&#x60; to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAnkiTemplateVo(packageUuid: string, modelId: number, ankiTemplateVo: AnkiTemplateVo, options?: any) {
        return DefaultApiFp(this.configuration).createAnkiTemplateVo(packageUuid, modelId, ankiTemplateVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建为\"购买订单\"创建”支付凭据“
     * @param {number} orderId 
     * @param {CreatePaymentRequest} createPaymentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTransactionForOrder(orderId: number, createPaymentRequest: CreatePaymentRequest, options?: any) {
        return DefaultApiFp(this.configuration).createTransactionForOrder(orderId, createPaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing `AnkiDeckVo`.
     * @summary Delete a AnkiDeckVo
     * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
     * @param {number} deckId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAnkiDeckVo(packageUuid: string, deckId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteAnkiDeckVo(packageUuid, deckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing `AnkiTemplateVo`.
     * @summary Delete a AnkiTemplateVo
     * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
     * @param {number} modelId 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAnkiTemplateVo(packageUuid: string, modelId: number, templateId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteAnkiTemplateVo(packageUuid, modelId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除我的卡包
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMyApkg(packageUuid: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteMyApkg(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUgcBookRequest} [deleteUgcBookRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMyBooks(deleteUgcBookRequest?: DeleteUgcBookRequest, options?: any) {
        return DefaultApiFp(this.configuration).deleteMyBooks(deleteUgcBookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 对应的指令是\'jstack -l\'会试图保存所有的线程相关信息
     * @summary 获取服务的thread dump信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadDump(options?: any) {
        return DefaultApiFp(this.configuration).downloadDump(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 延期学习计划（但是不更新每天学习卡数）
     * @param {ExtendStudyPlanRequest} extendStudyPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public extendStudyPlan(extendStudyPlanRequest: ExtendStudyPlanRequest, options?: any) {
        return DefaultApiFp(this.configuration).extendStudyPlan(extendStudyPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分页获取图书馆
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] page_size
     * @param {string} [category] 图书分类；如果传入，则显示该分类下的图书
     * @param {string} [categoryLabel] 分类对应的关键词标签
     * @param {number} [userId] 创建者user id。如果传入，则显示创作者名下的图书
     * @param {string} [orderBy] 说明排序的优先级 其中 impressionScoreDesc 印象分降序 impressionScoreAsc 印象分升序  priceDesc表示价格（单买价格和团购价格中最低的一个）降序 priceAsc表明价格升序， learnerNumDesc表示按照学习人数从高到底进行排序，studyTimeDesc表示按照学习时间从多到少，如果为空表示按照默认的 评分（rank_value+manual_value）
     * @param {string} [filterBy] 表明当前过滤的类型， free表示查询单买或拼团价格为0的图书,  vipGroupPass代表拼团图书（对应会员的免拼特权），bundle表明查询合集图书， vipFree 表示查询会员免费学的商品
     * @param {boolean} [showOfficialBook] 是否只展示官方推荐的卡册, 默认是false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findLibraryBooks(pageNumber?: number, pageSize?: number, category?: string, categoryLabel?: string, userId?: number, orderBy?: string, filterBy?: string, showOfficialBook?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).findLibraryBooks(pageNumber, pageSize, category, categoryLabel, userId, orderBy, filterBy, showOfficialBook, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取我购买的所有图书
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] page_size
     * @param {number} [version] 图书版本号，1表示原来只能在线学习的apkg，2表示可以在线离线学习的hpkg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public findMyPaidBooks(pageNumber?: number, pageSize?: number, version?: number, options?: any) {
        return DefaultApiFp(this.configuration).findMyPaidBooks(pageNumber, pageSize, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 生成兑换码接口
     * @summary 生成随机码
     * @param {CreateRedeemRequest} [createRedeemRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateRedeem(createRedeemRequest?: CreateRedeemRequest, options?: any) {
        return DefaultApiFp(this.configuration).generateRedeem(createRedeemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 默认返回二十个，返回最旧的 resourceId ，可以继续前翻。订单状态详见任务 HUA-2194
     * @summary 获取我的所有租借订单
     * @param {number} [resourceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllMyLease(resourceId?: number, options?: any) {
        return DefaultApiFp(this.configuration).getAllMyLease(resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all `AnkiDeckVo` entities.
     * @summary List All AnkiDeckVos
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnkiDeckVos(packageUuid: string, options?: any) {
        return DefaultApiFp(this.configuration).getAnkiDeckVos(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all `AnkiTemplateVo` entities.
     * @summary List All AnkiTemplateVos
     * @param {string} packageUuid 
     * @param {number} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnkiTemplateVos(packageUuid: string, modelId: number, options?: any) {
        return DefaultApiFp(this.configuration).getAnkiTemplateVos(packageUuid, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取APP信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAppInfo(options?: any) {
        return DefaultApiFp(this.configuration).getAppInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取文本对应的音频 OSS 链接
     * @summary 获取文本对应的音频 OSS 链接
     * @param {TTSRequest} [tTSRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAudioLink(tTSRequest?: TTSRequest, options?: any) {
        return DefaultApiFp(this.configuration).getAudioLink(tTSRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取学习计划
     * @param {number} deckId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDeckStudyPlan(deckId: number, options?: any) {
        return DefaultApiFp(this.configuration).getDeckStudyPlan(deckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取续期天数
     * @param {number} deckId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getExtendDaysCount(deckId: number, options?: any) {
        return DefaultApiFp(this.configuration).getExtendDaysCount(deckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 遗忘曲线数据点
     * @param {ForgettingCurveDotsDataRequest} forgettingCurveDotsDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getForgettingCurveDotsData(forgettingCurveDotsDataRequest: ForgettingCurveDotsDataRequest, options?: any) {
        return DefaultApiFp(this.configuration).getForgettingCurveDotsData(forgettingCurveDotsDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取当前用户对于这个模板的付款信息
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getIcpInfoByTemplateId(templateId: number, options?: any) {
        return DefaultApiFp(this.configuration).getIcpInfoByTemplateId(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取当前图书的租约以及价格
     * @param {number} libraryBookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLibraryBookSku(libraryBookId: number, options?: any) {
        return DefaultApiFp(this.configuration).getLibraryBookSku(libraryBookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取图书的详细信息
     * @param {number} libraryBookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLibraryInfo(libraryBookId: number, options?: any) {
        return DefaultApiFp(this.configuration).getLibraryInfo(libraryBookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户制作的卡包
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyApkg(options?: any) {
        return DefaultApiFp(this.configuration).getMyApkg(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取我的所有优惠券
     * @param {number} [libraryBookId] 
     * @param {number} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyCouponList(libraryBookId?: number, orderId?: number, options?: any) {
        return DefaultApiFp(this.configuration).getMyCouponList(libraryBookId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取我的学习计划统计数据
     * @param {number} startDate 
     * @param {number} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyStudyStat(startDate: number, endDate: number, options?: any) {
        return DefaultApiFp(this.configuration).getMyStudyStat(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary （新版）获取学习计划
     * @param {GetStudyPlanRequest} getStudyPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStudyPlan(getStudyPlanRequest: GetStudyPlanRequest, options?: any) {
        return DefaultApiFp(this.configuration).getStudyPlan(getStudyPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ugcBookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUgcBookInfo(ugcBookId: number, options?: any) {
        return DefaultApiFp(this.configuration).getUgcBookInfo(ugcBookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 根据cardId获得用户评论
     * @param {number} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserCardComment(cardId: number, options?: any) {
        return DefaultApiFp(this.configuration).getUserCardComment(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户偏好设置
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserPreference(key: string, options?: any) {
        return DefaultApiFp(this.configuration).getUserPreference(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取服务器环境目录
     * @summary 获取目录
     * @param {string} [path] 当前路径名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listFiles(path?: string, options?: any) {
        return DefaultApiFp(this.configuration).listFiles(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listMyCreateBook(options?: any) {
        return DefaultApiFp(this.configuration).listMyCreateBook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueryUgcBookRequest} [queryUgcBookRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPageMyCreateBook(queryUgcBookRequest?: QueryUgcBookRequest, options?: any) {
        return DefaultApiFp(this.configuration).listPageMyCreateBook(queryUgcBookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPreferenceReview(options?: any) {
        return DefaultApiFp(this.configuration).listPreferenceReview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueryRedeemConsumeLogRequest} [queryRedeemConsumeLogRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRedeemConsumeLogPage(queryRedeemConsumeLogRequest?: QueryRedeemConsumeLogRequest, options?: any) {
        return DefaultApiFp(this.configuration).listRedeemConsumeLogPage(queryRedeemConsumeLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueryRedeemGenerationLogRequest} [queryRedeemGenerationLogRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRedeemGenerateLogPage(queryRedeemGenerationLogRequest?: QueryRedeemGenerationLogRequest, options?: any) {
        return DefaultApiFp(this.configuration).listRedeemGenerateLogPage(queryRedeemGenerationLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueryRedeemRequest} [queryRedeemRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRedeemPage(queryRedeemRequest?: QueryRedeemRequest, options?: any) {
        return DefaultApiFp(this.configuration).listRedeemPage(queryRedeemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ankiPackageUuid 
     * @param {string} type type值: STAR 标星, COMMENT 评论, RECENT_LEARNING 最近学习, MASTERED 已掌握, LEARNING 学习中, NEW 未学习, SUSPENDED 已隐藏
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listUserCardInfoPage(ankiPackageUuid: string, type: string, pageNumber: number, pageSize: number, options?: any) {
        return DefaultApiFp(this.configuration).listUserCardInfoPage(ankiPackageUuid, type, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 下单order - 租借图书
     * @param {number} libraryBookId 
     * @param {CreateOrderRequest} [createOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makeLibraryBookLease(libraryBookId: number, createOrderRequest?: CreateOrderRequest, options?: any) {
        return DefaultApiFp(this.configuration).makeLibraryBookLease(libraryBookId, createOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 下单order - 租借图书, 根据AI推荐来制定图书
     * @param {number} libraryBookId 
     * @param {CreateOrderRequest} [createOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public makeLibraryBookLeaseWithAiPlan(libraryBookId: number, createOrderRequest?: CreateOrderRequest, options?: any) {
        return DefaultApiFp(this.configuration).makeLibraryBookLeaseWithAiPlan(libraryBookId, createOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分页获取ankiPackage
     * @param {QueryAnkiPackageRequest} [queryAnkiPackageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pageAnkiPackageList(queryAnkiPackageRequest?: QueryAnkiPackageRequest, options?: any) {
        return DefaultApiFp(this.configuration).pageAnkiPackageList(queryAnkiPackageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 追踪用户行为数据
     * @param {UserTraceSpan} userTraceSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postUserSpan(userTraceSpan: UserTraceSpan, options?: any) {
        return DefaultApiFp(this.configuration).postUserSpan(userTraceSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 随机获得一句警世名言
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public randomQuote(options?: any) {
        return DefaultApiFp(this.configuration).randomQuote(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理员修改ugc图书的排序参考和推荐
     * @param {number} ugcBookId 
     * @param {RankUgcBookRequest} rankUgcBookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rankUgcBook(ugcBookId: number, rankUgcBookRequest: RankUgcBookRequest, options?: any) {
        return DefaultApiFp(this.configuration).rankUgcBook(ugcBookId, rankUgcBookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 举报图书
     * @param {number} libraryBookId 
     * @param {LibraryBookReportRequest} [libraryBookReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportBook(libraryBookId: number, libraryBookReportRequest?: LibraryBookReportRequest, options?: any) {
        return DefaultApiFp(this.configuration).reportBook(libraryBookId, libraryBookReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 清空并重置学习计划
     * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public resetStudyPlan(updateStudyPlanRequest: UpdateStudyPlanRequest, options?: any) {
        return DefaultApiFp(this.configuration).resetStudyPlan(updateStudyPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ugcBookId 
     * @param {ReviewUgcBookRequest} reviewUgcBookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reviewUgcBook(ugcBookId: number, reviewUgcBookRequest: ReviewUgcBookRequest, options?: any) {
        return DefaultApiFp(this.configuration).reviewUgcBook(ugcBookId, reviewUgcBookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary APP 首页搜索
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public search(q?: string, options?: any) {
        return DefaultApiFp(this.configuration).search(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchClickEventRequest} [searchClickEventRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendSearchClickEvent(searchClickEventRequest?: SearchClickEventRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendSearchClickEvent(searchClickEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设置用户偏好
     * @param {string} key 
     * @param {UserPreference} userPreference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setUserPreference(key: string, userPreference: UserPreference, options?: any) {
        return DefaultApiFp(this.configuration).setUserPreference(key, userPreference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 苹果登录或绑定至登录中的账号
     * @param {SignInAppleRequest} signInAppleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public signInByApple(signInAppleRequest: SignInAppleRequest, options?: any) {
        return DefaultApiFp(this.configuration).signInByApple(signInAppleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 通过QQ登录或绑定至登录中的账号
     * @param {SignInQQRequest} [signInQQRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public signInByQQ(signInQQRequest?: SignInQQRequest, options?: any) {
        return DefaultApiFp(this.configuration).signInByQQ(signInQQRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 微信登录或绑定至登录中的账号；或直接根据微信user_info创建一个新账号
     * @param {SignInWeChatRequest} signInWeChatRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public signInByWeChat(signInWeChatRequest: SignInWeChatRequest, options?: any) {
        return DefaultApiFp(this.configuration).signInByWeChat(signInWeChatRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取消苹果登录的绑定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unbindAppleId(options?: any) {
        return DefaultApiFp(this.configuration).unbindAppleId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 解除QQ绑定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unbindQQ(options?: any) {
        return DefaultApiFp(this.configuration).unbindQQ(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 解除微信登录
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unbindWeChat(options?: any) {
        return DefaultApiFp(this.configuration).unbindWeChat(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 解锁单张卡片
     * @param {number} templateId 
     * @param {number} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unlockSingleCard(templateId: number, cardId: number, options?: any) {
        return DefaultApiFp(this.configuration).unlockSingleCard(templateId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 请求解锁该卡包模板
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unlockTemplateIcp(templateId: number, options?: any) {
        return DefaultApiFp(this.configuration).unlockTemplateIcp(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing `AnkiDeckVo`.
     * @summary Update a AnkiDeckVo
     * @param {string} packageUuid A unique identifier for a &#x60;AnkiDeckVo&#x60;.
     * @param {number} deckId 
     * @param {AnkiDeckVo} ankiDeckVo Updated &#x60;AnkiDeckVo&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAnkiDeckVo(packageUuid: string, deckId: number, ankiDeckVo: AnkiDeckVo, options?: any) {
        return DefaultApiFp(this.configuration).updateAnkiDeckVo(packageUuid, deckId, ankiDeckVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing `AnkiTemplateVo`.
     * @summary Update a AnkiTemplateVo
     * @param {string} packageUuid A unique identifier for a &#x60;AnkiTemplateVo&#x60;.
     * @param {number} modelId 
     * @param {number} templateId 
     * @param {AnkiTemplateVo} ankiTemplateVo Updated &#x60;AnkiTemplateVo&#x60; information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAnkiTemplateVo(packageUuid: string, modelId: number, templateId: number, ankiTemplateVo: AnkiTemplateVo, options?: any) {
        return DefaultApiFp(this.configuration).updateAnkiTemplateVo(packageUuid, modelId, templateId, ankiTemplateVo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改个人信息
     * @param {UpdateMyInfoRequest} updateMyInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateMyInfo(updateMyInfoRequest: UpdateMyInfoRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateMyInfo(updateMyInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UserPreference} [userPreference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updatePreferenceValue(id: number, userPreference?: UserPreference, options?: any) {
        return DefaultApiFp(this.configuration).updatePreferenceValue(id, userPreference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新学习计划
     * @param {UpdateStudyPlanRequest} updateStudyPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateStudyPlan(updateStudyPlanRequest: UpdateStudyPlanRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateStudyPlan(updateStudyPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 编辑ugcBook
     * @param {number} ugcBookId 
     * @param {UpdateUgcBookRequest} [updateUgcBookRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUgcBook(ugcBookId: number, updateUgcBookRequest?: UpdateUgcBookRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateUgcBook(ugcBookId, updateUgcBookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemoApi - axios parameter creator
 * @export
 */
export const DemoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoTest: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/demo/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoApi - functional programming interface
 * @export
 */
export const DemoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoTest(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.demoTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemoApi - factory interface
 * @export
 */
export const DemoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoTest(options?: any): AxiosPromise<void> {
            return localVarFp.demoTest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemoApi - object-oriented interface
 * @export
 * @class DemoApi
 * @extends {BaseAPI}
 */
export class DemoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoTest(options?: any) {
        return DemoApiFp(this.configuration).demoTest(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictApi - axios parameter creator
 * @export
 */
export const DictApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 根据id获取诗歌内容
         * @param {number} id 诗歌记录对应的id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoetryDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoetryDetail', 'id', id)
            const localVarPath = `/dict/v1/poetry/detail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询该单词的详细信息
         * @param {string} keyword 要查询的单词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWordDetail: async (keyword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getWordDetail', 'keyword', keyword)
            const localVarPath = `/dict/v1/word/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据关键词搜索诗歌列表
         * @param {string} keyword 搜索关键词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPoetries: async (keyword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('searchPoetries', 'keyword', keyword)
            const localVarPath = `/dict/v1/poetry/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据关键词搜索单词列表
         * @param {string} keyword 搜索关键词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWords: async (keyword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('searchWords', 'keyword', keyword)
            const localVarPath = `/dict/v1/word/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictApi - functional programming interface
 * @export
 */
export const DictApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 根据id获取诗歌内容
         * @param {number} id 诗歌记录对应的id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoetryDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoetryDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoetryDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询该单词的详细信息
         * @param {string} keyword 要查询的单词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWordDetail(keyword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnWordDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWordDetail(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据关键词搜索诗歌列表
         * @param {string} keyword 搜索关键词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPoetries(keyword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoetryDetailListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPoetries(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据关键词搜索单词列表
         * @param {string} keyword 搜索关键词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchWords(keyword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnWordListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchWords(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictApi - factory interface
 * @export
 */
export const DictApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictApiFp(configuration)
    return {
        /**
         * 
         * @summary 根据id获取诗歌内容
         * @param {number} id 诗歌记录对应的id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoetryDetail(id: number, options?: any): AxiosPromise<PoetryDetailResponse> {
            return localVarFp.getPoetryDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询该单词的详细信息
         * @param {string} keyword 要查询的单词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWordDetail(keyword: string, options?: any): AxiosPromise<EnWordDetailResponse> {
            return localVarFp.getWordDetail(keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据关键词搜索诗歌列表
         * @param {string} keyword 搜索关键词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPoetries(keyword: string, options?: any): AxiosPromise<PoetryDetailListResponse> {
            return localVarFp.searchPoetries(keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据关键词搜索单词列表
         * @param {string} keyword 搜索关键词
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWords(keyword: string, options?: any): AxiosPromise<EnWordListResponse> {
            return localVarFp.searchWords(keyword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictApi - object-oriented interface
 * @export
 * @class DictApi
 * @extends {BaseAPI}
 */
export class DictApi extends BaseAPI {
    /**
     * 
     * @summary 根据id获取诗歌内容
     * @param {number} id 诗歌记录对应的id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictApi
     */
    public getPoetryDetail(id: number, options?: any) {
        return DictApiFp(this.configuration).getPoetryDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询该单词的详细信息
     * @param {string} keyword 要查询的单词
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictApi
     */
    public getWordDetail(keyword: string, options?: any) {
        return DictApiFp(this.configuration).getWordDetail(keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据关键词搜索诗歌列表
     * @param {string} keyword 搜索关键词
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictApi
     */
    public searchPoetries(keyword: string, options?: any) {
        return DictApiFp(this.configuration).searchPoetries(keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据关键词搜索单词列表
     * @param {string} keyword 搜索关键词
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictApi
     */
    public searchWords(keyword: string, options?: any) {
        return DictApiFp(this.configuration).searchWords(keyword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EggApi - axios parameter creator
 * @export
 */
export const EggApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 添加信件
         * @param {CreateEggLetterRequest} [createEggLetterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEggLetter: async (createEggLetterRequest?: CreateEggLetterRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/egg/v1/letter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEggLetterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除信件
         * @param {number} letterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEggLetter: async (letterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'letterId' is not null or undefined
            assertParamExists('deleteEggLetter', 'letterId', letterId)
            const localVarPath = `/egg/v1/letter/{letter_id}`
                .replace(`{${"letter_id"}}`, encodeURIComponent(String(letterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取彩蛋信件内容，会填充用户名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEggLetter: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/egg/v1/letter/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取彩蛋信件列表，默认只返回可见的信件，不分页
         * @param {boolean} [showInvisible] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEggLetters: async (showInvisible?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/egg/v1/letter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showInvisible !== undefined) {
                localVarQueryParameter['showInvisible'] = showInvisible;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 编辑信件
         * @param {number} letterId 
         * @param {UpdateEggLetterRequest} [updateEggLetterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEggLetter: async (letterId: number, updateEggLetterRequest?: UpdateEggLetterRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'letterId' is not null or undefined
            assertParamExists('updateEggLetter', 'letterId', letterId)
            const localVarPath = `/egg/v1/letter/{letter_id}`
                .replace(`{${"letter_id"}}`, encodeURIComponent(String(letterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEggLetterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EggApi - functional programming interface
 * @export
 */
export const EggApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EggApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 添加信件
         * @param {CreateEggLetterRequest} [createEggLetterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEggLetter(createEggLetterRequest?: CreateEggLetterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEggLetter(createEggLetterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除信件
         * @param {number} letterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEggLetter(letterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEggLetter(letterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取彩蛋信件内容，会填充用户名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyEggLetter(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EggLetter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyEggLetter(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取彩蛋信件列表，默认只返回可见的信件，不分页
         * @param {boolean} [showInvisible] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEggLetters(showInvisible?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEggLetterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEggLetters(showInvisible, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 编辑信件
         * @param {number} letterId 
         * @param {UpdateEggLetterRequest} [updateEggLetterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEggLetter(letterId: number, updateEggLetterRequest?: UpdateEggLetterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEggLetter(letterId, updateEggLetterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EggApi - factory interface
 * @export
 */
export const EggApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EggApiFp(configuration)
    return {
        /**
         * 
         * @summary 添加信件
         * @param {CreateEggLetterRequest} [createEggLetterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEggLetter(createEggLetterRequest?: CreateEggLetterRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createEggLetter(createEggLetterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除信件
         * @param {number} letterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEggLetter(letterId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEggLetter(letterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取彩蛋信件内容，会填充用户名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEggLetter(options?: any): AxiosPromise<EggLetter> {
            return localVarFp.getMyEggLetter(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取彩蛋信件列表，默认只返回可见的信件，不分页
         * @param {boolean} [showInvisible] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEggLetters(showInvisible?: boolean, options?: any): AxiosPromise<ListEggLetterResponse> {
            return localVarFp.listEggLetters(showInvisible, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 编辑信件
         * @param {number} letterId 
         * @param {UpdateEggLetterRequest} [updateEggLetterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEggLetter(letterId: number, updateEggLetterRequest?: UpdateEggLetterRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateEggLetter(letterId, updateEggLetterRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EggApi - object-oriented interface
 * @export
 * @class EggApi
 * @extends {BaseAPI}
 */
export class EggApi extends BaseAPI {
    /**
     * 
     * @summary 添加信件
     * @param {CreateEggLetterRequest} [createEggLetterRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggApi
     */
    public createEggLetter(createEggLetterRequest?: CreateEggLetterRequest, options?: any) {
        return EggApiFp(this.configuration).createEggLetter(createEggLetterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除信件
     * @param {number} letterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggApi
     */
    public deleteEggLetter(letterId: number, options?: any) {
        return EggApiFp(this.configuration).deleteEggLetter(letterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取彩蛋信件内容，会填充用户名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggApi
     */
    public getMyEggLetter(options?: any) {
        return EggApiFp(this.configuration).getMyEggLetter(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取彩蛋信件列表，默认只返回可见的信件，不分页
     * @param {boolean} [showInvisible] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggApi
     */
    public listEggLetters(showInvisible?: boolean, options?: any) {
        return EggApiFp(this.configuration).listEggLetters(showInvisible, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 编辑信件
     * @param {number} letterId 
     * @param {UpdateEggLetterRequest} [updateEggLetterRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggApi
     */
    public updateEggLetter(letterId: number, updateEggLetterRequest?: UpdateEggLetterRequest, options?: any) {
        return EggApiFp(this.configuration).updateEggLetter(letterId, updateEggLetterRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EventLogListRequest} eventLogListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUploadEventLog: async (eventLogListRequest: EventLogListRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventLogListRequest' is not null or undefined
            assertParamExists('bulkUploadEventLog', 'eventLogListRequest', eventLogListRequest)
            const localVarPath = `/event/v1/bulk_upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventLogListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 上传学习起止事件
         * @param {RateStartAndEventRequest} rateStartAndEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRateStartAndEndEvent: async (rateStartAndEventRequest: RateStartAndEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rateStartAndEventRequest' is not null or undefined
            assertParamExists('uploadRateStartAndEndEvent', 'rateStartAndEventRequest', rateStartAndEventRequest)
            const localVarPath = `/event/v1/rate_start_end_event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rateStartAndEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EventLogListRequest} eventLogListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUploadEventLog(eventLogListRequest: EventLogListRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUploadEventLog(eventLogListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 上传学习起止事件
         * @param {RateStartAndEventRequest} rateStartAndEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadRateStartAndEndEvent(rateStartAndEventRequest: RateStartAndEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadRateStartAndEndEvent(rateStartAndEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @param {EventLogListRequest} eventLogListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUploadEventLog(eventLogListRequest: EventLogListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bulkUploadEventLog(eventLogListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 上传学习起止事件
         * @param {RateStartAndEventRequest} rateStartAndEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRateStartAndEndEvent(rateStartAndEventRequest: RateStartAndEventRequest, options?: any): AxiosPromise<void> {
            return localVarFp.uploadRateStartAndEndEvent(rateStartAndEventRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @param {EventLogListRequest} eventLogListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public bulkUploadEventLog(eventLogListRequest: EventLogListRequest, options?: any) {
        return EventApiFp(this.configuration).bulkUploadEventLog(eventLogListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 上传学习起止事件
     * @param {RateStartAndEventRequest} rateStartAndEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public uploadRateStartAndEndEvent(rateStartAndEventRequest: RateStartAndEventRequest, options?: any) {
        return EventApiFp(this.configuration).uploadRateStartAndEndEvent(rateStartAndEventRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取图书好评/差评率
         * @param {number} bookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookRemarkRatio: async (bookId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookId' is not null or undefined
            assertParamExists('getBookRemarkRatio', 'bookId', bookId)
            const localVarPath = `/feedback/v1/remark_ratio/{book_id}`
                .replace(`{${"book_id"}}`, encodeURIComponent(String(bookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查看某本图书的用户感谢列表
         * @param {number} libraryBookId 
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeedBackThanksAuthor: async (libraryBookId: number, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('listFeedBackThanksAuthor', 'libraryBookId', libraryBookId)
            const localVarPath = `/feedback/v1/public/library_book/{library_book_id}`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询所有反馈（管理员）
         * @param {'read' | 'unread' | 'replied' | 'ignored' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部, replied 已回复 ignored 已忽略
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [content] 反馈内容
         * @param {string} [reportUserNickname] 反馈用户的名称
         * @param {string} [libraryBookTitle] 反馈图书的标题
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageAllFeedback: async (status: 'read' | 'unread' | 'replied' | 'ignored' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('queryPageAllFeedback', 'status', status)
            // verify required parameter 'queryPageRequest' is not null or undefined
            assertParamExists('queryPageAllFeedback', 'queryPageRequest', queryPageRequest)
            const localVarPath = `/feedback/v1/query/admin/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (reportUserNickname !== undefined) {
                localVarQueryParameter['report_user_nickname'] = reportUserNickname;
            }

            if (libraryBookTitle !== undefined) {
                localVarQueryParameter['library_book_title'] = libraryBookTitle;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 管理员，或者看APP使用反馈时，不要用这个接口  创作者使用“内容纠错”时，设置type=内容纠错；可选参数：report_user_nickname，library_book_title，content  创作者使用“感谢作者”时，设置type=感谢作者；可选参数：library_book_title，filter_shown
         * @summary 创作者查询自己图书的反馈
         * @param {'read' | 'unread' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [content] 反馈内容
         * @param {string} [reportUserNickname] 反馈用户的名称
         * @param {string} [libraryBookTitle] 反馈图书的标题
         * @param {string} [type] 反馈类型 包括 内容纠错，感谢作者
         * @param {boolean} [filterShown] 是否过滤出展示给用的的数据 true只列出 展示给用户的数据 false 不列出展示给用户的数据
         * @param {string} [remarkType] 筛选好评或差评 若为空则是全部 可选值：GOOD/BAD
         * @param {number} [bookId] 需要查询的图书id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageCreatorFeedback: async (status: 'read' | 'unread' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, type?: string, filterShown?: boolean, remarkType?: string, bookId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('queryPageCreatorFeedback', 'status', status)
            // verify required parameter 'queryPageRequest' is not null or undefined
            assertParamExists('queryPageCreatorFeedback', 'queryPageRequest', queryPageRequest)
            const localVarPath = `/feedback/v1/query/creator/{status}`
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (reportUserNickname !== undefined) {
                localVarQueryParameter['report_user_nickname'] = reportUserNickname;
            }

            if (libraryBookTitle !== undefined) {
                localVarQueryParameter['library_book_title'] = libraryBookTitle;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (filterShown !== undefined) {
                localVarQueryParameter['filter_shown'] = filterShown;
            }

            if (remarkType !== undefined) {
                localVarQueryParameter['remark_type'] = remarkType;
            }

            if (bookId !== undefined) {
                localVarQueryParameter['book_id'] = bookId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 修改状态
         * @summary 修改反馈状态
         * @param {number} feedbackId 
         * @param {UpdateFeedbackStatueRequest} [updateFeedbackStatueRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readFeedback: async (feedbackId: number, updateFeedbackStatueRequest?: UpdateFeedbackStatueRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedbackId' is not null or undefined
            assertParamExists('readFeedback', 'feedbackId', feedbackId)
            const localVarPath = `/feedback/v1/read/{feedback_id}`
                .replace(`{${"feedback_id"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedbackStatueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 提交反馈/用户评价
         * @param {FeedbackUpdateVO} feedbackUpdateVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportFeedback: async (feedbackUpdateVO: FeedbackUpdateVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedbackUpdateVO' is not null or undefined
            assertParamExists('reportFeedback', 'feedbackUpdateVO', feedbackUpdateVO)
            const localVarPath = `/feedback/v1/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedbackUpdateVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改feedback(包括修改展示或者不展示)
         * @param {number} feedbackId 
         * @param {FeedbackUpdateVO} [feedbackUpdateVO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedbackInfo: async (feedbackId: number, feedbackUpdateVO?: FeedbackUpdateVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedbackId' is not null or undefined
            assertParamExists('updateFeedbackInfo', 'feedbackId', feedbackId)
            const localVarPath = `/feedback/v1/feedback/{feedback_id}`
                .replace(`{${"feedback_id"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedbackUpdateVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取图书好评/差评率
         * @param {number} bookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookRemarkRatio(bookId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRemarkRatioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookRemarkRatio(bookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查看某本图书的用户感谢列表
         * @param {number} libraryBookId 
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFeedBackThanksAuthor(libraryBookId: number, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFeedbackResponseVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFeedBackThanksAuthor(libraryBookId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询所有反馈（管理员）
         * @param {'read' | 'unread' | 'replied' | 'ignored' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部, replied 已回复 ignored 已忽略
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [content] 反馈内容
         * @param {string} [reportUserNickname] 反馈用户的名称
         * @param {string} [libraryBookTitle] 反馈图书的标题
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPageAllFeedback(status: 'read' | 'unread' | 'replied' | 'ignored' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFeedbackResponseVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPageAllFeedback(status, queryPageRequest, content, reportUserNickname, libraryBookTitle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 管理员，或者看APP使用反馈时，不要用这个接口  创作者使用“内容纠错”时，设置type=内容纠错；可选参数：report_user_nickname，library_book_title，content  创作者使用“感谢作者”时，设置type=感谢作者；可选参数：library_book_title，filter_shown
         * @summary 创作者查询自己图书的反馈
         * @param {'read' | 'unread' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [content] 反馈内容
         * @param {string} [reportUserNickname] 反馈用户的名称
         * @param {string} [libraryBookTitle] 反馈图书的标题
         * @param {string} [type] 反馈类型 包括 内容纠错，感谢作者
         * @param {boolean} [filterShown] 是否过滤出展示给用的的数据 true只列出 展示给用户的数据 false 不列出展示给用户的数据
         * @param {string} [remarkType] 筛选好评或差评 若为空则是全部 可选值：GOOD/BAD
         * @param {number} [bookId] 需要查询的图书id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPageCreatorFeedback(status: 'read' | 'unread' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, type?: string, filterShown?: boolean, remarkType?: string, bookId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFeedbackResponseVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPageCreatorFeedback(status, queryPageRequest, content, reportUserNickname, libraryBookTitle, type, filterShown, remarkType, bookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 修改状态
         * @summary 修改反馈状态
         * @param {number} feedbackId 
         * @param {UpdateFeedbackStatueRequest} [updateFeedbackStatueRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readFeedback(feedbackId: number, updateFeedbackStatueRequest?: UpdateFeedbackStatueRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readFeedback(feedbackId, updateFeedbackStatueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 提交反馈/用户评价
         * @param {FeedbackUpdateVO} feedbackUpdateVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportFeedback(feedbackUpdateVO: FeedbackUpdateVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportFeedback(feedbackUpdateVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改feedback(包括修改展示或者不展示)
         * @param {number} feedbackId 
         * @param {FeedbackUpdateVO} [feedbackUpdateVO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeedbackInfo(feedbackId: number, feedbackUpdateVO?: FeedbackUpdateVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeedbackInfo(feedbackId, feedbackUpdateVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取图书好评/差评率
         * @param {number} bookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookRemarkRatio(bookId: number, options?: any): AxiosPromise<BookRemarkRatioResponse> {
            return localVarFp.getBookRemarkRatio(bookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查看某本图书的用户感谢列表
         * @param {number} libraryBookId 
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFeedBackThanksAuthor(libraryBookId: number, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PageFeedbackResponseVO> {
            return localVarFp.listFeedBackThanksAuthor(libraryBookId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询所有反馈（管理员）
         * @param {'read' | 'unread' | 'replied' | 'ignored' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部, replied 已回复 ignored 已忽略
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [content] 反馈内容
         * @param {string} [reportUserNickname] 反馈用户的名称
         * @param {string} [libraryBookTitle] 反馈图书的标题
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageAllFeedback(status: 'read' | 'unread' | 'replied' | 'ignored' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, options?: any): AxiosPromise<PageFeedbackResponseVO> {
            return localVarFp.queryPageAllFeedback(status, queryPageRequest, content, reportUserNickname, libraryBookTitle, options).then((request) => request(axios, basePath));
        },
        /**
         * 管理员，或者看APP使用反馈时，不要用这个接口  创作者使用“内容纠错”时，设置type=内容纠错；可选参数：report_user_nickname，library_book_title，content  创作者使用“感谢作者”时，设置type=感谢作者；可选参数：library_book_title，filter_shown
         * @summary 创作者查询自己图书的反馈
         * @param {'read' | 'unread' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [content] 反馈内容
         * @param {string} [reportUserNickname] 反馈用户的名称
         * @param {string} [libraryBookTitle] 反馈图书的标题
         * @param {string} [type] 反馈类型 包括 内容纠错，感谢作者
         * @param {boolean} [filterShown] 是否过滤出展示给用的的数据 true只列出 展示给用户的数据 false 不列出展示给用户的数据
         * @param {string} [remarkType] 筛选好评或差评 若为空则是全部 可选值：GOOD/BAD
         * @param {number} [bookId] 需要查询的图书id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageCreatorFeedback(status: 'read' | 'unread' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, type?: string, filterShown?: boolean, remarkType?: string, bookId?: number, options?: any): AxiosPromise<PageFeedbackResponseVO> {
            return localVarFp.queryPageCreatorFeedback(status, queryPageRequest, content, reportUserNickname, libraryBookTitle, type, filterShown, remarkType, bookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 修改状态
         * @summary 修改反馈状态
         * @param {number} feedbackId 
         * @param {UpdateFeedbackStatueRequest} [updateFeedbackStatueRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readFeedback(feedbackId: number, updateFeedbackStatueRequest?: UpdateFeedbackStatueRequest, options?: any): AxiosPromise<void> {
            return localVarFp.readFeedback(feedbackId, updateFeedbackStatueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 提交反馈/用户评价
         * @param {FeedbackUpdateVO} feedbackUpdateVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportFeedback(feedbackUpdateVO: FeedbackUpdateVO, options?: any): AxiosPromise<void> {
            return localVarFp.reportFeedback(feedbackUpdateVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改feedback(包括修改展示或者不展示)
         * @param {number} feedbackId 
         * @param {FeedbackUpdateVO} [feedbackUpdateVO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedbackInfo(feedbackId: number, feedbackUpdateVO?: FeedbackUpdateVO, options?: any): AxiosPromise<void> {
            return localVarFp.updateFeedbackInfo(feedbackId, feedbackUpdateVO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @summary 获取图书好评/差评率
     * @param {number} bookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public getBookRemarkRatio(bookId: number, options?: any) {
        return FeedbackApiFp(this.configuration).getBookRemarkRatio(bookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查看某本图书的用户感谢列表
     * @param {number} libraryBookId 
     * @param {number} [pageNumber] 页码
     * @param {number} [pageSize] 页面数据量
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public listFeedBackThanksAuthor(libraryBookId: number, pageNumber?: number, pageSize?: number, options?: any) {
        return FeedbackApiFp(this.configuration).listFeedBackThanksAuthor(libraryBookId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询所有反馈（管理员）
     * @param {'read' | 'unread' | 'replied' | 'ignored' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部, replied 已回复 ignored 已忽略
     * @param {QueryPageRequest} queryPageRequest 
     * @param {string} [content] 反馈内容
     * @param {string} [reportUserNickname] 反馈用户的名称
     * @param {string} [libraryBookTitle] 反馈图书的标题
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public queryPageAllFeedback(status: 'read' | 'unread' | 'replied' | 'ignored' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, options?: any) {
        return FeedbackApiFp(this.configuration).queryPageAllFeedback(status, queryPageRequest, content, reportUserNickname, libraryBookTitle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 管理员，或者看APP使用反馈时，不要用这个接口  创作者使用“内容纠错”时，设置type=内容纠错；可选参数：report_user_nickname，library_book_title，content  创作者使用“感谢作者”时，设置type=感谢作者；可选参数：library_book_title，filter_shown
     * @summary 创作者查询自己图书的反馈
     * @param {'read' | 'unread' | 'all'} status read代表仅查询已读，unread代表仅查询未读，all代表查询全部
     * @param {QueryPageRequest} queryPageRequest 
     * @param {string} [content] 反馈内容
     * @param {string} [reportUserNickname] 反馈用户的名称
     * @param {string} [libraryBookTitle] 反馈图书的标题
     * @param {string} [type] 反馈类型 包括 内容纠错，感谢作者
     * @param {boolean} [filterShown] 是否过滤出展示给用的的数据 true只列出 展示给用户的数据 false 不列出展示给用户的数据
     * @param {string} [remarkType] 筛选好评或差评 若为空则是全部 可选值：GOOD/BAD
     * @param {number} [bookId] 需要查询的图书id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public queryPageCreatorFeedback(status: 'read' | 'unread' | 'all', queryPageRequest: QueryPageRequest, content?: string, reportUserNickname?: string, libraryBookTitle?: string, type?: string, filterShown?: boolean, remarkType?: string, bookId?: number, options?: any) {
        return FeedbackApiFp(this.configuration).queryPageCreatorFeedback(status, queryPageRequest, content, reportUserNickname, libraryBookTitle, type, filterShown, remarkType, bookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 修改状态
     * @summary 修改反馈状态
     * @param {number} feedbackId 
     * @param {UpdateFeedbackStatueRequest} [updateFeedbackStatueRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public readFeedback(feedbackId: number, updateFeedbackStatueRequest?: UpdateFeedbackStatueRequest, options?: any) {
        return FeedbackApiFp(this.configuration).readFeedback(feedbackId, updateFeedbackStatueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 提交反馈/用户评价
     * @param {FeedbackUpdateVO} feedbackUpdateVO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public reportFeedback(feedbackUpdateVO: FeedbackUpdateVO, options?: any) {
        return FeedbackApiFp(this.configuration).reportFeedback(feedbackUpdateVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改feedback(包括修改展示或者不展示)
     * @param {number} feedbackId 
     * @param {FeedbackUpdateVO} [feedbackUpdateVO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public updateFeedbackInfo(feedbackId: number, feedbackUpdateVO?: FeedbackUpdateVO, options?: any) {
        return FeedbackApiFp(this.configuration).updateFeedbackInfo(feedbackId, feedbackUpdateVO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlagApi - axios parameter creator
 * @export
 */
export const FlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 查询APP配置的flag
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppConfigFlag: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('findAppConfigFlag', 'key', key)
            const localVarPath = `/flag/v1/app/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGeneralEntityFlag: async (entityType: string, entityId: number, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('findGeneralEntityFlag', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findGeneralEntityFlag', 'entityId', entityId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('findGeneralEntityFlag', 'key', key)
            const localVarPath = `/flag/v1/{entity_type}/{entity_id}/{key}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGeneralEntityFlag: async (entityType: string, entityId: number, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('removeGeneralEntityFlag', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('removeGeneralEntityFlag', 'entityId', entityId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('removeGeneralEntityFlag', 'key', key)
            const localVarPath = `/flag/v1/{entity_type}/{entity_id}/{key}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 设置通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {GeneralEntityFlagRequest} [generalEntityFlagRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGeneralEntityFlag: async (entityType: string, entityId: number, key: string, generalEntityFlagRequest?: GeneralEntityFlagRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('setGeneralEntityFlag', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('setGeneralEntityFlag', 'entityId', entityId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('setGeneralEntityFlag', 'key', key)
            const localVarPath = `/flag/v1/{entity_type}/{entity_id}/{key}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalEntityFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlagApi - functional programming interface
 * @export
 */
export const FlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 查询APP配置的flag
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAppConfigFlag(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralEntityFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAppConfigFlag(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findGeneralEntityFlag(entityType: string, entityId: number, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralEntityFlagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findGeneralEntityFlag(entityType, entityId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeGeneralEntityFlag(entityType: string, entityId: number, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeGeneralEntityFlag(entityType, entityId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 设置通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {GeneralEntityFlagRequest} [generalEntityFlagRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGeneralEntityFlag(entityType: string, entityId: number, key: string, generalEntityFlagRequest?: GeneralEntityFlagRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGeneralEntityFlag(entityType, entityId, key, generalEntityFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlagApi - factory interface
 * @export
 */
export const FlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlagApiFp(configuration)
    return {
        /**
         * 
         * @summary 查询APP配置的flag
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAppConfigFlag(key: string, options?: any): AxiosPromise<GeneralEntityFlagResponse> {
            return localVarFp.findAppConfigFlag(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGeneralEntityFlag(entityType: string, entityId: number, key: string, options?: any): AxiosPromise<GeneralEntityFlagResponse> {
            return localVarFp.findGeneralEntityFlag(entityType, entityId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGeneralEntityFlag(entityType: string, entityId: number, key: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeGeneralEntityFlag(entityType, entityId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 设置通用实体flag
         * @param {string} entityType 
         * @param {number} entityId 
         * @param {string} key 
         * @param {GeneralEntityFlagRequest} [generalEntityFlagRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGeneralEntityFlag(entityType: string, entityId: number, key: string, generalEntityFlagRequest?: GeneralEntityFlagRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setGeneralEntityFlag(entityType, entityId, key, generalEntityFlagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlagApi - object-oriented interface
 * @export
 * @class FlagApi
 * @extends {BaseAPI}
 */
export class FlagApi extends BaseAPI {
    /**
     * 
     * @summary 查询APP配置的flag
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagApi
     */
    public findAppConfigFlag(key: string, options?: any) {
        return FlagApiFp(this.configuration).findAppConfigFlag(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询通用实体flag
     * @param {string} entityType 
     * @param {number} entityId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagApi
     */
    public findGeneralEntityFlag(entityType: string, entityId: number, key: string, options?: any) {
        return FlagApiFp(this.configuration).findGeneralEntityFlag(entityType, entityId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除通用实体flag
     * @param {string} entityType 
     * @param {number} entityId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagApi
     */
    public removeGeneralEntityFlag(entityType: string, entityId: number, key: string, options?: any) {
        return FlagApiFp(this.configuration).removeGeneralEntityFlag(entityType, entityId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 设置通用实体flag
     * @param {string} entityType 
     * @param {number} entityId 
     * @param {string} key 
     * @param {GeneralEntityFlagRequest} [generalEntityFlagRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlagApi
     */
    public setGeneralEntityFlag(entityType: string, entityId: number, key: string, generalEntityFlagRequest?: GeneralEntityFlagRequest, options?: any) {
        return FlagApiFp(this.configuration).setGeneralEntityFlag(entityType, entityId, key, generalEntityFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupbuyApi - axios parameter creator
 * @export
 */
export const GroupbuyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 取消拼团，拼团团长才能访问
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGroupBuy: async (groupBuyInstanceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupBuyInstanceId' is not null or undefined
            assertParamExists('cancelGroupBuy', 'groupBuyInstanceId', groupBuyInstanceId)
            const localVarPath = `/groupbuy/v1/group/{group_buy_instance_id}/cancel`
                .replace(`{${"group_buy_instance_id"}}`, encodeURIComponent(String(groupBuyInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeGroupBuyByInstanceId: async (groupBuyInstanceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupBuyInstanceId' is not null or undefined
            assertParamExists('completeGroupBuyByInstanceId', 'groupBuyInstanceId', groupBuyInstanceId)
            const localVarPath = `/groupbuy/v1/group/{group_buy_instance_id}/complete`
                .replace(`{${"group_buy_instance_id"}}`, encodeURIComponent(String(groupBuyInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建图书的拼团快照
         * @param {number} libraryBookId 
         * @param {GroupBuyConfigSnapshotVO} groupBuyConfigSnapshotVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupBuyConfigSnapshot: async (libraryBookId: number, groupBuyConfigSnapshotVO: GroupBuyConfigSnapshotVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('createGroupBuyConfigSnapshot', 'libraryBookId', libraryBookId)
            // verify required parameter 'groupBuyConfigSnapshotVO' is not null or undefined
            assertParamExists('createGroupBuyConfigSnapshot', 'groupBuyConfigSnapshotVO', groupBuyConfigSnapshotVO)
            const localVarPath = `/groupbuy/v1/book/{library_book_id}/config`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupBuyConfigSnapshotVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取图书的拼团快照
         * @param {number} libraryBookId 
         * @param {boolean} [showOriginalPrice] 是否展示原始价格（默认是false，创作者后台过来的请求请置为true）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupBuyConfigSnapshot: async (libraryBookId: number, showOriginalPrice?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('getGroupBuyConfigSnapshot', 'libraryBookId', libraryBookId)
            const localVarPath = `/groupbuy/v1/book/{library_book_id}/config`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showOriginalPrice !== undefined) {
                localVarQueryParameter['show_original_price'] = showOriginalPrice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取拼团信息
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupBuyInfo: async (groupBuyInstanceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupBuyInstanceId' is not null or undefined
            assertParamExists('getGroupBuyInfo', 'groupBuyInstanceId', groupBuyInstanceId)
            const localVarPath = `/groupbuy/v1/group/{group_buy_instance_id}/query`
                .replace(`{${"group_buy_instance_id"}}`, encodeURIComponent(String(groupBuyInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取剩余免费拼团次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeftFreeTimes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/groupbuy/v1/left-free-times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupBuyCancelable: async (groupBuyInstanceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupBuyInstanceId' is not null or undefined
            assertParamExists('groupBuyCancelable', 'groupBuyInstanceId', groupBuyInstanceId)
            const localVarPath = `/groupbuy/v1/group/{group_buy_instance_id}/is_cancellable`
                .replace(`{${"group_buy_instance_id"}}`, encodeURIComponent(String(groupBuyInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发起 免费拼团
         * @param {CreateFreeGroupBuyRequest} [createFreeGroupBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateFreeGroupBuy: async (createFreeGroupBuyRequest?: CreateFreeGroupBuyRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/groupbuy/v1/group-free`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFreeGroupBuyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发起拼团
         * @param {number} libraryBookId 
         * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateGroupBuy: async (libraryBookId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('initiateGroupBuy', 'libraryBookId', libraryBookId)
            const localVarPath = `/groupbuy/v1/group/book/{library_book_id}/initiate`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupBuyOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 参与拼团
         * @param {number} groupBuyInstanceId 
         * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinGroupBuy: async (groupBuyInstanceId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupBuyInstanceId' is not null or undefined
            assertParamExists('joinGroupBuy', 'groupBuyInstanceId', groupBuyInstanceId)
            const localVarPath = `/groupbuy/v1/group/{group_buy_instance_id}/join`
                .replace(`{${"group_buy_instance_id"}}`, encodeURIComponent(String(groupBuyInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupBuyOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户当前正在开启的所有拼团
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpenGroupBuyOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/groupbuy/v1/open-group/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueryGroupBuyRequest} [queryGroupBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageGroupBuyInstance: async (queryGroupBuyRequest?: QueryGroupBuyRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/groupbuy/v1/group/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryGroupBuyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分页获取图书的所有拼团
         * @param {number} libraryBookId 
         * @param {QueryPageRequest} queryPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageGroupBuyInfoByBookId: async (libraryBookId: number, queryPageRequest: QueryPageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('queryPageGroupBuyInfoByBookId', 'libraryBookId', libraryBookId)
            // verify required parameter 'queryPageRequest' is not null or undefined
            assertParamExists('queryPageGroupBuyInfoByBookId', 'queryPageRequest', queryPageRequest)
            const localVarPath = `/groupbuy/v1/book/{library_book_id}/query`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分页获取用户参与的所有拼团
         * @param {QueryPageRequest} queryPageRequest 
         * @param {number} [libraryBookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageMyGroupBuyInfo: async (queryPageRequest: QueryPageRequest, libraryBookId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryPageRequest' is not null or undefined
            assertParamExists('queryPageMyGroupBuyInfo', 'queryPageRequest', queryPageRequest)
            const localVarPath = `/groupbuy/v1/group/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (libraryBookId !== undefined) {
                localVarQueryParameter['library_book_id'] = libraryBookId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupbuyApi - functional programming interface
 * @export
 */
export const GroupbuyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupbuyApiAxiosParamCreator(configuration)
    return {
        /**
         * 取消拼团，拼团团长才能访问
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelGroupBuy(groupBuyInstanceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelGroupBuy(groupBuyInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeGroupBuyByInstanceId(groupBuyInstanceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeGroupBuyByInstanceId(groupBuyInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建图书的拼团快照
         * @param {number} libraryBookId 
         * @param {GroupBuyConfigSnapshotVO} groupBuyConfigSnapshotVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroupBuyConfigSnapshot(libraryBookId: number, groupBuyConfigSnapshotVO: GroupBuyConfigSnapshotVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupBuyConfigSnapshot(libraryBookId, groupBuyConfigSnapshotVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取图书的拼团快照
         * @param {number} libraryBookId 
         * @param {boolean} [showOriginalPrice] 是否展示原始价格（默认是false，创作者后台过来的请求请置为true）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupBuyConfigSnapshot(libraryBookId: number, showOriginalPrice?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupBuyConfigSnapshotVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupBuyConfigSnapshot(libraryBookId, showOriginalPrice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取拼团信息
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupBuyInfo(groupBuyInstanceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupBuyInstanceVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupBuyInfo(groupBuyInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取剩余免费拼团次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeftFreeTimes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreeGroupInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeftFreeTimes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupBuyCancelable(groupBuyInstanceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupBuyCancelableStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupBuyCancelable(groupBuyInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发起 免费拼团
         * @param {CreateFreeGroupBuyRequest} [createFreeGroupBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateFreeGroupBuy(createFreeGroupBuyRequest?: CreateFreeGroupBuyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateFreeGroupBuy(createFreeGroupBuyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发起拼团
         * @param {number} libraryBookId 
         * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateGroupBuy(libraryBookId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateGroupBuy(libraryBookId, createGroupBuyOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 参与拼团
         * @param {number} groupBuyInstanceId 
         * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinGroupBuy(groupBuyInstanceId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinGroupBuy(groupBuyInstanceId, createGroupBuyOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户当前正在开启的所有拼团
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOpenGroupBuyOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenGroupBuyOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOpenGroupBuyOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QueryGroupBuyRequest} [queryGroupBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPageGroupBuyInstance(queryGroupBuyRequest?: QueryGroupBuyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageGroupBuyInstanceVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPageGroupBuyInstance(queryGroupBuyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分页获取图书的所有拼团
         * @param {number} libraryBookId 
         * @param {QueryPageRequest} queryPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPageGroupBuyInfoByBookId(libraryBookId: number, queryPageRequest: QueryPageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageGroupBuyInstanceVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPageGroupBuyInfoByBookId(libraryBookId, queryPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分页获取用户参与的所有拼团
         * @param {QueryPageRequest} queryPageRequest 
         * @param {number} [libraryBookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPageMyGroupBuyInfo(queryPageRequest: QueryPageRequest, libraryBookId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageGroupBuyInstanceVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPageMyGroupBuyInfo(queryPageRequest, libraryBookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupbuyApi - factory interface
 * @export
 */
export const GroupbuyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupbuyApiFp(configuration)
    return {
        /**
         * 取消拼团，拼团团长才能访问
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGroupBuy(groupBuyInstanceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.cancelGroupBuy(groupBuyInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeGroupBuyByInstanceId(groupBuyInstanceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.completeGroupBuyByInstanceId(groupBuyInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建图书的拼团快照
         * @param {number} libraryBookId 
         * @param {GroupBuyConfigSnapshotVO} groupBuyConfigSnapshotVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupBuyConfigSnapshot(libraryBookId: number, groupBuyConfigSnapshotVO: GroupBuyConfigSnapshotVO, options?: any): AxiosPromise<void> {
            return localVarFp.createGroupBuyConfigSnapshot(libraryBookId, groupBuyConfigSnapshotVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取图书的拼团快照
         * @param {number} libraryBookId 
         * @param {boolean} [showOriginalPrice] 是否展示原始价格（默认是false，创作者后台过来的请求请置为true）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupBuyConfigSnapshot(libraryBookId: number, showOriginalPrice?: boolean, options?: any): AxiosPromise<GroupBuyConfigSnapshotVO> {
            return localVarFp.getGroupBuyConfigSnapshot(libraryBookId, showOriginalPrice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取拼团信息
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupBuyInfo(groupBuyInstanceId: number, options?: any): AxiosPromise<GroupBuyInstanceVO> {
            return localVarFp.getGroupBuyInfo(groupBuyInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取剩余免费拼团次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeftFreeTimes(options?: any): AxiosPromise<FreeGroupInfo> {
            return localVarFp.getLeftFreeTimes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupBuyInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupBuyCancelable(groupBuyInstanceId: number, options?: any): AxiosPromise<GroupBuyCancelableStatus> {
            return localVarFp.groupBuyCancelable(groupBuyInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发起 免费拼团
         * @param {CreateFreeGroupBuyRequest} [createFreeGroupBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateFreeGroupBuy(createFreeGroupBuyRequest?: CreateFreeGroupBuyRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.initiateFreeGroupBuy(createFreeGroupBuyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发起拼团
         * @param {number} libraryBookId 
         * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateGroupBuy(libraryBookId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.initiateGroupBuy(libraryBookId, createGroupBuyOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 参与拼团
         * @param {number} groupBuyInstanceId 
         * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinGroupBuy(groupBuyInstanceId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.joinGroupBuy(groupBuyInstanceId, createGroupBuyOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户当前正在开启的所有拼团
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpenGroupBuyOrders(options?: any): AxiosPromise<OpenGroupBuyOrderListResponse> {
            return localVarFp.listOpenGroupBuyOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueryGroupBuyRequest} [queryGroupBuyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageGroupBuyInstance(queryGroupBuyRequest?: QueryGroupBuyRequest, options?: any): AxiosPromise<PageGroupBuyInstanceVO> {
            return localVarFp.listPageGroupBuyInstance(queryGroupBuyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分页获取图书的所有拼团
         * @param {number} libraryBookId 
         * @param {QueryPageRequest} queryPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageGroupBuyInfoByBookId(libraryBookId: number, queryPageRequest: QueryPageRequest, options?: any): AxiosPromise<PageGroupBuyInstanceVO> {
            return localVarFp.queryPageGroupBuyInfoByBookId(libraryBookId, queryPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分页获取用户参与的所有拼团
         * @param {QueryPageRequest} queryPageRequest 
         * @param {number} [libraryBookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageMyGroupBuyInfo(queryPageRequest: QueryPageRequest, libraryBookId?: number, options?: any): AxiosPromise<PageGroupBuyInstanceVO> {
            return localVarFp.queryPageMyGroupBuyInfo(queryPageRequest, libraryBookId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupbuyApi - object-oriented interface
 * @export
 * @class GroupbuyApi
 * @extends {BaseAPI}
 */
export class GroupbuyApi extends BaseAPI {
    /**
     * 取消拼团，拼团团长才能访问
     * @param {number} groupBuyInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public cancelGroupBuy(groupBuyInstanceId: number, options?: any) {
        return GroupbuyApiFp(this.configuration).cancelGroupBuy(groupBuyInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupBuyInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public completeGroupBuyByInstanceId(groupBuyInstanceId: number, options?: any) {
        return GroupbuyApiFp(this.configuration).completeGroupBuyByInstanceId(groupBuyInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建图书的拼团快照
     * @param {number} libraryBookId 
     * @param {GroupBuyConfigSnapshotVO} groupBuyConfigSnapshotVO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public createGroupBuyConfigSnapshot(libraryBookId: number, groupBuyConfigSnapshotVO: GroupBuyConfigSnapshotVO, options?: any) {
        return GroupbuyApiFp(this.configuration).createGroupBuyConfigSnapshot(libraryBookId, groupBuyConfigSnapshotVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取图书的拼团快照
     * @param {number} libraryBookId 
     * @param {boolean} [showOriginalPrice] 是否展示原始价格（默认是false，创作者后台过来的请求请置为true）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public getGroupBuyConfigSnapshot(libraryBookId: number, showOriginalPrice?: boolean, options?: any) {
        return GroupbuyApiFp(this.configuration).getGroupBuyConfigSnapshot(libraryBookId, showOriginalPrice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取拼团信息
     * @param {number} groupBuyInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public getGroupBuyInfo(groupBuyInstanceId: number, options?: any) {
        return GroupbuyApiFp(this.configuration).getGroupBuyInfo(groupBuyInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取剩余免费拼团次数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public getLeftFreeTimes(options?: any) {
        return GroupbuyApiFp(this.configuration).getLeftFreeTimes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupBuyInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public groupBuyCancelable(groupBuyInstanceId: number, options?: any) {
        return GroupbuyApiFp(this.configuration).groupBuyCancelable(groupBuyInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发起 免费拼团
     * @param {CreateFreeGroupBuyRequest} [createFreeGroupBuyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public initiateFreeGroupBuy(createFreeGroupBuyRequest?: CreateFreeGroupBuyRequest, options?: any) {
        return GroupbuyApiFp(this.configuration).initiateFreeGroupBuy(createFreeGroupBuyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发起拼团
     * @param {number} libraryBookId 
     * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public initiateGroupBuy(libraryBookId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options?: any) {
        return GroupbuyApiFp(this.configuration).initiateGroupBuy(libraryBookId, createGroupBuyOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 参与拼团
     * @param {number} groupBuyInstanceId 
     * @param {CreateGroupBuyOrderRequest} [createGroupBuyOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public joinGroupBuy(groupBuyInstanceId: number, createGroupBuyOrderRequest?: CreateGroupBuyOrderRequest, options?: any) {
        return GroupbuyApiFp(this.configuration).joinGroupBuy(groupBuyInstanceId, createGroupBuyOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户当前正在开启的所有拼团
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public listOpenGroupBuyOrders(options?: any) {
        return GroupbuyApiFp(this.configuration).listOpenGroupBuyOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueryGroupBuyRequest} [queryGroupBuyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public listPageGroupBuyInstance(queryGroupBuyRequest?: QueryGroupBuyRequest, options?: any) {
        return GroupbuyApiFp(this.configuration).listPageGroupBuyInstance(queryGroupBuyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分页获取图书的所有拼团
     * @param {number} libraryBookId 
     * @param {QueryPageRequest} queryPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public queryPageGroupBuyInfoByBookId(libraryBookId: number, queryPageRequest: QueryPageRequest, options?: any) {
        return GroupbuyApiFp(this.configuration).queryPageGroupBuyInfoByBookId(libraryBookId, queryPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分页获取用户参与的所有拼团
     * @param {QueryPageRequest} queryPageRequest 
     * @param {number} [libraryBookId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupbuyApi
     */
    public queryPageMyGroupBuyInfo(queryPageRequest: QueryPageRequest, libraryBookId?: number, options?: any) {
        return GroupbuyApiFp(this.configuration).queryPageMyGroupBuyInfo(queryPageRequest, libraryBookId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HjdocTemplateApi - axios parameter creator
 * @export
 */
export const HjdocTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 新增共享模板
         * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSharedTemplate: async (hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hjdoc_template/v1/shared/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjdocTemplateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询共享模板详情
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedTemplateDetail: async (templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getSharedTemplateDetail', 'templateId', templateId)
            const localVarPath = `/hjdoc_template/v1/shared/detail/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取hjdoc共享模板列列表
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedTemplate: async (published?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hjdoc_template/v1/shared/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发布/取消发布 共享模板
         * @param {string} operation 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSharedTemplate: async (operation: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('publishSharedTemplate', 'operation', operation)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('publishSharedTemplate', 'templateId', templateId)
            const localVarPath = `/hjdoc_template/v1/shared/{operation}/{template_id}`
                .replace(`{${"operation"}}`, encodeURIComponent(String(operation)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改共享模板
         * @param {string} templateId 
         * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedTemplate: async (templateId: string, hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateSharedTemplate', 'templateId', templateId)
            const localVarPath = `/hjdoc_template/v1/shared/update/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjdocTemplateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HjdocTemplateApi - functional programming interface
 * @export
 */
export const HjdocTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HjdocTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 新增共享模板
         * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSharedTemplate(hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSharedTemplate(hjdocTemplateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询共享模板详情
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedTemplateDetail(templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjdocTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedTemplateDetail(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取hjdoc共享模板列列表
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSharedTemplate(published?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjdocTemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSharedTemplate(published, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发布/取消发布 共享模板
         * @param {string} operation 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishSharedTemplate(operation: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishSharedTemplate(operation, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改共享模板
         * @param {string} templateId 
         * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSharedTemplate(templateId: string, hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSharedTemplate(templateId, hjdocTemplateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HjdocTemplateApi - factory interface
 * @export
 */
export const HjdocTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HjdocTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary 新增共享模板
         * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSharedTemplate(hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addSharedTemplate(hjdocTemplateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询共享模板详情
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedTemplateDetail(templateId: string, options?: any): AxiosPromise<HjdocTemplateResponse> {
            return localVarFp.getSharedTemplateDetail(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取hjdoc共享模板列列表
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedTemplate(published?: boolean, options?: any): AxiosPromise<HjdocTemplateListResponse> {
            return localVarFp.listSharedTemplate(published, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发布/取消发布 共享模板
         * @param {string} operation 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSharedTemplate(operation: string, templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishSharedTemplate(operation, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改共享模板
         * @param {string} templateId 
         * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedTemplate(templateId: string, hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateSharedTemplate(templateId, hjdocTemplateUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HjdocTemplateApi - object-oriented interface
 * @export
 * @class HjdocTemplateApi
 * @extends {BaseAPI}
 */
export class HjdocTemplateApi extends BaseAPI {
    /**
     * 
     * @summary 新增共享模板
     * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjdocTemplateApi
     */
    public addSharedTemplate(hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options?: any) {
        return HjdocTemplateApiFp(this.configuration).addSharedTemplate(hjdocTemplateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询共享模板详情
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjdocTemplateApi
     */
    public getSharedTemplateDetail(templateId: string, options?: any) {
        return HjdocTemplateApiFp(this.configuration).getSharedTemplateDetail(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取hjdoc共享模板列列表
     * @param {boolean} [published] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjdocTemplateApi
     */
    public listSharedTemplate(published?: boolean, options?: any) {
        return HjdocTemplateApiFp(this.configuration).listSharedTemplate(published, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发布/取消发布 共享模板
     * @param {string} operation 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjdocTemplateApi
     */
    public publishSharedTemplate(operation: string, templateId: string, options?: any) {
        return HjdocTemplateApiFp(this.configuration).publishSharedTemplate(operation, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改共享模板
     * @param {string} templateId 
     * @param {HjdocTemplateUpdateRequest} [hjdocTemplateUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjdocTemplateApi
     */
    public updateSharedTemplate(templateId: string, hjdocTemplateUpdateRequest?: HjdocTemplateUpdateRequest, options?: any) {
        return HjdocTemplateApiFp(this.configuration).updateSharedTemplate(templateId, hjdocTemplateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HjmlTemplateApi - axios parameter creator
 * @export
 */
export const HjmlTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 新增共享模板
         * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSharedTemplate: async (hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hjml_template/v1/shared/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjmlTemplateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询共享模板详情
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedTemplateDetail: async (templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getSharedTemplateDetail', 'templateId', templateId)
            const localVarPath = `/hjml_template/v1/shared/detail/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取共享模板列列表
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedTemplate: async (published?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hjml_template/v1/shared/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发布/取消发布 共享模板
         * @param {string} operation 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSharedTemplate: async (operation: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('publishSharedTemplate', 'operation', operation)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('publishSharedTemplate', 'templateId', templateId)
            const localVarPath = `/hjml_template/v1/shared/{operation}/{template_id}`
                .replace(`{${"operation"}}`, encodeURIComponent(String(operation)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改共享模板
         * @param {string} templateId 
         * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedTemplate: async (templateId: string, hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateSharedTemplate', 'templateId', templateId)
            const localVarPath = `/hjml_template/v1/shared/update/{template_id}`
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hjmlTemplateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HjmlTemplateApi - functional programming interface
 * @export
 */
export const HjmlTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HjmlTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 新增共享模板
         * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSharedTemplate(hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSharedTemplate(hjmlTemplateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询共享模板详情
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedTemplateDetail(templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjmlTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedTemplateDetail(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取共享模板列列表
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSharedTemplate(published?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HjmlTemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSharedTemplate(published, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发布/取消发布 共享模板
         * @param {string} operation 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishSharedTemplate(operation: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishSharedTemplate(operation, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改共享模板
         * @param {string} templateId 
         * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSharedTemplate(templateId: string, hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSharedTemplate(templateId, hjmlTemplateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HjmlTemplateApi - factory interface
 * @export
 */
export const HjmlTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HjmlTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary 新增共享模板
         * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSharedTemplate(hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addSharedTemplate(hjmlTemplateUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询共享模板详情
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedTemplateDetail(templateId: string, options?: any): AxiosPromise<HjmlTemplateResponse> {
            return localVarFp.getSharedTemplateDetail(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取共享模板列列表
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedTemplate(published?: boolean, options?: any): AxiosPromise<HjmlTemplateListResponse> {
            return localVarFp.listSharedTemplate(published, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发布/取消发布 共享模板
         * @param {string} operation 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSharedTemplate(operation: string, templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishSharedTemplate(operation, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改共享模板
         * @param {string} templateId 
         * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedTemplate(templateId: string, hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateSharedTemplate(templateId, hjmlTemplateUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HjmlTemplateApi - object-oriented interface
 * @export
 * @class HjmlTemplateApi
 * @extends {BaseAPI}
 */
export class HjmlTemplateApi extends BaseAPI {
    /**
     * 
     * @summary 新增共享模板
     * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjmlTemplateApi
     */
    public addSharedTemplate(hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options?: any) {
        return HjmlTemplateApiFp(this.configuration).addSharedTemplate(hjmlTemplateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询共享模板详情
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjmlTemplateApi
     */
    public getSharedTemplateDetail(templateId: string, options?: any) {
        return HjmlTemplateApiFp(this.configuration).getSharedTemplateDetail(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取共享模板列列表
     * @param {boolean} [published] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjmlTemplateApi
     */
    public listSharedTemplate(published?: boolean, options?: any) {
        return HjmlTemplateApiFp(this.configuration).listSharedTemplate(published, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发布/取消发布 共享模板
     * @param {string} operation 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjmlTemplateApi
     */
    public publishSharedTemplate(operation: string, templateId: string, options?: any) {
        return HjmlTemplateApiFp(this.configuration).publishSharedTemplate(operation, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改共享模板
     * @param {string} templateId 
     * @param {HjmlTemplateUpdateRequest} [hjmlTemplateUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HjmlTemplateApi
     */
    public updateSharedTemplate(templateId: string, hjmlTemplateUpdateRequest?: HjmlTemplateUpdateRequest, options?: any) {
        return HjmlTemplateApiFp(this.configuration).updateSharedTemplate(templateId, hjmlTemplateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HpkgApi - axios parameter creator
 * @export
 */
export const HpkgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 主动备份卡包
         * @param {string} packageUuid 
         * @param {string} [tag] 自定义备份标签
         * @param {string} [backupChannel] 是用户手动点击还是程序自动备份
         * @param {boolean} [checkVersion] 是否检查版本，如果是false 就会强制备份一次
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupPackage: async (packageUuid: string, tag?: string, backupChannel?: string, checkVersion?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('backupPackage', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/backup/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (backupChannel !== undefined) {
                localVarQueryParameter['backup_channel'] = backupChannel;
            }

            if (checkVersion !== undefined) {
                localVarQueryParameter['check_version'] = checkVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据mediaName下载某个卡包的media包
         * @param {string} packageUuid 
         * @param {BatchDownloadMediaRequest} [batchDownloadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownLoadMediaZip: async (packageUuid: string, batchDownloadMediaRequest?: BatchDownloadMediaRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('batchDownLoadMediaZip', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/download/media_zip/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchDownloadMediaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查购买卡包是否有更新
         * @param {string} packageUuid 
         * @param {string} releaseTag 客户端当前版本
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPackageUpdates: async (packageUuid: string, releaseTag: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('checkPackageUpdates', 'packageUuid', packageUuid)
            // verify required parameter 'releaseTag' is not null or undefined
            assertParamExists('checkPackageUpdates', 'releaseTag', releaseTag)
            const localVarPath = `/hpkg/v1/package/check_updates/{package_uuid}/{release_tag}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"release_tag"}}`, encodeURIComponent(String(releaseTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackageOfHpkg: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('deletePackageOfHpkg', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/delete/package/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 下载某个卡包的media包
         * @param {string} packageUuid 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downLoadMediaZip: async (packageUuid: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('downLoadMediaZip', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/download/media_zip/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 可选地：带有 JWT token；如果是用户自己的卡册，则一定要带token，否则很多图片无法访问。  card_id 允许有两种形式：1. Hpkg 中的 uuid ；2. GET /apkg/v1/package/{package_uuid}/info 中返回的 card id  返回：302重定向至某图片；如果 302 响应的 Location (url) 中带有 \"fallback\" 字符，则不要缓存，说明是一个 fallback 图片。其它情况下，可以缓存。  403：如果用户没有权限访问某卡片（如未购买）  (可能带有滑记的水印，但会放在靠下的位置) 
         * @summary 通过 card id 获得预览图
         * @param {string} packageUuid 
         * @param {string} cardId 
         * @param {boolean} [html] 如果为 true, 则返回 html 内容（而非图片）
         * @param {boolean} [refreshCache] 如果为 true, 则重新生成缓存
         * @param {boolean} [useSnapshot] 如果为 true，则通过最新 snapshot 获取卡片内容（仅内部使用）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardPreview: async (packageUuid: string, cardId: string, html?: boolean, refreshCache?: boolean, useSnapshot?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getCardPreview', 'packageUuid', packageUuid)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getCardPreview', 'cardId', cardId)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/card/{card_id}/preview`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (html !== undefined) {
                localVarQueryParameter['html'] = html;
            }

            if (refreshCache !== undefined) {
                localVarQueryParameter['refresh_cache'] = refreshCache;
            }

            if (useSnapshot !== undefined) {
                localVarQueryParameter['use_snapshot'] = useSnapshot;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取某个卡片的学习次数数据
         * @param {HpkgCardStatisticRequest} [hpkgCardStatisticRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRateStatistic: async (hpkgCardStatisticRequest?: HpkgCardStatisticRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hpkg/v1/card/rate_statistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hpkgCardStatisticRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取 hpkg 未发布的更新情况
         * @summary 获取 hpkg 未发布的更新情况
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHpkgChanges: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getHpkgChanges', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/changes`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取当前备份状态
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageBackupStatus: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getPackageBackupStatus', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/backup_status/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 列举备份日志列表
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBackLog: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('listBackLog', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/backup/list/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取某个卡片的日志
         * @param {HpkgCardRateLogRequest} [hpkgCardRateLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCardRateLog: async (hpkgCardRateLogRequest?: HpkgCardRateLogRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hpkg/v1/card/rate_logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hpkgCardRateLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取hpkg更新日志
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHpkgChangeLogs: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('listHpkgChangeLogs', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/change_logs`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量获取Note信息
         * @param {string} packageUuid 
         * @param {NoteInfoGetRequest} [noteInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotesInfo: async (packageUuid: string, noteInfoGetRequest?: NoteInfoGetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('listNotesInfo', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/notes/info/{package_uuid}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteInfoGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 达到可上架状态，转 1.0 数据，首次发布创建 ugc_book + library_book + user_book
         * @summary 发布 Hpkg，并非上架，适用于更新
         * @param {string} packageUuid 
         * @param {HpkgPublishRequest} [hpkgPublishRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishHpkg: async (packageUuid: string, hpkgPublishRequest?: HpkgPublishRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('publishHpkg', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/publish`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hpkgPublishRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修复缺失的global_schedule_data（数据修复用）
         * @param {number} userId 
         * @param {RecoverHpkgRequest} [recoverHpkgRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverGlobalSchedule: async (userId: number, recoverHpkgRequest?: RecoverHpkgRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('recoverGlobalSchedule', 'userId', userId)
            const localVarPath = `/hpkg/v1/admin/global_schedule/{user_id}/recover`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recoverHpkgRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修复packag中缺失的model
         * @param {string} packageUuid 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverModel: async (packageUuid: string, modelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('recoverModel', 'packageUuid', packageUuid)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('recoverModel', 'modelId', modelId)
            const localVarPath = `/hpkg/v1/admin/package/{package_uuid}/{model_id}/recover`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 回滚package到某个版本
         * @param {number} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackPackage: async (transactionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('rollbackPackage', 'transactionId', transactionId)
            const localVarPath = `/hpkg/v1/package/rollback/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创作者切换release版本
         * @param {string} packageUuid 
         * @param {string} releaseTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchReleaseTag: async (packageUuid: string, releaseTag: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('switchReleaseTag', 'packageUuid', packageUuid)
            // verify required parameter 'releaseTag' is not null or undefined
            assertParamExists('switchReleaseTag', 'releaseTag', releaseTag)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/switch/{release_tag}`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"release_tag"}}`, encodeURIComponent(String(releaseTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 同步卡包meta信息
         * @param {SyncMetaRequest} [syncMetaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncHpkgMeta: async (syncMetaRequest?: SyncMetaRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hpkg/v1/package/simple_meta/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncMetaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 同步卡片盒列表
         * @summary 同步卡片盒列表
         * @param {HpkgSyncRequest} [hpkgSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncHpkgs: async (hpkgSyncRequest?: HpkgSyncRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hpkg/v1/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hpkgSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 更新卡包预览图
         * @summary 通过packageUuid更新整个卡包预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCardPreview: async (packageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('updateAllCardPreview', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/preview`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {'sketch' | 'copy_note' | 'comment'} fileType 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCardDataZipByPackageId: async (packageUuid: string, fileType: 'sketch' | 'copy_note' | 'comment', file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('uploadCardDataZipByPackageId', 'packageUuid', packageUuid)
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('uploadCardDataZipByPackageId', 'fileType', fileType)
            const localVarPath = `/hpkg/v1/sketch/{package_uuid}/{file_type}/upload`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)))
                .replace(`{${"file_type"}}`, encodeURIComponent(String(fileType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMediaByApkgUuid: async (packageUuid: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('uploadMediaByApkgUuid', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/media`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {boolean} [cardUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMediaZipByApkgUuid: async (packageUuid: string, file?: any, cardUserData?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('uploadMediaZipByApkgUuid', 'packageUuid', packageUuid)
            const localVarPath = `/hpkg/v1/package/{package_uuid}/media_zip`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (cardUserData !== undefined) { 
                localVarFormParams.append('card_user_data', cardUserData as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HpkgApi - functional programming interface
 * @export
 */
export const HpkgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HpkgApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 主动备份卡包
         * @param {string} packageUuid 
         * @param {string} [tag] 自定义备份标签
         * @param {string} [backupChannel] 是用户手动点击还是程序自动备份
         * @param {boolean} [checkVersion] 是否检查版本，如果是false 就会强制备份一次
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backupPackage(packageUuid: string, tag?: string, backupChannel?: string, checkVersion?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backupPackage(packageUuid, tag, backupChannel, checkVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据mediaName下载某个卡包的media包
         * @param {string} packageUuid 
         * @param {BatchDownloadMediaRequest} [batchDownloadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchDownLoadMediaZip(packageUuid: string, batchDownloadMediaRequest?: BatchDownloadMediaRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownLoadMediaZip(packageUuid, batchDownloadMediaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查购买卡包是否有更新
         * @param {string} packageUuid 
         * @param {string} releaseTag 客户端当前版本
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPackageUpdates(packageUuid: string, releaseTag: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HpkgChangeLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPackageUpdates(packageUuid, releaseTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePackageOfHpkg(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePackageOfHpkg(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 下载某个卡包的media包
         * @param {string} packageUuid 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downLoadMediaZip(packageUuid: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downLoadMediaZip(packageUuid, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 可选地：带有 JWT token；如果是用户自己的卡册，则一定要带token，否则很多图片无法访问。  card_id 允许有两种形式：1. Hpkg 中的 uuid ；2. GET /apkg/v1/package/{package_uuid}/info 中返回的 card id  返回：302重定向至某图片；如果 302 响应的 Location (url) 中带有 \"fallback\" 字符，则不要缓存，说明是一个 fallback 图片。其它情况下，可以缓存。  403：如果用户没有权限访问某卡片（如未购买）  (可能带有滑记的水印，但会放在靠下的位置) 
         * @summary 通过 card id 获得预览图
         * @param {string} packageUuid 
         * @param {string} cardId 
         * @param {boolean} [html] 如果为 true, 则返回 html 内容（而非图片）
         * @param {boolean} [refreshCache] 如果为 true, 则重新生成缓存
         * @param {boolean} [useSnapshot] 如果为 true，则通过最新 snapshot 获取卡片内容（仅内部使用）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardPreview(packageUuid: string, cardId: string, html?: boolean, refreshCache?: boolean, useSnapshot?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardPreview(packageUuid, cardId, html, refreshCache, useSnapshot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取某个卡片的学习次数数据
         * @param {HpkgCardStatisticRequest} [hpkgCardStatisticRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardRateStatistic(hpkgCardStatisticRequest?: HpkgCardStatisticRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardRateStatisticVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardRateStatistic(hpkgCardStatisticRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取 hpkg 未发布的更新情况
         * @summary 获取 hpkg 未发布的更新情况
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHpkgChanges(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HpkgChangesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHpkgChanges(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取当前备份状态
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackageBackupStatus(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackupStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackageBackupStatus(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 列举备份日志列表
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBackLog(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackupLogListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBackLog(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取某个卡片的日志
         * @param {HpkgCardRateLogRequest} [hpkgCardRateLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCardRateLog(hpkgCardRateLogRequest?: HpkgCardRateLogRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardRateLogListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCardRateLog(hpkgCardRateLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取hpkg更新日志
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHpkgChangeLogs(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HpkgChangeLogListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHpkgChangeLogs(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量获取Note信息
         * @param {string} packageUuid 
         * @param {NoteInfoGetRequest} [noteInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotesInfo(packageUuid: string, noteInfoGetRequest?: NoteInfoGetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteContentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotesInfo(packageUuid, noteInfoGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 达到可上架状态，转 1.0 数据，首次发布创建 ugc_book + library_book + user_book
         * @summary 发布 Hpkg，并非上架，适用于更新
         * @param {string} packageUuid 
         * @param {HpkgPublishRequest} [hpkgPublishRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishHpkg(packageUuid: string, hpkgPublishRequest?: HpkgPublishRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishHpkg(packageUuid, hpkgPublishRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修复缺失的global_schedule_data（数据修复用）
         * @param {number} userId 
         * @param {RecoverHpkgRequest} [recoverHpkgRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverGlobalSchedule(userId: number, recoverHpkgRequest?: RecoverHpkgRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverGlobalSchedule(userId, recoverHpkgRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修复packag中缺失的model
         * @param {string} packageUuid 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverModel(packageUuid: string, modelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverModel(packageUuid, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 回滚package到某个版本
         * @param {number} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rollbackPackage(transactionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rollbackPackage(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创作者切换release版本
         * @param {string} packageUuid 
         * @param {string} releaseTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchReleaseTag(packageUuid: string, releaseTag: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchReleaseTag(packageUuid, releaseTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 同步卡包meta信息
         * @param {SyncMetaRequest} [syncMetaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncHpkgMeta(syncMetaRequest?: SyncMetaRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncHpkgMetaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncHpkgMeta(syncMetaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 同步卡片盒列表
         * @summary 同步卡片盒列表
         * @param {HpkgSyncRequest} [hpkgSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncHpkgs(hpkgSyncRequest?: HpkgSyncRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HpkgSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncHpkgs(hpkgSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 更新卡包预览图
         * @summary 通过packageUuid更新整个卡包预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllCardPreview(packageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllCardPreview(packageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {'sketch' | 'copy_note' | 'comment'} fileType 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCardDataZipByPackageId(packageUuid: string, fileType: 'sketch' | 'copy_note' | 'comment', file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCardDataZipByPackageId(packageUuid, fileType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMediaByApkgUuid(packageUuid: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApkgMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMediaByApkgUuid(packageUuid, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {boolean} [cardUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMediaZipByApkgUuid(packageUuid: string, file?: any, cardUserData?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMediaZipByApkgUuid(packageUuid, file, cardUserData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HpkgApi - factory interface
 * @export
 */
export const HpkgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HpkgApiFp(configuration)
    return {
        /**
         * 
         * @summary 主动备份卡包
         * @param {string} packageUuid 
         * @param {string} [tag] 自定义备份标签
         * @param {string} [backupChannel] 是用户手动点击还是程序自动备份
         * @param {boolean} [checkVersion] 是否检查版本，如果是false 就会强制备份一次
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backupPackage(packageUuid: string, tag?: string, backupChannel?: string, checkVersion?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.backupPackage(packageUuid, tag, backupChannel, checkVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据mediaName下载某个卡包的media包
         * @param {string} packageUuid 
         * @param {BatchDownloadMediaRequest} [batchDownloadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownLoadMediaZip(packageUuid: string, batchDownloadMediaRequest?: BatchDownloadMediaRequest, options?: any): AxiosPromise<any> {
            return localVarFp.batchDownLoadMediaZip(packageUuid, batchDownloadMediaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查购买卡包是否有更新
         * @param {string} packageUuid 
         * @param {string} releaseTag 客户端当前版本
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPackageUpdates(packageUuid: string, releaseTag: string, options?: any): AxiosPromise<HpkgChangeLogResponse> {
            return localVarFp.checkPackageUpdates(packageUuid, releaseTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePackageOfHpkg(packageUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePackageOfHpkg(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 下载某个卡包的media包
         * @param {string} packageUuid 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downLoadMediaZip(packageUuid: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<any> {
            return localVarFp.downLoadMediaZip(packageUuid, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 可选地：带有 JWT token；如果是用户自己的卡册，则一定要带token，否则很多图片无法访问。  card_id 允许有两种形式：1. Hpkg 中的 uuid ；2. GET /apkg/v1/package/{package_uuid}/info 中返回的 card id  返回：302重定向至某图片；如果 302 响应的 Location (url) 中带有 \"fallback\" 字符，则不要缓存，说明是一个 fallback 图片。其它情况下，可以缓存。  403：如果用户没有权限访问某卡片（如未购买）  (可能带有滑记的水印，但会放在靠下的位置) 
         * @summary 通过 card id 获得预览图
         * @param {string} packageUuid 
         * @param {string} cardId 
         * @param {boolean} [html] 如果为 true, 则返回 html 内容（而非图片）
         * @param {boolean} [refreshCache] 如果为 true, 则重新生成缓存
         * @param {boolean} [useSnapshot] 如果为 true，则通过最新 snapshot 获取卡片内容（仅内部使用）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardPreview(packageUuid: string, cardId: string, html?: boolean, refreshCache?: boolean, useSnapshot?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.getCardPreview(packageUuid, cardId, html, refreshCache, useSnapshot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取某个卡片的学习次数数据
         * @param {HpkgCardStatisticRequest} [hpkgCardStatisticRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRateStatistic(hpkgCardStatisticRequest?: HpkgCardStatisticRequest, options?: any): AxiosPromise<CardRateStatisticVO> {
            return localVarFp.getCardRateStatistic(hpkgCardStatisticRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取 hpkg 未发布的更新情况
         * @summary 获取 hpkg 未发布的更新情况
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHpkgChanges(packageUuid: string, options?: any): AxiosPromise<HpkgChangesResponse> {
            return localVarFp.getHpkgChanges(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取当前备份状态
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageBackupStatus(packageUuid: string, options?: any): AxiosPromise<BackupStatusResponse> {
            return localVarFp.getPackageBackupStatus(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 列举备份日志列表
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBackLog(packageUuid: string, options?: any): AxiosPromise<BackupLogListResponse> {
            return localVarFp.listBackLog(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取某个卡片的日志
         * @param {HpkgCardRateLogRequest} [hpkgCardRateLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCardRateLog(hpkgCardRateLogRequest?: HpkgCardRateLogRequest, options?: any): AxiosPromise<CardRateLogListResponse> {
            return localVarFp.listCardRateLog(hpkgCardRateLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取hpkg更新日志
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHpkgChangeLogs(packageUuid: string, options?: any): AxiosPromise<HpkgChangeLogListResponse> {
            return localVarFp.listHpkgChangeLogs(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量获取Note信息
         * @param {string} packageUuid 
         * @param {NoteInfoGetRequest} [noteInfoGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotesInfo(packageUuid: string, noteInfoGetRequest?: NoteInfoGetRequest, options?: any): AxiosPromise<NoteContentListResponse> {
            return localVarFp.listNotesInfo(packageUuid, noteInfoGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 达到可上架状态，转 1.0 数据，首次发布创建 ugc_book + library_book + user_book
         * @summary 发布 Hpkg，并非上架，适用于更新
         * @param {string} packageUuid 
         * @param {HpkgPublishRequest} [hpkgPublishRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishHpkg(packageUuid: string, hpkgPublishRequest?: HpkgPublishRequest, options?: any): AxiosPromise<void> {
            return localVarFp.publishHpkg(packageUuid, hpkgPublishRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修复缺失的global_schedule_data（数据修复用）
         * @param {number} userId 
         * @param {RecoverHpkgRequest} [recoverHpkgRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverGlobalSchedule(userId: number, recoverHpkgRequest?: RecoverHpkgRequest, options?: any): AxiosPromise<void> {
            return localVarFp.recoverGlobalSchedule(userId, recoverHpkgRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修复packag中缺失的model
         * @param {string} packageUuid 
         * @param {string} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverModel(packageUuid: string, modelId: string, options?: any): AxiosPromise<void> {
            return localVarFp.recoverModel(packageUuid, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 回滚package到某个版本
         * @param {number} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackPackage(transactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.rollbackPackage(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创作者切换release版本
         * @param {string} packageUuid 
         * @param {string} releaseTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchReleaseTag(packageUuid: string, releaseTag: string, options?: any): AxiosPromise<void> {
            return localVarFp.switchReleaseTag(packageUuid, releaseTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 同步卡包meta信息
         * @param {SyncMetaRequest} [syncMetaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncHpkgMeta(syncMetaRequest?: SyncMetaRequest, options?: any): AxiosPromise<SyncHpkgMetaResponse> {
            return localVarFp.syncHpkgMeta(syncMetaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 同步卡片盒列表
         * @summary 同步卡片盒列表
         * @param {HpkgSyncRequest} [hpkgSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncHpkgs(hpkgSyncRequest?: HpkgSyncRequest, options?: any): AxiosPromise<HpkgSyncResponse> {
            return localVarFp.syncHpkgs(hpkgSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 更新卡包预览图
         * @summary 通过packageUuid更新整个卡包预览图
         * @param {string} packageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllCardPreview(packageUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateAllCardPreview(packageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {'sketch' | 'copy_note' | 'comment'} fileType 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCardDataZipByPackageId(packageUuid: string, fileType: 'sketch' | 'copy_note' | 'comment', file?: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadCardDataZipByPackageId(packageUuid, fileType, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMediaByApkgUuid(packageUuid: string, file?: any, options?: any): AxiosPromise<ApkgMediaResponse> {
            return localVarFp.uploadMediaByApkgUuid(packageUuid, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageUuid 
         * @param {any} [file] 
         * @param {boolean} [cardUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMediaZipByApkgUuid(packageUuid: string, file?: any, cardUserData?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.uploadMediaZipByApkgUuid(packageUuid, file, cardUserData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HpkgApi - object-oriented interface
 * @export
 * @class HpkgApi
 * @extends {BaseAPI}
 */
export class HpkgApi extends BaseAPI {
    /**
     * 
     * @summary 主动备份卡包
     * @param {string} packageUuid 
     * @param {string} [tag] 自定义备份标签
     * @param {string} [backupChannel] 是用户手动点击还是程序自动备份
     * @param {boolean} [checkVersion] 是否检查版本，如果是false 就会强制备份一次
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public backupPackage(packageUuid: string, tag?: string, backupChannel?: string, checkVersion?: boolean, options?: any) {
        return HpkgApiFp(this.configuration).backupPackage(packageUuid, tag, backupChannel, checkVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据mediaName下载某个卡包的media包
     * @param {string} packageUuid 
     * @param {BatchDownloadMediaRequest} [batchDownloadMediaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public batchDownLoadMediaZip(packageUuid: string, batchDownloadMediaRequest?: BatchDownloadMediaRequest, options?: any) {
        return HpkgApiFp(this.configuration).batchDownLoadMediaZip(packageUuid, batchDownloadMediaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查购买卡包是否有更新
     * @param {string} packageUuid 
     * @param {string} releaseTag 客户端当前版本
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public checkPackageUpdates(packageUuid: string, releaseTag: string, options?: any) {
        return HpkgApiFp(this.configuration).checkPackageUpdates(packageUuid, releaseTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public deletePackageOfHpkg(packageUuid: string, options?: any) {
        return HpkgApiFp(this.configuration).deletePackageOfHpkg(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 下载某个卡包的media包
     * @param {string} packageUuid 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public downLoadMediaZip(packageUuid: string, pageNumber?: number, pageSize?: number, options?: any) {
        return HpkgApiFp(this.configuration).downLoadMediaZip(packageUuid, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 可选地：带有 JWT token；如果是用户自己的卡册，则一定要带token，否则很多图片无法访问。  card_id 允许有两种形式：1. Hpkg 中的 uuid ；2. GET /apkg/v1/package/{package_uuid}/info 中返回的 card id  返回：302重定向至某图片；如果 302 响应的 Location (url) 中带有 \"fallback\" 字符，则不要缓存，说明是一个 fallback 图片。其它情况下，可以缓存。  403：如果用户没有权限访问某卡片（如未购买）  (可能带有滑记的水印，但会放在靠下的位置) 
     * @summary 通过 card id 获得预览图
     * @param {string} packageUuid 
     * @param {string} cardId 
     * @param {boolean} [html] 如果为 true, 则返回 html 内容（而非图片）
     * @param {boolean} [refreshCache] 如果为 true, 则重新生成缓存
     * @param {boolean} [useSnapshot] 如果为 true，则通过最新 snapshot 获取卡片内容（仅内部使用）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public getCardPreview(packageUuid: string, cardId: string, html?: boolean, refreshCache?: boolean, useSnapshot?: boolean, options?: any) {
        return HpkgApiFp(this.configuration).getCardPreview(packageUuid, cardId, html, refreshCache, useSnapshot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取某个卡片的学习次数数据
     * @param {HpkgCardStatisticRequest} [hpkgCardStatisticRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public getCardRateStatistic(hpkgCardStatisticRequest?: HpkgCardStatisticRequest, options?: any) {
        return HpkgApiFp(this.configuration).getCardRateStatistic(hpkgCardStatisticRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取 hpkg 未发布的更新情况
     * @summary 获取 hpkg 未发布的更新情况
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public getHpkgChanges(packageUuid: string, options?: any) {
        return HpkgApiFp(this.configuration).getHpkgChanges(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取当前备份状态
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public getPackageBackupStatus(packageUuid: string, options?: any) {
        return HpkgApiFp(this.configuration).getPackageBackupStatus(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 列举备份日志列表
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public listBackLog(packageUuid: string, options?: any) {
        return HpkgApiFp(this.configuration).listBackLog(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取某个卡片的日志
     * @param {HpkgCardRateLogRequest} [hpkgCardRateLogRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public listCardRateLog(hpkgCardRateLogRequest?: HpkgCardRateLogRequest, options?: any) {
        return HpkgApiFp(this.configuration).listCardRateLog(hpkgCardRateLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取hpkg更新日志
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public listHpkgChangeLogs(packageUuid: string, options?: any) {
        return HpkgApiFp(this.configuration).listHpkgChangeLogs(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量获取Note信息
     * @param {string} packageUuid 
     * @param {NoteInfoGetRequest} [noteInfoGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public listNotesInfo(packageUuid: string, noteInfoGetRequest?: NoteInfoGetRequest, options?: any) {
        return HpkgApiFp(this.configuration).listNotesInfo(packageUuid, noteInfoGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 达到可上架状态，转 1.0 数据，首次发布创建 ugc_book + library_book + user_book
     * @summary 发布 Hpkg，并非上架，适用于更新
     * @param {string} packageUuid 
     * @param {HpkgPublishRequest} [hpkgPublishRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public publishHpkg(packageUuid: string, hpkgPublishRequest?: HpkgPublishRequest, options?: any) {
        return HpkgApiFp(this.configuration).publishHpkg(packageUuid, hpkgPublishRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修复缺失的global_schedule_data（数据修复用）
     * @param {number} userId 
     * @param {RecoverHpkgRequest} [recoverHpkgRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public recoverGlobalSchedule(userId: number, recoverHpkgRequest?: RecoverHpkgRequest, options?: any) {
        return HpkgApiFp(this.configuration).recoverGlobalSchedule(userId, recoverHpkgRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修复packag中缺失的model
     * @param {string} packageUuid 
     * @param {string} modelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public recoverModel(packageUuid: string, modelId: string, options?: any) {
        return HpkgApiFp(this.configuration).recoverModel(packageUuid, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 回滚package到某个版本
     * @param {number} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public rollbackPackage(transactionId: number, options?: any) {
        return HpkgApiFp(this.configuration).rollbackPackage(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创作者切换release版本
     * @param {string} packageUuid 
     * @param {string} releaseTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public switchReleaseTag(packageUuid: string, releaseTag: string, options?: any) {
        return HpkgApiFp(this.configuration).switchReleaseTag(packageUuid, releaseTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 同步卡包meta信息
     * @param {SyncMetaRequest} [syncMetaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public syncHpkgMeta(syncMetaRequest?: SyncMetaRequest, options?: any) {
        return HpkgApiFp(this.configuration).syncHpkgMeta(syncMetaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 同步卡片盒列表
     * @summary 同步卡片盒列表
     * @param {HpkgSyncRequest} [hpkgSyncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public syncHpkgs(hpkgSyncRequest?: HpkgSyncRequest, options?: any) {
        return HpkgApiFp(this.configuration).syncHpkgs(hpkgSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 更新卡包预览图
     * @summary 通过packageUuid更新整个卡包预览图
     * @param {string} packageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public updateAllCardPreview(packageUuid: string, options?: any) {
        return HpkgApiFp(this.configuration).updateAllCardPreview(packageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageUuid 
     * @param {'sketch' | 'copy_note' | 'comment'} fileType 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public uploadCardDataZipByPackageId(packageUuid: string, fileType: 'sketch' | 'copy_note' | 'comment', file?: any, options?: any) {
        return HpkgApiFp(this.configuration).uploadCardDataZipByPackageId(packageUuid, fileType, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageUuid 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public uploadMediaByApkgUuid(packageUuid: string, file?: any, options?: any) {
        return HpkgApiFp(this.configuration).uploadMediaByApkgUuid(packageUuid, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageUuid 
     * @param {any} [file] 
     * @param {boolean} [cardUserData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HpkgApi
     */
    public uploadMediaZipByApkgUuid(packageUuid: string, file?: any, cardUserData?: boolean, options?: any) {
        return HpkgApiFp(this.configuration).uploadMediaZipByApkgUuid(packageUuid, file, cardUserData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdentityApi - axios parameter creator
 * @export
 */
export const IdentityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取当前实名认证请求的认证状态和认证资料
         * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
         * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerifyResult: async (bizId: string, bizType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bizId' is not null or undefined
            assertParamExists('getVerifyResult', 'bizId', bizId)
            // verify required parameter 'bizType' is not null or undefined
            assertParamExists('getVerifyResult', 'bizType', bizType)
            const localVarPath = `/identity/v1/cloud/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bizId !== undefined) {
                localVarQueryParameter['biz_id'] = bizId;
            }

            if (bizType !== undefined) {
                localVarQueryParameter['biz_type'] = bizType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询用户是否已实名认证
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUserIdentified: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发送实名认证请求，返回实名认证的token（30分钟内有效）
         * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
         * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
         * @param {IdentityVerifyTokenRequest} identityVerifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerifyToken: async (bizId: string, bizType: string, identityVerifyTokenRequest: IdentityVerifyTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bizId' is not null or undefined
            assertParamExists('requestVerifyToken', 'bizId', bizId)
            // verify required parameter 'bizType' is not null or undefined
            assertParamExists('requestVerifyToken', 'bizType', bizType)
            // verify required parameter 'identityVerifyTokenRequest' is not null or undefined
            assertParamExists('requestVerifyToken', 'identityVerifyTokenRequest', identityVerifyTokenRequest)
            const localVarPath = `/identity/v1/cloud/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bizId !== undefined) {
                localVarQueryParameter['biz_id'] = bizId;
            }

            if (bizType !== undefined) {
                localVarQueryParameter['biz_type'] = bizType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityVerifyTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityApi - functional programming interface
 * @export
 */
export const IdentityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取当前实名认证请求的认证状态和认证资料
         * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
         * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerifyResult(bizId: string, bizType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityVerifyResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerifyResult(bizId, bizType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询用户是否已实名认证
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUserIdentified(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityVerifyResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUserIdentified(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发送实名认证请求，返回实名认证的token（30分钟内有效）
         * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
         * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
         * @param {IdentityVerifyTokenRequest} identityVerifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestVerifyToken(bizId: string, bizType: string, identityVerifyTokenRequest: IdentityVerifyTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityVerifyTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestVerifyToken(bizId, bizType, identityVerifyTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdentityApi - factory interface
 * @export
 */
export const IdentityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentityApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取当前实名认证请求的认证状态和认证资料
         * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
         * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerifyResult(bizId: string, bizType: string, options?: any): AxiosPromise<IdentityVerifyResultResponse> {
            return localVarFp.getVerifyResult(bizId, bizType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询用户是否已实名认证
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUserIdentified(options?: any): AxiosPromise<IdentityVerifyResultResponse> {
            return localVarFp.isUserIdentified(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发送实名认证请求，返回实名认证的token（30分钟内有效）
         * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
         * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
         * @param {IdentityVerifyTokenRequest} identityVerifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestVerifyToken(bizId: string, bizType: string, identityVerifyTokenRequest: IdentityVerifyTokenRequest, options?: any): AxiosPromise<IdentityVerifyTokenResponse> {
            return localVarFp.requestVerifyToken(bizId, bizType, identityVerifyTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentityApi - object-oriented interface
 * @export
 * @class IdentityApi
 * @extends {BaseAPI}
 */
export class IdentityApi extends BaseAPI {
    /**
     * 
     * @summary 获取当前实名认证请求的认证状态和认证资料
     * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
     * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public getVerifyResult(bizId: string, bizType: string, options?: any) {
        return IdentityApiFp(this.configuration).getVerifyResult(bizId, bizType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询用户是否已实名认证
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public isUserIdentified(options?: any) {
        return IdentityApiFp(this.configuration).isUserIdentified(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发送实名认证请求，返回实名认证的token（30分钟内有效）
     * @param {string} bizId 认证ID, 由接入方指定, 发起不同的认证任务需要更换不同的认证ID
     * @param {string} bizType 实人认证控制台上创建场景时对应的场景标识
     * @param {IdentityVerifyTokenRequest} identityVerifyTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public requestVerifyToken(bizId: string, bizType: string, identityVerifyTokenRequest: IdentityVerifyTokenRequest, options?: any) {
        return IdentityApiFp(this.configuration).requestVerifyToken(bizId, bizType, identityVerifyTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InnerMessageApi - axios parameter creator
 * @export
 */
export const InnerMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 客户端批量修改消息状态
         * @param {BatchUpdateMessageStatusRequest} [batchUpdateMessageStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateReceiverMessageStatus: async (batchUpdateMessageStatusRequest?: BatchUpdateMessageStatusRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inner_message/v1/batch/update_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchUpdateMessageStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 只能由管理员创建站内信
         * @summary 创建站内信API
         * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInnerMessage: async (updateInnerMessageRequest?: UpdateInnerMessageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inner_message/v1/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInnerMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 查看站内信详情
         * @summary 查看站内信详情
         * @param {number} innerMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerMessageDetail: async (innerMessageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'innerMessageId' is not null or undefined
            assertParamExists('getInnerMessageDetail', 'innerMessageId', innerMessageId)
            const localVarPath = `/inner_message/v1/detail/{inner_message_id}`
                .replace(`{${"inner_message_id"}}`, encodeURIComponent(String(innerMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 管理员查看站内信列表
         * @summary 管理员查看站内信列表
         * @param {PageInnerMessageRequest} [pageInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageInnerMessage: async (pageInnerMessageRequest?: PageInnerMessageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inner_message/v1/admin/message_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageInnerMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 客户端拉取消息列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReceiverMessage: async (pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inner_message/v1/receive_message/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 管理员发送信息
         * @summary 管理员发送信息
         * @param {SendInnerMessageRequest} [sendInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInnerMessage: async (sendInnerMessageRequest?: SendInnerMessageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inner_message/v1/admin/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendInnerMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 只能由管理员修改站内信
         * @summary 修改站内信API
         * @param {number} messageId 站内信Id
         * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInnerMessage: async (messageId: number, updateInnerMessageRequest?: UpdateInnerMessageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('updateInnerMessage', 'messageId', messageId)
            const localVarPath = `/inner_message/v1/update/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInnerMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 客户端修改消息状态
         * @param {number} receiverInnerMessageId 
         * @param {string} status 可取值 已读, 删除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceiverMessageStatus: async (receiverInnerMessageId: number, status: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiverInnerMessageId' is not null or undefined
            assertParamExists('updateReceiverMessageStatus', 'receiverInnerMessageId', receiverInnerMessageId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('updateReceiverMessageStatus', 'status', status)
            const localVarPath = `/inner_message/v1/update_status/{receiver_inner_message_id}/{status}`
                .replace(`{${"receiver_inner_message_id"}}`, encodeURIComponent(String(receiverInnerMessageId)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InnerMessageApi - functional programming interface
 * @export
 */
export const InnerMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InnerMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 客户端批量修改消息状态
         * @param {BatchUpdateMessageStatusRequest} [batchUpdateMessageStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateReceiverMessageStatus(batchUpdateMessageStatusRequest?: BatchUpdateMessageStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateReceiverMessageStatus(batchUpdateMessageStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 只能由管理员创建站内信
         * @summary 创建站内信API
         * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInnerMessage(updateInnerMessageRequest?: UpdateInnerMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInnerMessage(updateInnerMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 查看站内信详情
         * @summary 查看站内信详情
         * @param {number} innerMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnerMessageDetail(innerMessageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerMessageDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnerMessageDetail(innerMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 管理员查看站内信列表
         * @summary 管理员查看站内信列表
         * @param {PageInnerMessageRequest} [pageInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPageInnerMessage(pageInnerMessageRequest?: PageInnerMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInnerMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPageInnerMessage(pageInnerMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 客户端拉取消息列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReceiverMessage(pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInnerMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReceiverMessage(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 管理员发送信息
         * @summary 管理员发送信息
         * @param {SendInnerMessageRequest} [sendInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInnerMessage(sendInnerMessageRequest?: SendInnerMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInnerMessage(sendInnerMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 只能由管理员修改站内信
         * @summary 修改站内信API
         * @param {number} messageId 站内信Id
         * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInnerMessage(messageId: number, updateInnerMessageRequest?: UpdateInnerMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInnerMessage(messageId, updateInnerMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 客户端修改消息状态
         * @param {number} receiverInnerMessageId 
         * @param {string} status 可取值 已读, 删除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReceiverMessageStatus(receiverInnerMessageId: number, status: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReceiverMessageStatus(receiverInnerMessageId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InnerMessageApi - factory interface
 * @export
 */
export const InnerMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InnerMessageApiFp(configuration)
    return {
        /**
         * 
         * @summary 客户端批量修改消息状态
         * @param {BatchUpdateMessageStatusRequest} [batchUpdateMessageStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateReceiverMessageStatus(batchUpdateMessageStatusRequest?: BatchUpdateMessageStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchUpdateReceiverMessageStatus(batchUpdateMessageStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 只能由管理员创建站内信
         * @summary 创建站内信API
         * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInnerMessage(updateInnerMessageRequest?: UpdateInnerMessageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createInnerMessage(updateInnerMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 查看站内信详情
         * @summary 查看站内信详情
         * @param {number} innerMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnerMessageDetail(innerMessageId: number, options?: any): AxiosPromise<InnerMessageDetail> {
            return localVarFp.getInnerMessageDetail(innerMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 管理员查看站内信列表
         * @summary 管理员查看站内信列表
         * @param {PageInnerMessageRequest} [pageInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageInnerMessage(pageInnerMessageRequest?: PageInnerMessageRequest, options?: any): AxiosPromise<PageInnerMessageResponse> {
            return localVarFp.listPageInnerMessage(pageInnerMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 客户端拉取消息列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReceiverMessage(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PageInnerMessageResponse> {
            return localVarFp.listReceiverMessage(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 管理员发送信息
         * @summary 管理员发送信息
         * @param {SendInnerMessageRequest} [sendInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInnerMessage(sendInnerMessageRequest?: SendInnerMessageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendInnerMessage(sendInnerMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 只能由管理员修改站内信
         * @summary 修改站内信API
         * @param {number} messageId 站内信Id
         * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInnerMessage(messageId: number, updateInnerMessageRequest?: UpdateInnerMessageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInnerMessage(messageId, updateInnerMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 客户端修改消息状态
         * @param {number} receiverInnerMessageId 
         * @param {string} status 可取值 已读, 删除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceiverMessageStatus(receiverInnerMessageId: number, status: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateReceiverMessageStatus(receiverInnerMessageId, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InnerMessageApi - object-oriented interface
 * @export
 * @class InnerMessageApi
 * @extends {BaseAPI}
 */
export class InnerMessageApi extends BaseAPI {
    /**
     * 
     * @summary 客户端批量修改消息状态
     * @param {BatchUpdateMessageStatusRequest} [batchUpdateMessageStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public batchUpdateReceiverMessageStatus(batchUpdateMessageStatusRequest?: BatchUpdateMessageStatusRequest, options?: any) {
        return InnerMessageApiFp(this.configuration).batchUpdateReceiverMessageStatus(batchUpdateMessageStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 只能由管理员创建站内信
     * @summary 创建站内信API
     * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public createInnerMessage(updateInnerMessageRequest?: UpdateInnerMessageRequest, options?: any) {
        return InnerMessageApiFp(this.configuration).createInnerMessage(updateInnerMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 查看站内信详情
     * @summary 查看站内信详情
     * @param {number} innerMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public getInnerMessageDetail(innerMessageId: number, options?: any) {
        return InnerMessageApiFp(this.configuration).getInnerMessageDetail(innerMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 管理员查看站内信列表
     * @summary 管理员查看站内信列表
     * @param {PageInnerMessageRequest} [pageInnerMessageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public listPageInnerMessage(pageInnerMessageRequest?: PageInnerMessageRequest, options?: any) {
        return InnerMessageApiFp(this.configuration).listPageInnerMessage(pageInnerMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 客户端拉取消息列表
     * @param {number} [pageNumber] 页码
     * @param {number} [pageSize] 页面数据量
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public listReceiverMessage(pageNumber?: number, pageSize?: number, options?: any) {
        return InnerMessageApiFp(this.configuration).listReceiverMessage(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 管理员发送信息
     * @summary 管理员发送信息
     * @param {SendInnerMessageRequest} [sendInnerMessageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public sendInnerMessage(sendInnerMessageRequest?: SendInnerMessageRequest, options?: any) {
        return InnerMessageApiFp(this.configuration).sendInnerMessage(sendInnerMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 只能由管理员修改站内信
     * @summary 修改站内信API
     * @param {number} messageId 站内信Id
     * @param {UpdateInnerMessageRequest} [updateInnerMessageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public updateInnerMessage(messageId: number, updateInnerMessageRequest?: UpdateInnerMessageRequest, options?: any) {
        return InnerMessageApiFp(this.configuration).updateInnerMessage(messageId, updateInnerMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 客户端修改消息状态
     * @param {number} receiverInnerMessageId 
     * @param {string} status 可取值 已读, 删除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InnerMessageApi
     */
    public updateReceiverMessageStatus(receiverInnerMessageId: number, status: string, options?: any) {
        return InnerMessageApiFp(this.configuration).updateReceiverMessageStatus(receiverInnerMessageId, status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LetterApi - axios parameter creator
 * @export
 */
export const LetterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 用户创建祈愿信息（breadcast版本）
         * @param {string} namespace 表明事件类型
         * @param {CreateLetterRequest} createLetterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBroadcastLetter: async (namespace: string, createLetterRequest: CreateLetterRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createBroadcastLetter', 'namespace', namespace)
            // verify required parameter 'createLetterRequest' is not null or undefined
            assertParamExists('createBroadcastLetter', 'createLetterRequest', createLetterRequest)
            const localVarPath = `/letter/v1/namepace/{namespace}/broadcast/create`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLetterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建评论
         * @param {string} namespace 
         * @param {number} instanceLetterId 
         * @param {CreateLetterCommentRequest} createLetterCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (namespace: string, instanceLetterId: number, createLetterCommentRequest: CreateLetterCommentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createComment', 'namespace', namespace)
            // verify required parameter 'instanceLetterId' is not null or undefined
            assertParamExists('createComment', 'instanceLetterId', instanceLetterId)
            // verify required parameter 'createLetterCommentRequest' is not null or undefined
            assertParamExists('createComment', 'createLetterCommentRequest', createLetterCommentRequest)
            const localVarPath = `/letter/v1/namepace/{namespace}/instance/{instance_letter_id}/comment`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"instance_letter_id"}}`, encodeURIComponent(String(instanceLetterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLetterCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除祈愿信息（broadcast版本）
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBroadcastLetter: async (broadcastLetterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'broadcastLetterId' is not null or undefined
            assertParamExists('deleteBroadcastLetter', 'broadcastLetterId', broadcastLetterId)
            const localVarPath = `/letter/v1/{broadcast_letter_id}`
                .replace(`{${"broadcast_letter_id"}}`, encodeURIComponent(String(broadcastLetterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除评论
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (commentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/letter/v1/comment/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户根据broadcastId获取祈愿信息的内容
         * @param {string} namespace 
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastLetter: async (namespace: string, broadcastLetterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getBroadcastLetter', 'namespace', namespace)
            // verify required parameter 'broadcastLetterId' is not null or undefined
            assertParamExists('getBroadcastLetter', 'broadcastLetterId', broadcastLetterId)
            const localVarPath = `/letter/v1/namepace/{namespace}/broadcast/{broadcast_letter_id}`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"broadcast_letter_id"}}`, encodeURIComponent(String(broadcastLetterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取祈愿信息（instance）详情包括评论等等
         * @param {string} namespace 标明不同活动的性质：本次是benidiction
         * @param {number} instanceLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceLetter: async (namespace: string, instanceLetterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getInstanceLetter', 'namespace', namespace)
            // verify required parameter 'instanceLetterId' is not null or undefined
            assertParamExists('getInstanceLetter', 'instanceLetterId', instanceLetterId)
            const localVarPath = `/letter/v1/namepace/{namespace}/instance/{instance_letter_id}`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"instance_letter_id"}}`, encodeURIComponent(String(instanceLetterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取全局祈愿信息概况
         * @param {string} namespace 
         * @param {string} filterType 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLetterList: async (namespace: string, filterType: string, pageNumber: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getLetterList', 'namespace', namespace)
            // verify required parameter 'filterType' is not null or undefined
            assertParamExists('getLetterList', 'filterType', filterType)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getLetterList', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getLetterList', 'pageSize', pageSize)
            const localVarPath = `/letter/v1/namepace/{namespace}/instance/filter_type/{filter_type}/list`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"filter_type"}}`, encodeURIComponent(String(filterType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 实例化祈愿信息，即接受这封信，变成自己的instance
         * @param {string} namespace 
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        instantiateLetter: async (namespace: string, broadcastLetterId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('instantiateLetter', 'namespace', namespace)
            // verify required parameter 'broadcastLetterId' is not null or undefined
            assertParamExists('instantiateLetter', 'broadcastLetterId', broadcastLetterId)
            const localVarPath = `/letter/v1/namepace/{namespace}/broadcast/{broadcast_letter_id}/instantiate`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"broadcast_letter_id"}}`, encodeURIComponent(String(broadcastLetterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LetterApi - functional programming interface
 * @export
 */
export const LetterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LetterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 用户创建祈愿信息（breadcast版本）
         * @param {string} namespace 表明事件类型
         * @param {CreateLetterRequest} createLetterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBroadcastLetter(namespace: string, createLetterRequest: CreateLetterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LetterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBroadcastLetter(namespace, createLetterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建评论
         * @param {string} namespace 
         * @param {number} instanceLetterId 
         * @param {CreateLetterCommentRequest} createLetterCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(namespace: string, instanceLetterId: number, createLetterCommentRequest: CreateLetterCommentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(namespace, instanceLetterId, createLetterCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除祈愿信息（broadcast版本）
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBroadcastLetter(broadcastLetterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBroadcastLetter(broadcastLetterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除评论
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(commentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户根据broadcastId获取祈愿信息的内容
         * @param {string} namespace 
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBroadcastLetter(namespace: string, broadcastLetterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LetterInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBroadcastLetter(namespace, broadcastLetterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取祈愿信息（instance）详情包括评论等等
         * @param {string} namespace 标明不同活动的性质：本次是benidiction
         * @param {number} instanceLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstanceLetter(namespace: string, instanceLetterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LetterInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstanceLetter(namespace, instanceLetterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取全局祈愿信息概况
         * @param {string} namespace 
         * @param {string} filterType 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLetterList(namespace: string, filterType: string, pageNumber: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LetterBriefInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLetterList(namespace, filterType, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 实例化祈愿信息，即接受这封信，变成自己的instance
         * @param {string} namespace 
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async instantiateLetter(namespace: string, broadcastLetterId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.instantiateLetter(namespace, broadcastLetterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LetterApi - factory interface
 * @export
 */
export const LetterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LetterApiFp(configuration)
    return {
        /**
         * 
         * @summary 用户创建祈愿信息（breadcast版本）
         * @param {string} namespace 表明事件类型
         * @param {CreateLetterRequest} createLetterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBroadcastLetter(namespace: string, createLetterRequest: CreateLetterRequest, options?: any): AxiosPromise<LetterResponse> {
            return localVarFp.createBroadcastLetter(namespace, createLetterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建评论
         * @param {string} namespace 
         * @param {number} instanceLetterId 
         * @param {CreateLetterCommentRequest} createLetterCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(namespace: string, instanceLetterId: number, createLetterCommentRequest: CreateLetterCommentRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createComment(namespace, instanceLetterId, createLetterCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除祈愿信息（broadcast版本）
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBroadcastLetter(broadcastLetterId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBroadcastLetter(broadcastLetterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除评论
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户根据broadcastId获取祈愿信息的内容
         * @param {string} namespace 
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBroadcastLetter(namespace: string, broadcastLetterId: number, options?: any): AxiosPromise<LetterInfoResponse> {
            return localVarFp.getBroadcastLetter(namespace, broadcastLetterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取祈愿信息（instance）详情包括评论等等
         * @param {string} namespace 标明不同活动的性质：本次是benidiction
         * @param {number} instanceLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceLetter(namespace: string, instanceLetterId: number, options?: any): AxiosPromise<LetterInfoResponse> {
            return localVarFp.getInstanceLetter(namespace, instanceLetterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取全局祈愿信息概况
         * @param {string} namespace 
         * @param {string} filterType 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLetterList(namespace: string, filterType: string, pageNumber: number, pageSize: number, options?: any): AxiosPromise<Array<LetterBriefInfo>> {
            return localVarFp.getLetterList(namespace, filterType, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 实例化祈愿信息，即接受这封信，变成自己的instance
         * @param {string} namespace 
         * @param {number} broadcastLetterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        instantiateLetter(namespace: string, broadcastLetterId: number, options?: any): AxiosPromise<number> {
            return localVarFp.instantiateLetter(namespace, broadcastLetterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LetterApi - object-oriented interface
 * @export
 * @class LetterApi
 * @extends {BaseAPI}
 */
export class LetterApi extends BaseAPI {
    /**
     * 
     * @summary 用户创建祈愿信息（breadcast版本）
     * @param {string} namespace 表明事件类型
     * @param {CreateLetterRequest} createLetterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public createBroadcastLetter(namespace: string, createLetterRequest: CreateLetterRequest, options?: any) {
        return LetterApiFp(this.configuration).createBroadcastLetter(namespace, createLetterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建评论
     * @param {string} namespace 
     * @param {number} instanceLetterId 
     * @param {CreateLetterCommentRequest} createLetterCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public createComment(namespace: string, instanceLetterId: number, createLetterCommentRequest: CreateLetterCommentRequest, options?: any) {
        return LetterApiFp(this.configuration).createComment(namespace, instanceLetterId, createLetterCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除祈愿信息（broadcast版本）
     * @param {number} broadcastLetterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public deleteBroadcastLetter(broadcastLetterId: number, options?: any) {
        return LetterApiFp(this.configuration).deleteBroadcastLetter(broadcastLetterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除评论
     * @param {number} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public deleteComment(commentId: number, options?: any) {
        return LetterApiFp(this.configuration).deleteComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户根据broadcastId获取祈愿信息的内容
     * @param {string} namespace 
     * @param {number} broadcastLetterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public getBroadcastLetter(namespace: string, broadcastLetterId: number, options?: any) {
        return LetterApiFp(this.configuration).getBroadcastLetter(namespace, broadcastLetterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取祈愿信息（instance）详情包括评论等等
     * @param {string} namespace 标明不同活动的性质：本次是benidiction
     * @param {number} instanceLetterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public getInstanceLetter(namespace: string, instanceLetterId: number, options?: any) {
        return LetterApiFp(this.configuration).getInstanceLetter(namespace, instanceLetterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取全局祈愿信息概况
     * @param {string} namespace 
     * @param {string} filterType 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public getLetterList(namespace: string, filterType: string, pageNumber: number, pageSize: number, options?: any) {
        return LetterApiFp(this.configuration).getLetterList(namespace, filterType, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 实例化祈愿信息，即接受这封信，变成自己的instance
     * @param {string} namespace 
     * @param {number} broadcastLetterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LetterApi
     */
    public instantiateLetter(namespace: string, broadcastLetterId: number, options?: any) {
        return LetterApiFp(this.configuration).instantiateLetter(namespace, broadcastLetterId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LibraryApi - axios parameter creator
 * @export
 */
export const LibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取图书馆分类列表的树形结构
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryCategoryTree: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/library/v1/category/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecommendBooksInLearnTab: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/library/v1/books/learn_tab/recommend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LibraryApi - functional programming interface
 * @export
 */
export const LibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取图书馆分类列表的树形结构
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryCategoryTree(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLibraryCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLibraryCategoryTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecommendBooksInLearnTab(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLibraryBookVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecommendBooksInLearnTab(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LibraryApi - factory interface
 * @export
 */
export const LibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LibraryApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取图书馆分类列表的树形结构
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryCategoryTree(options?: any): AxiosPromise<GetLibraryCategoryResponse> {
            return localVarFp.getLibraryCategoryTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecommendBooksInLearnTab(options?: any): AxiosPromise<ListLibraryBookVO> {
            return localVarFp.listRecommendBooksInLearnTab(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LibraryApi - object-oriented interface
 * @export
 * @class LibraryApi
 * @extends {BaseAPI}
 */
export class LibraryApi extends BaseAPI {
    /**
     * 
     * @summary 获取图书馆分类列表的树形结构
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getLibraryCategoryTree(options?: any) {
        return LibraryApiFp(this.configuration).getLibraryCategoryTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public listRecommendBooksInLearnTab(options?: any) {
        return LibraryApiFp(this.configuration).listRecommendBooksInLearnTab(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoggingApi - axios parameter creator
 * @export
 */
export const LoggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AppBulkLogRequest} appBulkLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSendAppLog: async (appBulkLogRequest: AppBulkLogRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appBulkLogRequest' is not null or undefined
            assertParamExists('bulkSendAppLog', 'appBulkLogRequest', appBulkLogRequest)
            const localVarPath = `/logging/v1/bulk_log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appBulkLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 添加一条日志（会打印到ES中）
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggingAddLog: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logging/v1/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoggingApi - functional programming interface
 * @export
 */
export const LoggingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoggingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AppBulkLogRequest} appBulkLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSendAppLog(appBulkLogRequest: AppBulkLogRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSendAppLog(appBulkLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 添加一条日志（会打印到ES中）
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loggingAddLog(inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loggingAddLog(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoggingApi - factory interface
 * @export
 */
export const LoggingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoggingApiFp(configuration)
    return {
        /**
         * 
         * @param {AppBulkLogRequest} appBulkLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSendAppLog(appBulkLogRequest: AppBulkLogRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bulkSendAppLog(appBulkLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 添加一条日志（会打印到ES中）
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loggingAddLog(inlineObject?: InlineObject, options?: any): AxiosPromise<object> {
            return localVarFp.loggingAddLog(inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoggingApi - object-oriented interface
 * @export
 * @class LoggingApi
 * @extends {BaseAPI}
 */
export class LoggingApi extends BaseAPI {
    /**
     * 
     * @param {AppBulkLogRequest} appBulkLogRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggingApi
     */
    public bulkSendAppLog(appBulkLogRequest: AppBulkLogRequest, options?: any) {
        return LoggingApiFp(this.configuration).bulkSendAppLog(appBulkLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 添加一条日志（会打印到ES中）
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggingApi
     */
    public loggingAddLog(inlineObject?: InlineObject, options?: any) {
        return LoggingApiFp(this.configuration).loggingAddLog(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManageUserApi - axios parameter creator
 * @export
 */
export const ManageUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 管理员查询用户验证码
         * @param {string} phoneNumber 
         * @param {number} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmsCode: async (phoneNumber: string, countryCode: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('getSmsCode', 'phoneNumber', phoneNumber)
            // verify required parameter 'countryCode' is not null or undefined
            assertParamExists('getSmsCode', 'countryCode', countryCode)
            const localVarPath = `/manage/user/v1/phone/{country_code}/{phone_number}/code`
                .replace(`{${"phone_number"}}`, encodeURIComponent(String(phoneNumber)))
                .replace(`{${"country_code"}}`, encodeURIComponent(String(countryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理员分页模糊查询用户信息
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [phoneNumber] 
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageUserInfoByManager: async (queryPageRequest: QueryPageRequest, phoneNumber?: string, nickname?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryPageRequest' is not null or undefined
            assertParamExists('queryPageUserInfoByManager', 'queryPageRequest', queryPageRequest)
            const localVarPath = `/manage/user/v1/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }

            if (nickname !== undefined) {
                localVarQueryParameter['nickname'] = nickname;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 管理员查询用户信息
         * @param {string} [phoneNumber] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUserInfoByManager: async (phoneNumber?: string, userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/manage/user/v1/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManageUserApi - functional programming interface
 * @export
 */
export const ManageUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManageUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 管理员查询用户验证码
         * @param {string} phoneNumber 
         * @param {number} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSmsCode(phoneNumber: string, countryCode: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSmsCode(phoneNumber, countryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理员分页模糊查询用户信息
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [phoneNumber] 
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPageUserInfoByManager(queryPageRequest: QueryPageRequest, phoneNumber?: string, nickname?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPageUserInfoByManager(queryPageRequest, phoneNumber, nickname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 管理员查询用户信息
         * @param {string} [phoneNumber] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryUserInfoByManager(phoneNumber?: string, userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryUserInfoByManager(phoneNumber, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManageUserApi - factory interface
 * @export
 */
export const ManageUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManageUserApiFp(configuration)
    return {
        /**
         * 
         * @summary 管理员查询用户验证码
         * @param {string} phoneNumber 
         * @param {number} countryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSmsCode(phoneNumber: string, countryCode: number, options?: any): AxiosPromise<SmsCodeResponse> {
            return localVarFp.getSmsCode(phoneNumber, countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理员分页模糊查询用户信息
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [phoneNumber] 
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageUserInfoByManager(queryPageRequest: QueryPageRequest, phoneNumber?: string, nickname?: string, options?: any): AxiosPromise<PageUserInfo> {
            return localVarFp.queryPageUserInfoByManager(queryPageRequest, phoneNumber, nickname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 管理员查询用户信息
         * @param {string} [phoneNumber] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUserInfoByManager(phoneNumber?: string, userId?: number, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.queryUserInfoByManager(phoneNumber, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManageUserApi - object-oriented interface
 * @export
 * @class ManageUserApi
 * @extends {BaseAPI}
 */
export class ManageUserApi extends BaseAPI {
    /**
     * 
     * @summary 管理员查询用户验证码
     * @param {string} phoneNumber 
     * @param {number} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageUserApi
     */
    public getSmsCode(phoneNumber: string, countryCode: number, options?: any) {
        return ManageUserApiFp(this.configuration).getSmsCode(phoneNumber, countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理员分页模糊查询用户信息
     * @param {QueryPageRequest} queryPageRequest 
     * @param {string} [phoneNumber] 
     * @param {string} [nickname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageUserApi
     */
    public queryPageUserInfoByManager(queryPageRequest: QueryPageRequest, phoneNumber?: string, nickname?: string, options?: any) {
        return ManageUserApiFp(this.configuration).queryPageUserInfoByManager(queryPageRequest, phoneNumber, nickname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 管理员查询用户信息
     * @param {string} [phoneNumber] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageUserApi
     */
    public queryUserInfoByManager(phoneNumber?: string, userId?: number, options?: any) {
        return ManageUserApiFp(this.configuration).queryUserInfoByManager(phoneNumber, userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 批量已读创作者消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReadCreatorMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/creator-msg/batch-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量已读消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReadInteractiveMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/interactive/batch-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量回复消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReplyInteractiveMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/interactive/merit/batch-reply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取收到的小红花和鼓励消息数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInteractiveCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/interactive-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取未读消息数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/unread-message-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取创作消息历史列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistoryCreatorMessages: async (pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/history-creator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取历史消息列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistoryInteractiveMessages: async (pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/history-interactive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取创作消息未读列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnreadCreatorMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/unread-creator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取新消息列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnreadInteractiveMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/unread-interactive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发送互动消息
         * @param {SendInteractiveMsgRequest} [sendInteractiveMsgRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInteractiveMessage: async (sendInteractiveMsgRequest?: SendInteractiveMsgRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/v1/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendInteractiveMsgRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 批量已读创作者消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReadCreatorMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReadCreatorMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量已读消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReadInteractiveMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReadInteractiveMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量回复消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReplyInteractiveMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReplyInteractiveMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取收到的小红花和鼓励消息数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInteractiveCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InteractiveCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInteractiveCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取未读消息数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadMessageCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageUnReadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadMessageCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取创作消息历史列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHistoryCreatorMessages(pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorMsgPageListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHistoryCreatorMessages(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取历史消息列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHistoryInteractiveMessages(pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InteractiveMsgPageListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHistoryInteractiveMessages(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取创作消息未读列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnreadCreatorMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorMsgListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnreadCreatorMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取新消息列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnreadInteractiveMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InteractiveMsgListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnreadInteractiveMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发送互动消息
         * @param {SendInteractiveMsgRequest} [sendInteractiveMsgRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInteractiveMessage(sendInteractiveMsgRequest?: SendInteractiveMsgRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInteractiveMessage(sendInteractiveMsgRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary 批量已读创作者消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReadCreatorMessages(options?: any): AxiosPromise<void> {
            return localVarFp.batchReadCreatorMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量已读消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReadInteractiveMessages(options?: any): AxiosPromise<void> {
            return localVarFp.batchReadInteractiveMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量回复消息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReplyInteractiveMessages(options?: any): AxiosPromise<void> {
            return localVarFp.batchReplyInteractiveMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取收到的小红花和鼓励消息数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInteractiveCount(options?: any): AxiosPromise<InteractiveCountResponse> {
            return localVarFp.getInteractiveCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取未读消息数量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCount(options?: any): AxiosPromise<MessageUnReadResponse> {
            return localVarFp.getUnreadMessageCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取创作消息历史列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistoryCreatorMessages(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<CreatorMsgPageListResponse> {
            return localVarFp.listHistoryCreatorMessages(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取历史消息列表
         * @param {number} [pageNumber] 页码
         * @param {number} [pageSize] 页面数据量
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHistoryInteractiveMessages(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<InteractiveMsgPageListResponse> {
            return localVarFp.listHistoryInteractiveMessages(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取创作消息未读列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnreadCreatorMessages(options?: any): AxiosPromise<CreatorMsgListResponse> {
            return localVarFp.listUnreadCreatorMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取新消息列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnreadInteractiveMessages(options?: any): AxiosPromise<InteractiveMsgListResponse> {
            return localVarFp.listUnreadInteractiveMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发送互动消息
         * @param {SendInteractiveMsgRequest} [sendInteractiveMsgRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInteractiveMessage(sendInteractiveMsgRequest?: SendInteractiveMsgRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendInteractiveMessage(sendInteractiveMsgRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary 批量已读创作者消息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public batchReadCreatorMessages(options?: any) {
        return MessageApiFp(this.configuration).batchReadCreatorMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量已读消息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public batchReadInteractiveMessages(options?: any) {
        return MessageApiFp(this.configuration).batchReadInteractiveMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量回复消息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public batchReplyInteractiveMessages(options?: any) {
        return MessageApiFp(this.configuration).batchReplyInteractiveMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取收到的小红花和鼓励消息数量
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getInteractiveCount(options?: any) {
        return MessageApiFp(this.configuration).getInteractiveCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取未读消息数量
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getUnreadMessageCount(options?: any) {
        return MessageApiFp(this.configuration).getUnreadMessageCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取创作消息历史列表
     * @param {number} [pageNumber] 页码
     * @param {number} [pageSize] 页面数据量
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public listHistoryCreatorMessages(pageNumber?: number, pageSize?: number, options?: any) {
        return MessageApiFp(this.configuration).listHistoryCreatorMessages(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取历史消息列表
     * @param {number} [pageNumber] 页码
     * @param {number} [pageSize] 页面数据量
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public listHistoryInteractiveMessages(pageNumber?: number, pageSize?: number, options?: any) {
        return MessageApiFp(this.configuration).listHistoryInteractiveMessages(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取创作消息未读列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public listUnreadCreatorMessages(options?: any) {
        return MessageApiFp(this.configuration).listUnreadCreatorMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取新消息列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public listUnreadInteractiveMessages(options?: any) {
        return MessageApiFp(this.configuration).listUnreadInteractiveMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发送互动消息
     * @param {SendInteractiveMsgRequest} [sendInteractiveMsgRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public sendInteractiveMessage(sendInteractiveMsgRequest?: SendInteractiveMsgRequest, options?: any) {
        return MessageApiFp(this.configuration).sendInteractiveMessage(sendInteractiveMsgRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OCRApi - axios parameter creator
 * @export
 */
export const OCRApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pictureFileOCRTransform: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ocr/v1/image/transform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrcQueryRequest} [orcQueryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pictureOCRTransform: async (orcQueryRequest?: OrcQueryRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ocr/v1/url/transform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orcQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OCRApi - functional programming interface
 * @export
 */
export const OCRApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OCRApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pictureFileOCRTransform(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OCRResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pictureFileOCRTransform(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrcQueryRequest} [orcQueryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pictureOCRTransform(orcQueryRequest?: OrcQueryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OCRResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pictureOCRTransform(orcQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OCRApi - factory interface
 * @export
 */
export const OCRApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OCRApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pictureFileOCRTransform(file?: any, options?: any): AxiosPromise<OCRResult> {
            return localVarFp.pictureFileOCRTransform(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrcQueryRequest} [orcQueryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pictureOCRTransform(orcQueryRequest?: OrcQueryRequest, options?: any): AxiosPromise<OCRResult> {
            return localVarFp.pictureOCRTransform(orcQueryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OCRApi - object-oriented interface
 * @export
 * @class OCRApi
 * @extends {BaseAPI}
 */
export class OCRApi extends BaseAPI {
    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OCRApi
     */
    public pictureFileOCRTransform(file?: any, options?: any) {
        return OCRApiFp(this.configuration).pictureFileOCRTransform(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrcQueryRequest} [orcQueryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OCRApi
     */
    public pictureOCRTransform(orcQueryRequest?: OrcQueryRequest, options?: any) {
        return OCRApiFp(this.configuration).pictureOCRTransform(orcQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfficialSiteApi - axios parameter creator
 * @export
 */
export const OfficialSiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 数据是周期性从数据库中读取的。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/official_site/v1/product/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfficialSiteApi - functional programming interface
 * @export
 */
export const OfficialSiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfficialSiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 数据是周期性从数据库中读取的。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfficialSiteApi - factory interface
 * @export
 */
export const OfficialSiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfficialSiteApiFp(configuration)
    return {
        /**
         * 数据是周期性从数据库中读取的。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductInfo(options?: any): AxiosPromise<ProductInfoResponse> {
            return localVarFp.getProductInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfficialSiteApi - object-oriented interface
 * @export
 * @class OfficialSiteApi
 * @extends {BaseAPI}
 */
export class OfficialSiteApi extends BaseAPI {
    /**
     * 数据是周期性从数据库中读取的。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficialSiteApi
     */
    public getProductInfo(options?: any) {
        return OfficialSiteApiFp(this.configuration).getProductInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 导出创作者收入明细
         * @param {string} token /auth/v1/generate/download_token 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCreatorIncomeDetail: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('downloadCreatorIncomeDetail', 'token', token)
            const localVarPath = `/order/v1/creator/download/excel/income_detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 用于图书订单分享，不能作为购买依据，因为此订单有可能被退款
         * @summary 获取用户某本书状态为delivered的订单详情
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDeliveredOrderByBookId: async (libraryBookId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'libraryBookId' is not null or undefined
            assertParamExists('getMyDeliveredOrderByBookId', 'libraryBookId', libraryBookId)
            const localVarPath = `/order/v1/book/{library_book_id}/query`
                .replace(`{${"library_book_id"}}`, encodeURIComponent(String(libraryBookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取订单详情
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetail: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getOrderDetail', 'orderId', orderId)
            const localVarPath = `/order/v1/{order_id}/query`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 批量获取VIP订单
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [phoneNumber] 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageVipOrder: async (pageSize: number, pageNumber: number, phoneNumber?: string, orderId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listPageVipOrder', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('listPageVipOrder', 'pageNumber', pageNumber)
            const localVarPath = `/order/v1/vip/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 下载用户的订单数据，导出为excel
         * @param {string} [token] &#x60;/auth/v1/generate/download_token&#x60; 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDownLoadExcel: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/order/v1/creator/my/download/excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrderRefund: async (orderId: number, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('postOrderRefund', 'orderId', orderId)
            const localVarPath = `/order/v1/{order_id}/refund`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * order_status:     DELIVERED,PAID : 已支付     CREATED: 未付款
         * @summary 获取创作者用户的订单信息
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [filterType] 根据支付状态对订单进行筛选，如果不传则为 全部支付状态的订单
         * @param {number} [creatorId] 创作者id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageOrderInfo: async (queryPageRequest: QueryPageRequest, filterType?: string, creatorId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryPageRequest' is not null or undefined
            assertParamExists('queryPageOrderInfo', 'queryPageRequest', queryPageRequest)
            const localVarPath = `/order/v1/creator/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterType !== undefined) {
                localVarQueryParameter['filter_type'] = filterType;
            }

            if (creatorId !== undefined) {
                localVarQueryParameter['creator_id'] = creatorId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分页查询用户营销订单信息
         * @param {QueryPageRequest} [queryPageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageOrderMarketingInfo: async (queryPageRequest?: QueryPageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/order/v1/marketing/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 导出创作者收入明细
         * @param {string} token /auth/v1/generate/download_token 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCreatorIncomeDetail(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCreatorIncomeDetail(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 用于图书订单分享，不能作为购买依据，因为此订单有可能被退款
         * @summary 获取用户某本书状态为delivered的订单详情
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyDeliveredOrderByBookId(libraryBookId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyDeliveredOrderByBookId(libraryBookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取订单详情
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderDetail(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderDetail(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 批量获取VIP订单
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [phoneNumber] 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPageVipOrder(pageSize: number, pageNumber: number, phoneNumber?: string, orderId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOrderInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPageVipOrder(pageSize, pageNumber, phoneNumber, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 下载用户的订单数据，导出为excel
         * @param {string} [token] &#x60;/auth/v1/generate/download_token&#x60; 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderDownLoadExcel(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderDownLoadExcel(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrderRefund(orderId: number, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrderRefund(orderId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * order_status:     DELIVERED,PAID : 已支付     CREATED: 未付款
         * @summary 获取创作者用户的订单信息
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [filterType] 根据支付状态对订单进行筛选，如果不传则为 全部支付状态的订单
         * @param {number} [creatorId] 创作者id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPageOrderInfo(queryPageRequest: QueryPageRequest, filterType?: string, creatorId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOrderInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPageOrderInfo(queryPageRequest, filterType, creatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分页查询用户营销订单信息
         * @param {QueryPageRequest} [queryPageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPageOrderMarketingInfo(queryPageRequest?: QueryPageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOrderMarketingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPageOrderMarketingInfo(queryPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary 导出创作者收入明细
         * @param {string} token /auth/v1/generate/download_token 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCreatorIncomeDetail(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadCreatorIncomeDetail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 用于图书订单分享，不能作为购买依据，因为此订单有可能被退款
         * @summary 获取用户某本书状态为delivered的订单详情
         * @param {number} libraryBookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyDeliveredOrderByBookId(libraryBookId: number, options?: any): AxiosPromise<OrderDetailVO> {
            return localVarFp.getMyDeliveredOrderByBookId(libraryBookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取订单详情
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDetail(orderId: number, options?: any): AxiosPromise<OrderDetailVO> {
            return localVarFp.getOrderDetail(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 批量获取VIP订单
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [phoneNumber] 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageVipOrder(pageSize: number, pageNumber: number, phoneNumber?: string, orderId?: number, options?: any): AxiosPromise<PageOrderInfo> {
            return localVarFp.listPageVipOrder(pageSize, pageNumber, phoneNumber, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 下载用户的订单数据，导出为excel
         * @param {string} [token] &#x60;/auth/v1/generate/download_token&#x60; 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDownLoadExcel(token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderDownLoadExcel(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrderRefund(orderId: number, body?: object, options?: any): AxiosPromise<CommonResponse> {
            return localVarFp.postOrderRefund(orderId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * order_status:     DELIVERED,PAID : 已支付     CREATED: 未付款
         * @summary 获取创作者用户的订单信息
         * @param {QueryPageRequest} queryPageRequest 
         * @param {string} [filterType] 根据支付状态对订单进行筛选，如果不传则为 全部支付状态的订单
         * @param {number} [creatorId] 创作者id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageOrderInfo(queryPageRequest: QueryPageRequest, filterType?: string, creatorId?: number, options?: any): AxiosPromise<PageOrderInfo> {
            return localVarFp.queryPageOrderInfo(queryPageRequest, filterType, creatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分页查询用户营销订单信息
         * @param {QueryPageRequest} [queryPageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPageOrderMarketingInfo(queryPageRequest?: QueryPageRequest, options?: any): AxiosPromise<PageOrderMarketingInfo> {
            return localVarFp.queryPageOrderMarketingInfo(queryPageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary 导出创作者收入明细
     * @param {string} token /auth/v1/generate/download_token 生成的token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public downloadCreatorIncomeDetail(token: string, options?: any) {
        return OrderApiFp(this.configuration).downloadCreatorIncomeDetail(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 用于图书订单分享，不能作为购买依据，因为此订单有可能被退款
     * @summary 获取用户某本书状态为delivered的订单详情
     * @param {number} libraryBookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getMyDeliveredOrderByBookId(libraryBookId: number, options?: any) {
        return OrderApiFp(this.configuration).getMyDeliveredOrderByBookId(libraryBookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取订单详情
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderDetail(orderId: number, options?: any) {
        return OrderApiFp(this.configuration).getOrderDetail(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 批量获取VIP订单
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [phoneNumber] 
     * @param {number} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public listPageVipOrder(pageSize: number, pageNumber: number, phoneNumber?: string, orderId?: number, options?: any) {
        return OrderApiFp(this.configuration).listPageVipOrder(pageSize, pageNumber, phoneNumber, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 下载用户的订单数据，导出为excel
     * @param {string} [token] &#x60;/auth/v1/generate/download_token&#x60; 生成的token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderDownLoadExcel(token?: string, options?: any) {
        return OrderApiFp(this.configuration).orderDownLoadExcel(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orderId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postOrderRefund(orderId: number, body?: object, options?: any) {
        return OrderApiFp(this.configuration).postOrderRefund(orderId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * order_status:     DELIVERED,PAID : 已支付     CREATED: 未付款
     * @summary 获取创作者用户的订单信息
     * @param {QueryPageRequest} queryPageRequest 
     * @param {string} [filterType] 根据支付状态对订单进行筛选，如果不传则为 全部支付状态的订单
     * @param {number} [creatorId] 创作者id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public queryPageOrderInfo(queryPageRequest: QueryPageRequest, filterType?: string, creatorId?: number, options?: any) {
        return OrderApiFp(this.configuration).queryPageOrderInfo(queryPageRequest, filterType, creatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分页查询用户营销订单信息
     * @param {QueryPageRequest} [queryPageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public queryPageOrderMarketingInfo(queryPageRequest?: QueryPageRequest, options?: any) {
        return OrderApiFp(this.configuration).queryPageOrderMarketingInfo(queryPageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 检查苹果支付结果，并且发放商品
         * @param {CheckPaymentRequest} checkPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applePayCallback: async (checkPaymentRequest: CheckPaymentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkPaymentRequest' is not null or undefined
            assertParamExists('applePayCallback', 'checkPaymentRequest', checkPaymentRequest)
            const localVarPath = `/payment/v1/apple/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据授权码拿到支付请求表单
         * @param {string} authCode 支付授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayForm: async (authCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authCode' is not null or undefined
            assertParamExists('getPayForm', 'authCode', authCode)
            const localVarPath = `/payment/v1/pay_form/{auth_code}`
                .replace(`{${"auth_code"}}`, encodeURIComponent(String(authCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping++支付回调
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPPCallback: async (body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('pingPPCallback', 'body', body)
            const localVarPath = `/payment/v1/pingpp/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apple恢复订单（目前仅限于 信仰会员和订阅会员）
         * @param {RecoverOrderRequest} recoverOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverAppleOrder: async (recoverOrderRequest: RecoverOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recoverOrderRequest' is not null or undefined
            assertParamExists('recoverAppleOrder', 'recoverOrderRequest', recoverOrderRequest)
            const localVarPath = `/payment/v1/apple/order/recover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recoverOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 检查苹果支付结果，并且发放商品
         * @param {CheckPaymentRequest} checkPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applePayCallback(checkPaymentRequest: CheckPaymentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applePayCallback(checkPaymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据授权码拿到支付请求表单
         * @param {string} authCode 支付授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayForm(authCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayForm(authCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ping++支付回调
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPPCallback(body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingPPCallback(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apple恢复订单（目前仅限于 信仰会员和订阅会员）
         * @param {RecoverOrderRequest} recoverOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverAppleOrder(recoverOrderRequest: RecoverOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverAppleOrder(recoverOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary 检查苹果支付结果，并且发放商品
         * @param {CheckPaymentRequest} checkPaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applePayCallback(checkPaymentRequest: CheckPaymentRequest, options?: any): AxiosPromise<CommonResponse> {
            return localVarFp.applePayCallback(checkPaymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据授权码拿到支付请求表单
         * @param {string} authCode 支付授权码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayForm(authCode: string, options?: any): AxiosPromise<PayFormResponse> {
            return localVarFp.getPayForm(authCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ping++支付回调
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPPCallback(body: string, options?: any): AxiosPromise<void> {
            return localVarFp.pingPPCallback(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apple恢复订单（目前仅限于 信仰会员和订阅会员）
         * @param {RecoverOrderRequest} recoverOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverAppleOrder(recoverOrderRequest: RecoverOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.recoverAppleOrder(recoverOrderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary 检查苹果支付结果，并且发放商品
     * @param {CheckPaymentRequest} checkPaymentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public applePayCallback(checkPaymentRequest: CheckPaymentRequest, options?: any) {
        return PaymentApiFp(this.configuration).applePayCallback(checkPaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据授权码拿到支付请求表单
     * @param {string} authCode 支付授权码
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getPayForm(authCode: string, options?: any) {
        return PaymentApiFp(this.configuration).getPayForm(authCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ping++支付回调
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public pingPPCallback(body: string, options?: any) {
        return PaymentApiFp(this.configuration).pingPPCallback(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apple恢复订单（目前仅限于 信仰会员和订阅会员）
     * @param {RecoverOrderRequest} recoverOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public recoverAppleOrder(recoverOrderRequest: RecoverOrderRequest, options?: any) {
        return PaymentApiFp(this.configuration).recoverAppleOrder(recoverOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReviewApi - axios parameter creator
 * @export
 */
export const ReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获得机器审核结果
         * @param {number} objectId 
         * @param {string} objectType UGC_BOOK,USER_INFO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewResult: async (objectId: number, objectType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getReviewResult', 'objectId', objectId)
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('getReviewResult', 'objectType', objectType)
            const localVarPath = `/content_machine_review/v1/{objectId}/{objectType}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"objectType"}}`, encodeURIComponent(String(objectType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewApi - functional programming interface
 * @export
 */
export const ReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获得机器审核结果
         * @param {number} objectId 
         * @param {string} objectType UGC_BOOK,USER_INFO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewResult(objectId: number, objectType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentMachineReviewResultVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewResult(objectId, objectType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewApi - factory interface
 * @export
 */
export const ReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewApiFp(configuration)
    return {
        /**
         * 
         * @summary 获得机器审核结果
         * @param {number} objectId 
         * @param {string} objectType UGC_BOOK,USER_INFO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewResult(objectId: number, objectType: string, options?: any): AxiosPromise<ContentMachineReviewResultVO> {
            return localVarFp.getReviewResult(objectId, objectType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewApi - object-oriented interface
 * @export
 * @class ReviewApi
 * @extends {BaseAPI}
 */
export class ReviewApi extends BaseAPI {
    /**
     * 
     * @summary 获得机器审核结果
     * @param {number} objectId 
     * @param {string} objectType UGC_BOOK,USER_INFO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public getReviewResult(objectId: number, objectType: string, options?: any) {
        return ReviewApiFp(this.configuration).getReviewResult(objectId, objectType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add extra cards today.
         * @param {AddExtraCardsTodayRequest} addExtraCardsTodayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExtraCardsToday: async (addExtraCardsTodayRequest: AddExtraCardsTodayRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addExtraCardsTodayRequest' is not null or undefined
            assertParamExists('addExtraCardsToday', 'addExtraCardsTodayRequest', addExtraCardsTodayRequest)
            const localVarPath = `/scheduler/v1/add_extra_cards_today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addExtraCardsTodayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clear scheduler & study stat data in DB, with choices of different level.
         * @param {ClearSchedulerAndStudyStatRequest} clearSchedulerAndStudyStatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearSchedulerAndStudyStat: async (clearSchedulerAndStudyStatRequest: ClearSchedulerAndStudyStatRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clearSchedulerAndStudyStatRequest' is not null or undefined
            assertParamExists('clearSchedulerAndStudyStat', 'clearSchedulerAndStudyStatRequest', clearSchedulerAndStudyStatRequest)
            const localVarPath = `/scheduler/v1/clear_scheduler_and_study_stat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clearSchedulerAndStudyStatRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取用户所有上锁的decks
         * @param {number} rootDeckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLockedDecks: async (rootDeckId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rootDeckId' is not null or undefined
            assertParamExists('getAllLockedDecks', 'rootDeckId', rootDeckId)
            const localVarPath = `/studyplan/v1/decks/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rootDeckId !== undefined) {
                localVarQueryParameter['root_deck_id'] = rootDeckId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all suspended cards.
         * @param {GetAllSuspendedCardsRequest} getAllSuspendedCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSuspendedCards: async (getAllSuspendedCardsRequest: GetAllSuspendedCardsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAllSuspendedCardsRequest' is not null or undefined
            assertParamExists('getAllSuspendedCards', 'getAllSuspendedCardsRequest', getAllSuspendedCardsRequest)
            const localVarPath = `/scheduler/v1/get_all_suspended_cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAllSuspendedCardsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取用户每日新学/复习卡片限额的自定义配置
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFixedCardsCountLimit: async (deckId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deckId' is not null or undefined
            assertParamExists('getFixedCardsCountLimit', 'deckId', deckId)
            const localVarPath = `/scheduler/v1/fixed_cards_count_limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deckId !== undefined) {
                localVarQueryParameter['deck_id'] = deckId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return next card id list for user from deck, scheduled by CardScheduler. Currently, the size of card id list could be either 1 (if there EXISTS card that could be learned/reviewed today) or 0 (if NO cards could be learned/reviewed today).
         * @param {NextCardListRequest} nextCardListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCardList: async (nextCardListRequest: NextCardListRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nextCardListRequest' is not null or undefined
            assertParamExists('getNextCardList', 'nextCardListRequest', nextCardListRequest)
            const localVarPath = `/scheduler/v1/next_card_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nextCardListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get study count today.
         * @param {StudyCountTodayRequest} [studyCountTodayRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyCountToday: async (studyCountTodayRequest?: StudyCountTodayRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/scheduler/v1/get_study_count_today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyCountTodayRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 从es中查询某用户在某卡包上xx(xx<60)天内的学习记录(最多查询5000条es记录)
         * @param {string} packageUuid 查询json_doc的数据格式为 \&#39;hpkg:${package_uuid}\&#39;
         * @param {StudyHistoryRequest} studyHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyHistories: async (packageUuid: string, studyHistoryRequest: StudyHistoryRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageUuid' is not null or undefined
            assertParamExists('getStudyHistories', 'packageUuid', packageUuid)
            // verify required parameter 'studyHistoryRequest' is not null or undefined
            assertParamExists('getStudyHistories', 'studyHistoryRequest', studyHistoryRequest)
            const localVarPath = `/scheduler/v1/{package_uuid}/debug/study_history`
                .replace(`{${"package_uuid"}}`, encodeURIComponent(String(packageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 今日学习完成相关数据
         * @param {TodayStudyFinishedRelatedDataRequest} todayStudyFinishedRelatedDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodayStudyFinishedRelatedData: async (todayStudyFinishedRelatedDataRequest: TodayStudyFinishedRelatedDataRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'todayStudyFinishedRelatedDataRequest' is not null or undefined
            assertParamExists('getTodayStudyFinishedRelatedData', 'todayStudyFinishedRelatedDataRequest', todayStudyFinishedRelatedDataRequest)
            const localVarPath = `/scheduler/v1/get_today_study_finished_related_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(todayStudyFinishedRelatedDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Init default deck-level & card-level dynamic schedule data of specific user, should be called before user\'s first time learning of package.
         * @param {InitDefaultDeckAndCardDynamicScheduleRequest} initDefaultDeckAndCardDynamicScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDefaultDeckAndCardDynamicSchedule: async (initDefaultDeckAndCardDynamicScheduleRequest: InitDefaultDeckAndCardDynamicScheduleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'initDefaultDeckAndCardDynamicScheduleRequest' is not null or undefined
            assertParamExists('initDefaultDeckAndCardDynamicSchedule', 'initDefaultDeckAndCardDynamicScheduleRequest', initDefaultDeckAndCardDynamicScheduleRequest)
            const localVarPath = `/scheduler/v1/init_default_deck_and_card_dynamic_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initDefaultDeckAndCardDynamicScheduleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Init default package-level & deck-level config for card schedule of specific user, should be called when user own the package.
         * @param {InitDefaultPackageAndDeckConfigRequest} initDefaultPackageAndDeckConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDefaultPackageAndDeckConfig: async (initDefaultPackageAndDeckConfigRequest: InitDefaultPackageAndDeckConfigRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'initDefaultPackageAndDeckConfigRequest' is not null or undefined
            assertParamExists('initDefaultPackageAndDeckConfig', 'initDefaultPackageAndDeckConfigRequest', initDefaultPackageAndDeckConfigRequest)
            const localVarPath = `/scheduler/v1/init_default_package_and_deck_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initDefaultPackageAndDeckConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Init default user-level config for card schedule, should be called when user registered.
         * @param {InitDefaultUserConfigRequest} initDefaultUserConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDefaultUserConfig: async (initDefaultUserConfigRequest: InitDefaultUserConfigRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'initDefaultUserConfigRequest' is not null or undefined
            assertParamExists('initDefaultUserConfig', 'initDefaultUserConfigRequest', initDefaultUserConfigRequest)
            const localVarPath = `/scheduler/v1/init_default_user_config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initDefaultUserConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Called when user click card rating buttons. Will affect future card schedule.
         * @param {RateCardRequest} rateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rateCard: async (rateCardRequest: RateCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rateCardRequest' is not null or undefined
            assertParamExists('rateCard', 'rateCardRequest', rateCardRequest)
            const localVarPath = `/scheduler/v1/rate_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rateCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Record study audit log start time.
         * @param {RecordStudyAuditLogStartTimeRequest} recordStudyAuditLogStartTimeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordStudyAuditLogStartTime: async (recordStudyAuditLogStartTimeRequest: RecordStudyAuditLogStartTimeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordStudyAuditLogStartTimeRequest' is not null or undefined
            assertParamExists('recordStudyAuditLogStartTime', 'recordStudyAuditLogStartTimeRequest', recordStudyAuditLogStartTimeRequest)
            const localVarPath = `/scheduler/v1/record_study_audit_log_start_time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recordStudyAuditLogStartTimeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 设置指定deck的锁定状态
         * @param {LockedDecksVO} lockedDecksVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDecksLockedStatus: async (lockedDecksVO: LockedDecksVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockedDecksVO' is not null or undefined
            assertParamExists('setDecksLockedStatus', 'lockedDecksVO', lockedDecksVO)
            const localVarPath = `/studyplan/v1/decks/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lockedDecksVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 用户自定义配置每日新学/复习卡片限额，如果配置了自定义限额， 则不允许调用 addExtraCardsToday 等扩展当日卡片限额的函数，而是继续调用本接口来提高限额。
         * @param {FixedCardsCountLimitRequest} fixedCardsCountLimitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFixedCardsCountLimit: async (fixedCardsCountLimitRequest: FixedCardsCountLimitRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fixedCardsCountLimitRequest' is not null or undefined
            assertParamExists('setFixedCardsCountLimit', 'fixedCardsCountLimitRequest', fixedCardsCountLimitRequest)
            const localVarPath = `/scheduler/v1/fixed_cards_count_limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fixedCardsCountLimitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * suspend card.
         * @param {SuspendCardRequest} suspendCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendCard: async (suspendCardRequest: SuspendCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'suspendCardRequest' is not null or undefined
            assertParamExists('suspendCard', 'suspendCardRequest', suspendCardRequest)
            const localVarPath = `/scheduler/v1/suspend_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspendCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Undo rate card.
         * @param {UndoRateCardRequest} undoRateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoRateCard: async (undoRateCardRequest: UndoRateCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'undoRateCardRequest' is not null or undefined
            assertParamExists('undoRateCard', 'undoRateCardRequest', undoRateCardRequest)
            const localVarPath = `/scheduler/v1/undo_rate_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(undoRateCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * unsuspend card.
         * @param {UnsuspendCardRequest} unsuspendCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsuspendCard: async (unsuspendCardRequest: UnsuspendCardRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unsuspendCardRequest' is not null or undefined
            assertParamExists('unsuspendCard', 'unsuspendCardRequest', unsuspendCardRequest)
            const localVarPath = `/scheduler/v1/unsuspend_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unsuspendCardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerApiAxiosParamCreator(configuration)
    return {
        /**
         * Add extra cards today.
         * @param {AddExtraCardsTodayRequest} addExtraCardsTodayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addExtraCardsToday(addExtraCardsTodayRequest: AddExtraCardsTodayRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addExtraCardsToday(addExtraCardsTodayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Clear scheduler & study stat data in DB, with choices of different level.
         * @param {ClearSchedulerAndStudyStatRequest} clearSchedulerAndStudyStatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearSchedulerAndStudyStat(clearSchedulerAndStudyStatRequest: ClearSchedulerAndStudyStatRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearSchedulerAndStudyStat(clearSchedulerAndStudyStatRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取用户所有上锁的decks
         * @param {number} rootDeckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLockedDecks(rootDeckId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockedDecksVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLockedDecks(rootDeckId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all suspended cards.
         * @param {GetAllSuspendedCardsRequest} getAllSuspendedCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSuspendedCards(getAllSuspendedCardsRequest: GetAllSuspendedCardsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllSuspendedCardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSuspendedCards(getAllSuspendedCardsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取用户每日新学/复习卡片限额的自定义配置
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFixedCardsCountLimit(deckId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FixedCardsCountLimitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFixedCardsCountLimit(deckId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return next card id list for user from deck, scheduled by CardScheduler. Currently, the size of card id list could be either 1 (if there EXISTS card that could be learned/reviewed today) or 0 (if NO cards could be learned/reviewed today).
         * @param {NextCardListRequest} nextCardListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextCardList(nextCardListRequest: NextCardListRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NextCardListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextCardList(nextCardListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get study count today.
         * @param {StudyCountTodayRequest} [studyCountTodayRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyCountToday(studyCountTodayRequest?: StudyCountTodayRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyCountTodayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyCountToday(studyCountTodayRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 从es中查询某用户在某卡包上xx(xx<60)天内的学习记录(最多查询5000条es记录)
         * @param {string} packageUuid 查询json_doc的数据格式为 \&#39;hpkg:${package_uuid}\&#39;
         * @param {StudyHistoryRequest} studyHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyHistories(packageUuid: string, studyHistoryRequest: StudyHistoryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebugStudyHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyHistories(packageUuid, studyHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 今日学习完成相关数据
         * @param {TodayStudyFinishedRelatedDataRequest} todayStudyFinishedRelatedDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodayStudyFinishedRelatedData(todayStudyFinishedRelatedDataRequest: TodayStudyFinishedRelatedDataRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TodayStudyFinishedRelatedDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodayStudyFinishedRelatedData(todayStudyFinishedRelatedDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Init default deck-level & card-level dynamic schedule data of specific user, should be called before user\'s first time learning of package.
         * @param {InitDefaultDeckAndCardDynamicScheduleRequest} initDefaultDeckAndCardDynamicScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initDefaultDeckAndCardDynamicSchedule(initDefaultDeckAndCardDynamicScheduleRequest: InitDefaultDeckAndCardDynamicScheduleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initDefaultDeckAndCardDynamicSchedule(initDefaultDeckAndCardDynamicScheduleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Init default package-level & deck-level config for card schedule of specific user, should be called when user own the package.
         * @param {InitDefaultPackageAndDeckConfigRequest} initDefaultPackageAndDeckConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initDefaultPackageAndDeckConfig(initDefaultPackageAndDeckConfigRequest: InitDefaultPackageAndDeckConfigRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initDefaultPackageAndDeckConfig(initDefaultPackageAndDeckConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Init default user-level config for card schedule, should be called when user registered.
         * @param {InitDefaultUserConfigRequest} initDefaultUserConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initDefaultUserConfig(initDefaultUserConfigRequest: InitDefaultUserConfigRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initDefaultUserConfig(initDefaultUserConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Called when user click card rating buttons. Will affect future card schedule.
         * @param {RateCardRequest} rateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rateCard(rateCardRequest: RateCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rateCard(rateCardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Record study audit log start time.
         * @param {RecordStudyAuditLogStartTimeRequest} recordStudyAuditLogStartTimeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordStudyAuditLogStartTime(recordStudyAuditLogStartTimeRequest: RecordStudyAuditLogStartTimeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordStudyAuditLogStartTime(recordStudyAuditLogStartTimeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 设置指定deck的锁定状态
         * @param {LockedDecksVO} lockedDecksVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDecksLockedStatus(lockedDecksVO: LockedDecksVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDecksLockedStatus(lockedDecksVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 用户自定义配置每日新学/复习卡片限额，如果配置了自定义限额， 则不允许调用 addExtraCardsToday 等扩展当日卡片限额的函数，而是继续调用本接口来提高限额。
         * @param {FixedCardsCountLimitRequest} fixedCardsCountLimitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFixedCardsCountLimit(fixedCardsCountLimitRequest: FixedCardsCountLimitRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFixedCardsCountLimit(fixedCardsCountLimitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * suspend card.
         * @param {SuspendCardRequest} suspendCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendCard(suspendCardRequest: SuspendCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendCard(suspendCardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Undo rate card.
         * @param {UndoRateCardRequest} undoRateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoRateCard(undoRateCardRequest: UndoRateCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UndoRateCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undoRateCard(undoRateCardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * unsuspend card.
         * @param {UnsuspendCardRequest} unsuspendCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsuspendCard(unsuspendCardRequest: UnsuspendCardRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsuspendCard(unsuspendCardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerApiFp(configuration)
    return {
        /**
         * Add extra cards today.
         * @param {AddExtraCardsTodayRequest} addExtraCardsTodayRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExtraCardsToday(addExtraCardsTodayRequest: AddExtraCardsTodayRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addExtraCardsToday(addExtraCardsTodayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Clear scheduler & study stat data in DB, with choices of different level.
         * @param {ClearSchedulerAndStudyStatRequest} clearSchedulerAndStudyStatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearSchedulerAndStudyStat(clearSchedulerAndStudyStatRequest: ClearSchedulerAndStudyStatRequest, options?: any): AxiosPromise<void> {
            return localVarFp.clearSchedulerAndStudyStat(clearSchedulerAndStudyStatRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取用户所有上锁的decks
         * @param {number} rootDeckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLockedDecks(rootDeckId: number, options?: any): AxiosPromise<LockedDecksVO> {
            return localVarFp.getAllLockedDecks(rootDeckId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all suspended cards.
         * @param {GetAllSuspendedCardsRequest} getAllSuspendedCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSuspendedCards(getAllSuspendedCardsRequest: GetAllSuspendedCardsRequest, options?: any): AxiosPromise<GetAllSuspendedCardsResponse> {
            return localVarFp.getAllSuspendedCards(getAllSuspendedCardsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取用户每日新学/复习卡片限额的自定义配置
         * @param {number} deckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFixedCardsCountLimit(deckId: number, options?: any): AxiosPromise<FixedCardsCountLimitResponse> {
            return localVarFp.getFixedCardsCountLimit(deckId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return next card id list for user from deck, scheduled by CardScheduler. Currently, the size of card id list could be either 1 (if there EXISTS card that could be learned/reviewed today) or 0 (if NO cards could be learned/reviewed today).
         * @param {NextCardListRequest} nextCardListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCardList(nextCardListRequest: NextCardListRequest, options?: any): AxiosPromise<NextCardListResponse> {
            return localVarFp.getNextCardList(nextCardListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get study count today.
         * @param {StudyCountTodayRequest} [studyCountTodayRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyCountToday(studyCountTodayRequest?: StudyCountTodayRequest, options?: any): AxiosPromise<StudyCountTodayResponse> {
            return localVarFp.getStudyCountToday(studyCountTodayRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 从es中查询某用户在某卡包上xx(xx<60)天内的学习记录(最多查询5000条es记录)
         * @param {string} packageUuid 查询json_doc的数据格式为 \&#39;hpkg:${package_uuid}\&#39;
         * @param {StudyHistoryRequest} studyHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyHistories(packageUuid: string, studyHistoryRequest: StudyHistoryRequest, options?: any): AxiosPromise<DebugStudyHistory> {
            return localVarFp.getStudyHistories(packageUuid, studyHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 今日学习完成相关数据
         * @param {TodayStudyFinishedRelatedDataRequest} todayStudyFinishedRelatedDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodayStudyFinishedRelatedData(todayStudyFinishedRelatedDataRequest: TodayStudyFinishedRelatedDataRequest, options?: any): AxiosPromise<TodayStudyFinishedRelatedDataResponse> {
            return localVarFp.getTodayStudyFinishedRelatedData(todayStudyFinishedRelatedDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Init default deck-level & card-level dynamic schedule data of specific user, should be called before user\'s first time learning of package.
         * @param {InitDefaultDeckAndCardDynamicScheduleRequest} initDefaultDeckAndCardDynamicScheduleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDefaultDeckAndCardDynamicSchedule(initDefaultDeckAndCardDynamicScheduleRequest: InitDefaultDeckAndCardDynamicScheduleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.initDefaultDeckAndCardDynamicSchedule(initDefaultDeckAndCardDynamicScheduleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Init default package-level & deck-level config for card schedule of specific user, should be called when user own the package.
         * @param {InitDefaultPackageAndDeckConfigRequest} initDefaultPackageAndDeckConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDefaultPackageAndDeckConfig(initDefaultPackageAndDeckConfigRequest: InitDefaultPackageAndDeckConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.initDefaultPackageAndDeckConfig(initDefaultPackageAndDeckConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Init default user-level config for card schedule, should be called when user registered.
         * @param {InitDefaultUserConfigRequest} initDefaultUserConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initDefaultUserConfig(initDefaultUserConfigRequest: InitDefaultUserConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.initDefaultUserConfig(initDefaultUserConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Called when user click card rating buttons. Will affect future card schedule.
         * @param {RateCardRequest} rateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rateCard(rateCardRequest: RateCardRequest, options?: any): AxiosPromise<void> {
            return localVarFp.rateCard(rateCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Record study audit log start time.
         * @param {RecordStudyAuditLogStartTimeRequest} recordStudyAuditLogStartTimeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordStudyAuditLogStartTime(recordStudyAuditLogStartTimeRequest: RecordStudyAuditLogStartTimeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.recordStudyAuditLogStartTime(recordStudyAuditLogStartTimeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 设置指定deck的锁定状态
         * @param {LockedDecksVO} lockedDecksVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDecksLockedStatus(lockedDecksVO: LockedDecksVO, options?: any): AxiosPromise<void> {
            return localVarFp.setDecksLockedStatus(lockedDecksVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 用户自定义配置每日新学/复习卡片限额，如果配置了自定义限额， 则不允许调用 addExtraCardsToday 等扩展当日卡片限额的函数，而是继续调用本接口来提高限额。
         * @param {FixedCardsCountLimitRequest} fixedCardsCountLimitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFixedCardsCountLimit(fixedCardsCountLimitRequest: FixedCardsCountLimitRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setFixedCardsCountLimit(fixedCardsCountLimitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * suspend card.
         * @param {SuspendCardRequest} suspendCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendCard(suspendCardRequest: SuspendCardRequest, options?: any): AxiosPromise<void> {
            return localVarFp.suspendCard(suspendCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Undo rate card.
         * @param {UndoRateCardRequest} undoRateCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoRateCard(undoRateCardRequest: UndoRateCardRequest, options?: any): AxiosPromise<UndoRateCardResponse> {
            return localVarFp.undoRateCard(undoRateCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * unsuspend card.
         * @param {UnsuspendCardRequest} unsuspendCardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsuspendCard(unsuspendCardRequest: UnsuspendCardRequest, options?: any): AxiosPromise<void> {
            return localVarFp.unsuspendCard(unsuspendCardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
    /**
     * Add extra cards today.
     * @param {AddExtraCardsTodayRequest} addExtraCardsTodayRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public addExtraCardsToday(addExtraCardsTodayRequest: AddExtraCardsTodayRequest, options?: any) {
        return SchedulerApiFp(this.configuration).addExtraCardsToday(addExtraCardsTodayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Clear scheduler & study stat data in DB, with choices of different level.
     * @param {ClearSchedulerAndStudyStatRequest} clearSchedulerAndStudyStatRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public clearSchedulerAndStudyStat(clearSchedulerAndStudyStatRequest: ClearSchedulerAndStudyStatRequest, options?: any) {
        return SchedulerApiFp(this.configuration).clearSchedulerAndStudyStat(clearSchedulerAndStudyStatRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取用户所有上锁的decks
     * @param {number} rootDeckId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getAllLockedDecks(rootDeckId: number, options?: any) {
        return SchedulerApiFp(this.configuration).getAllLockedDecks(rootDeckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all suspended cards.
     * @param {GetAllSuspendedCardsRequest} getAllSuspendedCardsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getAllSuspendedCards(getAllSuspendedCardsRequest: GetAllSuspendedCardsRequest, options?: any) {
        return SchedulerApiFp(this.configuration).getAllSuspendedCards(getAllSuspendedCardsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取用户每日新学/复习卡片限额的自定义配置
     * @param {number} deckId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getFixedCardsCountLimit(deckId: number, options?: any) {
        return SchedulerApiFp(this.configuration).getFixedCardsCountLimit(deckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return next card id list for user from deck, scheduled by CardScheduler. Currently, the size of card id list could be either 1 (if there EXISTS card that could be learned/reviewed today) or 0 (if NO cards could be learned/reviewed today).
     * @param {NextCardListRequest} nextCardListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getNextCardList(nextCardListRequest: NextCardListRequest, options?: any) {
        return SchedulerApiFp(this.configuration).getNextCardList(nextCardListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get study count today.
     * @param {StudyCountTodayRequest} [studyCountTodayRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getStudyCountToday(studyCountTodayRequest?: StudyCountTodayRequest, options?: any) {
        return SchedulerApiFp(this.configuration).getStudyCountToday(studyCountTodayRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 从es中查询某用户在某卡包上xx(xx<60)天内的学习记录(最多查询5000条es记录)
     * @param {string} packageUuid 查询json_doc的数据格式为 \&#39;hpkg:${package_uuid}\&#39;
     * @param {StudyHistoryRequest} studyHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getStudyHistories(packageUuid: string, studyHistoryRequest: StudyHistoryRequest, options?: any) {
        return SchedulerApiFp(this.configuration).getStudyHistories(packageUuid, studyHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 今日学习完成相关数据
     * @param {TodayStudyFinishedRelatedDataRequest} todayStudyFinishedRelatedDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getTodayStudyFinishedRelatedData(todayStudyFinishedRelatedDataRequest: TodayStudyFinishedRelatedDataRequest, options?: any) {
        return SchedulerApiFp(this.configuration).getTodayStudyFinishedRelatedData(todayStudyFinishedRelatedDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Init default deck-level & card-level dynamic schedule data of specific user, should be called before user\'s first time learning of package.
     * @param {InitDefaultDeckAndCardDynamicScheduleRequest} initDefaultDeckAndCardDynamicScheduleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public initDefaultDeckAndCardDynamicSchedule(initDefaultDeckAndCardDynamicScheduleRequest: InitDefaultDeckAndCardDynamicScheduleRequest, options?: any) {
        return SchedulerApiFp(this.configuration).initDefaultDeckAndCardDynamicSchedule(initDefaultDeckAndCardDynamicScheduleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Init default package-level & deck-level config for card schedule of specific user, should be called when user own the package.
     * @param {InitDefaultPackageAndDeckConfigRequest} initDefaultPackageAndDeckConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public initDefaultPackageAndDeckConfig(initDefaultPackageAndDeckConfigRequest: InitDefaultPackageAndDeckConfigRequest, options?: any) {
        return SchedulerApiFp(this.configuration).initDefaultPackageAndDeckConfig(initDefaultPackageAndDeckConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Init default user-level config for card schedule, should be called when user registered.
     * @param {InitDefaultUserConfigRequest} initDefaultUserConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public initDefaultUserConfig(initDefaultUserConfigRequest: InitDefaultUserConfigRequest, options?: any) {
        return SchedulerApiFp(this.configuration).initDefaultUserConfig(initDefaultUserConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Called when user click card rating buttons. Will affect future card schedule.
     * @param {RateCardRequest} rateCardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public rateCard(rateCardRequest: RateCardRequest, options?: any) {
        return SchedulerApiFp(this.configuration).rateCard(rateCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Record study audit log start time.
     * @param {RecordStudyAuditLogStartTimeRequest} recordStudyAuditLogStartTimeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public recordStudyAuditLogStartTime(recordStudyAuditLogStartTimeRequest: RecordStudyAuditLogStartTimeRequest, options?: any) {
        return SchedulerApiFp(this.configuration).recordStudyAuditLogStartTime(recordStudyAuditLogStartTimeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 设置指定deck的锁定状态
     * @param {LockedDecksVO} lockedDecksVO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public setDecksLockedStatus(lockedDecksVO: LockedDecksVO, options?: any) {
        return SchedulerApiFp(this.configuration).setDecksLockedStatus(lockedDecksVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 用户自定义配置每日新学/复习卡片限额，如果配置了自定义限额， 则不允许调用 addExtraCardsToday 等扩展当日卡片限额的函数，而是继续调用本接口来提高限额。
     * @param {FixedCardsCountLimitRequest} fixedCardsCountLimitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public setFixedCardsCountLimit(fixedCardsCountLimitRequest: FixedCardsCountLimitRequest, options?: any) {
        return SchedulerApiFp(this.configuration).setFixedCardsCountLimit(fixedCardsCountLimitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * suspend card.
     * @param {SuspendCardRequest} suspendCardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public suspendCard(suspendCardRequest: SuspendCardRequest, options?: any) {
        return SchedulerApiFp(this.configuration).suspendCard(suspendCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Undo rate card.
     * @param {UndoRateCardRequest} undoRateCardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public undoRateCard(undoRateCardRequest: UndoRateCardRequest, options?: any) {
        return SchedulerApiFp(this.configuration).undoRateCard(undoRateCardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * unsuspend card.
     * @param {UnsuspendCardRequest} unsuspendCardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public unsuspendCard(unsuspendCardRequest: UnsuspendCardRequest, options?: any) {
        return SchedulerApiFp(this.configuration).unsuspendCard(unsuspendCardRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShareMApi - axios parameter creator
 * @export
 */
export const ShareMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取共享模板详情
         * @param {string} sharedModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedModelInfo: async (sharedModelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sharedModelId' is not null or undefined
            assertParamExists('getSharedModelInfo', 'sharedModelId', sharedModelId)
            const localVarPath = `/apkg_shared_model/v1/model/{shared_model_id}`
                .replace(`{${"shared_model_id"}}`, encodeURIComponent(String(sharedModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 编辑模板（包括新增和修改）
         * @param {string} sharedModelId 
         * @param {UpdateSharedModelRequest} [updateSharedModelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrAddSharedModel: async (sharedModelId: string, updateSharedModelRequest?: UpdateSharedModelRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sharedModelId' is not null or undefined
            assertParamExists('updateOrAddSharedModel', 'sharedModelId', sharedModelId)
            const localVarPath = `/apkg_shared_model/v1/update/{shared_model_id}`
                .replace(`{${"shared_model_id"}}`, encodeURIComponent(String(sharedModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSharedModelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShareMApi - functional programming interface
 * @export
 */
export const ShareMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShareMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取共享模板详情
         * @param {string} sharedModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedModelInfo(sharedModelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedModelInfo(sharedModelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 编辑模板（包括新增和修改）
         * @param {string} sharedModelId 
         * @param {UpdateSharedModelRequest} [updateSharedModelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrAddSharedModel(sharedModelId: string, updateSharedModelRequest?: UpdateSharedModelRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrAddSharedModel(sharedModelId, updateSharedModelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShareMApi - factory interface
 * @export
 */
export const ShareMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShareMApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取共享模板详情
         * @param {string} sharedModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedModelInfo(sharedModelId: string, options?: any): AxiosPromise<SharedModelResponse> {
            return localVarFp.getSharedModelInfo(sharedModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 编辑模板（包括新增和修改）
         * @param {string} sharedModelId 
         * @param {UpdateSharedModelRequest} [updateSharedModelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrAddSharedModel(sharedModelId: string, updateSharedModelRequest?: UpdateSharedModelRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateOrAddSharedModel(sharedModelId, updateSharedModelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShareMApi - object-oriented interface
 * @export
 * @class ShareMApi
 * @extends {BaseAPI}
 */
export class ShareMApi extends BaseAPI {
    /**
     * 
     * @summary 获取共享模板详情
     * @param {string} sharedModelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareMApi
     */
    public getSharedModelInfo(sharedModelId: string, options?: any) {
        return ShareMApiFp(this.configuration).getSharedModelInfo(sharedModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 编辑模板（包括新增和修改）
     * @param {string} sharedModelId 
     * @param {UpdateSharedModelRequest} [updateSharedModelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareMApi
     */
    public updateOrAddSharedModel(sharedModelId: string, updateSharedModelRequest?: UpdateSharedModelRequest, options?: any) {
        return ShareMApiFp(this.configuration).updateOrAddSharedModel(sharedModelId, updateSharedModelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SharedMApi - axios parameter creator
 * @export
 */
export const SharedMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 删除共享模板（并不会真正删除，只不过对管理员隐藏起来了）
         * @param {string} sharedModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSharedModel: async (sharedModelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sharedModelId' is not null or undefined
            assertParamExists('deleteSharedModel', 'sharedModelId', sharedModelId)
            const localVarPath = `/apkg_shared_model/v1/delete/{shared_model_id}`
                .replace(`{${"shared_model_id"}}`, encodeURIComponent(String(sharedModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取共享模板元数据列表，不包含模板内容，不分页
         * @summary 获取共享模板元数据列表，不包含模板内容，不分页
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedModels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/apkg_shared_model/v1/shared_models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发布共享模板
         * @param {string} sharedModelId 
         * @param {PublishSharedModelRequest} publishSharedModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSharedModel: async (sharedModelId: string, publishSharedModelRequest: PublishSharedModelRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sharedModelId' is not null or undefined
            assertParamExists('publishSharedModel', 'sharedModelId', sharedModelId)
            // verify required parameter 'publishSharedModelRequest' is not null or undefined
            assertParamExists('publishSharedModel', 'publishSharedModelRequest', publishSharedModelRequest)
            const localVarPath = `/apkg_shared_model/v1/publish/{shared_model_id}`
                .replace(`{${"shared_model_id"}}`, encodeURIComponent(String(sharedModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishSharedModelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharedMApi - functional programming interface
 * @export
 */
export const SharedMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharedMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 删除共享模板（并不会真正删除，只不过对管理员隐藏起来了）
         * @param {string} sharedModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSharedModel(sharedModelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSharedModel(sharedModelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取共享模板元数据列表，不包含模板内容，不分页
         * @summary 获取共享模板元数据列表，不包含模板内容，不分页
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSharedModels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSharedModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发布共享模板
         * @param {string} sharedModelId 
         * @param {PublishSharedModelRequest} publishSharedModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishSharedModel(sharedModelId: string, publishSharedModelRequest: PublishSharedModelRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishSharedModel(sharedModelId, publishSharedModelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SharedMApi - factory interface
 * @export
 */
export const SharedMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharedMApiFp(configuration)
    return {
        /**
         * 
         * @summary 删除共享模板（并不会真正删除，只不过对管理员隐藏起来了）
         * @param {string} sharedModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSharedModel(sharedModelId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSharedModel(sharedModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取共享模板元数据列表，不包含模板内容，不分页
         * @summary 获取共享模板元数据列表，不包含模板内容，不分页
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSharedModels(options?: any): AxiosPromise<SharedModelListResponse> {
            return localVarFp.listSharedModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发布共享模板
         * @param {string} sharedModelId 
         * @param {PublishSharedModelRequest} publishSharedModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishSharedModel(sharedModelId: string, publishSharedModelRequest: PublishSharedModelRequest, options?: any): AxiosPromise<void> {
            return localVarFp.publishSharedModel(sharedModelId, publishSharedModelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharedMApi - object-oriented interface
 * @export
 * @class SharedMApi
 * @extends {BaseAPI}
 */
export class SharedMApi extends BaseAPI {
    /**
     * 
     * @summary 删除共享模板（并不会真正删除，只不过对管理员隐藏起来了）
     * @param {string} sharedModelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedMApi
     */
    public deleteSharedModel(sharedModelId: string, options?: any) {
        return SharedMApiFp(this.configuration).deleteSharedModel(sharedModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取共享模板元数据列表，不包含模板内容，不分页
     * @summary 获取共享模板元数据列表，不包含模板内容，不分页
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedMApi
     */
    public listSharedModels(options?: any) {
        return SharedMApiFp(this.configuration).listSharedModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发布共享模板
     * @param {string} sharedModelId 
     * @param {PublishSharedModelRequest} publishSharedModelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedMApi
     */
    public publishSharedModel(sharedModelId: string, publishSharedModelRequest: PublishSharedModelRequest, options?: any) {
        return SharedMApiFp(this.configuration).publishSharedModel(sharedModelId, publishSharedModelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShortLinkApi - axios parameter creator
 * @export
 */
export const ShortLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 创建一个对象的短链
         * @param {CreateShortLinkRequest} [createShortLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShortLink: async (createShortLinkRequest?: CreateShortLinkRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/short_link/v1/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShortLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 由 short_link_key 获得 ShortLinkVO
         * @param {string} shortLinkKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortLinkByKey: async (shortLinkKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shortLinkKey' is not null or undefined
            assertParamExists('getShortLinkByKey', 'shortLinkKey', shortLinkKey)
            const localVarPath = `/short_link/v1/{short_link_key}`
                .replace(`{${"short_link_key"}}`, encodeURIComponent(String(shortLinkKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortLinkApi - functional programming interface
 * @export
 */
export const ShortLinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortLinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 创建一个对象的短链
         * @param {CreateShortLinkRequest} [createShortLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShortLink(createShortLinkRequest?: CreateShortLinkRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortLinkVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShortLink(createShortLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 由 short_link_key 获得 ShortLinkVO
         * @param {string} shortLinkKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShortLinkByKey(shortLinkKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortLinkVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShortLinkByKey(shortLinkKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShortLinkApi - factory interface
 * @export
 */
export const ShortLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortLinkApiFp(configuration)
    return {
        /**
         * 
         * @summary 创建一个对象的短链
         * @param {CreateShortLinkRequest} [createShortLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShortLink(createShortLinkRequest?: CreateShortLinkRequest, options?: any): AxiosPromise<ShortLinkVO> {
            return localVarFp.createShortLink(createShortLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 由 short_link_key 获得 ShortLinkVO
         * @param {string} shortLinkKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShortLinkByKey(shortLinkKey: string, options?: any): AxiosPromise<ShortLinkVO> {
            return localVarFp.getShortLinkByKey(shortLinkKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortLinkApi - object-oriented interface
 * @export
 * @class ShortLinkApi
 * @extends {BaseAPI}
 */
export class ShortLinkApi extends BaseAPI {
    /**
     * 
     * @summary 创建一个对象的短链
     * @param {CreateShortLinkRequest} [createShortLinkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortLinkApi
     */
    public createShortLink(createShortLinkRequest?: CreateShortLinkRequest, options?: any) {
        return ShortLinkApiFp(this.configuration).createShortLink(createShortLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 由 short_link_key 获得 ShortLinkVO
     * @param {string} shortLinkKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortLinkApi
     */
    public getShortLinkByKey(shortLinkKey: string, options?: any) {
        return ShortLinkApiFp(this.configuration).getShortLinkByKey(shortLinkKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGlobalStatistic: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/v1/calculate/global_statistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取每小时正确率统计数据
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccuracyStatistic: async (cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/v1/accuracy_in_hours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardRateLogHistogramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取学习行为直方图
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRateLogHistogramData: async (cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/v1/card_rate_histogram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardRateLogHistogramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ``` 新学习者 new_learner_statistics, 活跃学习者 active_learner_statistics, 学习完成率 complete_rate_statistics, 累计学习者 accumulate_learner_statistics, 平均学习时间 avg_learn_time_statistics, 平均学习卡片数 avg_learn_card_statistics, 每日订单金额 order_amount_statistics, 累计订单金额 order_amount_accumulate_statistics, 每日订单数量 order_count_statistics, 累计订单数量 order_count_accumulate_statistics; ``` 
         * @param {StatisticsRequest} [statisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLearnerAndOrderStatistics: async (statisticsRequest?: StatisticsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/v1/learner_and_order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 掌握度每日变化统计数据
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProficiencyInDays: async (cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/v1/proficieny_in_days`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardRateLogHistogramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取学习行为直方图
         * @param {string} huaId 
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionLearnSchedule: async (huaId: string, cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'huaId' is not null or undefined
            assertParamExists('getPromotionLearnSchedule', 'huaId', huaId)
            const localVarPath = `/statistics/v1/promotion/learn_schedule/{hua_id}`
                .replace(`{${"hua_id"}}`, encodeURIComponent(String(huaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardRateLogHistogramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 活跃留存率 的 event_type为 active_retention
         * @summary 获取留存率
         * @param {StatisticsRequest} [statisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRetention: async (statisticsRequest?: StatisticsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/v1/retention`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取卡册收益列表
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookRewards: async (pageNumber: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('listBookRewards', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listBookRewards', 'pageSize', pageSize)
            const localVarPath = `/statistics/v1/book-rewards/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取某个卡册收益列表
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {number} bookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookRewardsDetail: async (pageNumber: number, pageSize: number, bookId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('listBookRewardsDetail', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listBookRewardsDetail', 'pageSize', pageSize)
            // verify required parameter 'bookId' is not null or undefined
            assertParamExists('listBookRewardsDetail', 'bookId', bookId)
            const localVarPath = `/statistics/v1/book-date-rewards/{book_id}/page`
                .replace(`{${"book_id"}}`, encodeURIComponent(String(bookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取创作者激励收益列表 「按日期」
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorRewards: async (pageNumber: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('listCreatorRewards', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listCreatorRewards', 'pageSize', pageSize)
            const localVarPath = `/statistics/v1/creator-rewards/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取创作者激励收益列表详情列表
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorRewardsDetail: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('listCreatorRewardsDetail', 'date', date)
            const localVarPath = `/statistics/v1/creator-rewards/date-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateGlobalStatistic(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateGlobalStatistic(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取每小时正确率统计数据
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccuracyStatistic(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccuracyStatistic(cardRateLogHistogramRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取学习行为直方图
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardRateLogHistogramData(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardRateLogHistogramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardRateLogHistogramData(cardRateLogHistogramRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ``` 新学习者 new_learner_statistics, 活跃学习者 active_learner_statistics, 学习完成率 complete_rate_statistics, 累计学习者 accumulate_learner_statistics, 平均学习时间 avg_learn_time_statistics, 平均学习卡片数 avg_learn_card_statistics, 每日订单金额 order_amount_statistics, 累计订单金额 order_amount_accumulate_statistics, 每日订单数量 order_count_statistics, 累计订单数量 order_count_accumulate_statistics; ``` 
         * @param {StatisticsRequest} [statisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLearnerAndOrderStatistics(statisticsRequest?: StatisticsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLearnerAndOrderStatistics(statisticsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 掌握度每日变化统计数据
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProficiencyInDays(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProficiencyInDays(cardRateLogHistogramRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取学习行为直方图
         * @param {string} huaId 
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionLearnSchedule(huaId: string, cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearnScheduleStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionLearnSchedule(huaId, cardRateLogHistogramRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 活跃留存率 的 event_type为 active_retention
         * @summary 获取留存率
         * @param {StatisticsRequest} [statisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRetention(statisticsRequest?: StatisticsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRetention(statisticsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取卡册收益列表
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookRewards(pageNumber: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookRewardDetailPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBookRewards(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取某个卡册收益列表
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {number} bookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookRewardsDetail(pageNumber: number, pageSize: number, bookId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorRewardDetailPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBookRewardsDetail(pageNumber, pageSize, bookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取创作者激励收益列表 「按日期」
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCreatorRewards(pageNumber: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorRewardDetailPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCreatorRewards(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取创作者激励收益列表详情列表
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCreatorRewardsDetail(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorRewardBookDetailListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCreatorRewardsDetail(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGlobalStatistic(options?: any): AxiosPromise<void> {
            return localVarFp.calculateGlobalStatistic(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取每小时正确率统计数据
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccuracyStatistic(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): AxiosPromise<StatisticResponse> {
            return localVarFp.getAccuracyStatistic(cardRateLogHistogramRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取学习行为直方图
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardRateLogHistogramData(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): AxiosPromise<CardRateLogHistogramResponse> {
            return localVarFp.getCardRateLogHistogramData(cardRateLogHistogramRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ``` 新学习者 new_learner_statistics, 活跃学习者 active_learner_statistics, 学习完成率 complete_rate_statistics, 累计学习者 accumulate_learner_statistics, 平均学习时间 avg_learn_time_statistics, 平均学习卡片数 avg_learn_card_statistics, 每日订单金额 order_amount_statistics, 累计订单金额 order_amount_accumulate_statistics, 每日订单数量 order_count_statistics, 累计订单数量 order_count_accumulate_statistics; ``` 
         * @param {StatisticsRequest} [statisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLearnerAndOrderStatistics(statisticsRequest?: StatisticsRequest, options?: any): AxiosPromise<string> {
            return localVarFp.getLearnerAndOrderStatistics(statisticsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 掌握度每日变化统计数据
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProficiencyInDays(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): AxiosPromise<StatisticResponse> {
            return localVarFp.getProficiencyInDays(cardRateLogHistogramRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 获取学习行为直方图
         * @param {string} huaId 
         * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionLearnSchedule(huaId: string, cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any): AxiosPromise<LearnScheduleStatisticsResponse> {
            return localVarFp.getPromotionLearnSchedule(huaId, cardRateLogHistogramRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 活跃留存率 的 event_type为 active_retention
         * @summary 获取留存率
         * @param {StatisticsRequest} [statisticsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRetention(statisticsRequest?: StatisticsRequest, options?: any): AxiosPromise<StatisticResponse> {
            return localVarFp.getUserRetention(statisticsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取卡册收益列表
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookRewards(pageNumber: number, pageSize: number, options?: any): AxiosPromise<BookRewardDetailPageResponse> {
            return localVarFp.listBookRewards(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取某个卡册收益列表
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {number} bookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookRewardsDetail(pageNumber: number, pageSize: number, bookId: number, options?: any): AxiosPromise<CreatorRewardDetailPageResponse> {
            return localVarFp.listBookRewardsDetail(pageNumber, pageSize, bookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取创作者激励收益列表 「按日期」
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorRewards(pageNumber: number, pageSize: number, options?: any): AxiosPromise<CreatorRewardDetailPageResponse> {
            return localVarFp.listCreatorRewards(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取创作者激励收益列表详情列表
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorRewardsDetail(date: string, options?: any): AxiosPromise<CreatorRewardBookDetailListResponse> {
            return localVarFp.listCreatorRewardsDetail(date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public calculateGlobalStatistic(options?: any) {
        return StatisticsApiFp(this.configuration).calculateGlobalStatistic(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取每小时正确率统计数据
     * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getAccuracyStatistic(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any) {
        return StatisticsApiFp(this.configuration).getAccuracyStatistic(cardRateLogHistogramRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取学习行为直方图
     * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getCardRateLogHistogramData(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any) {
        return StatisticsApiFp(this.configuration).getCardRateLogHistogramData(cardRateLogHistogramRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ``` 新学习者 new_learner_statistics, 活跃学习者 active_learner_statistics, 学习完成率 complete_rate_statistics, 累计学习者 accumulate_learner_statistics, 平均学习时间 avg_learn_time_statistics, 平均学习卡片数 avg_learn_card_statistics, 每日订单金额 order_amount_statistics, 累计订单金额 order_amount_accumulate_statistics, 每日订单数量 order_count_statistics, 累计订单数量 order_count_accumulate_statistics; ``` 
     * @param {StatisticsRequest} [statisticsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getLearnerAndOrderStatistics(statisticsRequest?: StatisticsRequest, options?: any) {
        return StatisticsApiFp(this.configuration).getLearnerAndOrderStatistics(statisticsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 掌握度每日变化统计数据
     * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getProficiencyInDays(cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any) {
        return StatisticsApiFp(this.configuration).getProficiencyInDays(cardRateLogHistogramRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取学习行为直方图
     * @param {string} huaId 
     * @param {CardRateLogHistogramRequest} [cardRateLogHistogramRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getPromotionLearnSchedule(huaId: string, cardRateLogHistogramRequest?: CardRateLogHistogramRequest, options?: any) {
        return StatisticsApiFp(this.configuration).getPromotionLearnSchedule(huaId, cardRateLogHistogramRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 活跃留存率 的 event_type为 active_retention
     * @summary 获取留存率
     * @param {StatisticsRequest} [statisticsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getUserRetention(statisticsRequest?: StatisticsRequest, options?: any) {
        return StatisticsApiFp(this.configuration).getUserRetention(statisticsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取卡册收益列表
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public listBookRewards(pageNumber: number, pageSize: number, options?: any) {
        return StatisticsApiFp(this.configuration).listBookRewards(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取某个卡册收益列表
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {number} bookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public listBookRewardsDetail(pageNumber: number, pageSize: number, bookId: number, options?: any) {
        return StatisticsApiFp(this.configuration).listBookRewardsDetail(pageNumber, pageSize, bookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取创作者激励收益列表 「按日期」
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public listCreatorRewards(pageNumber: number, pageSize: number, options?: any) {
        return StatisticsApiFp(this.configuration).listCreatorRewards(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取创作者激励收益列表详情列表
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public listCreatorRewardsDetail(date: string, options?: any) {
        return StatisticsApiFp(this.configuration).listCreatorRewardsDetail(date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SyncApi - axios parameter creator
 * @export
 */
export const SyncApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 批量删除 json doc
         * @param {JsonDocBatchDeleteRequest} [jsonDocBatchDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDeleteJsonDocs: async (jsonDocBatchDeleteRequest?: JsonDocBatchDeleteRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/json_doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocBatchDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 目前用于下载用户自制卡包 ower才能下载
         * @summary 根据前缀批量下载jsonDoc
         * @param {string} docIdPrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocByPrefix: async (docIdPrefix: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docIdPrefix' is not null or undefined
            assertParamExists('batchDownloadJsonDocByPrefix', 'docIdPrefix', docIdPrefix)
            const localVarPath = `/sync/v1/download_json_docs/{doc_id_prefix}`
                .replace(`{${"doc_id_prefix"}}`, encodeURIComponent(String(docIdPrefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端批量下载 json doc release
         * @summary 批量下载 json doc release，拥有 READ_RELEASE 权限的用户可以下载
         * @param {JsonDocReleaseBatchDownloadRequest} [jsonDocReleaseBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocReleases: async (jsonDocReleaseBatchDownloadRequest?: JsonDocReleaseBatchDownloadRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/download_json_doc_releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocReleaseBatchDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端批量下载 json doc 快照
         * @summary （弃用！）批量下载 json doc 快照，owner 才能下载
         * @param {JsonDocSnapshotBatchDownloadRequest} [jsonDocSnapshotBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocSnapshots: async (jsonDocSnapshotBatchDownloadRequest?: JsonDocSnapshotBatchDownloadRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/download_json_doc_snapshots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocSnapshotBatchDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端批量下载 json doc
         * @summary 批量下载 json doc，owner 才能下载
         * @param {JsonDocBatchDownloadRequest} [jsonDocBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocs: async (jsonDocBatchDownloadRequest?: JsonDocBatchDownloadRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/download_json_docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocBatchDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端批量上传本地创建的 json doc
         * @summary 批量上传 json doc
         * @param {JsonDocBatchUploadRequest} [jsonDocBatchUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUploadJsonDoc: async (jsonDocBatchUploadRequest?: JsonDocBatchUploadRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/json_docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocBatchUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查cardData数据好是否需要升级
         * @param {'card_data'} upgradeType 可取值 card_data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCardDataUpgrade: async (upgradeType: 'card_data', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'upgradeType' is not null or undefined
            assertParamExists('checkCardDataUpgrade', 'upgradeType', upgradeType)
            const localVarPath = `/sync/v1/{upgrade_type}/check_upgrade`
                .replace(`{${"upgrade_type"}}`, encodeURIComponent(String(upgradeType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 下载 json doc
         * @summary 下载 json doc，owner 才能下载，用于编辑
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadJsonDoc: async (docId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('downloadJsonDoc', 'docId', docId)
            const localVarPath = `/sync/v1/json_doc/{docId}`
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 下载 json doc 发布版本，release_tag 可传 \'latest\' 下载最新发布版本
         * @param {string} docId 
         * @param {string} releaseTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadJsonDocRelease: async (docId: string, releaseTag: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('downloadJsonDocRelease', 'docId', docId)
            // verify required parameter 'releaseTag' is not null or undefined
            assertParamExists('downloadJsonDocRelease', 'releaseTag', releaseTag)
            const localVarPath = `/sync/v1/json_docs/{doc_id}/releases/{release_tag}`
                .replace(`{${"doc_id"}}`, encodeURIComponent(String(docId)))
                .replace(`{${"release_tag"}}`, encodeURIComponent(String(releaseTag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [仅管理员] 用于查询jsonDoc对应版本的内容
         * @param {string} docId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadJsonDocSnapshot: async (docId: string, version: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('downloadJsonDocSnapshot', 'docId', docId)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('downloadJsonDocSnapshot', 'version', version)
            const localVarPath = `/sync/v1/json_doc/{docId}/{version}`
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取某个 jd 最新版本号及最近发布版本号，用于判断是否有更新待发布
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonDocReleasedVersionInfo: async (docId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('getJsonDocReleasedVersionInfo', 'docId', docId)
            const localVarPath = `/sync/v1/json_doc/{docId}/released_version_info`
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 列出某个jsonDoc的版本信息
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJsonDocVersionInfo: async (docId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('listJsonDocVersionInfo', 'docId', docId)
            const localVarPath = `/sync/v1/json_doc/{docId}/versions`
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 用于客户端 json doc 同步组件获取上次同步后其他客户端产生的更新
         * @summary 获取某个时间点之后的更新，包括需要更新的 json doc 和公开的 release
         * @param {JsonDocRecentUpdatesRequest} [jsonDocRecentUpdatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecentUpdates: async (jsonDocRecentUpdatesRequest?: JsonDocRecentUpdatesRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/recent_updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocRecentUpdatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [仅管理员] 将指定内容设置为该jsonDoc的内容，并设置为最新snapshot
         * @param {string} docId 
         * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJsonDocSnapshot: async (docId: string, jsonDocUploadRequest?: JsonDocUploadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('putJsonDocSnapshot', 'docId', docId)
            const localVarPath = `/sync/v1/admin/json_doc/{docId}`
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 回滚jsonDoc到某个版本,只支持创建该jsonDoc的用户回滚
         * @param {string} docId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackJsonDoc: async (docId: string, version: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('rollbackJsonDoc', 'docId', docId)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('rollbackJsonDoc', 'version', version)
            const localVarPath = `/sync/v1/rollback/{doc_id}/{version}`
                .replace(`{${"doc_id"}}`, encodeURIComponent(String(docId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端拿到差异后，可以再进一步获取 jd 内容
         * @summary 对于同一类 jd，客户端传入本地所有的 jd id 和 version，服务端返回差异
         * @param {SyncIdVersionsForTypeRequest} [syncIdVersionsForTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncIdVersionsForType: async (syncIdVersionsForTypeRequest?: SyncIdVersionsForTypeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/json_docs/id_versions_for_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncIdVersionsForTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端发送本地 patch，详见 https://qianmojiaoyu.feishu.cn/docs/doccni6OoJU3TkQ6tULhrNt55eg#kHKcga
         * @summary 同步 json doc
         * @param {string} docId 
         * @param {JsonDocSyncRequest} [jsonDocSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncJsonDoc: async (docId: string, jsonDocSyncRequest?: JsonDocSyncRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('syncJsonDoc', 'docId', docId)
            const localVarPath = `/sync/v1/json_doc/{docId}`
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 升级jsonDoc数据
         * @param {'card_data'} upgradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeJsonDoc: async (upgradeType: 'card_data', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'upgradeType' is not null or undefined
            assertParamExists('upgradeJsonDoc', 'upgradeType', upgradeType)
            const localVarPath = `/sync/v1/json_doc/upgrade/{upgrade_type}`
                .replace(`{${"upgrade_type"}}`, encodeURIComponent(String(upgradeType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端上传本地创建的 json doc
         * @summary 上传 json doc
         * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJsonDoc: async (jsonDocUploadRequest?: JsonDocUploadRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/json_doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonDocUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 客户端上传学习日志
         * @summary 上传学习日志
         * @param {SyncUploadScheduleLogRequest} [syncUploadScheduleLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadScheduleLog: async (syncUploadScheduleLogRequest?: SyncUploadScheduleLogRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/v1/schedule_log/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncUploadScheduleLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncApi - functional programming interface
 * @export
 */
export const SyncApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 批量删除 json doc
         * @param {JsonDocBatchDeleteRequest} [jsonDocBatchDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchDeleteJsonDocs(jsonDocBatchDeleteRequest?: JsonDocBatchDeleteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchDeleteJsonDocs(jsonDocBatchDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 目前用于下载用户自制卡包 ower才能下载
         * @summary 根据前缀批量下载jsonDoc
         * @param {string} docIdPrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchDownloadJsonDocByPrefix(docIdPrefix: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadJsonDocByPrefix(docIdPrefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端批量下载 json doc release
         * @summary 批量下载 json doc release，拥有 READ_RELEASE 权限的用户可以下载
         * @param {JsonDocReleaseBatchDownloadRequest} [jsonDocReleaseBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchDownloadJsonDocReleases(jsonDocReleaseBatchDownloadRequest?: JsonDocReleaseBatchDownloadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadJsonDocReleases(jsonDocReleaseBatchDownloadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端批量下载 json doc 快照
         * @summary （弃用！）批量下载 json doc 快照，owner 才能下载
         * @param {JsonDocSnapshotBatchDownloadRequest} [jsonDocSnapshotBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchDownloadJsonDocSnapshots(jsonDocSnapshotBatchDownloadRequest?: JsonDocSnapshotBatchDownloadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadJsonDocSnapshots(jsonDocSnapshotBatchDownloadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端批量下载 json doc
         * @summary 批量下载 json doc，owner 才能下载
         * @param {JsonDocBatchDownloadRequest} [jsonDocBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchDownloadJsonDocs(jsonDocBatchDownloadRequest?: JsonDocBatchDownloadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadJsonDocs(jsonDocBatchDownloadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端批量上传本地创建的 json doc
         * @summary 批量上传 json doc
         * @param {JsonDocBatchUploadRequest} [jsonDocBatchUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUploadJsonDoc(jsonDocBatchUploadRequest?: JsonDocBatchUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUploadJsonDoc(jsonDocBatchUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查cardData数据好是否需要升级
         * @param {'card_data'} upgradeType 可取值 card_data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCardDataUpgrade(upgradeType: 'card_data', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpgradeStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCardDataUpgrade(upgradeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 下载 json doc
         * @summary 下载 json doc，owner 才能下载，用于编辑
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadJsonDoc(docId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadJsonDoc(docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 下载 json doc 发布版本，release_tag 可传 \'latest\' 下载最新发布版本
         * @param {string} docId 
         * @param {string} releaseTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadJsonDocRelease(docId: string, releaseTag: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadJsonDocRelease(docId, releaseTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [仅管理员] 用于查询jsonDoc对应版本的内容
         * @param {string} docId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadJsonDocSnapshot(docId: string, version: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadJsonDocSnapshot(docId, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取某个 jd 最新版本号及最近发布版本号，用于判断是否有更新待发布
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJsonDocReleasedVersionInfo(docId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocReleasedVersionInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJsonDocReleasedVersionInfo(docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 列出某个jsonDoc的版本信息
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJsonDocVersionInfo(docId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocVersionInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJsonDocVersionInfo(docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 用于客户端 json doc 同步组件获取上次同步后其他客户端产生的更新
         * @summary 获取某个时间点之后的更新，包括需要更新的 json doc 和公开的 release
         * @param {JsonDocRecentUpdatesRequest} [jsonDocRecentUpdatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecentUpdates(jsonDocRecentUpdatesRequest?: JsonDocRecentUpdatesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocRecentUpdatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecentUpdates(jsonDocRecentUpdatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary [仅管理员] 将指定内容设置为该jsonDoc的内容，并设置为最新snapshot
         * @param {string} docId 
         * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putJsonDocSnapshot(docId: string, jsonDocUploadRequest?: JsonDocUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putJsonDocSnapshot(docId, jsonDocUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 回滚jsonDoc到某个版本,只支持创建该jsonDoc的用户回滚
         * @param {string} docId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rollbackJsonDoc(docId: string, version: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rollbackJsonDoc(docId, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端拿到差异后，可以再进一步获取 jd 内容
         * @summary 对于同一类 jd，客户端传入本地所有的 jd id 和 version，服务端返回差异
         * @param {SyncIdVersionsForTypeRequest} [syncIdVersionsForTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncIdVersionsForType(syncIdVersionsForTypeRequest?: SyncIdVersionsForTypeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncIdVersionsForTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncIdVersionsForType(syncIdVersionsForTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端发送本地 patch，详见 https://qianmojiaoyu.feishu.cn/docs/doccni6OoJU3TkQ6tULhrNt55eg#kHKcga
         * @summary 同步 json doc
         * @param {string} docId 
         * @param {JsonDocSyncRequest} [jsonDocSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncJsonDoc(docId: string, jsonDocSyncRequest?: JsonDocSyncRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocSyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncJsonDoc(docId, jsonDocSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 升级jsonDoc数据
         * @param {'card_data'} upgradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upgradeJsonDoc(upgradeType: 'card_data', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeJsonDoc(upgradeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端上传本地创建的 json doc
         * @summary 上传 json doc
         * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadJsonDoc(jsonDocUploadRequest?: JsonDocUploadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonDocResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadJsonDoc(jsonDocUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 客户端上传学习日志
         * @summary 上传学习日志
         * @param {SyncUploadScheduleLogRequest} [syncUploadScheduleLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadScheduleLog(syncUploadScheduleLogRequest?: SyncUploadScheduleLogRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadScheduleLog(syncUploadScheduleLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SyncApi - factory interface
 * @export
 */
export const SyncApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncApiFp(configuration)
    return {
        /**
         * 
         * @summary 批量删除 json doc
         * @param {JsonDocBatchDeleteRequest} [jsonDocBatchDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDeleteJsonDocs(jsonDocBatchDeleteRequest?: JsonDocBatchDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchDeleteJsonDocs(jsonDocBatchDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 目前用于下载用户自制卡包 ower才能下载
         * @summary 根据前缀批量下载jsonDoc
         * @param {string} docIdPrefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocByPrefix(docIdPrefix: string, options?: any): AxiosPromise<JsonDocListResponse> {
            return localVarFp.batchDownloadJsonDocByPrefix(docIdPrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端批量下载 json doc release
         * @summary 批量下载 json doc release，拥有 READ_RELEASE 权限的用户可以下载
         * @param {JsonDocReleaseBatchDownloadRequest} [jsonDocReleaseBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocReleases(jsonDocReleaseBatchDownloadRequest?: JsonDocReleaseBatchDownloadRequest, options?: any): AxiosPromise<JsonDocListResponse> {
            return localVarFp.batchDownloadJsonDocReleases(jsonDocReleaseBatchDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端批量下载 json doc 快照
         * @summary （弃用！）批量下载 json doc 快照，owner 才能下载
         * @param {JsonDocSnapshotBatchDownloadRequest} [jsonDocSnapshotBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocSnapshots(jsonDocSnapshotBatchDownloadRequest?: JsonDocSnapshotBatchDownloadRequest, options?: any): AxiosPromise<JsonDocListResponse> {
            return localVarFp.batchDownloadJsonDocSnapshots(jsonDocSnapshotBatchDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端批量下载 json doc
         * @summary 批量下载 json doc，owner 才能下载
         * @param {JsonDocBatchDownloadRequest} [jsonDocBatchDownloadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchDownloadJsonDocs(jsonDocBatchDownloadRequest?: JsonDocBatchDownloadRequest, options?: any): AxiosPromise<JsonDocListResponse> {
            return localVarFp.batchDownloadJsonDocs(jsonDocBatchDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端批量上传本地创建的 json doc
         * @summary 批量上传 json doc
         * @param {JsonDocBatchUploadRequest} [jsonDocBatchUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUploadJsonDoc(jsonDocBatchUploadRequest?: JsonDocBatchUploadRequest, options?: any): AxiosPromise<JsonDocListResponse> {
            return localVarFp.batchUploadJsonDoc(jsonDocBatchUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查cardData数据好是否需要升级
         * @param {'card_data'} upgradeType 可取值 card_data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCardDataUpgrade(upgradeType: 'card_data', options?: any): AxiosPromise<UpgradeStatusResponse> {
            return localVarFp.checkCardDataUpgrade(upgradeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 下载 json doc
         * @summary 下载 json doc，owner 才能下载，用于编辑
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadJsonDoc(docId: string, options?: any): AxiosPromise<JsonDocResponse> {
            return localVarFp.downloadJsonDoc(docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 下载 json doc 发布版本，release_tag 可传 \'latest\' 下载最新发布版本
         * @param {string} docId 
         * @param {string} releaseTag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadJsonDocRelease(docId: string, releaseTag: string, options?: any): AxiosPromise<JsonDocResponse> {
            return localVarFp.downloadJsonDocRelease(docId, releaseTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [仅管理员] 用于查询jsonDoc对应版本的内容
         * @param {string} docId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadJsonDocSnapshot(docId: string, version: string, options?: any): AxiosPromise<JsonDocResponse> {
            return localVarFp.downloadJsonDocSnapshot(docId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取某个 jd 最新版本号及最近发布版本号，用于判断是否有更新待发布
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonDocReleasedVersionInfo(docId: string, options?: any): AxiosPromise<JsonDocReleasedVersionInfoResponse> {
            return localVarFp.getJsonDocReleasedVersionInfo(docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 列出某个jsonDoc的版本信息
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJsonDocVersionInfo(docId: string, options?: any): AxiosPromise<JsonDocVersionInfoResponse> {
            return localVarFp.listJsonDocVersionInfo(docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 用于客户端 json doc 同步组件获取上次同步后其他客户端产生的更新
         * @summary 获取某个时间点之后的更新，包括需要更新的 json doc 和公开的 release
         * @param {JsonDocRecentUpdatesRequest} [jsonDocRecentUpdatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecentUpdates(jsonDocRecentUpdatesRequest?: JsonDocRecentUpdatesRequest, options?: any): AxiosPromise<JsonDocRecentUpdatesResponse> {
            return localVarFp.listRecentUpdates(jsonDocRecentUpdatesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [仅管理员] 将指定内容设置为该jsonDoc的内容，并设置为最新snapshot
         * @param {string} docId 
         * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putJsonDocSnapshot(docId: string, jsonDocUploadRequest?: JsonDocUploadRequest, options?: any): AxiosPromise<JsonDocResponse> {
            return localVarFp.putJsonDocSnapshot(docId, jsonDocUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 回滚jsonDoc到某个版本,只支持创建该jsonDoc的用户回滚
         * @param {string} docId 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackJsonDoc(docId: string, version: string, options?: any): AxiosPromise<void> {
            return localVarFp.rollbackJsonDoc(docId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端拿到差异后，可以再进一步获取 jd 内容
         * @summary 对于同一类 jd，客户端传入本地所有的 jd id 和 version，服务端返回差异
         * @param {SyncIdVersionsForTypeRequest} [syncIdVersionsForTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncIdVersionsForType(syncIdVersionsForTypeRequest?: SyncIdVersionsForTypeRequest, options?: any): AxiosPromise<SyncIdVersionsForTypeResponse> {
            return localVarFp.syncIdVersionsForType(syncIdVersionsForTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端发送本地 patch，详见 https://qianmojiaoyu.feishu.cn/docs/doccni6OoJU3TkQ6tULhrNt55eg#kHKcga
         * @summary 同步 json doc
         * @param {string} docId 
         * @param {JsonDocSyncRequest} [jsonDocSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncJsonDoc(docId: string, jsonDocSyncRequest?: JsonDocSyncRequest, options?: any): AxiosPromise<JsonDocSyncResponse> {
            return localVarFp.syncJsonDoc(docId, jsonDocSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 升级jsonDoc数据
         * @param {'card_data'} upgradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeJsonDoc(upgradeType: 'card_data', options?: any): AxiosPromise<void> {
            return localVarFp.upgradeJsonDoc(upgradeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端上传本地创建的 json doc
         * @summary 上传 json doc
         * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJsonDoc(jsonDocUploadRequest?: JsonDocUploadRequest, options?: any): AxiosPromise<JsonDocResponse> {
            return localVarFp.uploadJsonDoc(jsonDocUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 客户端上传学习日志
         * @summary 上传学习日志
         * @param {SyncUploadScheduleLogRequest} [syncUploadScheduleLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadScheduleLog(syncUploadScheduleLogRequest?: SyncUploadScheduleLogRequest, options?: any): AxiosPromise<void> {
            return localVarFp.uploadScheduleLog(syncUploadScheduleLogRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SyncApi - object-oriented interface
 * @export
 * @class SyncApi
 * @extends {BaseAPI}
 */
export class SyncApi extends BaseAPI {
    /**
     * 
     * @summary 批量删除 json doc
     * @param {JsonDocBatchDeleteRequest} [jsonDocBatchDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public batchDeleteJsonDocs(jsonDocBatchDeleteRequest?: JsonDocBatchDeleteRequest, options?: any) {
        return SyncApiFp(this.configuration).batchDeleteJsonDocs(jsonDocBatchDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 目前用于下载用户自制卡包 ower才能下载
     * @summary 根据前缀批量下载jsonDoc
     * @param {string} docIdPrefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public batchDownloadJsonDocByPrefix(docIdPrefix: string, options?: any) {
        return SyncApiFp(this.configuration).batchDownloadJsonDocByPrefix(docIdPrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端批量下载 json doc release
     * @summary 批量下载 json doc release，拥有 READ_RELEASE 权限的用户可以下载
     * @param {JsonDocReleaseBatchDownloadRequest} [jsonDocReleaseBatchDownloadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public batchDownloadJsonDocReleases(jsonDocReleaseBatchDownloadRequest?: JsonDocReleaseBatchDownloadRequest, options?: any) {
        return SyncApiFp(this.configuration).batchDownloadJsonDocReleases(jsonDocReleaseBatchDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端批量下载 json doc 快照
     * @summary （弃用！）批量下载 json doc 快照，owner 才能下载
     * @param {JsonDocSnapshotBatchDownloadRequest} [jsonDocSnapshotBatchDownloadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public batchDownloadJsonDocSnapshots(jsonDocSnapshotBatchDownloadRequest?: JsonDocSnapshotBatchDownloadRequest, options?: any) {
        return SyncApiFp(this.configuration).batchDownloadJsonDocSnapshots(jsonDocSnapshotBatchDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端批量下载 json doc
     * @summary 批量下载 json doc，owner 才能下载
     * @param {JsonDocBatchDownloadRequest} [jsonDocBatchDownloadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public batchDownloadJsonDocs(jsonDocBatchDownloadRequest?: JsonDocBatchDownloadRequest, options?: any) {
        return SyncApiFp(this.configuration).batchDownloadJsonDocs(jsonDocBatchDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端批量上传本地创建的 json doc
     * @summary 批量上传 json doc
     * @param {JsonDocBatchUploadRequest} [jsonDocBatchUploadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public batchUploadJsonDoc(jsonDocBatchUploadRequest?: JsonDocBatchUploadRequest, options?: any) {
        return SyncApiFp(this.configuration).batchUploadJsonDoc(jsonDocBatchUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查cardData数据好是否需要升级
     * @param {'card_data'} upgradeType 可取值 card_data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public checkCardDataUpgrade(upgradeType: 'card_data', options?: any) {
        return SyncApiFp(this.configuration).checkCardDataUpgrade(upgradeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 下载 json doc
     * @summary 下载 json doc，owner 才能下载，用于编辑
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public downloadJsonDoc(docId: string, options?: any) {
        return SyncApiFp(this.configuration).downloadJsonDoc(docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 下载 json doc 发布版本，release_tag 可传 \'latest\' 下载最新发布版本
     * @param {string} docId 
     * @param {string} releaseTag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public downloadJsonDocRelease(docId: string, releaseTag: string, options?: any) {
        return SyncApiFp(this.configuration).downloadJsonDocRelease(docId, releaseTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [仅管理员] 用于查询jsonDoc对应版本的内容
     * @param {string} docId 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public downloadJsonDocSnapshot(docId: string, version: string, options?: any) {
        return SyncApiFp(this.configuration).downloadJsonDocSnapshot(docId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取某个 jd 最新版本号及最近发布版本号，用于判断是否有更新待发布
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public getJsonDocReleasedVersionInfo(docId: string, options?: any) {
        return SyncApiFp(this.configuration).getJsonDocReleasedVersionInfo(docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 列出某个jsonDoc的版本信息
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public listJsonDocVersionInfo(docId: string, options?: any) {
        return SyncApiFp(this.configuration).listJsonDocVersionInfo(docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 用于客户端 json doc 同步组件获取上次同步后其他客户端产生的更新
     * @summary 获取某个时间点之后的更新，包括需要更新的 json doc 和公开的 release
     * @param {JsonDocRecentUpdatesRequest} [jsonDocRecentUpdatesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public listRecentUpdates(jsonDocRecentUpdatesRequest?: JsonDocRecentUpdatesRequest, options?: any) {
        return SyncApiFp(this.configuration).listRecentUpdates(jsonDocRecentUpdatesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [仅管理员] 将指定内容设置为该jsonDoc的内容，并设置为最新snapshot
     * @param {string} docId 
     * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public putJsonDocSnapshot(docId: string, jsonDocUploadRequest?: JsonDocUploadRequest, options?: any) {
        return SyncApiFp(this.configuration).putJsonDocSnapshot(docId, jsonDocUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 回滚jsonDoc到某个版本,只支持创建该jsonDoc的用户回滚
     * @param {string} docId 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public rollbackJsonDoc(docId: string, version: string, options?: any) {
        return SyncApiFp(this.configuration).rollbackJsonDoc(docId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端拿到差异后，可以再进一步获取 jd 内容
     * @summary 对于同一类 jd，客户端传入本地所有的 jd id 和 version，服务端返回差异
     * @param {SyncIdVersionsForTypeRequest} [syncIdVersionsForTypeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public syncIdVersionsForType(syncIdVersionsForTypeRequest?: SyncIdVersionsForTypeRequest, options?: any) {
        return SyncApiFp(this.configuration).syncIdVersionsForType(syncIdVersionsForTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端发送本地 patch，详见 https://qianmojiaoyu.feishu.cn/docs/doccni6OoJU3TkQ6tULhrNt55eg#kHKcga
     * @summary 同步 json doc
     * @param {string} docId 
     * @param {JsonDocSyncRequest} [jsonDocSyncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public syncJsonDoc(docId: string, jsonDocSyncRequest?: JsonDocSyncRequest, options?: any) {
        return SyncApiFp(this.configuration).syncJsonDoc(docId, jsonDocSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 升级jsonDoc数据
     * @param {'card_data'} upgradeType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public upgradeJsonDoc(upgradeType: 'card_data', options?: any) {
        return SyncApiFp(this.configuration).upgradeJsonDoc(upgradeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端上传本地创建的 json doc
     * @summary 上传 json doc
     * @param {JsonDocUploadRequest} [jsonDocUploadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public uploadJsonDoc(jsonDocUploadRequest?: JsonDocUploadRequest, options?: any) {
        return SyncApiFp(this.configuration).uploadJsonDoc(jsonDocUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 客户端上传学习日志
     * @summary 上传学习日志
     * @param {SyncUploadScheduleLogRequest} [syncUploadScheduleLogRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public uploadScheduleLog(syncUploadScheduleLogRequest?: SyncUploadScheduleLogRequest, options?: any) {
        return SyncApiFp(this.configuration).uploadScheduleLog(syncUploadScheduleLogRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UgcApi - axios parameter creator
 * @export
 */
export const UgcApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ankiPackageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUgcBookByApkgUuid: async (ankiPackageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ankiPackageUuid' is not null or undefined
            assertParamExists('getUgcBookByApkgUuid', 'ankiPackageUuid', ankiPackageUuid)
            const localVarPath = `/ugc/v1/books/by_apkg_uuid/{anki_package_uuid}`
                .replace(`{${"anki_package_uuid"}}`, encodeURIComponent(String(ankiPackageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UgcApi - functional programming interface
 * @export
 */
export const UgcApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UgcApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ankiPackageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUgcBookByApkgUuid(ankiPackageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UgcBook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUgcBookByApkgUuid(ankiPackageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UgcApi - factory interface
 * @export
 */
export const UgcApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UgcApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ankiPackageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUgcBookByApkgUuid(ankiPackageUuid: string, options?: any): AxiosPromise<UgcBook> {
            return localVarFp.getUgcBookByApkgUuid(ankiPackageUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UgcApi - object-oriented interface
 * @export
 * @class UgcApi
 * @extends {BaseAPI}
 */
export class UgcApi extends BaseAPI {
    /**
     * 
     * @param {string} ankiPackageUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UgcApi
     */
    public getUgcBookByApkgUuid(ankiPackageUuid: string, options?: any) {
        return UgcApiFp(this.configuration).getUgcBookByApkgUuid(ankiPackageUuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 填写邀请码完成相关得活动触发，要求用户登录
         * @param {ActivateInviteCodeRequest} activateInviteCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateInviteCode: async (activateInviteCodeRequest: ActivateInviteCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'activateInviteCodeRequest' is not null or undefined
            assertParamExists('activateInviteCode', 'activateInviteCodeRequest', activateInviteCodeRequest)
            const localVarPath = `/user/v1/activate_invitation_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateInviteCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 注销当前账号的API（JWT或X-FAKE-USER），请小心测试，会在数据库软删除user（标为deleted），注意prod&QA共用相同的JWT，不要误操作prod！
         * @summary 注销账号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/cancellation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查是否是新用户
         * @param {string} campaignName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkNewUser: async (campaignName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignName' is not null or undefined
            assertParamExists('checkNewUser', 'campaignName', campaignName)
            const localVarPath = `/user/v1/check_new_user/{campaign_name}`
                .replace(`{${"campaign_name"}}`, encodeURIComponent(String(campaignName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 关注取关用户
         * @param {number} userId 
         * @param {string} operation 可取值 ADD, CANCEL; ADD 表示关注 CANCEL表示取关
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followUser: async (userId: number, operation: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('followUser', 'userId', userId)
            // verify required parameter 'operation' is not null or undefined
            assertParamExists('followUser', 'operation', operation)
            const localVarPath = `/user/v1/follower/{userId}/{operation}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"operation"}}`, encodeURIComponent(String(operation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 生成用户的邀请码（要求用户登录）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInviteCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/invite_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取邀请码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInviteCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/invite_code_v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查看勤学奖励领取状态
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLearnRewardStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/learn-reward-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBasicInfo: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserBasicInfo', 'userId', userId)
            const localVarPath = `/user/v1/basic_info/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInviteCodeStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/invite_code/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 分页获取关注人列表
         * @param {QueryFollowerRequest} [queryFollowerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFollowers: async (queryFollowerRequest?: QueryFollowerRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/followers/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryFollowerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据huaId等条件查询用户基本信息
         * @param {string} [huaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUserBasicInfo: async (huaId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/basic_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (huaId !== undefined) {
                localVarQueryParameter['hua_id'] = huaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 领取勤学奖励
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveLearnReward: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/learn-reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} keyword 搜索关键字
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCreator: async (keyword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('searchCreator', 'keyword', keyword)
            const localVarPath = `/user/v1/creator/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 填写邀请码完成相关得活动触发，要求用户登录
         * @param {ActivateInviteCodeRequest} activateInviteCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateInviteCode(activateInviteCodeRequest: ActivateInviteCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateInviteCode(activateInviteCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 注销当前账号的API（JWT或X-FAKE-USER），请小心测试，会在数据库软删除user（标为deleted），注意prod&QA共用相同的JWT，不要误操作prod！
         * @summary 注销账号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查是否是新用户
         * @param {string} campaignName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkNewUser(campaignName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckNewUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkNewUser(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 关注取关用户
         * @param {number} userId 
         * @param {string} operation 可取值 ADD, CANCEL; ADD 表示关注 CANCEL表示取关
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followUser(userId: number, operation: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followUser(userId, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 生成用户的邀请码（要求用户登录）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateInviteCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInviteCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateInviteCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取邀请码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInviteCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInviteCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInviteCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查看勤学奖励领取状态
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLearnRewardStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearnRewardStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLearnRewardStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBasicInfo(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBasicInfo(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInviteCodeStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInviteCodeStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInviteCodeStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 分页获取关注人列表
         * @param {QueryFollowerRequest} [queryFollowerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFollowers(queryFollowerRequest?: QueryFollowerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserFollowerInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFollowers(queryFollowerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据huaId等条件查询用户基本信息
         * @param {string} [huaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryUserBasicInfo(huaId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryUserBasicInfo(huaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 领取勤学奖励
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiveLearnReward(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiveLearnReward(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} keyword 搜索关键字
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCreator(keyword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCreator(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 填写邀请码完成相关得活动触发，要求用户登录
         * @param {ActivateInviteCodeRequest} activateInviteCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateInviteCode(activateInviteCodeRequest: ActivateInviteCodeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.activateInviteCode(activateInviteCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 注销当前账号的API（JWT或X-FAKE-USER），请小心测试，会在数据库软删除user（标为deleted），注意prod&QA共用相同的JWT，不要误操作prod！
         * @summary 注销账号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUser(options?: any): AxiosPromise<void> {
            return localVarFp.cancelUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查是否是新用户
         * @param {string} campaignName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkNewUser(campaignName: string, options?: any): AxiosPromise<CheckNewUserResponse> {
            return localVarFp.checkNewUser(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 关注取关用户
         * @param {number} userId 
         * @param {string} operation 可取值 ADD, CANCEL; ADD 表示关注 CANCEL表示取关
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followUser(userId: number, operation: string, options?: any): AxiosPromise<void> {
            return localVarFp.followUser(userId, operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 生成用户的邀请码（要求用户登录）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateInviteCode(options?: any): AxiosPromise<UserInviteCodeResponse> {
            return localVarFp.generateInviteCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取邀请码
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInviteCode(options?: any): AxiosPromise<UserInviteCodeResponse> {
            return localVarFp.getInviteCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查看勤学奖励领取状态
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLearnRewardStatus(options?: any): AxiosPromise<LearnRewardStatus> {
            return localVarFp.getLearnRewardStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBasicInfo(userId: number, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserBasicInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInviteCodeStatus(options?: any): AxiosPromise<UserInviteCodeStatusResponse> {
            return localVarFp.getUserInviteCodeStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 分页获取关注人列表
         * @param {QueryFollowerRequest} [queryFollowerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFollowers(queryFollowerRequest?: QueryFollowerRequest, options?: any): AxiosPromise<PageUserFollowerInfo> {
            return localVarFp.listFollowers(queryFollowerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据huaId等条件查询用户基本信息
         * @param {string} [huaId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUserBasicInfo(huaId?: string, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.queryUserBasicInfo(huaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 领取勤学奖励
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveLearnReward(options?: any): AxiosPromise<void> {
            return localVarFp.receiveLearnReward(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} keyword 搜索关键字
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCreator(keyword: string, options?: any): AxiosPromise<UserInfoList> {
            return localVarFp.searchCreator(keyword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 填写邀请码完成相关得活动触发，要求用户登录
     * @param {ActivateInviteCodeRequest} activateInviteCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public activateInviteCode(activateInviteCodeRequest: ActivateInviteCodeRequest, options?: any) {
        return UserApiFp(this.configuration).activateInviteCode(activateInviteCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 注销当前账号的API（JWT或X-FAKE-USER），请小心测试，会在数据库软删除user（标为deleted），注意prod&QA共用相同的JWT，不要误操作prod！
     * @summary 注销账号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public cancelUser(options?: any) {
        return UserApiFp(this.configuration).cancelUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查是否是新用户
     * @param {string} campaignName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkNewUser(campaignName: string, options?: any) {
        return UserApiFp(this.configuration).checkNewUser(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 关注取关用户
     * @param {number} userId 
     * @param {string} operation 可取值 ADD, CANCEL; ADD 表示关注 CANCEL表示取关
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public followUser(userId: number, operation: string, options?: any) {
        return UserApiFp(this.configuration).followUser(userId, operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 生成用户的邀请码（要求用户登录）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public generateInviteCode(options?: any) {
        return UserApiFp(this.configuration).generateInviteCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取邀请码
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getInviteCode(options?: any) {
        return UserApiFp(this.configuration).getInviteCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查看勤学奖励领取状态
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getLearnRewardStatus(options?: any) {
        return UserApiFp(this.configuration).getLearnRewardStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserBasicInfo(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUserBasicInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserInviteCodeStatus(options?: any) {
        return UserApiFp(this.configuration).getUserInviteCodeStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 分页获取关注人列表
     * @param {QueryFollowerRequest} [queryFollowerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public listFollowers(queryFollowerRequest?: QueryFollowerRequest, options?: any) {
        return UserApiFp(this.configuration).listFollowers(queryFollowerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据huaId等条件查询用户基本信息
     * @param {string} [huaId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public queryUserBasicInfo(huaId?: string, options?: any) {
        return UserApiFp(this.configuration).queryUserBasicInfo(huaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 领取勤学奖励
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public receiveLearnReward(options?: any) {
        return UserApiFp(this.configuration).receiveLearnReward(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} keyword 搜索关键字
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchCreator(keyword: string, options?: any) {
        return UserApiFp(this.configuration).searchCreator(keyword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserWithdrawalApi - axios parameter creator
 * @export
 */
export const UserWithdrawalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 申请提现
         * @param {WithdrawalApplicationRequest} [withdrawalApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyWithdrawal: async (withdrawalApplicationRequest?: WithdrawalApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/withdrawal/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawalApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 绑定支付宝账号为提现账号
         * @param {UserPayBindRequest} [userPayBindRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindAlipayUser: async (userPayBindRequest?: UserPayBindRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/binding/alipay/bind`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPayBindRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 统计待发放金额
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRequestingAmount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/calculate_requesting_amount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 导出提现明细
         * @param {string} token /auth/v1/generate/download_token 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWithdrawalRequests: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('downloadWithdrawalRequests', 'token', token)
            const localVarPath = `/user_withdrawal/v1/download/withdrawal_requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查询剩余可提现次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRequestTimes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/requests/available_times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查看收入明细
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeDetail: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getIncomeDetail', 'orderId', orderId)
            const localVarPath = `/user_withdrawal/v1/income_detail/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取商家账户余额
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantAccount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/merchant_account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查看账号绑定状态
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayBindingInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/binding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 查看用户钱包，包括创作者和非创作者的钱包
         * @summary 查看用户钱包余额
         * @param {string} [walletType] CREATOR_WALLET, MARKETING_WALLET, CREATOR_REWARD_WALLET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalWallet: async (walletType?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/withdrawal_wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (walletType !== undefined) {
                localVarQueryParameter['wallet_type'] = walletType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查看激励金明细
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorRewardDetails: async (pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/creator_reward_wallet/detail-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 收入明细列表
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [withdrawalWalletType] MARKETING_WALLET/CREATOR_WALLET
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncomeDetailPage: async (pageNumber?: number, pageSize?: number, withdrawalWalletType?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/income_detail/list/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (withdrawalWalletType !== undefined) {
                localVarQueryParameter['withdrawal_wallet_type'] = withdrawalWalletType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 会员返利列表
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVipMarketingIncomeDetailPage: async (pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/vip_marketing/income_detail/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 查看提现明细列表
         * @param {QueryWithdrawalApplicationRequest} [queryWithdrawalApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithdrawalApplicationPage: async (queryWithdrawalApplicationRequest?: QueryWithdrawalApplicationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_withdrawal/v1/withdrawal/list/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryWithdrawalApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 审核提现
         * @param {number} requestsId 
         * @param {ReviewWithdrawalRequest} [reviewWithdrawalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewWithdrawalRequests: async (requestsId: number, reviewWithdrawalRequest?: ReviewWithdrawalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestsId' is not null or undefined
            assertParamExists('reviewWithdrawalRequests', 'requestsId', requestsId)
            const localVarPath = `/user_withdrawal/v1/withdrawal/review/{requests_id}`
                .replace(`{${"requests_id"}}`, encodeURIComponent(String(requestsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewWithdrawalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 解绑提现账号
         * @param {string} type ALIPAY/WECHAT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindPay: async (type: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('unbindPay', 'type', type)
            const localVarPath = `/user_withdrawal/v1/binding/unbind/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserWithdrawalApi - functional programming interface
 * @export
 */
export const UserWithdrawalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserWithdrawalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 申请提现
         * @param {WithdrawalApplicationRequest} [withdrawalApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyWithdrawal(withdrawalApplicationRequest?: WithdrawalApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyWithdrawal(withdrawalApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 绑定支付宝账号为提现账号
         * @param {UserPayBindRequest} [userPayBindRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bindAlipayUser(userPayBindRequest?: UserPayBindRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bindAlipayUser(userPayBindRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 统计待发放金额
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateRequestingAmount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChannelRequestingAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateRequestingAmount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 导出提现明细
         * @param {string} token /auth/v1/generate/download_token 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadWithdrawalRequests(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadWithdrawalRequests(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查询剩余可提现次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableRequestTimes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawalRequestInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableRequestTimes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查看收入明细
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIncomeDetail(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncomeDetailVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncomeDetail(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取商家账户余额
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchantAccount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantAccountVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMerchantAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查看账号绑定状态
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayBindingInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PayBindingInfoVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayBindingInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 查看用户钱包，包括创作者和非创作者的钱包
         * @summary 查看用户钱包余额
         * @param {string} [walletType] CREATOR_WALLET, MARKETING_WALLET, CREATOR_REWARD_WALLET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawalWallet(walletType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawalWalletVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawalWallet(walletType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查看激励金明细
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCreatorRewardDetails(pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RewardWalletDetailListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCreatorRewardDetails(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 收入明细列表
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [withdrawalWalletType] MARKETING_WALLET/CREATOR_WALLET
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIncomeDetailPage(pageNumber?: number, pageSize?: number, withdrawalWalletType?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageIncomeDetailVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIncomeDetailPage(pageNumber, pageSize, withdrawalWalletType, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 会员返利列表
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVipMarketingIncomeDetailPage(pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVipMarketingIncomeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVipMarketingIncomeDetailPage(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 查看提现明细列表
         * @param {QueryWithdrawalApplicationRequest} [queryWithdrawalApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWithdrawalApplicationPage(queryWithdrawalApplicationRequest?: QueryWithdrawalApplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWithdrawalApplicationVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWithdrawalApplicationPage(queryWithdrawalApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 审核提现
         * @param {number} requestsId 
         * @param {ReviewWithdrawalRequest} [reviewWithdrawalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewWithdrawalRequests(requestsId: number, reviewWithdrawalRequest?: ReviewWithdrawalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewWithdrawalRequests(requestsId, reviewWithdrawalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 解绑提现账号
         * @param {string} type ALIPAY/WECHAT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unbindPay(type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unbindPay(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserWithdrawalApi - factory interface
 * @export
 */
export const UserWithdrawalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserWithdrawalApiFp(configuration)
    return {
        /**
         * 
         * @summary 申请提现
         * @param {WithdrawalApplicationRequest} [withdrawalApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyWithdrawal(withdrawalApplicationRequest?: WithdrawalApplicationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.applyWithdrawal(withdrawalApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 绑定支付宝账号为提现账号
         * @param {UserPayBindRequest} [userPayBindRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bindAlipayUser(userPayBindRequest?: UserPayBindRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bindAlipayUser(userPayBindRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 统计待发放金额
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRequestingAmount(options?: any): AxiosPromise<ChannelRequestingAmount> {
            return localVarFp.calculateRequestingAmount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 导出提现明细
         * @param {string} token /auth/v1/generate/download_token 生成的token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWithdrawalRequests(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadWithdrawalRequests(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查询剩余可提现次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRequestTimes(options?: any): AxiosPromise<WithdrawalRequestInfo> {
            return localVarFp.getAvailableRequestTimes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查看收入明细
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomeDetail(orderId: string, options?: any): AxiosPromise<IncomeDetailVO> {
            return localVarFp.getIncomeDetail(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取商家账户余额
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantAccount(options?: any): AxiosPromise<MerchantAccountVO> {
            return localVarFp.getMerchantAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查看账号绑定状态
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayBindingInfo(options?: any): AxiosPromise<Array<PayBindingInfoVO>> {
            return localVarFp.getPayBindingInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 查看用户钱包，包括创作者和非创作者的钱包
         * @summary 查看用户钱包余额
         * @param {string} [walletType] CREATOR_WALLET, MARKETING_WALLET, CREATOR_REWARD_WALLET
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalWallet(walletType?: string, options?: any): AxiosPromise<WithdrawalWalletVO> {
            return localVarFp.getWithdrawalWallet(walletType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查看激励金明细
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCreatorRewardDetails(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<RewardWalletDetailListResponse> {
            return localVarFp.listCreatorRewardDetails(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 收入明细列表
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [withdrawalWalletType] MARKETING_WALLET/CREATOR_WALLET
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIncomeDetailPage(pageNumber?: number, pageSize?: number, withdrawalWalletType?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PageIncomeDetailVO> {
            return localVarFp.listIncomeDetailPage(pageNumber, pageSize, withdrawalWalletType, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 会员返利列表
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVipMarketingIncomeDetailPage(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PageVipMarketingIncomeDetailResponse> {
            return localVarFp.listVipMarketingIncomeDetailPage(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 查看提现明细列表
         * @param {QueryWithdrawalApplicationRequest} [queryWithdrawalApplicationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWithdrawalApplicationPage(queryWithdrawalApplicationRequest?: QueryWithdrawalApplicationRequest, options?: any): AxiosPromise<PageWithdrawalApplicationVO> {
            return localVarFp.listWithdrawalApplicationPage(queryWithdrawalApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 审核提现
         * @param {number} requestsId 
         * @param {ReviewWithdrawalRequest} [reviewWithdrawalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewWithdrawalRequests(requestsId: number, reviewWithdrawalRequest?: ReviewWithdrawalRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reviewWithdrawalRequests(requestsId, reviewWithdrawalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 解绑提现账号
         * @param {string} type ALIPAY/WECHAT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbindPay(type: string, options?: any): AxiosPromise<void> {
            return localVarFp.unbindPay(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserWithdrawalApi - object-oriented interface
 * @export
 * @class UserWithdrawalApi
 * @extends {BaseAPI}
 */
export class UserWithdrawalApi extends BaseAPI {
    /**
     * 
     * @summary 申请提现
     * @param {WithdrawalApplicationRequest} [withdrawalApplicationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public applyWithdrawal(withdrawalApplicationRequest?: WithdrawalApplicationRequest, options?: any) {
        return UserWithdrawalApiFp(this.configuration).applyWithdrawal(withdrawalApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 绑定支付宝账号为提现账号
     * @param {UserPayBindRequest} [userPayBindRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public bindAlipayUser(userPayBindRequest?: UserPayBindRequest, options?: any) {
        return UserWithdrawalApiFp(this.configuration).bindAlipayUser(userPayBindRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 统计待发放金额
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public calculateRequestingAmount(options?: any) {
        return UserWithdrawalApiFp(this.configuration).calculateRequestingAmount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 导出提现明细
     * @param {string} token /auth/v1/generate/download_token 生成的token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public downloadWithdrawalRequests(token: string, options?: any) {
        return UserWithdrawalApiFp(this.configuration).downloadWithdrawalRequests(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查询剩余可提现次数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public getAvailableRequestTimes(options?: any) {
        return UserWithdrawalApiFp(this.configuration).getAvailableRequestTimes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查看收入明细
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public getIncomeDetail(orderId: string, options?: any) {
        return UserWithdrawalApiFp(this.configuration).getIncomeDetail(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取商家账户余额
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public getMerchantAccount(options?: any) {
        return UserWithdrawalApiFp(this.configuration).getMerchantAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查看账号绑定状态
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public getPayBindingInfo(options?: any) {
        return UserWithdrawalApiFp(this.configuration).getPayBindingInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 查看用户钱包，包括创作者和非创作者的钱包
     * @summary 查看用户钱包余额
     * @param {string} [walletType] CREATOR_WALLET, MARKETING_WALLET, CREATOR_REWARD_WALLET
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public getWithdrawalWallet(walletType?: string, options?: any) {
        return UserWithdrawalApiFp(this.configuration).getWithdrawalWallet(walletType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查看激励金明细
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public listCreatorRewardDetails(pageNumber?: number, pageSize?: number, options?: any) {
        return UserWithdrawalApiFp(this.configuration).listCreatorRewardDetails(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 收入明细列表
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [withdrawalWalletType] MARKETING_WALLET/CREATOR_WALLET
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public listIncomeDetailPage(pageNumber?: number, pageSize?: number, withdrawalWalletType?: string, startDate?: string, endDate?: string, options?: any) {
        return UserWithdrawalApiFp(this.configuration).listIncomeDetailPage(pageNumber, pageSize, withdrawalWalletType, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 会员返利列表
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public listVipMarketingIncomeDetailPage(pageNumber?: number, pageSize?: number, options?: any) {
        return UserWithdrawalApiFp(this.configuration).listVipMarketingIncomeDetailPage(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 查看提现明细列表
     * @param {QueryWithdrawalApplicationRequest} [queryWithdrawalApplicationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public listWithdrawalApplicationPage(queryWithdrawalApplicationRequest?: QueryWithdrawalApplicationRequest, options?: any) {
        return UserWithdrawalApiFp(this.configuration).listWithdrawalApplicationPage(queryWithdrawalApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 审核提现
     * @param {number} requestsId 
     * @param {ReviewWithdrawalRequest} [reviewWithdrawalRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public reviewWithdrawalRequests(requestsId: number, reviewWithdrawalRequest?: ReviewWithdrawalRequest, options?: any) {
        return UserWithdrawalApiFp(this.configuration).reviewWithdrawalRequests(requestsId, reviewWithdrawalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 解绑提现账号
     * @param {string} type ALIPAY/WECHAT
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserWithdrawalApi
     */
    public unbindPay(type: string, options?: any) {
        return UserWithdrawalApiFp(this.configuration).unbindPay(type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsercardApi - axios parameter creator
 * @export
 */
export const UsercardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 给卡片标星
         * @param {string} ankiPackageUuid 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        starCard: async (ankiPackageUuid: string, cardId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ankiPackageUuid' is not null or undefined
            assertParamExists('starCard', 'ankiPackageUuid', ankiPackageUuid)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('starCard', 'cardId', cardId)
            const localVarPath = `/usercard/v1/package/{anki_package_uuid}/cards/{card_id}/star`
                .replace(`{${"anki_package_uuid"}}`, encodeURIComponent(String(ankiPackageUuid)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 取消卡片标星
         * @param {string} ankiPackageUuid 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unstarCard: async (ankiPackageUuid: string, cardId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ankiPackageUuid' is not null or undefined
            assertParamExists('unstarCard', 'ankiPackageUuid', ankiPackageUuid)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('unstarCard', 'cardId', cardId)
            const localVarPath = `/usercard/v1/package/{anki_package_uuid}/cards/{card_id}/star`
                .replace(`{${"anki_package_uuid"}}`, encodeURIComponent(String(ankiPackageUuid)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsercardApi - functional programming interface
 * @export
 */
export const UsercardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsercardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 给卡片标星
         * @param {string} ankiPackageUuid 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async starCard(ankiPackageUuid: string, cardId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.starCard(ankiPackageUuid, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 取消卡片标星
         * @param {string} ankiPackageUuid 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unstarCard(ankiPackageUuid: string, cardId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unstarCard(ankiPackageUuid, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsercardApi - factory interface
 * @export
 */
export const UsercardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsercardApiFp(configuration)
    return {
        /**
         * 
         * @summary 给卡片标星
         * @param {string} ankiPackageUuid 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        starCard(ankiPackageUuid: string, cardId: number, options?: any): AxiosPromise<void> {
            return localVarFp.starCard(ankiPackageUuid, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 取消卡片标星
         * @param {string} ankiPackageUuid 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unstarCard(ankiPackageUuid: string, cardId: number, options?: any): AxiosPromise<void> {
            return localVarFp.unstarCard(ankiPackageUuid, cardId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsercardApi - object-oriented interface
 * @export
 * @class UsercardApi
 * @extends {BaseAPI}
 */
export class UsercardApi extends BaseAPI {
    /**
     * 
     * @summary 给卡片标星
     * @param {string} ankiPackageUuid 
     * @param {number} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsercardApi
     */
    public starCard(ankiPackageUuid: string, cardId: number, options?: any) {
        return UsercardApiFp(this.configuration).starCard(ankiPackageUuid, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 取消卡片标星
     * @param {string} ankiPackageUuid 
     * @param {number} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsercardApi
     */
    public unstarCard(ankiPackageUuid: string, cardId: number, options?: any) {
        return UsercardApiFp(this.configuration).unstarCard(ankiPackageUuid, cardId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionsApi - axios parameter creator
 * @export
 */
export const VersionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取最新版本信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/v1/versions/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取最新版本列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/app/v1/version/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新AppVersion
         * @param {number} appVersionId 
         * @param {AppVersionUpdateRequest} [appVersionUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppVersion: async (appVersionId: number, appVersionUpdateRequest?: AppVersionUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appVersionId' is not null or undefined
            assertParamExists('updateAppVersion', 'appVersionId', appVersionId)
            const localVarPath = `/app/v1/version/update/{app_version_id}`
                .replace(`{${"app_version_id"}}`, encodeURIComponent(String(appVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appVersionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionsApi - functional programming interface
 * @export
 */
export const VersionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取最新版本信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestVersion(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAppVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取最新版本列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAppVersion(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppVersionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAppVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新AppVersion
         * @param {number} appVersionId 
         * @param {AppVersionUpdateRequest} [appVersionUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppVersion(appVersionId: number, appVersionUpdateRequest?: AppVersionUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppVersion(appVersionId, appVersionUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionsApi - factory interface
 * @export
 */
export const VersionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionsApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取最新版本信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestVersion(options?: any): AxiosPromise<LatestAppVersionResponse> {
            return localVarFp.getLatestVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取最新版本列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAppVersion(options?: any): AxiosPromise<AppVersionListResponse> {
            return localVarFp.listAppVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新AppVersion
         * @param {number} appVersionId 
         * @param {AppVersionUpdateRequest} [appVersionUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppVersion(appVersionId: number, appVersionUpdateRequest?: AppVersionUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAppVersion(appVersionId, appVersionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionsApi - object-oriented interface
 * @export
 * @class VersionsApi
 * @extends {BaseAPI}
 */
export class VersionsApi extends BaseAPI {
    /**
     * 
     * @summary 获取最新版本信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getLatestVersion(options?: any) {
        return VersionsApiFp(this.configuration).getLatestVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取最新版本列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public listAppVersion(options?: any) {
        return VersionsApiFp(this.configuration).listAppVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新AppVersion
     * @param {number} appVersionId 
     * @param {AppVersionUpdateRequest} [appVersionUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public updateAppVersion(appVersionId: number, appVersionUpdateRequest?: AppVersionUpdateRequest, options?: any) {
        return VersionsApiFp(this.configuration).updateAppVersion(appVersionId, appVersionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VipApi - axios parameter creator
 * @export
 */
export const VipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 取消用户周期订阅协议
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAgreement: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip/v1/cancel_agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 创建购买会员订单
         * @param {CreateVipOrderRequest} createVipOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVipOrder: async (createVipOrderRequest: CreateVipOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVipOrderRequest' is not null or undefined
            assertParamExists('createVipOrder', 'createVipOrderRequest', createVipOrderRequest)
            const localVarPath = `/vip/v1/create_order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVipOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 退出会员队伍
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exitVipSquad: async (squadId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'squadId' is not null or undefined
            assertParamExists('exitVipSquad', 'squadId', squadId)
            const localVarPath = `/vip/v1/squad/{squad_id}/exit`
                .replace(`{${"squad_id"}}`, encodeURIComponent(String(squadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取剩余免拼次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeftGroupPassTimes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip/v1/group_buy/left_pass_times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyVipInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip/v1/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取我的会员队伍
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyVipSquad: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip/v1/squad/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 获取会员连续订阅的签约记录
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodPayAgreementList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip/v1/period_pay_agreement/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} iosSystem true的话表明客户端是ios系统，false则为安卓系统
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipSKuInfo: async (iosSystem: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iosSystem' is not null or undefined
            assertParamExists('getVipSKuInfo', 'iosSystem', iosSystem)
            const localVarPath = `/vip/v1/sku/{ios_system}`
                .replace(`{${"ios_system"}}`, encodeURIComponent(String(iosSystem)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据邀请码获取会员队伍信息
         * @param {string} inviteCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipSquadByInviteCode: async (inviteCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteCode' is not null or undefined
            assertParamExists('getVipSquadByInviteCode', 'inviteCode', inviteCode)
            const localVarPath = `/vip/v1/squad/by_invide_code/{invite_code}`
                .replace(`{${"invite_code"}}`, encodeURIComponent(String(inviteCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取会员队伍成员
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipSquadMembers: async (squadId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'squadId' is not null or undefined
            assertParamExists('getVipSquadMembers', 'squadId', squadId)
            const localVarPath = `/vip/v1/squad/{squad_id}/members`
                .replace(`{${"squad_id"}}`, encodeURIComponent(String(squadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 加入会员队伍
         * @param {JoinSquadRequest} joinSquadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinVipSquad: async (joinSquadRequest: JoinSquadRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinSquadRequest' is not null or undefined
            assertParamExists('joinVipSquad', 'joinSquadRequest', joinSquadRequest)
            const localVarPath = `/vip/v1/squad/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinSquadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取队伍邀请码列表
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSquadInviteCode: async (squadId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'squadId' is not null or undefined
            assertParamExists('listSquadInviteCode', 'squadId', squadId)
            const localVarPath = `/vip/v1/squad/{squad_id}/invite_code/list`
                .replace(`{${"squad_id"}}`, encodeURIComponent(String(squadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueryRedeemRequest} [queryRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVipRedeemLogPage: async (queryRedeemRequest?: QueryRedeemRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/redeem/v1/vip/list/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryRedeemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VipApi - functional programming interface
 * @export
 */
export const VipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VipApiAxiosParamCreator(configuration)
    return {
        /**
         * 取消用户周期订阅协议
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAgreement(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAgreement(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 创建购买会员订单
         * @param {CreateVipOrderRequest} createVipOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVipOrder(createVipOrderRequest: CreateVipOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVipOrder(createVipOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 退出会员队伍
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exitVipSquad(squadId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exitVipSquad(squadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取剩余免拼次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeftGroupPassTimes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VipGroupPassInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeftGroupPassTimes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyVipInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserVipInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyVipInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取我的会员队伍
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyVipSquad(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VipSquadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyVipSquad(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 获取会员连续订阅的签约记录
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriodPayAgreementList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VipStatusDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriodPayAgreementList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} iosSystem true的话表明客户端是ios系统，false则为安卓系统
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVipSKuInfo(iosSystem: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVipSKuInfo(iosSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据邀请码获取会员队伍信息
         * @param {string} inviteCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVipSquadByInviteCode(inviteCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VipSquadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVipSquadByInviteCode(inviteCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取会员队伍成员
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVipSquadMembers(squadId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VipSquadMemberListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVipSquadMembers(squadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 加入会员队伍
         * @param {JoinSquadRequest} joinSquadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinVipSquad(joinSquadRequest: JoinSquadRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinVipSquad(joinSquadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取队伍邀请码列表
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSquadInviteCode(squadId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VipSquadInviteCodeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSquadInviteCode(squadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QueryRedeemRequest} [queryRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVipRedeemLogPage(queryRedeemRequest?: QueryRedeemRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVipLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVipRedeemLogPage(queryRedeemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VipApi - factory interface
 * @export
 */
export const VipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VipApiFp(configuration)
    return {
        /**
         * 取消用户周期订阅协议
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAgreement(options?: any): AxiosPromise<void> {
            return localVarFp.cancelAgreement(options).then((request) => request(axios, basePath));
        },
        /**
         * 创建购买会员订单
         * @param {CreateVipOrderRequest} createVipOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVipOrder(createVipOrderRequest: CreateVipOrderRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.createVipOrder(createVipOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 退出会员队伍
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exitVipSquad(squadId: number, options?: any): AxiosPromise<void> {
            return localVarFp.exitVipSquad(squadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取剩余免拼次数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeftGroupPassTimes(options?: any): AxiosPromise<VipGroupPassInfo> {
            return localVarFp.getLeftGroupPassTimes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyVipInfo(options?: any): AxiosPromise<UserVipInfo> {
            return localVarFp.getMyVipInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取我的会员队伍
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyVipSquad(options?: any): AxiosPromise<VipSquadResponse> {
            return localVarFp.getMyVipSquad(options).then((request) => request(axios, basePath));
        },
        /**
         * 获取会员连续订阅的签约记录
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodPayAgreementList(options?: any): AxiosPromise<VipStatusDetailResponse> {
            return localVarFp.getPeriodPayAgreementList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} iosSystem true的话表明客户端是ios系统，false则为安卓系统
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipSKuInfo(iosSystem: boolean, options?: any): AxiosPromise<PurchaseInfoResponse> {
            return localVarFp.getVipSKuInfo(iosSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据邀请码获取会员队伍信息
         * @param {string} inviteCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipSquadByInviteCode(inviteCode: string, options?: any): AxiosPromise<VipSquadResponse> {
            return localVarFp.getVipSquadByInviteCode(inviteCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取会员队伍成员
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVipSquadMembers(squadId: number, options?: any): AxiosPromise<VipSquadMemberListResponse> {
            return localVarFp.getVipSquadMembers(squadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 加入会员队伍
         * @param {JoinSquadRequest} joinSquadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinVipSquad(joinSquadRequest: JoinSquadRequest, options?: any): AxiosPromise<void> {
            return localVarFp.joinVipSquad(joinSquadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取队伍邀请码列表
         * @param {number} squadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSquadInviteCode(squadId: number, options?: any): AxiosPromise<VipSquadInviteCodeListResponse> {
            return localVarFp.listSquadInviteCode(squadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueryRedeemRequest} [queryRedeemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVipRedeemLogPage(queryRedeemRequest?: QueryRedeemRequest, options?: any): AxiosPromise<PageVipLog> {
            return localVarFp.listVipRedeemLogPage(queryRedeemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VipApi - object-oriented interface
 * @export
 * @class VipApi
 * @extends {BaseAPI}
 */
export class VipApi extends BaseAPI {
    /**
     * 取消用户周期订阅协议
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public cancelAgreement(options?: any) {
        return VipApiFp(this.configuration).cancelAgreement(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 创建购买会员订单
     * @param {CreateVipOrderRequest} createVipOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public createVipOrder(createVipOrderRequest: CreateVipOrderRequest, options?: any) {
        return VipApiFp(this.configuration).createVipOrder(createVipOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 退出会员队伍
     * @param {number} squadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public exitVipSquad(squadId: number, options?: any) {
        return VipApiFp(this.configuration).exitVipSquad(squadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取剩余免拼次数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public getLeftGroupPassTimes(options?: any) {
        return VipApiFp(this.configuration).getLeftGroupPassTimes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public getMyVipInfo(options?: any) {
        return VipApiFp(this.configuration).getMyVipInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取我的会员队伍
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public getMyVipSquad(options?: any) {
        return VipApiFp(this.configuration).getMyVipSquad(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 获取会员连续订阅的签约记录
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public getPeriodPayAgreementList(options?: any) {
        return VipApiFp(this.configuration).getPeriodPayAgreementList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} iosSystem true的话表明客户端是ios系统，false则为安卓系统
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public getVipSKuInfo(iosSystem: boolean, options?: any) {
        return VipApiFp(this.configuration).getVipSKuInfo(iosSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据邀请码获取会员队伍信息
     * @param {string} inviteCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public getVipSquadByInviteCode(inviteCode: string, options?: any) {
        return VipApiFp(this.configuration).getVipSquadByInviteCode(inviteCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取会员队伍成员
     * @param {number} squadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public getVipSquadMembers(squadId: number, options?: any) {
        return VipApiFp(this.configuration).getVipSquadMembers(squadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 加入会员队伍
     * @param {JoinSquadRequest} joinSquadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public joinVipSquad(joinSquadRequest: JoinSquadRequest, options?: any) {
        return VipApiFp(this.configuration).joinVipSquad(joinSquadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取队伍邀请码列表
     * @param {number} squadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public listSquadInviteCode(squadId: number, options?: any) {
        return VipApiFp(this.configuration).listSquadInviteCode(squadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueryRedeemRequest} [queryRedeemRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipApi
     */
    public listVipRedeemLogPage(queryRedeemRequest?: QueryRedeemRequest, options?: any) {
        return VipApiFp(this.configuration).listVipRedeemLogPage(queryRedeemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取可用的充值列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositSkuList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/wallet/deposit/skus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 钱包充值
         * @param {DepositWalletRequest} depositWalletRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositWalletBalance: async (depositWalletRequest: DepositWalletRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'depositWalletRequest' is not null or undefined
            assertParamExists('depositWalletBalance', 'depositWalletRequest', depositWalletRequest)
            const localVarPath = `/user/v1/wallet/deposit/buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(depositWalletRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary （根据当前系统）虾滑余额兑换会员
         * @param {boolean} [isIosSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeVipDays: async (isIosSystem?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/exchange-vip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isIosSystem !== undefined) {
                localVarQueryParameter['is_ios_system'] = isIosSystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary （根据当前系统）获取虾滑余额可生成会员天数
         * @param {boolean} [isIosSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeVipDays: async (isIosSystem?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/wallet/can-exchange-vip-days`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isIosSystem !== undefined) {
                localVarQueryParameter['is_ios_system'] = isIosSystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWallet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/v1/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取可用的充值列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async depositSkuList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletSkuItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.depositSkuList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 钱包充值
         * @param {DepositWalletRequest} depositWalletRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async depositWalletBalance(depositWalletRequest: DepositWalletRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositWalletResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.depositWalletBalance(depositWalletRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary （根据当前系统）虾滑余额兑换会员
         * @param {boolean} [isIosSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeVipDays(isIosSystem?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeVipResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeVipDays(isIosSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary （根据当前系统）获取虾滑余额可生成会员天数
         * @param {boolean} [isIosSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeVipDays(isIosSystem?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeVipDaysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeVipDays(isIosSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyWallet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWalletResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyWallet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取可用的充值列表
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositSkuList(options?: any): AxiosPromise<Array<WalletSkuItem>> {
            return localVarFp.depositSkuList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 钱包充值
         * @param {DepositWalletRequest} depositWalletRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        depositWalletBalance(depositWalletRequest: DepositWalletRequest, options?: any): AxiosPromise<DepositWalletResponse> {
            return localVarFp.depositWalletBalance(depositWalletRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary （根据当前系统）虾滑余额兑换会员
         * @param {boolean} [isIosSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeVipDays(isIosSystem?: boolean, options?: any): AxiosPromise<ExchangeVipResultResponse> {
            return localVarFp.exchangeVipDays(isIosSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary （根据当前系统）获取虾滑余额可生成会员天数
         * @param {boolean} [isIosSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeVipDays(isIosSystem?: boolean, options?: any): AxiosPromise<ExchangeVipDaysResponse> {
            return localVarFp.getExchangeVipDays(isIosSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyWallet(options?: any): AxiosPromise<UserWalletResponse> {
            return localVarFp.getMyWallet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * 
     * @summary 获取可用的充值列表
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public depositSkuList(options?: any) {
        return WalletApiFp(this.configuration).depositSkuList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 钱包充值
     * @param {DepositWalletRequest} depositWalletRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public depositWalletBalance(depositWalletRequest: DepositWalletRequest, options?: any) {
        return WalletApiFp(this.configuration).depositWalletBalance(depositWalletRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary （根据当前系统）虾滑余额兑换会员
     * @param {boolean} [isIosSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public exchangeVipDays(isIosSystem?: boolean, options?: any) {
        return WalletApiFp(this.configuration).exchangeVipDays(isIosSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary （根据当前系统）获取虾滑余额可生成会员天数
     * @param {boolean} [isIosSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getExchangeVipDays(isIosSystem?: boolean, options?: any) {
        return WalletApiFp(this.configuration).getExchangeVipDays(isIosSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public getMyWallet(options?: any) {
        return WalletApiFp(this.configuration).getMyWallet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WeixinApi - axios parameter creator
 * @export
 */
export const WeixinApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取scheme码
         * @param {GenerateMpSchemeRequest} [generateMpSchemeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateScheme: async (generateMpSchemeRequest?: GenerateMpSchemeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/weixin/v1/mp/generate_scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateMpSchemeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公众号回调页面
         * @param {string} [signature] 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {string} [echostr] 
         * @param {string} [r] 跳转链接，如果链接里有 &#x60;__CODE__&#x60; 字符串，且query param中有&#x60;code&#x60;字段，则把code值代入
         * @param {string} [code] 如果是oauth授权回调，则会有code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCallback: async (signature?: string, timestamp?: string, nonce?: string, echostr?: string, r?: string, code?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/weixin/v1/mp/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (signature !== undefined) {
                localVarQueryParameter['signature'] = signature;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (nonce !== undefined) {
                localVarQueryParameter['nonce'] = nonce;
            }

            if (echostr !== undefined) {
                localVarQueryParameter['echostr'] = echostr;
            }

            if (r !== undefined) {
                localVarQueryParameter['r'] = r;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary （不需要登录）检查某 open id 绑定到某滑记账号
         * @param {string} mpId 
         * @param {string} openId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCheckOpenIdBinding: async (mpId: string, openId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mpId' is not null or undefined
            assertParamExists('mpCheckOpenIdBinding', 'mpId', mpId)
            // verify required parameter 'openId' is not null or undefined
            assertParamExists('mpCheckOpenIdBinding', 'openId', openId)
            const localVarPath = `/weixin/v1/mp/{mp_id}/open_id/check/{open_id}`
                .replace(`{${"mp_id"}}`, encodeURIComponent(String(mpId)))
                .replace(`{${"open_id"}}`, encodeURIComponent(String(openId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 公众号/小程序授权，获得openId （仅适用于微信需要open id的情况（支付、订阅消息等），微信第三方登录见 `/auth/v1/signin/wechat`）
         * @param {WeixinMpCodeRequest} weixinMpCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCodeAuthorize: async (weixinMpCodeRequest: WeixinMpCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'weixinMpCodeRequest' is not null or undefined
            assertParamExists('mpCodeAuthorize', 'weixinMpCodeRequest', weixinMpCodeRequest)
            const localVarPath = `/weixin/v1/mp/code_authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weixinMpCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WeixinJsApiSignatureRequest} weixinJsApiSignatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCreateJsApiSignature: async (weixinJsApiSignatureRequest: WeixinJsApiSignatureRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'weixinJsApiSignatureRequest' is not null or undefined
            assertParamExists('mpCreateJsApiSignature', 'weixinJsApiSignatureRequest', weixinJsApiSignatureRequest)
            const localVarPath = `/weixin/v1/mp/create_js_api_signature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weixinJsApiSignatureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 总返回 200 （无论用户是否绑定过）
         * @summary 清除本账号在该公众号下面的绑定
         * @param {string} mpId 
         * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpDeleteBinding: async (mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mpId' is not null or undefined
            assertParamExists('mpDeleteBinding', 'mpId', mpId)
            const localVarPath = `/weixin/v1/mp/{mp_id}/open_id/binding`
                .replace(`{${"mp_id"}}`, encodeURIComponent(String(mpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWeixinMpBindingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获得该账号是否绑定了某一个公众号
         * @param {string} mpId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpGetBinding: async (mpId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mpId' is not null or undefined
            assertParamExists('mpGetBinding', 'mpId', mpId)
            const localVarPath = `/weixin/v1/mp/{mp_id}/open_id/binding`
                .replace(`{${"mp_id"}}`, encodeURIComponent(String(mpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 如果该用户已经绑定过其它openid，则直接替换。 如果该openid被其他用户绑定过，则报400错误, 报错响应：`{\"message\": \"该微信号已被其他滑记账号绑定\", \"nickname\": \"另外一个账号的昵称\"}`。 
         * @summary 请求绑定一个openId
         * @param {string} mpId 如果是绑定小程序 则mp_id&#x3D;huaji_mini_program
         * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpPostBinding: async (mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mpId' is not null or undefined
            assertParamExists('mpPostBinding', 'mpId', mpId)
            const localVarPath = `/weixin/v1/mp/{mp_id}/open_id/binding`
                .replace(`{${"mp_id"}}`, encodeURIComponent(String(mpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWeixinMpBindingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeixinApi - functional programming interface
 * @export
 */
export const WeixinApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeixinApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取scheme码
         * @param {GenerateMpSchemeRequest} [generateMpSchemeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateScheme(generateMpSchemeRequest?: GenerateMpSchemeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateMpSchemeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateScheme(generateMpSchemeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公众号回调页面
         * @param {string} [signature] 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {string} [echostr] 
         * @param {string} [r] 跳转链接，如果链接里有 &#x60;__CODE__&#x60; 字符串，且query param中有&#x60;code&#x60;字段，则把code值代入
         * @param {string} [code] 如果是oauth授权回调，则会有code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpCallback(signature?: string, timestamp?: string, nonce?: string, echostr?: string, r?: string, code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpCallback(signature, timestamp, nonce, echostr, r, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary （不需要登录）检查某 open id 绑定到某滑记账号
         * @param {string} mpId 
         * @param {string} openId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpCheckOpenIdBinding(mpId: string, openId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckWeixinMpBindingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpCheckOpenIdBinding(mpId, openId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 公众号/小程序授权，获得openId （仅适用于微信需要open id的情况（支付、订阅消息等），微信第三方登录见 `/auth/v1/signin/wechat`）
         * @param {WeixinMpCodeRequest} weixinMpCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpCodeAuthorize(weixinMpCodeRequest: WeixinMpCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeixinMpCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpCodeAuthorize(weixinMpCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WeixinJsApiSignatureRequest} weixinJsApiSignatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpCreateJsApiSignature(weixinJsApiSignatureRequest: WeixinJsApiSignatureRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeixinJsApiSignatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpCreateJsApiSignature(weixinJsApiSignatureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 总返回 200 （无论用户是否绑定过）
         * @summary 清除本账号在该公众号下面的绑定
         * @param {string} mpId 
         * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpDeleteBinding(mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpDeleteBinding(mpId, postWeixinMpBindingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获得该账号是否绑定了某一个公众号
         * @param {string} mpId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpGetBinding(mpId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWeixinMpBindingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpGetBinding(mpId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 如果该用户已经绑定过其它openid，则直接替换。 如果该openid被其他用户绑定过，则报400错误, 报错响应：`{\"message\": \"该微信号已被其他滑记账号绑定\", \"nickname\": \"另外一个账号的昵称\"}`。 
         * @summary 请求绑定一个openId
         * @param {string} mpId 如果是绑定小程序 则mp_id&#x3D;huaji_mini_program
         * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mpPostBinding(mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mpPostBinding(mpId, postWeixinMpBindingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeixinApi - factory interface
 * @export
 */
export const WeixinApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeixinApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取scheme码
         * @param {GenerateMpSchemeRequest} [generateMpSchemeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateScheme(generateMpSchemeRequest?: GenerateMpSchemeRequest, options?: any): AxiosPromise<GenerateMpSchemeResponse> {
            return localVarFp.generateScheme(generateMpSchemeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公众号回调页面
         * @param {string} [signature] 
         * @param {string} [timestamp] 
         * @param {string} [nonce] 
         * @param {string} [echostr] 
         * @param {string} [r] 跳转链接，如果链接里有 &#x60;__CODE__&#x60; 字符串，且query param中有&#x60;code&#x60;字段，则把code值代入
         * @param {string} [code] 如果是oauth授权回调，则会有code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCallback(signature?: string, timestamp?: string, nonce?: string, echostr?: string, r?: string, code?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mpCallback(signature, timestamp, nonce, echostr, r, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary （不需要登录）检查某 open id 绑定到某滑记账号
         * @param {string} mpId 
         * @param {string} openId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCheckOpenIdBinding(mpId: string, openId: string, options?: any): AxiosPromise<CheckWeixinMpBindingResponse> {
            return localVarFp.mpCheckOpenIdBinding(mpId, openId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 公众号/小程序授权，获得openId （仅适用于微信需要open id的情况（支付、订阅消息等），微信第三方登录见 `/auth/v1/signin/wechat`）
         * @param {WeixinMpCodeRequest} weixinMpCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCodeAuthorize(weixinMpCodeRequest: WeixinMpCodeRequest, options?: any): AxiosPromise<WeixinMpCodeResponse> {
            return localVarFp.mpCodeAuthorize(weixinMpCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WeixinJsApiSignatureRequest} weixinJsApiSignatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpCreateJsApiSignature(weixinJsApiSignatureRequest: WeixinJsApiSignatureRequest, options?: any): AxiosPromise<WeixinJsApiSignatureResponse> {
            return localVarFp.mpCreateJsApiSignature(weixinJsApiSignatureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 总返回 200 （无论用户是否绑定过）
         * @summary 清除本账号在该公众号下面的绑定
         * @param {string} mpId 
         * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpDeleteBinding(mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.mpDeleteBinding(mpId, postWeixinMpBindingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获得该账号是否绑定了某一个公众号
         * @param {string} mpId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpGetBinding(mpId: string, options?: any): AxiosPromise<GetWeixinMpBindingResponse> {
            return localVarFp.mpGetBinding(mpId, options).then((request) => request(axios, basePath));
        },
        /**
         * 如果该用户已经绑定过其它openid，则直接替换。 如果该openid被其他用户绑定过，则报400错误, 报错响应：`{\"message\": \"该微信号已被其他滑记账号绑定\", \"nickname\": \"另外一个账号的昵称\"}`。 
         * @summary 请求绑定一个openId
         * @param {string} mpId 如果是绑定小程序 则mp_id&#x3D;huaji_mini_program
         * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mpPostBinding(mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.mpPostBinding(mpId, postWeixinMpBindingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WeixinApi - object-oriented interface
 * @export
 * @class WeixinApi
 * @extends {BaseAPI}
 */
export class WeixinApi extends BaseAPI {
    /**
     * 
     * @summary 获取scheme码
     * @param {GenerateMpSchemeRequest} [generateMpSchemeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public generateScheme(generateMpSchemeRequest?: GenerateMpSchemeRequest, options?: any) {
        return WeixinApiFp(this.configuration).generateScheme(generateMpSchemeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公众号回调页面
     * @param {string} [signature] 
     * @param {string} [timestamp] 
     * @param {string} [nonce] 
     * @param {string} [echostr] 
     * @param {string} [r] 跳转链接，如果链接里有 &#x60;__CODE__&#x60; 字符串，且query param中有&#x60;code&#x60;字段，则把code值代入
     * @param {string} [code] 如果是oauth授权回调，则会有code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public mpCallback(signature?: string, timestamp?: string, nonce?: string, echostr?: string, r?: string, code?: string, options?: any) {
        return WeixinApiFp(this.configuration).mpCallback(signature, timestamp, nonce, echostr, r, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary （不需要登录）检查某 open id 绑定到某滑记账号
     * @param {string} mpId 
     * @param {string} openId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public mpCheckOpenIdBinding(mpId: string, openId: string, options?: any) {
        return WeixinApiFp(this.configuration).mpCheckOpenIdBinding(mpId, openId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 公众号/小程序授权，获得openId （仅适用于微信需要open id的情况（支付、订阅消息等），微信第三方登录见 `/auth/v1/signin/wechat`）
     * @param {WeixinMpCodeRequest} weixinMpCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public mpCodeAuthorize(weixinMpCodeRequest: WeixinMpCodeRequest, options?: any) {
        return WeixinApiFp(this.configuration).mpCodeAuthorize(weixinMpCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WeixinJsApiSignatureRequest} weixinJsApiSignatureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public mpCreateJsApiSignature(weixinJsApiSignatureRequest: WeixinJsApiSignatureRequest, options?: any) {
        return WeixinApiFp(this.configuration).mpCreateJsApiSignature(weixinJsApiSignatureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 总返回 200 （无论用户是否绑定过）
     * @summary 清除本账号在该公众号下面的绑定
     * @param {string} mpId 
     * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public mpDeleteBinding(mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options?: any) {
        return WeixinApiFp(this.configuration).mpDeleteBinding(mpId, postWeixinMpBindingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获得该账号是否绑定了某一个公众号
     * @param {string} mpId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public mpGetBinding(mpId: string, options?: any) {
        return WeixinApiFp(this.configuration).mpGetBinding(mpId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 如果该用户已经绑定过其它openid，则直接替换。 如果该openid被其他用户绑定过，则报400错误, 报错响应：`{\"message\": \"该微信号已被其他滑记账号绑定\", \"nickname\": \"另外一个账号的昵称\"}`。 
     * @summary 请求绑定一个openId
     * @param {string} mpId 如果是绑定小程序 则mp_id&#x3D;huaji_mini_program
     * @param {PostWeixinMpBindingRequest} [postWeixinMpBindingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeixinApi
     */
    public mpPostBinding(mpId: string, postWeixinMpBindingRequest?: PostWeixinMpBindingRequest, options?: any) {
        return WeixinApiFp(this.configuration).mpPostBinding(mpId, postWeixinMpBindingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



<template lang="pug">
Popup.popup-constraint(
  :show="loginDialogVisible && isWx"
  round
  position="bottom"
)
  .wx-btn(@click="wxLogin")
    Icon(
      name="wechat"
      color="white"
    )
    span 微信一键登录

  .phone-btn(@click="phoneDialogVisible = true") 手机号码登录

Popup(
  :show="loginDialogVisible && isQQ"
  round
  position="bottom"
)
  .qq-btn(@click="qqLogin")
    Icon(
      name="qq"
      color="white"
    )
    span QQ一键登录

  .phone-btn.qq(@click="phoneDialogVisible = true") 手机号码登录

Popup.popup-constraint.phone-login-dialog(
  :show="phoneDialogVisible"
  closeable
  round
  position="bottom"
  @update:show="(phoneDialogVisible = false), hideLoginDialog()"
)
  .login__title 登录/注册后才能继续操作

  Field(
    v-model="phone"
    type="tel"
    label-class="login-dialog__count-code"
    placeholder="手机号"
    :error-message="phoneErr"
  )
    template(#label)
      .country_code +86

    template(#button)
      .login-dialog__counting(v-if="counting")
        CountDown(
          :time="60000"
          format="ss"
          @finish="counting = false"
        )
        span.login-dialog__second s

      .login__send(
        v-else
        @click="sendCode"
      ) 发送验证码

  Field(
    id="smscode"
    v-model="code"
    type="digit"
    maxlength="4"
    placeholder="请输入验证码"
    :error-message="codeErr"
  )

  .login__hint
    | 未注册滑记的手机号验证通过后将自动注册，登录代表您已阅读并同意
    a.login__hint__a(
      href="https://huaji.wiki/terms/user.html"
      target="_blank"
    ) 《用户协议》
    | 和
    a.login__hint__a(
      href="https://huaji.wiki/terms/privacy.html"
      target="_blank"
    ) 《隐私政策》

  #captcha

  .login__submit
    Button(
      type="primary"
      :loading="loading"
      round
      block
      loading-text="加载中..."
      @click="login"
    ) 立即登录
</template>

<script setup lang="ts">
import { Popup, Field, Button, CountDown, Toast, Icon } from "vant"
import {
  hideLoginDialog,
  loginDialogVisible,
  phoneDialogVisible,
  phone,
  shouldReloadAfterLogin,
} from "~/logic/auth"
import { ref, onMounted } from "vue"
import api from "~/api"
import db from "~/db"
import {
  SendSmsCodeRequestPurposeEnum,
  SignInPhoneRequestMethodNameEnum,
} from "~/api-generated"
import { login as wxLogin } from "~/logic/wx"
import { login as qqLogin } from "~/logic/qq"
import { parseUserId, isIos } from "~/utils"
import { useRoute } from "vue-router"
import { onUnmounted } from "vue"

const code = ref("")
const phoneErr = ref("")
const codeErr = ref("")
const loading = ref(false)
const counting = ref(false)
const isQQ = ref(_global.isQQ)
const isWx = ref(_global.isWx)
const route = useRoute()

const onViewportResize = () => {
  const el = document.body.querySelector(".phone-login-dialog") as HTMLDivElement

  if (window.visualViewport == null || el == null) {
    return
  }

  const rect = el.getBoundingClientRect()

  if (rect.bottom > window.visualViewport.height) {
    const diff = rect.bottom - window.visualViewport.height
    el.style.bottom = diff + "px"
  } else {
    el.style.bottom = "0px"
  }
}

// HUA-6753
// chrome 和 safari 的判断方法在微信和 qq 中同样是成立的
// 这里先判断非微信和非 qq 的场景，微信和 qq 已经处理过这个问题
if (isIos() && !_global.isWx && !_global.isQQ) {
  onMounted(() => {
    window.visualViewport?.addEventListener('resize', onViewportResize)
  })

  onUnmounted(() => {
    window.visualViewport?.removeEventListener('resize', onViewportResize)
  })
}

const validatePhone = (): boolean => {
  if (phone.value === "") {
    phoneErr.value = "请输入手机号码"
    return false
  }

  if (!/^\d{11}$/.test(phone.value)) {
    phoneErr.value = "手机号码格式不正确"
    return false
  }

  phoneErr.value = ""
  return true
}
const validateCode = (): boolean => {
  if (code.value === "") {
    codeErr.value = "请输入验证码"
    return false
  }

  codeErr.value = ""
  return true
}

const login = async () => {
  if (loading.value) return
  const isInvitePage = route.name == "invite"

  if (validatePhone() && validateCode()) {
    loading.value = true

    try {
      const uid = await parseUserId(route.params.huaid as string)
      const res = await api.auth.signInByPhone({
        country_code: 86,
        phone_number: phone.value,
        code: code.value,
        method_name: SignInPhoneRequestMethodNameEnum.SmsCode,
        pvt_inviter: isInvitePage ? Number(uid) : undefined,
      })

      db.value.token = "Bearer " + res.data.token
      Toast.success("登录成功")
      phoneDialogVisible.value = false
      hideLoginDialog()

      if (shouldReloadAfterLogin.value) {
        shouldReloadAfterLogin.value = false
        location.reload()
      }
    } finally {
      loading.value = false
    }
  }
}

const sendCode = () => {
  const codeInput = document.querySelector("#smscode") as HTMLInputElement
  console.log(codeInput)
  codeInput?.focus()

  if (validatePhone()) {
    counting.value = true

    nvc.getNVCValAsync(async (captcha: string) => {
      const res = await api.auth.signInSendSmsCode({
        purpose: SendSmsCodeRequestPurposeEnum.Signin,
        country_code: 86,
        phone_number: phone.value,
        captcha,
      })

      switch (res.data.bizCode) {
        case 100:
        case 200:
          Toast.success("验证码短信已发送，请注意查收")
          break

        // 二次滑动验证
        case 400:
          nvc.getNC({ renderTo: "captcha" })
          break

        default:
          Toast.fail("人机验证失败")
      }
    })
  }
}
</script>

<style lang="less">
@import "../main.less";

.login-dialog__counting {
  display: flex;
  align-items: center;
}

.login-dialog__second {
  margin-left: 4px;
  color: rgba(72, 72, 63, 0.4);
}

.login-dialog__counting .van-count-down {
  color: rgba(72, 72, 63, 0.4);
}

.nc_wrapper {
  margin: auto;
}

.login__title {
  text-align: center;
  font-size: 18px;
  padding-top: 14px;
  padding-bottom: 24px;
}

.login__hint {
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
  color: rgba(72, 72, 63, 0.5);
}

.login__hint__a {
  color: var(--main);
}

#captcha {
  display: flex;
  justify-content: center;
}

.login__submit {
  margin: 0 12px 12px;
  display: flex;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: env(safe-area-inset-bottom, 34px);
}

.login__send {
  color: var(--black);
}

.login-dialog__count-code {
  width: 30px !important;
}

.phone-btn {
  font-size: 16px;
  color: #07c160;
  width: 350px;
  height: 40px;
  border: 1px solid #07c160;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 40px;
}

.phone-btn.qq {
  color: #50a8f1;
  border: 1px solid #50a8f1;
}

.wx-btn,
.qq-btn {
  font-size: 16px;
  color: white;
  width: 350px;
  height: 40px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0;
  background: #07c160;

  .van-icon {
    margin-right: 10px;
    font-size: 20px;
  }
}

.qq-btn {
  background: #50a8f1;
}
</style>

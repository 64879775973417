import { ref, watch } from "vue"
import api from "~/api"
import db from "~/db"
import { collectEvent } from "~/logic/eventCollector"
import { configureScope } from "~/sentry"

export const loginDialogVisible = ref(false)
export const phoneDialogVisible = ref(false)
export const phone = ref("")
export const shouldReloadAfterLogin = ref(false)

type Callback = () => void
let _cb: Callback | undefined
export const showLoginDialog = (cb?: Callback) => {
  _cb = cb
  phoneDialogVisible.value = true
}

export const hideLoginDialog = () => {
  loginDialogVisible.value = false
  _cb?.()
}

export const auth: () => Promise<void> = () => {
  if (db.value.token) return Promise.resolve()

  return new Promise((res, rej) => {
    showLoginDialog(() => {
      if (db.value.token) {
        collectEvent("auth_success")
        res()
      } else {
        collectEvent("auth_cancelled")
        const err = new Error("取消登录")
        err.skipSentry = true
        rej(err)
      }
    })
  })
}

watch(
  () => db.value.token,
  async () => {
    if (!db.value.token) {
      configureScope(function (scope) {
        scope.setUser(null)
      })
      return
    }

    const myInfoRes = await api.auth.authGetMyInfo()
    db.value.userInfo = myInfoRes.data
    configureScope(function (scope) {
      scope.setUser({
        id: myInfoRes.data.user_id?.toString(),
        username: myInfoRes.data.nickname,
        extras: JSON.stringify(myInfoRes.data),
      })
    })
  },
  { immediate: true }
)

export const logout = () => {
  db.value.token = ""
  db.value.userInfo = undefined
}

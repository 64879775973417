<template lang="pug">
router-view

LoginDialog

DebugPanel(v-if="!isProd")

div
  .logout-button(
    @click="confirmLogout"
    :style="{ color: logoutCounter >= 2 ? 'grey' : 'rgba(0,0,0,0.01)' }"
  ) 退出登录
</template>

<script setup lang="ts">
import DebugPanel from "./DebugPanle.vue"
import { logout } from "~/logic/auth"
import { ref } from "vue"

const isProd = _global.isProd

// 连点三下右下角，显示退出登录
var logoutCounter = ref(0)
const confirmLogout = () => {
  logoutCounter.value = logoutCounter.value + 1
  if (logoutCounter.value < 5) {
    return
  }
  logout()
  location.reload()
}

// Now out web page supports PC layout!
// if (
//   window.innerWidth > 800 &&
//   location.pathname !== "/pc"
//   && location.pathname !== '/pc-pay'
// ) {
//   router.push("/pc?href=" + location.href)
// }

// HUA-1599: 存在 alipay 支付回调，为了及时清除过长的URL（照顾QQ分享），才强制这样的跳转
// 但有可能会导致method=alipay这样的功能性参数失效。而且其它过长的URL也没有考虑。
// 但总不能无脑更改过长的URL吧
if (window.location.search.indexOf("method=alipay") > -1) {
  window.location.href = window.location.origin + window.location.pathname
}
</script>

<style lang="less">
.logout-button {
  position: absolute;
  right: 0;
  bottom: 0;
  color: rgba(0, 0, 0, 0.01);
  font-size: 12px;
}

/* https://segmentfault.com/a/1190000038314318 */
body {
  /* IOS禁止微信调整字体大小 */
  -webkit-text-size-adjust: 100% !important;
}
</style>

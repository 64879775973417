// this file is the very first file to run
// we use this file to init _global and store something
// that can be used by all other files

import db from "./db"

export type Env = "local" | "staging" | "prod"
export type ApiEnv = "staging" | "prod"

const API_URL_PROD = "https://api.qianmoai.com/api"
const API_URL_STAGING = "https://api.staging.qianmo8.com/api"
export type ApiURL = typeof API_URL_STAGING | typeof API_URL_PROD

const OSS_URL_STAGING = "http://media-qa.qmcdn.net"
const OSS_URL_PROD = "https://hjcdn.cn"
export type OssURL = typeof OSS_URL_STAGING | typeof OSS_URL_PROD

const env = parseEnv(location.hostname)

let apiEnv = db.value.debug.apiEnv || "staging"

if (env === "prod") {
  apiEnv = "prod"
}

const apiURL = apiEnv === "prod" ? API_URL_PROD : API_URL_STAGING
const ossURL = apiEnv === "prod" ? OSS_URL_PROD : OSS_URL_STAGING

window._global = {
  env,

  get isLocal() {
    return this.env === "local"
  },
  get isStaging() {
    return this.env === "staging"
  },
  get isProd() {
    return this.env === "prod"
  },

  isWx: navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1,

  isQQ: navigator.userAgent.toLowerCase().indexOf("qq/") > -1,

  apiEnv,
  apiURL,
  ossURL,

  gitCommit: __GIT_COMMIT__,
}

function parseEnv(host: string): Env {
  switch (host) {
    case "localhost":
      return "local"

    case "staging.huajiwap.cn":
      return "staging"

    case "m.huajiwap.cn":
      return "prod"

    default:
      if (location.port != "") return "local"
      return "prod"
  }
}

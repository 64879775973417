import { useStorage } from "@vueuse/core"
import type { UserInfo } from "./api-generated"
import type { ApiEnv } from "./init"

export default useStorage<{
  token: string
  openID: string
  userInfo?: UserInfo
  uuid: string
  debug: {
    apiEnv: ApiEnv
  }
}>(
  "huaji-wap",
  {
    token: "",
    openID: "",
    userInfo: undefined,
    uuid: "",
    debug: {
      apiEnv: "staging",
    },
  },
  localStorage,
  { mergeDefaults: true }
)

import { EventLogListRequest, EventLogRequest } from "~/api-generated"
import api from "~/api"
import db from "~/db"

let app_id: any

export function initEventCollectors() {
  if (_global.isLocal) return

  app_id = _global.isProd ? 232518 : 234660

  collectEventVolcano("init", {
    app_id: app_id,
    channel: "cn",
    log: true,
    enable_ab_test: true,
    enable_stay_duration: true,
  })
  collectEventVolcano("config", {
    user_unique_id: db.value.userInfo?.user_id,
  })
  collectEventVolcano("start")
}

/**
 * 这是面向业务的收集事件的接口。它的实现会向两个地方发送事件：1. 火山平台（collectEventVolcano）；2. 我们的后端
 */
export function collectEvent(event_key: string, ...event_params: any) {
  if (_global.isLocal) return

  //发送事件到火山
  collectEventVolcano(event_key, ...event_params)

  // 发送事件到后端
  collectEventVolcano("getToken", (token: any) => {
    let ssid
    let web_id
    ssid = token.ssid
    web_id = token.web_id

    if (event_params[0] == null) {
      return
    }

    let eventLogRequest: EventLogRequest = {
      event_time: new Date().toISOString(),
      event_key: event_key,
      event_data: JSON.stringify(event_params[0]),
      user_unique_id: db.value.userInfo?.user_id,
      app_id: app_id,
      ssid: ssid,
      device_id: web_id,
    }
    let eventLogListRequest: EventLogListRequest = {
      events_data: [eventLogRequest],
    }
    api.event.bulkUploadEventLog(eventLogListRequest, {
      _skipError: true,
    })
  })
}

export function collectCampaign202307Event(
  event_key: string,
  event_params: any
) {
  if (_global.isLocal) return
  collectEventVolcano("campaign202307_" + event_key, event_params)
}

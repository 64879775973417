import "./init"
import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import routes from "./routes"
import App from "./App.vue"
import "./main.less"
import { initWx } from "~/logic/wx"
import db from "./db"
import { v4 as uuidv4 } from "uuid"
import "~/logic/auth"
import { logout } from "~/logic/auth"
import initSentry, { reportError } from "./sentry"
import { initEventCollectors } from "~/logic/eventCollector"
import "vant/lib/index.css"

if (!db.value.uuid) {
  db.value.uuid = uuidv4()
}

// js bridge
window.__qm_setToken = function (token: string) {
  if (token.trim().toLowerCase() === "bearer") {
    token = ""
  }
  var oldToken = db.value.token
  if (!token) {
    logout()
    // HUA-1832，如果token发生变化，则强刷一下页面
    if (oldToken) {
      location.reload()
    }
  } else {
    db.value.token = token
    // HUA-1832，如果token发生变化，则强刷一下页面
    if (token !== oldToken) {
      location.reload()
    }
  }
}

window._db = db

const app = createApp(App)
const router = createRouter({
  history: createWebHistory(),
  routes,
})

initSentry(app, router)

router.beforeEach(initWx)
router.beforeEach(() => {
  collectEventVolcano("predefinePageView")
  collectEventVolcano(
    "resetStayDuration",
    location.pathname,
    document.title,
    location.href
  )
})
app.use(router)
app.mount("#app")

window._app = app
window._router = router

window._reportError = reportError

initEventCollectors()

import { watch } from "vue"
import type { UserInfo } from "./api-generated"
import * as Sentry from "@sentry/vue"
import { App } from "vue"
import { Router } from "vue-router"
import db from "./db"

function initSentry(app: App<Element>, router: Router) {
  if (_global.isLocal) return

  Sentry.init({
    app,
    dsn: "https://9f84219657bd69c8919a61c3def0aa96@sentry.qianmo8.com/3",
    environment: _global.env,

    release: _global.gitCommit,

    beforeSend(event, hint) {
      const err = hint.originalException as any

      // 手动标记忽略
      if (typeof err !== "string" && err?.skipSentry) return null

      // 忽略 404, 401
      if (err?.isAxiosError && [404, 401].includes(err?.response?.status))
        return null

      return event
    },
  })

  if (db.value.userInfo != null) {
    configureUser(db.value.userInfo)
  }

  watch(
    () => db.value.userInfo,
    val => {
      if (val != null) {
        configureUser(db.value.userInfo!)
      }
    }
  )
}

function configureUser(userInfo: UserInfo) {
  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: userInfo.user_id?.toString(),
      username: userInfo.nickname,
      extras: JSON.stringify(userInfo),
    })
  })
}

export function reportError(err: Error | string) {
  if (typeof err === "string") {
    err = new Error(err)
  }

  Sentry.captureException(err)
}

export default initSentry

export function configureScope(cb) {
  Sentry.configureScope(cb)
}

import {
  AuthApi,
  DefaultApi,
  ApkgApi,
  WalletApi,
  WeixinApi,
  GroupbuyApi,
  OrderApi,
  ManageUserApi,
  UserApi,
  ShortLinkApi,
  FeedbackApi,
  LetterApi,
  Campaign202111Api,
  Contest202208Api,
  FlagApi,
  EventApi,
  BooksApi,
  LoggingApi,
  HpkgApi,
  PaymentApi,
  ChestApi,
  VipApi,
  CategoryApi,
  AppApi,
} from "./api-generated"
import axios, { AxiosRequestConfig } from "axios"
import db from "./db"
import { Toast } from "vant"
import { tryParseLosslessJSON } from "~/utils"
import { logout, phoneDialogVisible } from "~/logic/auth"

declare module "axios" {
  interface AxiosRequestConfig {
    _skipError?: boolean
    _skip401?: boolean
    _prevent400Msg?: boolean
  }
}

// Axios Config
axios.interceptors.request.use(config => {
  // 以下的接口不需要携带 token
  const ignoreTokenUris = [
    "/auth/v1/signin/send_sms_code",
    "/auth/v1/signin/phone",
  ].some(url => config.url?.includes(url))

  if (db.value.token && !ignoreTokenUris) {
    config.headers.common["authorization"] = db.value.token
  }

  // https://qianmojiaoyu.feishu.cn/docs/doccnB4MC4iizkX4LxCn0CHrA3b
  config.headers.common["X-QM-ViewPage"] = location.pathname
  config.headers.common["X-QM-Device-FingerPrint"] = db.value.uuid

  // 决定source。如果没有query，则根据页面属性来区别
  var source = ""
  const path = _router.currentRoute.value.path
  if (path.startsWith("/vip-order") || path.startsWith("/buy-vip-gift")) {
    source = "c202307"
  } else if (_router.currentRoute.value.name === "invite") {
    source = "pvt_invite"
  } else if (_router.currentRoute.value.name === "squad") {
    source = "squad_invite"
  } else {
    source = "groupbuy"
  }

  config.headers.common["X-QM-H5-Source"] = source
  if (localStorage.getItem("inviter")) {
    config.headers.common["X-QM-Inviter-Id"] = localStorage.getItem("inviter")
  }

  return config
})

axios.interceptors.response.use(
  res => res,
  e => {
    if (e.config == null) {
      return Promise.reject(e)
    }

    const config: AxiosRequestConfig = e.config

    if (config._skipError) {
      return Promise.reject(e)
    }

    if (!e.response) {
      Toast.fail({
        message: `请检查网络连接\n${e.message}\nurl:${e.config.url}`,
        duration: 5000,
      })
    } else {
      switch (e.response.status) {
        case 400:
          if (!config._prevent400Msg) {
            Toast.fail(e.response.data.message)
          }
          break

        case 401:
          if (config._skip401) {
            return Promise.reject(e)
          }

          logout()
          phoneDialogVisible.value = false
          break

        case 404:
          break

        case 500:
          Toast.fail("服务器出错，请刷新重试")
          break

        default:
          Toast.fail(e.response.data.message)
      }
    }

    return Promise.reject(e)
  }
)

axios.defaults.transformResponse = [
  (data, headers) => {
    if (typeof data === "string") {
      return tryParseLosslessJSON(data, data)
    }

    return data
  },
]

const baseURL = _global.apiURL

export default {
  default: new DefaultApi(undefined, baseURL, axios),
  auth: new AuthApi(undefined, baseURL, axios),
  apkg: new ApkgApi(undefined, baseURL, axios),
  wallet: new WalletApi(undefined, baseURL, axios),
  weixin: new WeixinApi(undefined, baseURL, axios),
  groupbuy: new GroupbuyApi(undefined, baseURL, axios),
  order: new OrderApi(undefined, baseURL, axios),
  manager: new ManageUserApi(undefined, baseURL, axios),
  user: new UserApi(undefined, baseURL, axios),
  short_link: new ShortLinkApi(undefined, baseURL, axios),
  feedback: new FeedbackApi(undefined, baseURL, axios),
  letter: new LetterApi(undefined, baseURL, axios),
  campaign202111: new Campaign202111Api(undefined, baseURL, axios),
  contest202208: new Contest202208Api(undefined, baseURL, axios),
  flag: new FlagApi(undefined, baseURL, axios),
  event: new EventApi(undefined, baseURL, axios),
  book: new BooksApi(undefined, baseURL, axios),
  logging: new LoggingApi(undefined, baseURL, axios),
  hpkg: new HpkgApi(undefined, baseURL, axios),
  payment: new PaymentApi(undefined, baseURL, axios),
  chest: new ChestApi(undefined, baseURL, axios),
  vip: new VipApi(undefined, baseURL, axios),
  category: new CategoryApi(undefined, baseURL, axios),
  app: new AppApi(undefined, baseURL, axios),
}

// @ts-ignore
import wx from "weixin-js-sdk"
import api from "~/api"
import db from "~/db"

const APP_ID = "wx729d86d68b8dfb6f"

export const login = async () => {
  const r1 = location.pathname + location.search
  const r2 = `${location.origin}/wx-login-cb?r=${encodeURIComponent(
    r1
  )}&code=__CODE__`
  const r3 = `https://api.qianmoai.com/api/weixin/v1/mp/callback?r=${encodeURIComponent(
    r2
  )}`
  const rx = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${encodeURIComponent(
    r3
  )}&response_type=code&scope=snsapi_userinfo&state=unused#wechat_redirect`

  window.location.href = rx
}

/**
 * 这段代码是检查是否是微信H5环境，如果是，则使用 snsapi_base scope，获得用户的 openId （全程跳转用户无感）
 * 目的是在前端拿到openId，以调用微信支付。这个路径无法作为使用微信登录的路径（第三方登录），需要另行开发。
 */
export const initWx = async () => {
  if (!_global.isWx) return true

  await wxSign()

  // fetch wx openID
  if (db.value.openID || location.pathname === "/go/wxCallback") return true

  const r1 = location.pathname + location.search
  const r2 = `${location.origin}/go/wxCallback?r=${encodeURIComponent(
    r1
  )}&code=__CODE__`
  const r3 = `https://api.qianmoai.com/api/weixin/v1/mp/callback?r=${encodeURIComponent(
    r2
  )}`
  const rx = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${encodeURIComponent(
    r3
  )}&response_type=code&scope=snsapi_base&state=unused#wechat_redirect`

  window.location.href = rx

  return false
}

async function wxSign() {
  if (location.hostname === "localhost" || location.hostname == "127.0.0.1") {
    return
  }

  const res = await api.weixin.mpCreateJsApiSignature({
    url: window.location.href,
  })
  const sig = res.data

  wx.config({
    debug: false,
    appId: APP_ID,
    timestamp: sig.timestamp,
    nonceStr: sig.nonceStr,
    signature: sig.signature,
    jsApiList: [
      "checkJsApi",
      "onMenuShareTimeline",
      "onMenuShareAppMessage",
      "onMenuShareQQ",
      "onMenuShareWeibo",
      "onMenuShareQZone",
      "hideMenuItems",
      "showMenuItems",
      "hideAllNonBaseMenuItem",
      "showAllNonBaseMenuItem",
      "translateVoice",
      "startRecord",
      "stopRecord",
      "onVoiceRecordEnd",
      "playVoice",
      "onVoicePlayEnd",
      "pauseVoice",
      "stopVoice",
      "uploadVoice",
      "downloadVoice",
      "chooseImage",
      "previewImage",
      "uploadImage",
      "downloadImage",
      "getNetworkType",
      "openLocation",
      "getLocation",
      "hideOptionMenu",
      "showOptionMenu",
      "closeWindow",
      "scanQRCode",
      "chooseWXPay",
      "openProductSpecificView",
      "addCard",
      "chooseCard",
      "openCard",
    ],
  })
}

export default [
  { path: "/", component: () => import("~/pages/index.vue") },
  {
    path: "/vip-subscribe",
    component: () => import("~/pages/vip-subscribe.vue"),
  },
  {
    name: "invite",
    path: "/invite/:huaid",
    component: () => import("~/pages/invite.vue"),
  },

  {
    name: "squad",
    path: "/squad/:inviteCode",
    component: () => import("~/pages/squad.vue"),
  },
  { path: "/txc/:productId", component: () => import("~/pages/txc.vue") },
  { path: "/book/:id", component: () => import("~/pages/book-detail.vue") },
  { path: "/buy-vip-gift", component: () => import("~/pages/vip-gift.vue") },
  {
    path: "/vip-order/:orderId",
    component: () => import("~/pages/vip-order.vue"),
  },
  { path: "/page/libraryBook", redirect: "/sale/cm" },
  {
    path: "/book/:bookId/u/:uid",
    component: () => import("~/pages/book-marketing.vue"),
  },
  {
    path: "/book-previewer/:id",
    component: () => import("~/pages/book-detail.vue"),
  },
  {
    path: "/book/:id/groupbuy/:parentInstanceId",
    component: () => import("~/pages/book-detail.vue"),
  },
  { path: "/order/:id", component: () => import("~/pages/order-detail.vue") },
  { path: "/u/:uid", component: () => import("~/pages/user.vue") },
  { path: "/pc", component: () => import("~/pages/pc.vue") },
  { path: "/pc-pay", component: () => import("~/pages/pc-pay.vue") },

  {
    path: "/go/wxCallback",
    component: () => import("~/pages/wx-callback.vue"),
  },

  {
    path: "/go/landing",
    component: () => import("~/pages/go-landing.vue"),
  },

  {
    path: "/wx-login-cb",
    component: () => import("~/pages/wx-login-cb.vue"),
  },

  {
    path: "/qq-login-cb",
    component: () => import("~/pages/qq-login-cb.vue"),
  },

  {
    path: "/czyBinding",
    component: () => import("~/pages/czyBinding.vue"),
  },

  {
    path: "/go/pingppCallback",
    component: () => import("~/pages/pingpp-callback.vue"),
  },

  {
    path: "/book-comments/:id",
    component: () => import("~/pages/book-thanks.vue"),
  },

  { path: "/:catchAll(.*)", component: () => import("~/pages/404.vue") },

  // 机工社图书销售页面 cm --> China Machine
  { path: "/sale/cm", component: () => import("~/pages/sale/cm.vue") },

  {
    path: "/creator/apply",
    component: () => import("~/pages/creator-apply.vue"),
  },

  {
    path: "/creator/apply-status",
    component: () => import("~/pages/creator-apply-status.vue"),
  },
  // 免费领会员活动页面
  {
    path: "/free-vip",
    component: () => import("~/pages/free-vip.vue"),
  },
  // 邀请好友页面
  {
    path: "/invite-friend",
    component: () => import("~/pages/invite-friend.vue"),
  },
]
